import { takeLatest,put,call,all,fork} from 'redux-saga/effects'
import {
    SET_CANCELLATION_POLICY,
    GET_CANCELLATION_POLICY,
    SET_CANCELLATION_POLICY_LOADING,
    LOADING_ACTION,
    CREATE_CANCELLATION_POLICY,
    UPDATE_CANCELLATION_POLICY,
    DELETE_CANCELLATION_POLICY
} from '../actions/action-types';
import { message } from 'antd';

export function* getCancellationPolicyDetails(action){
    try{
        const {
            api,
            url,
            apiPayload
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload=apiResult.payload;
         if(clientPayload.Status=='SUCCESS'){
           let serverPayload=clientPayload.Payload;
           console.log(serverPayload)
            yield put({
                type: SET_CANCELLATION_POLICY,
                payload: serverPayload.cancellationPolicy
            });
          }
        }
    }
    catch(error){
        console.error("Error in getting cancellation",error);
    }
 }

 function* watchGetCancellationPolicySaga () {
    yield takeLatest(GET_CANCELLATION_POLICY,getCancellationPolicyDetails)
 }

 export function* createCancellationPolicy(action){
    try{
        yield put({
            type:LOADING_ACTION,
            payload:true
        })
        const {
            api,
            url ,
            apiPayload,
            onSuccess
            } = {...action.payload};
        const apiResults = yield call(api , url,apiPayload);
        if(apiResults.status=="SUCCESS"){
            let clientPayload = apiResults.payload;
            if(clientPayload.Status=='SUCCESS'){
               message.success(clientPayload.Message);
               onSuccess();
            }else{
              message.error(clientPayload.Message);
            }
          }
          yield put({
            type:LOADING_ACTION,
            payload:false
        })
       }catch(error){
        yield put({
              type:LOADING_ACTION,
              payload:false
          })
            console.error("Error in create cancellation policy",error);
        }  
    }
 
 function* watchCreateCancellationPolicySaga () {
    yield takeLatest(CREATE_CANCELLATION_POLICY,createCancellationPolicy)
 }


 export function* updateCancellationPolicy(action){
    try{
      yield put({
          type:LOADING_ACTION,
          payload:true
      })
        const {
            api,
            url ,
            apiPayload,
            onSuccess
            } = {...action.payload};
            console.log(apiPayload)
        const apiResults = yield call(api , url,apiPayload);
        if(apiResults.status=="SUCCESS"){
            let clientPayload = apiResults.payload;
            if(clientPayload.Status=='SUCCESS'){
               message.success(clientPayload.Message);
               onSuccess();
            }else{
              message.error(clientPayload.Message);
            }
          }
          yield put({
            type:LOADING_ACTION,
            payload:false
        })
       }catch(error){
        yield put({
          type:LOADING_ACTION,
          payload:false
        })
            console.error("Error in update cancellation",error);
        }  
    }
 
 function* watchUpdateCancellationPolicySaga () {
    yield takeLatest(UPDATE_CANCELLATION_POLICY,updateCancellationPolicy)
 }


 export function* deleteCancellationPolicy(action){
    try{
        yield put({
          type:LOADING_ACTION,
          payload:true
      })
        const {
            api,
            url ,
            apiPayload,
            onSuccess
            } = {...action.payload};
        const apiResults = yield call(api , url,apiPayload);
        console.log(apiResults)
        if(apiResults.status=="SUCCESS"){
            let clientPayload = apiResults.payload;
            if(clientPayload.Status=='SUCCESS'){
               message.success(clientPayload.Message);
               onSuccess();
            }else{
              message.error(clientPayload.Message);
            }
          }
          yield put({
            type:LOADING_ACTION,
            payload:false
        })
       }catch(error){
        yield put({
          type:LOADING_ACTION,
          payload:false
      })
            console.error("Error in delete cancellation policy",error);
        }  
    }

    function* watchDeleteCancellationPolicySaga () {
        yield takeLatest(DELETE_CANCELLATION_POLICY,deleteCancellationPolicy)
     }

export function*  watchCancellationPolicyDetailsPage() {
    yield all([
        fork(watchGetCancellationPolicySaga),
        fork(watchCreateCancellationPolicySaga),
        fork(watchUpdateCancellationPolicySaga),
        fork(watchDeleteCancellationPolicySaga)
    ])
}