/**
 * @author Sanjay
 * @description Reducer for the components in home page
 */
import {
    SET_PRIVILLEGE_INFO,
    RESET_ALL  } from '../actions/action-types';
  
  const initialState = {
      userPrivilleges: []
  }
  
  export const userPrivillegeReducer = (state = initialState,action) => {
      let newState = Object.assign({},state);
      switch (action.type) {
            
        case SET_PRIVILLEGE_INFO:
              newState["userPrivilleges"] = action.payload;
              return newState;

        case RESET_ALL :
              newState["userPrivilleges"] = [];
              return newState;
  
          default:
              return state;
      }
  }
  