import React ,{useState , useEffect}  from 'react';
import { Row, Col , Typography , Select , Button , DatePicker } from 'antd';
import {
   LeftOutlined,
   RightOutlined
  } from '@ant-design/icons';
import { incrementDays , decrementDays} from '../../../utils/dates-util';
import{ onFilteredChangeCustom } from './price-list-header.module';

const { Title } = Typography;
const { Option } = Select;
const { Text } = Typography;
const dateFormat = 'YYYY-MM-DD';
const { MonthPicker } = DatePicker;

export const PriceListHeader = (props) => {

    const {
        selectedDate,
        setDate,
        unFilteredData,
        setFilteredData
    } = props;


    const [origin,setOrigin]=useState('all');
    const [destination,setDestination]=useState('all');
    const [originsData,seOriginsData]=useState([]);
    const [destinationsData,setDestinationsData] =useState([]);
  
    useEffect(()=>{
        let {
          destinations,origins,filter_data
        } = onFilteredChangeCustom(origin,destination,unFilteredData);
        setFilteredData(filter_data);
      seOriginsData(origins);
      setDestinationsData(destinations);
    },[unFilteredData])
    useEffect(()=>{
      let {
          filter_data
      } = onFilteredChangeCustom(origin,destination,unFilteredData);
      setFilteredData(filter_data);
  },[origin,destination])


    const printOption = (option) => {
        return <Option key={option} value={option}>{option}</Option>;
      };

    return (
        <Row justify="end"> 
            <Col span={12}>
                    <div>
                        <Row gutter={[0, 16]}>
                                    <Col>
                                    <Text>Origin</Text>
                                    </Col>
                                    <Col offset={1}>
                                        <Select 
                                         showSearch
                                         placeholder="Select Origin" 
                                         style={{width:200}}
                                         value={origin}
                                         optionFilterProp="children"
                                         onChange={(value)=>{
                                              setOrigin(value)
                                          }}>
                                                    <Option key={"all"} value={"all"}>all</Option>
                                                    {originsData.map(printOption)}
                                        </Select>
                                    </Col>
                                    <Col offset={1}>
                                                <Text>Destination</Text>
                                    </Col>
                                    <Col offset={1}>
                                                <Select 
                                                showSearch 
                                                placeholder="Select Destination"
                                                style={{width:200}}
                                                value={destination}
                                                optionFilterProp="children"
                                                onChange={(value)=>{
                                                     setDestination(value)
                                                }}>
                                                    <Option key={"all"} value={"all"}>all</Option>
                                                    {destinationsData.map(printOption)}
                                                </Select>
                                    </Col>
                            </Row>

                    </div>
            </Col>
          
            <Col>
                    <Button type="" 
                    onClick={()=>{
                                setDate(decrementDays(selectedDate,2));
                    }}>
                                <LeftOutlined />
                    </Button>
                     <DatePicker
                      onChange={(date)=>{
                        setDate(date);
                      }}
                      value={selectedDate}
                      />
                    <Button type="" 
                    onClick={()=>{
                                    setDate(incrementDays(selectedDate,2));
                            }}>
                        <RightOutlined />
                    </Button>
            </Col>
        </Row>
    );
}
