/**
 * @author Rejo Varghese
 * @description Admin driver mapping related action
 */

import { GET_DRIVER_MANAGEMENT_BY_OPERATOR, GET_ALL_ADMIN_DRIVER, ADD_ADMIN_DRIVER } from './action-types';
import { GET_DRIVER_MANAGEMENT_BY_OPERATOR_URL, ADD_ADMIN_DRIVER_URL, GET_ALL_ADMIN_DRIVER_URL } from "../utils/api-url";
import { getCall, postCall } from "../utils/network.util";


export const getDriverManagementByOperator= (operatorCode) => {
    return {
        type: GET_DRIVER_MANAGEMENT_BY_OPERATOR,
        payload: {
            url: GET_DRIVER_MANAGEMENT_BY_OPERATOR_URL,
            api: postCall,
            apiPayload: {
                operatorCode
            }
        }
    }
}

export const getAllAdminDriver = () => {
    return {
        type: GET_ALL_ADMIN_DRIVER,
        payload: {
            url: GET_ALL_ADMIN_DRIVER_URL,
            api: postCall,
            apiPayload: {}
        }
    }
}


export const addAdminDriverAction = (adminData, onSuccess) => {
    return {
        type: ADD_ADMIN_DRIVER,
        payload: {
            url: ADD_ADMIN_DRIVER_URL,
            api: postCall,
            apiPayload:{
                adminData: adminData
            },
            onSuccess:onSuccess
        }
    }
}
