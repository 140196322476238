import React, { useEffect, useState }  from 'react';
import { Table } from 'antd';
import { getOriginsDestinations , getTripColumns} from './edit-trip-table.module';
import './edit-trip-table.style.scss';
import { setSelectedTrip ,getRoutesByTripId } from '../../../actions/trip-actions';
import { useDispatch } from 'react-redux';

export const EditTripsTable = (props) => {

    const {
        trips,
        tripsLoading,
        updateformData,
        formData
    }=props;

    const dispatch = useDispatch();
    const [origins,setOrigins] = useState([]);
    const [destinations,setDestinations] = useState([]);

    useEffect(()=>{
    let {
        originsInfo,
        destinationsInfo
    } = getOriginsDestinations(trips)
    setOrigins(originsInfo);
    setDestinations(destinationsInfo);
    },[trips])

    return (
        <div>
                    <Table
                   dataSource={trips!=undefined?trips:[]}
                   columns={getTripColumns(origins,destinations)}
                   pagination={{ pageSize: 5 }}
                   loading={tripsLoading}
                   rowKey={record => record.id}
                   onRow={(record, rowIndex) => {
                      return {
                        onClick: event => {
                          updateformData(record);
                          dispatch(setSelectedTrip(record));
                          dispatch(getRoutesByTripId(record.id));
                        }, // click row
                        onDoubleClick: event => {

                        }, // double click row
                        onContextMenu: event => {

                        }, // right button click row
                        onMouseEnter: event => {

                        }, // mouse enter row
                        onMouseLeave: event => {

                        }, // mouse leave row
                      };
                    }}
                    rowClassName={(record, index)=>{
                        let rowClassName="";
                            if(record.today){
                                rowClassName = "activeRow" ;
                            }else{
                                rowClassName = "inactiveRow";
                            }
                            if(record.id== formData.id){
                                rowClassName=rowClassName+" TableRowClicked"
                            }
                            
                            return  rowClassName
                    }}
                    />            

        </div>
    );
}
