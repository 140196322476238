import React , {useState} from 'react';
import { Button, Modal, Row ,Form , Col } from 'antd';
import { ScreenOne } from './screen-one';
import { ScreenTwo } from './screen-two';
import { BeforeScreenOne } from './before-screen-one';
import { ScreenThree } from './screen-three';
import { updateRouteTimings , getCitiesInfo} from './change-routes-timings.module';
import { useDispatch } from 'react-redux';
import { getRoutesByTripId , getTrips} from './../../actions/trip-actions';

let intialScreen = "intial";
let allCities = "allcities";
let sameTimeForAllRoutes = "same";
let differentTimeForEachRoute="different";
let editDifferentRoutes = "EditTimings";

export const ChangeRoutesTimingsComponent = (props) => {


    const {
        selectedRoutes,
        tripId
    } = props;

    const [screenName,setScreenaName] = useState(intialScreen);
    const [isClearExistingDaySpecificTimings,setClearExistingDaySpecificTimings] = useState(false);

    const [rowKeys,setRowKeys] = useState([]);
    let [cities,setCities] = useState([]);
    const dispatch = useDispatch();

    const onSuccess=()=>{
        dispatch(getRoutesByTripId(tripId));
        dispatch(getTrips(global.operator_id));
        handleCancel();
    }

    const [screenOneForm] = Form.useForm();
    const [screenTwoForm] = Form.useForm();
    const [beforScreenOneForm] = Form.useForm();

    const decreaseScreenNumber=()=>{
        if(screenName===intialScreen){
            handleCancel();
        }else if (screenName === allCities){
            setScreenaName(intialScreen);
        }
        else if(screenName===sameTimeForAllRoutes||screenName===differentTimeForEachRoute){
            setScreenaName(allCities);
        }else if(screenName===editDifferentRoutes){
            setScreenaName(differentTimeForEachRoute);
        }
    }
  
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setScreenaName(intialScreen);
        screenOneForm.resetFields();
        screenTwoForm.resetFields();
        beforScreenOneForm.resetFields();
    };


    const onSubmit=(e)=>{
        e.preventDefault();
        if(screenName==intialScreen){
            beforScreenOneForm.validateFields()
            .then(values => {
                let {
                    clearExistingDaySpecificTimings
                } = values;
                setScreenaName(allCities);
                setClearExistingDaySpecificTimings(clearExistingDaySpecificTimings)
            })
            .catch(errorInfo => {
                console.log({errorInfo});
            })
        }else if(screenName == allCities){
            screenOneForm.validateFields()
            .then(values => {
                let {
                    isAllCities
                }= values;
                if(isAllCities){
                    setScreenaName(sameTimeForAllRoutes);
                }else{
                    setScreenaName(differentTimeForEachRoute);
                }
            })
            .catch(errorInfo => {
                console.log({errorInfo});
            })
        }
        else if(screenName==differentTimeForEachRoute){
            setScreenaName(editDifferentRoutes);
        }else{
            let citiesInfo = cities;
            if(screenName===sameTimeForAllRoutes){
                screenTwoForm.validateFields()
                .then(values=>{
                    let {
                        newStartTiming
                    } = values;
                     citiesInfo = getCitiesInfo(selectedRoutes,newStartTiming);
                  updateRouteTimings(citiesInfo,tripId,true,onSuccess,isClearExistingDaySpecificTimings);
                })
            }else{
               updateRouteTimings(citiesInfo,tripId,false,onSuccess,isClearExistingDaySpecificTimings);
            }
        }
    }

  return (
      <div>
            <Row justify="center" align="middle">
                <Button type="primary" onClick={showModal}>
                    Change route timings
                </Button>
            </Row>
          <Modal title="Change Time" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
          { screenName===intialScreen&&
                <BeforeScreenOne 
                beforScreenOneForm={beforScreenOneForm}/>
             }
             {screenName===allCities&&
                <ScreenOne 
                screenOneForm={screenOneForm}/>
             }
             {screenName===sameTimeForAllRoutes&&
                <ScreenTwo 
                screenTwoForm={screenTwoForm} />
             }
             {screenName===differentTimeForEachRoute&&
                <ScreenThree 
                selectedRoutes={selectedRoutes}
                isEditTimings={false}
                isSelectRoutes={true}
                rowKeys={rowKeys}
                setRowKeys={setRowKeys}
                cities={cities}
                setCities={setCities}
                tripId={tripId}
                />
             }
             {screenName==editDifferentRoutes&&
                    <ScreenThree 
                    selectedRoutes={selectedRoutes}
                    isEditTimings={true}
                    isSelectRoutes={false}
                    rowKeys={rowKeys}
                    setRowKeys={setRowKeys}
                    cities={cities}
                    setCities={setCities}
                    tripId={tripId}
                    />
             }
             <Row gutter={[16,16]}>
                 <Col>
                    <Button onClick={decreaseScreenNumber}>{screenName==intialScreen?"Exit":"Back"}</Button>
                 </Col>
                 <Col>
                    <Button onClick={onSubmit} type="primary">{screenName==editDifferentRoutes||screenName==sameTimeForAllRoutes?"Update":"Continue"}</Button>
                 </Col>
             </Row>
          </Modal>
      </div>
  );
};
