/**
 * Util methods for math operations
 * @author Sanjay
 */

const roundNumber = (num) => Math.round((num + Number.EPSILON) * 100) / 100

const trancateNumber = (num) =>  Math.trunc(num*100)/100;

const addFloatingPoints = (a,b) =>{
    // if (isWholeNumber(a)||isWholeNumber(b)){
    //     return (a+b);
    // }
    let intA = Math.round(a * 100);
    let intB = Math.round(b * 100);
    let intC = intA + intB;
    return (intC/100);
}

const subtractFloatingPoints = (a,b) => {
    // if (isWholeNumber(a)||isWholeNumber(b)){
    //     return (a-b);
    // }
    let intA = Math.round(a * 100);
    let intB = Math.round(b * 100);
    let intC = intA - intB;
    return (intC/100);
}

const multiplyFloatingPoints = (a,b) => {
    let intA = Math.round(a * 100);
    let intB = Math.round(b * 100);
    let intC = intA * intB;
    return (intC/10000);
}

const divideFloatingPoints = (a,b) => {
    let intA = Math.round(a * 100);
    let intB = Math.round(b * 100);
    let intC = intA / intB;
    return roundNumber(intC);
}

const isWholeNumber = (n) => {
   var result = (n - Math.floor(n)) !== 0;
  if (result){
    return false;
  }
   else{
    return true;
   }
}

module.exports = {
    roundNumber : roundNumber,
    addFloatingPoints: addFloatingPoints,
    subtractFloatingPoints: subtractFloatingPoints,
    multiplyFloatingPoints: multiplyFloatingPoints,
    divideFloatingPoints:divideFloatingPoints,
    trancateNumber:trancateNumber
}
