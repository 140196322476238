import React, {  useEffect, useState } from 'react';
import { Row, Col,Table , Typography , Layout , InputNumber  } from 'antd';
const { Title } = Typography;
const { Text } = Typography;
const { Header, Content, Footer, Sider } = Layout;

export const ExpanderRoutesTable = (props) => {

    const {
        data,
        onChangeTripRouteIds
    }=props;

    const [formatedRouteData  , setFormatedRouteData] = useState([]);

    useEffect(()=>{
        formatedDataForExtendedTable();
    },[]);

   const formatedDataForExtendedTable = ()=>{
        let formatedData=[]
        let routesData=data.routesData;
        routesData.forEach((route)=>{
          let row={description:route.origin+"-"+route.destination};
          for (var key in data) {
            let trip=data[key];
            let tripRoutes=trip.tripRoutes;
            if(tripRoutes!=undefined){
            tripRoutes.forEach((tripRoute)=>{
                if(tripRoute.route_id==route.id){
                  if(row[key]==undefined){
                    row[key]={};
                  }
                  row[key]=tripRoute;
                }
              })
            }
          }
          formatedData.push(row);
        })
        setFormatedRouteData(formatedData);
      }

     const renderComponentTable=({record,rowIndex ,viewIndex,dataIndex})=>{
        return {
          props: {
            className:record!=undefined&&record['isImportant']==1?"holiday":"",
          },
         children:  <span>
                        {record!=undefined&&record['fare']!=undefined&&
                        <Row type="flex" justify='space-between' >
                          <Col span={11}>
                              <InputNumber value={record['fare']}
                                           onChange={(value)=>{
                                               formatedRouteData[viewIndex][dataIndex]['fare']=value;
                                               setFormatedRouteData([...formatedRouteData]);
                                               let tripRoute = formatedRouteData[viewIndex][dataIndex];
                                               onChangeTripRouteIds(tripRoute);
                                          }}
                              />
                          </Col>
                        </Row>
                        }
                        {record==undefined&&
                        <Row type="flex" justify='center' >
                          <Col>
      
                          </Col>
                        </Row>
                        }
                     </span>,
        };
      }

      const columns = [
        {
          title: 'Description',
          dataIndex: 'description',
          ellipsis: true,
          align:'center'
        },
        {
          title: 'fare',
          dataIndex: '0',
          ellipsis: true,
          align:'center',
          render: (record,rowIndex ,viewIndex) => {
                  let dataIndex=0;
                  return renderComponentTable({record,rowIndex ,viewIndex,dataIndex});
            },
        },
        {
          title: 'fare',
          dataIndex: '1',
          ellipsis: true,
          align:'center',
          render: (record,rowIndex ,viewIndex) => {
                  let dataIndex=1;
                  return renderComponentTable({record,rowIndex ,viewIndex,dataIndex});
            },
        },
        {
          title: 'fare',
          dataIndex: '2',
          ellipsis: true,
          align:'center',
          render: (record,rowIndex ,viewIndex) => {
                  let dataIndex=2;
                  return renderComponentTable({record,rowIndex ,viewIndex,dataIndex});
            },
        },
        {
          title: 'fare',
          dataIndex: '3',
          ellipsis: true,
          align:'center',
          render: (record,rowIndex ,viewIndex) => {
                  let dataIndex=3;
                  return renderComponentTable({record,rowIndex ,viewIndex,dataIndex});
            },
        },
        {
          title: 'fare',
          dataIndex: '4',
          ellipsis: true,
          align:'center',
          render: (record,rowIndex ,viewIndex) => {
                  let dataIndex=4;
                  return renderComponentTable({record,rowIndex ,viewIndex,dataIndex});
            },
        },
        {
          title: 'fare',
          dataIndex: '5',
          ellipsis: true,
          align:'center',
          render: (record,rowIndex ,viewIndex) => {
                  let dataIndex=5;
                  return renderComponentTable({record,rowIndex ,viewIndex,dataIndex});
            },
        },
        {
          title: 'fare',
          dataIndex: '6',
          ellipsis: true,
          align:'center',
          render: (record,rowIndex ,viewIndex) => {
                  let dataIndex=6;
                  return renderComponentTable({record,rowIndex ,viewIndex,dataIndex});
            },
        }
      ];
  
    return (
        <div>
                <Table
                className={'extenderTable'}
                dataSource={formatedRouteData}
                columns={columns}
                pagination={false}
                showHeader={false}
                />
        </div>
    );
}
