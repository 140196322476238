import { Form , Input, Button , Select , Typography , Layout, Row  } from 'antd';
import React, { useEffect }  from 'react';
import { useDispatch } from 'react-redux';
import { isValid  } from '../../../utils/utilities';
import { showConfirm } from '../../../utils/utilities';
import './vechicle-management-form.style.scss';
import {createVechicle , deleteVechicle , getVechicles} from '../../../actions/vechicle-actions';



const { Option } = Select;
const { TextArea } = Input;

const { Header, Content, Footer } = Layout;
const { Title , Text } = Typography;

export const  VechicleManagementForm = (props) => {

  const [form] = Form.useForm();
  const dispatch = useDispatch();

      const{
        formData,
        updateFormData,
        busesData       
      } = {...props};
   

      const getVechiclesAction=()=>{
        dispatch(getVechicles());
      }
      
      const onSuccess=()=>{
        getVechiclesAction();
        onReset();
      }
      
      const onReset = () => {
        updateFormData({});
      };

 const handleSubmit = (e,buttonType) => {
    e.preventDefault();
    form.validateFields()
    .then(values => {
       dispatch(createVechicle({...values},onSuccess));
    })
    .catch(errorInfo => {
      console.log({errorInfo});
    })
 };


 useEffect(()=>{
    fillForm()
 },[formData])

 const fillForm=()=>{
      if(isValid(formData)){
        let {
          bus_id,
          vechicle_no
        }=formData;
          form.setFieldsValue({
            bus_id: bus_id,
            vechicle_no: vechicle_no
          })
      }else{
        onReset();
      }
 }


const deleteAction=()=>{
  showConfirm(
      ()=>{
          dispatch(deleteVechicle(formData.id,onSuccess));
      },
      '',
      ''
    )
}

  let isRowClicked = isValid(formData)&&isValid(formData.id)?true:false;

  const printOption = (option) => {
    return <Option  value={option.id} data={option}>{option.description}</Option>
  };

    return (
      <Layout>
            <Title level={4}>Vehicle Management Form</Title>
              <Content style={{ margin:'10px'}} className='vechicle-content'>
                        <Form 
                            className="form"
                             form={form} 
                             name="basic"
                             initialValues={{ }}
                        >
                            <Row>
                                        <Form.Item
                                                label="Vechicle No"
                                                name="vechicle_no"
                                                rules={[{ required: true, message: 'Please input your name!' }]}
                                            >
                                                <Input 
                                                placeholder="Name"
                                                />
                                        </Form.Item>
                                        <Form.Item
                                                label="Bus description"
                                                name="bus_id"
                                                rules={[{ required: true, message: 'Please input your type!' }]}
                                            >
                                                  <Select
                                                      showSearch
                                                      style={{ width: 200 }}
                                                     placeholder="Select bus"
                                                  >
                                                    {busesData.map(printOption)}
                                                </Select>
                                        </Form.Item>
                                </Row>
                  </Form>
              </Content>
              <Footer style={{ textAlign: 'center' }} type="flex">
              <Row type="flex" justify="space-around">
                      <Button
                        style={{margin:'5px',background:'#2EC17C'}}
                        disabled={isRowClicked}
                        onClick={(e)=>{
                        handleSubmit(e);
                        }}>
                       <Text>Add</Text>
                      </Button>
                      {/* <Button type="primary"
                        style={{margin:'5px'}}
                        disabled={!isRowClicked}
                       onClick={(e)=>{
                       //handleSubmit(e,'update');
                       }}>
                        Update
                      </Button> */}
                        <Button type="danger"
                        style={{margin:'5px'}}
                        disabled={!isRowClicked}
                        onClick={deleteAction}>
                        Delete
                     </Button>
                      <Button type="block"
                        style={{margin:'5px'}}
                        onClick={()=>{
                          updateFormData({});
                        }}>
                        Clear
                      </Button>
                  </Row>
              </Footer>
      </Layout>
    );
}
