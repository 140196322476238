export const onFilteredChangeCustom=(origin,destination,data)=>{
    let filter_data=[],origins=[],destinations=[];
    
    data.forEach((row)=>{
        if(origins.indexOf(row.origin)==-1){
            origins.push(row.origin);
          }
          if(destinations.indexOf(row.destination)==-1){
            destinations.push(row.destination);
          }
       if((origin=='all'||origin==row.origin)&&(destination=='all'||row.destination==destination)){
         filter_data.push(row);
       }
    })
    return {destinations,origins,filter_data};
  }
