 import { 
    SET_CANCELLATION_POLICY,
    RESET_ALL , 
    SET_CANCELLATION_POLICY_LOADING ,
   } from '../actions/action-types';

const initialState = {
    cancellationPolicy: [],
} 

export const cancellationPolicyReducer = (state = initialState,action) => {
    let newState = Object.assign({},state);
    switch (action.type) {
    
        case SET_CANCELLATION_POLICY:
            newState["cancellationPolicy"] = action.payload;
            return newState;

        default:
            return state;
    }
}
