/**
 * @author Sanjay
 * @description Reducer for the components in home page
 */
 import {
    SET_SEAT_GROUP_IDS,
    RESET_ALL 
  } from '../actions/action-types';
  
  const initialState = {
    seatGroupIds:[]
  }
  
  export const seatGroupsReducer = (state = initialState,action) => {
      let newState = Object.assign({},state);
      switch (action.type) {
  
          case SET_SEAT_GROUP_IDS:
              newState["seatGroupIds"] = action.payload;
              return newState;

        case RESET_ALL :
            newState["seatGroupIds"] = [];
            return newState;
  
          default:
              return state;
      }
  }
  