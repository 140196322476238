import { Form , Input, Button  , Typography , Layout, Row , Select} from 'antd';
import React, { useEffect }  from 'react';
import { useDispatch , useSelector} from 'react-redux';
import { 
  insertLocationDetailsAction,
  updateLocationDetailsAction,
  deleteLocationDetailsAction,
  getLocationDetailsAction
 } from '../../actions/locations-actions';
 import {
  locationReducerSelector
 } from '../../selectors/location-selectors';
 import {
  capitalizeFirstLetterOfEachWord,
  showConfirm 
 } from '../../utils/utilities';
 import { getMasterCities } from '../../actions/locations-actions';
import { isValid } from 'date-fns';

const {  Content, Footer } = Layout;
const { Title , Text } = Typography;
const { Option } = Select;

export const  LocationForm = (props) => {

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const {
    addLoading,
    updateLoading,
    deleteLoading,
    masterCities
  } = useSelector(locationReducerSelector);

  const getMasterCitiesAction=()=>{
    dispatch(getMasterCities());
  }
  useEffect(()=>{
    getMasterCitiesAction();
  },[])

    const{
        formData,
        updateformData,
        isFromAddress=false    
      } = {...props};
   

 const handleSubmit = (e,buttonType) => {
    e.preventDefault();
    form.validateFields()
    .then(values => {
      if(buttonType=='add'){
        dispatch(insertLocationDetailsAction(values,onSuccess));
      }else if(buttonType=='update'){
        let cities ={
        id : formData.id,
        latitude : values.latitude,
        longitude : values.longitude
      }
      dispatch(updateLocationDetailsAction({...cities},onSuccess));
      }
    })
    .catch(errorInfo => {
      console.log({errorInfo});
    })
 };

 const onSuccess = ()=>{
  updateformData({});
  dispatch(getLocationDetailsAction())
}


useEffect(()=>{
    form.setFieldsValue({
      name : formData.name,
      latitude : formData.latitude,
      longitude : formData.longitude
    })
},[formData]);

const onFinishFailed=(error)=>{
  console.log({error});
}

const deleteLocationAction = ()=>{
  dispatch(deleteLocationDetailsAction(formData.id,onSuccess));
}

const printOption = (option) => {
  return <Option  value={option.city_name} data={option}>{option.city_name}</Option>
};
    return (
      <Layout>
            <Title level={4}>Cities Form</Title>
              <Content className='form'>
                        <Form layout="inline" 
                                 form={form}
                                 name="basic"
                                 initialValues={{}}
                                 onFinish={handleSubmit}
                                 onFinishFailed={onFinishFailed}
                        >
                                <Form.Item
                                        label="Location Name"
                                        name="name"
                                        rules={[{ required: true, message: 'Please input your location name!' }]}
                                      >
                                       <Select
                                          showSearch
                                          placeholder="Select city"
                                          onChange={(value,info)=>{
                                            let{
                                              latitude,longitude
                                            } = info.data;
                                            form.setFieldsValue({
                                              latitude,
                                              longitude
                                            });
                                          }}
                                          style={{width:300}}
                                          disabled={isValid(formData.id)?true:false}
                                          >
                                       {masterCities.map(printOption)}
                                       </Select>
                                </Form.Item>
                                <Form.Item
                                        label="Latitude"
                                        name="latitude"
                                        rules={[{ required: false, message: 'Please input your latitude name!' }]}
                                      >
                                        <Input 
                                          placeholder="Latitude"
                                        />
                                </Form.Item>
                                <Form.Item
                                        label="Longitude"
                                        name="longitude"
                                        rules={[{ required: false, message: 'Please input your longitude name!' }]}
                                      >
                                        <Input 
                                          placeholder="Longitude"
                                        />
                                </Form.Item>
                  </Form>
              </Content>
              <Footer style={{ textAlign: 'center' }} type="flex">
              <Row type="flex" justify="space-around">
                      <Button
                        loading={addLoading}
                        style={{margin:'5px',background:'#2EC17C'}}
                        disabled={formData.id==undefined?false:true}
                        onClick={(e)=>{
                         handleSubmit(e,'add');
                        }}>
                        <Text>Add</Text>
                      </Button>
                      {!isFromAddress&&
                          <Button type="primary"
                          loading={updateLoading}
                          style={{margin:'5px'}}
                          disabled={formData.id==undefined?true:false}
                          onClick={(e)=>{
                          handleSubmit(e,'update');
                          }}>
                            Update
                          </Button>
                      }
                      {!isFromAddress&&
                        <Button type="danger"
                          loading={deleteLoading}
                          style={{margin:'5px'}}
                          disabled={formData.id==undefined?true:false}
                          onClick={()=>{
                            showConfirm(deleteLocationAction,'');
                          }}>
                          Delete
                        </Button>
                      }  
                      {!isFromAddress&&
                        <Button type="block"
                          style={{margin:'5px'}}
                          onClick={()=>{
                              updateformData({});
                          }}>
                          Clear
                        </Button>
                      }
                  </Row>
              </Footer>
      </Layout>
    );
}


export const WrappedLocationForm = LocationForm;
