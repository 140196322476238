/**
 * All sagas for Agents Saga must be defined here
 * @author Manne Hareesh
 */

import { takeLatest,put,call,all,fork } from 'redux-saga/effects'
import { 
    CREATE_AGENT,
    GET_AGENTS,
    SET_AGENTS,
    UPDATE_AGENT,
    DELETE_AGENT,
    GET_AGENT_PRIVILEGES,
    SET_AGENT_PRIVILLEGES,
    LOADING_ACTION
} from '../actions/action-types';
import { message } from 'antd';

/**
 * 
 * @param { get agents} action 
 */

export function* getAgentsDetails(action){
    try{
        const {
            api,
            url,
            apiPayload
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload=apiResult.payload;
         if(clientPayload.Status=='SUCCESS'){
           let serverPayload=clientPayload.Payload;
            yield put({
                type: SET_AGENTS,
                payload: serverPayload.agents
            });
          }
        }
    }
    catch(error){
        console.error("Error in getting agents",error);
    }
 }

 function* watchGetAgentsSaga () {
    yield takeLatest(GET_AGENTS,getAgentsDetails)
 }
 
 export function* createAgent(action){
    try{
        yield put({
            type:LOADING_ACTION,
            payload:true
        })
        const {
            api,
            url ,
            apiPayload,
            onSuccess
            } = {...action.payload};
        const apiResults = yield call(api , url,apiPayload);
        if(apiResults.status=="SUCCESS"){
            let clientPayload = apiResults.payload;
            if(clientPayload.Status=='SUCCESS'){
               message.success(clientPayload.Message);
               onSuccess();
            }else{
              message.error(clientPayload.Message);
            }
          }
          yield put({
            type:LOADING_ACTION,
            payload:false
        })
       }catch(error){
        yield put({
              type:LOADING_ACTION,
              payload:false
          })
            console.error("Error in create agent",error);
        }  
    }
 
 function* watchCreateAgentSaga () {
    yield takeLatest(CREATE_AGENT,createAgent)
 }

 export function* updateAgent(action){
    try{
      yield put({
          type:LOADING_ACTION,
          payload:true
      })
        const {
            api,
            url ,
            apiPayload,
            onSuccess
            } = {...action.payload};
        const apiResults = yield call(api , url,apiPayload);
        if(apiResults.status=="SUCCESS"){
            let clientPayload = apiResults.payload;
            if(clientPayload.Status=='SUCCESS'){
               message.success(clientPayload.Message);
               onSuccess();
            }else{
              message.error(clientPayload.Message);
            }
          }
          yield put({
            type:LOADING_ACTION,
            payload:false
        })
       }catch(error){
        yield put({
          type:LOADING_ACTION,
          payload:false
        })
            console.error("Error in update agent",error);
        }  
    }
 
 function* watchUpdateAgentSaga () {
    yield takeLatest(UPDATE_AGENT,updateAgent)
 }

 export function* deleteAgent(action){
    try{
        yield put({
          type:LOADING_ACTION,
          payload:true
      })
        const {
            api,
            url ,
            apiPayload,
            onSuccess
            } = {...action.payload};
        const apiResults = yield call(api , url,apiPayload);
        if(apiResults.status=="SUCCESS"){
            let clientPayload = apiResults.payload;
            if(clientPayload.Status=='SUCCESS'){
               message.success(clientPayload.Message);
               onSuccess();
            }else{
              message.error(clientPayload.Message);
            }
          }
          yield put({
            type:LOADING_ACTION,
            payload:false
        })
       }catch(error){
        yield put({
          type:LOADING_ACTION,
          payload:false
      })
            console.error("Error in delete agent",error);
        }  
    }



/**
 *
 * @param {Get agent privileges Details} action
 */
 export function* getAgentPrivileges(action) {
    try{
        const {
            api,
            url
        } = {...action.payload};
        const apiResult = yield call(api,url);
        if(apiResult.status=="SUCCESS"){
          let clientPayload = apiResult.payload;
          if(clientPayload.Status=='SUCCESS'){
            let serverPayload = clientPayload.Payload
            yield put({
                type: SET_AGENT_PRIVILLEGES,
                payload: serverPayload
            });
          }else{
            message.error(clientPayload.Message);
          }
        }
    }
    catch(error){
        console.error("Error in getting agent priviliges",error);
    }
  }
  
 
 function* watchDeleteAgentSaga () {
    yield takeLatest(DELETE_AGENT,deleteAgent)
 }

 function* watchGetAgentPrivillegeSaga (){
    yield takeLatest(GET_AGENT_PRIVILEGES,getAgentPrivileges);
  }

export function*  watchAgentsDetailsPage() {
    yield all([
        fork(watchGetAgentsSaga),
        fork(watchCreateAgentSaga),
        fork(watchUpdateAgentSaga),
        fork(watchDeleteAgentSaga),
        fork(watchGetAgentPrivillegeSaga)
    ])
}