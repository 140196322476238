/**
 * @author Manne Hareesh
 * @description Reducer for the components in home page
 */
 import {
     LOADING_ACTION,
     RESET_ALL  } from '../actions/action-types';
  
  const initialState = {
      loadingActionRunning: false
  }
  
  export const loadingReducer = (state = initialState,action) => {
      let newState = Object.assign({},state);
      switch (action.type) {
          
         case LOADING_ACTION:
             newState["loadingActionRunning"] = action.payload;
             return newState;

        case RESET_ALL :
              newState["loadingActionRunning"] = false;
              return newState;
  
          default:
              return state;
      }
  }
  