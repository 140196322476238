import React from 'react';
import "../assets/styles/main.scss";
import { Typography } from 'antd';
import { locationReducerSelector } from "../selectors/location-selectors";
import { connect } from 'react-redux';
import { EditTripsRoot } from '../components/edit-trips-component/edit-trips-root-component/edit-trips-root.component';
import { VerifyPagePermission } from '../components/verify-page-permission-component/verify-page-permission.component';
import Permissions from "../utils/permissions-constant";

const { Title } = Typography;

export const Trips = (props) => {

  const renderChildren=  <div style={{position:'relative',top:'10px'  , margin: '20px' }}>
                  <Title level={4}>Trip Details</Title>
                  <EditTripsRoot />
                </div>

    return (
      <VerifyPagePermission 
            pagePermission={Permissions.pagePermission.ADMIN_VIEW_TRIPS_PAGE}
            renderChildren={renderChildren}
            onPermissionSuccess={()=>{

            }}
            />
    );
}



const mapReduxStateToProps = reduxState => {
    return {
      locationReducer : locationReducerSelector(reduxState)
    }
}

const mapReduxActionsToProps = dispatch => {
return {
  }
}

export const TripsPage = connect(mapReduxStateToProps,
    mapReduxActionsToProps)(Trips);
