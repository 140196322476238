import React , {useEffect,useState} from 'react';
import { Typography , Steps } from 'antd';
import {TripStep} from './trip-step-1';
import './result.component.scss';
import {RouteStep} from './trip-step-2';
import { BoardingPointComponent } from './boarding-points/boarding-points.component';
import { DropPointComponent } from './drop-points/drop-points.component';
import { isValid } from '../../utils/utilities';
import { getLocationDetailsAction } from '../../actions/locations-actions';
import { getBusDetailsAction } from '../../actions/bus-actions';
import { locationReducerSelector } from "../../selectors/location-selectors";
import { tripReducerSelector } from "../../selectors/trip-selectors";
import { useSelector  , useDispatch } from 'react-redux';
import { getTripById , clearSelectedTrip , getRoutesByTripId} from '../../actions/trip-actions';
import { useNavigate } from "react-router-dom";
import { pageRedirect} from '../../utils/utilities';




const { Title } = Typography;
const { Step } = Steps;
const { Text } = Typography;

export const TripHomeComponent = (props) => {

  const history = useNavigate();

  const[tripFormData,setTripFormData] = useState({});
  let locationReducer=useSelector(locationReducerSelector);
  let tripReducer=useSelector(tripReducerSelector);
  let dispatch=useDispatch();

  let {
    tripId
  } = props;

  const [stepno, updateStep] = useState(0);

  useEffect(() => {
     dispatch(getLocationDetailsAction());
     dispatch(getBusDetailsAction());
  },[]);

  useEffect(()=>{
    if(isValid(tripId)){
      dispatch(getTripById(tripId));
      dispatch(getRoutesByTripId(tripId));
    }
  },[tripId])

  const {
    selectedTrip
  } = tripReducer;

  useEffect(()=>{
    setTripFormData(selectedTrip);
  },[selectedTrip]);

  let isTripCreated = isValid(selectedTrip)&&isValid(selectedTrip.id)?true:false;

  const onTripClear = ()=>{
    pageRedirect(history,'newtrip');
    dispatch(clearSelectedTrip());
  }

  const onTripCreateSuccess = (id)=>{
    if(isValid(id)){
      pageRedirect(history,'newtrip?tripId='+id);
    }
  }
  
    return (
      <div>
           <Text>Create Trip</Text>
            <Steps direction="vertical"
                   current={stepno}
                   onChange={(current)=>{
                     updateStep(current);
                   }}
                   >
                  <Step 
                  title={isTripCreated?selectedTrip.description:"Select Origin & Destination"}
                  description={stepno==0?<TripStep
                                          showFareInfo={true}
                                          formData={tripFormData}
                                          showCancelButton={true}
                                          onClear={onTripClear}
                                          onCreateSuccess={onTripCreateSuccess}
                                          showEditBlockSeats={true}
                                          showUpdateButton={true} 
                                          />:''} />
                  <Step 
                  title="Add Via Cities" 
                  description={stepno==1?<RouteStep citiesData={locationReducer.citiesData}
                                                    {...tripReducer}
                                                    />:''} 
                  disabled={!isTripCreated}
                  />
                  <Step 
                  title="Add Boarding Points" 
                  description={stepno==2?<BoardingPointComponent {...tripReducer}
                                                                 isRowClickable={true}
                                                                 showDeleteUpdateButtons={true}
                                                                  />:''} 
                                                                  disabled={!isTripCreated}
                                                                  />
                  <Step 
                  title="Add Drop Points" 
                  description ={stepno==3?<DropPointComponent {...tripReducer} 
                                                              isRowClickEnabled={true}
                                                              showDeleteUpdateButtons={true}
                                                              />:''}
                                                              disabled={!isTripCreated}
                                                              />  
            </Steps>
      </div>
    );
}
