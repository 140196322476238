import React  from 'react';
import { VechicleAllocationRoot } from '../components/vechicle-alllocation/vechicle-allocation-root/vechicle-allocation-root.component';
import { Typography } from 'antd';
import { VerifyPagePermission } from '../components/verify-page-permission-component/verify-page-permission.component';
import Permissions from "../utils/permissions-constant";

const { Title } = Typography;


export const VechicleAllocationPage = (props) => {

    const renderChildren =    <div style={{position:'relative',top:'10px'  , margin: '20px'}}>
                                <Title level={4}>Vehicle Allocation</Title>
                                <VechicleAllocationRoot />
                              </div>


    return (
        <VerifyPagePermission 
        pagePermission={Permissions.pagePermission.ADMIN_VIEW_VEHICLE_ALLOCATION_PAGE}
        renderChildren={renderChildren}
        onPermissionSuccess={()=>{
  
        }}
        />
    );
}
