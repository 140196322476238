import { Form , Input, Button , Select , Typography , Layout, Row , Col, Checkbox, InputNumber} from 'antd';
import React, { useEffect , useState}  from 'react';
import { useDispatch } from 'react-redux'
 import {
   isValid,
   isValidArray,
  showConfirm 
 } from '../../../utils/utilities';
 import { 
    getBusOperators,
    createBusOperator,
    updateBusOperator,
    deleteBusOperator, createOperatorMappingAction
 } from '../../../actions/bus-operator-actions';
 import './bus-operator-form.style.scss';
//  import ImageUpload from './../../../assets/ui-components/image-upload/image-upload';

const { Option } = Select;
const { TextArea } = Input;

const { Content, Footer } = Layout;
const { Title , Text } = Typography;

export const  BusOperatorForm = (props) => {

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [image, updateImage] = useState([]);
  const [base64Image, updateBase64Image] = useState(null);


      const{
        formData,
        updateformData,
        addLoading,
        updateLoading,
        deleteLoading,
        operatorList,
        setIntegrationModalOpen
      } = {...props};
   

 const handleSubmit = (e,buttonType) => {
    e.preventDefault();
    form.validateFields()
    .then(values => {
      if(buttonType=='add'){
         dispatch(createBusOperator({...values,base64Image:base64Image},() => {
           dispatch(createOperatorMappingAction(values.operatorCode))
           onSuccess();}))
        }
        else if(buttonType=='update'){
          dispatch(updateBusOperator({...values,base64Image:base64Image},formData.id,onSuccess))
         }
    })
    .catch(errorInfo => {
      console.log({errorInfo});
    })
 };

const onSuccess = ()=>{
   dispatch(getBusOperators());
   updateformData({});
}

const printOperatorOption = () => {
  let operatorOption = [];
  operatorList.map((operator) => {
    operatorOption.push(<Option key = {operator.key}>{operator.value}</Option>)
  })
  return operatorOption;
}

useEffect(()=>{
  if(isValidArray(formData)&&isValid(formData.name)){
    form.setFieldsValue({
      name : formData.name,
      ownerName : formData.owner_name,
      address:formData.address, 
      phoneNumber:formData.phone_number,
      operatorCode:formData.operator_code,
      gstNumber:formData.gst_number,
      websiteUrl:formData.website_url,
      contactEmail:formData.contact_email,
      logoUrl:formData.logo_url,
      aboutUs:formData.about_us,
      showInAbhibus:formData.show_in_abhibus,
      showInRedbus:formData.show_in_redbus,
      showRemarks: formData.use_remarks,
      sendMarketingSMS:formData.send_marketing_sms,
      isUseReviewFeature:formData.is_use_review_feature,
      blockedTicketsInTripSheet:formData.blocked_tickets_in_trip_sheet,
      cancellationOperator: formData.cancellationOperator,
      operatorNumber: formData.operator_number,
      overdueAmount:formData.overdue_amount
    })
    // if(isValid(formData.logo_url)){
    //   updateImage([{
    //     uid: formData.id,
    //     name: 'image.png',
    //     status: 'done',
    //     url: formData.logo_url
    //   }]);
    // }else{
    //   updateImage([]);
    //   updateBase64Image(null);
    // }
  }else{
    form.resetFields();
    updateImage([]);
    updateBase64Image(null);
  }
},[formData]);

const onFinishFailed=(error)=>{
  console.log({error});
}

const deleteLocationAction = ()=>{
  dispatch(deleteBusOperator(formData.id,onSuccess));
}

let isNewTravel=formData.id?false:true;
const layout = {
    labelCol: { span: 6 },
    // wrapperCol: { span: 16 },
  };
  
    return (
      <Layout>
            <Title level={4}>Bus Operator Form</Title>
              <Content>
                        <Form                           
                                 form={form}
                                 name="basic"
                                 initialValues={{websiteUrl:null,logoUrl:null,aboutUs:null,showInRedbus:false,showInAbhibus:false,showRemarks:false,blockedTicketsInTripSheet:false}}
                                 onFinish={handleSubmit}
                                 onFinishFailed={onFinishFailed}
                                 className="form"
                        >
                        <Row>
                            <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 7, offset: 1}} lg={{ span: 7}}>
                               <Form.Item
                                        label="Operator Name"
                                        name="name"
                                        rules={[{ required: true, message: 'Please input your operator name!' }]}
                                      >
                                        <Input 
                                          placeholder="Operator Name"
                                        />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 7, offset: 1}} lg={{ span: 7 }}>
                              <Form.Item
                                        label="Owner name"
                                        name="ownerName"
                                        rules={[{ required: true, message: 'Please input your owner name!' }]}
                                      >
                                        <Input 
                                          placeholder="Owner name"
                                        />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 7, offset: 1}} lg={{ span: 7 }}>
                               <Form.Item
                                        label="Operator code"
                                        name="operatorCode"
                                        rules={[{ required: true, message: 'Please input your operator code!' }]}
                                      >
                                        <Input 
                                          disabled={!isNewTravel} 
                                          placeholder="Operator code"
                                        />
                                </Form.Item>
                            </Col>           
                        </Row>
                        {/* <Row>
                            <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 7, offset: 1}} lg={{ span: 7 }}>
                                <Form.Item
                                        label="User Name"
                                        name="userName"
                                        rules={[{ required: true, message: 'Please input your user name!' }]}
                                      >
                                        <Input 
                                          placeholder="User name"
                                        />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 7, offset: 1}} lg={{ span: 7 }}>
                                <Form.Item
                                        label="Password"
                                        name="password"
                                        rules={[{ required: true, message: 'Please input your password name!' }]}
                                      >
                                        <Input 
                                          placeholder="Password"
                                        />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 7, offset: 1}} lg={{ span: 7 }}>
                                <Form.Item
                                        label="Access code"
                                        name="accessCode"
                                        rules={[{ required: true, message: 'Please input your access code!' }]}
                                      >
                                        <Input 
                                          placeholder="Access code"
                                        />
                                </Form.Item>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 7, offset: 1}} lg={{ span: 7 }}>
                                    <Form.Item
                                            label="Contact email"
                                            name="contactEmail"
                                            rules={[{ required: true, message: 'Please input your contact email!' }]}
                                        >
                                            <Input 
                                            placeholder="Contact email"
                                            />
                                    </Form.Item>
                            </Col>
                            <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 7, offset: 1}} lg={{ span: 7 }}>
                                <Form.Item
                                        label="Phone number"
                                        name="phoneNumber"
                                        rules={[{ required: true, message: 'Please input your phone number!' }]}
                                      >
                                        <Input 
                                          placeholder="Phone number"
                                        />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 7, offset: 1}} lg={{ span: 7}}>
                                <Form.Item
                                        label="Address"
                                        name="address"
                                        rules={[{ required: true, message: 'Please input your address!' }]}
                                      >
                                        <Input 
                                          placeholder="Address"
                                        />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 7, offset: 1}} lg={{ span: 7 }}>
                                <Form.Item
                                        label="Website url"
                                        name="websiteUrl"
                                        rules={[{ required: true, message: 'Please input your website url!' }]}
                                      >
                                        <Input 
                                          placeholder="Website url"
                                        />
                                </Form.Item>
                            </Col>
                            {/* <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 7, offset: 1}} lg={{ span: 7}}>
                                <Form.Item
                                        label="Logo url"
                                        name="logoUrl"
                                        rules={[{ required: false, message: 'Please input your logo url!' }]}
                                      >
                                        <Input 
                                          placeholder="Logo url"
                                        />
                                </Form.Item>
                            </Col> */}
                            <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 7, offset: 1}} lg={{ span: 7 }}>
                                <Form.Item
                                        label="Gst Number"
                                        name="gstNumber"
                                        rules={[{ required: true, message: 'Please input your gst number!' }]}
                                      >
                                        <Input 
                                          placeholder="Gst Number"
                                        />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 7, offset: 1}} lg={{ span: 7 }}>
                               <Form.Item
                                        label="Cancellation Policy"
                                        name="cancellationOperator"
                                        rules={[{ required: false, message: 'Please input your show in remarks!' }]}
                                      >
                                        <Select showSearch>{printOperatorOption()}</Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 7, offset: 1}} lg={{ span: 7 }}>
                                <Form.Item
                                        label="Operator Number"
                                        name="operatorNumber"
                                        rules={[{ required: false, message: 'Please input your operator number!' }]}
                                      >
                                        <Input
                                          placeholder="Operator Number"
                                        />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 7, offset: 1}} lg={{ span: 7 }}>
                                <Form.Item
                                        label="Overdue amount"
                                        name="overdueAmount"
                                        rules={[{ required: false, message: 'Please input your Overdue amount!' }]}
                                      >
                                        <InputNumber
                                          placeholder="Overdue amount"
                                        />
                                </Form.Item>
                            </Col>
                          </Row>
                        <Row>
                          <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 24}} lg={{ span: 24 }} >
                              <Form.Item
                                        label="About us"
                                        name="aboutUs"
                                        rules={[{ required: true, message: 'Please input your about us!' }]}
                                        tooltip="For next line \n. For space \r."
                                      >
                                        <TextArea 
                                          placeholder="About us"
                                        />
                                </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 5, offset: 1}} lg={{ span: 5 }}>
                               <Form.Item
                                        valuePropName="checked"
                                        label="Show in redbus"
                                        name="showInRedbus"
                                        rules={[{ required: false, message: 'Please input your show in redbus!' }]}
                                      >
                                        <Checkbox />
                                </Form.Item>
                            </Col>       
                            <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 5, offset: 1}} lg={{ span: 5 }}>
                               <Form.Item
                                        valuePropName="checked"
                                        label="Show in abhibus"
                                        name="showInAbhibus"
                                        rules={[{ required: false, message: 'Please input your show in abhibus!' }]}
                                      >
                                        <Checkbox />
                                </Form.Item>
                            </Col>          
                            <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 5, offset: 1}} lg={{ span: 5 }}>
                               <Form.Item
                                        valuePropName="checked"
                                        label="Blocked Tickets In TripSheet"
                                        name="blockedTicketsInTripSheet"
                                        rules={[{ required: false, message: '' }]}
                                      >
                                        <Checkbox />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 5, offset: 1}} lg={{ span: 5 }}>
                               <Form.Item
                                        valuePropName="checked"
                                        label="Show remarks"
                                        name="showRemarks"
                                        rules={[{ required: false, message: 'Please input your show in remarks!' }]}
                                      >
                                        <Checkbox />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 5, offset: 1}} lg={{ span: 5 }}>
                               <Form.Item
                                        valuePropName="checked"
                                        label="Use Review Feature"
                                        name="isUseReviewFeature"
                                        rules={[{ required: false, message: 'Please input your use review feature!' }]}
                                      >
                                        <Checkbox />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 5, offset: 1}} lg={{ span: 5 }}>
                               <Form.Item
                                        valuePropName="checked"
                                        label="Send Marketing SMS"
                                        name="sendMarketingSMS"
                                        rules={[{ required: false, message: 'Please input your send marketing SMS!' }]}
                                      >
                                        <Checkbox />
                                </Form.Item>
                            </Col>
                        </Row>
                        {/* <Row>
                          <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 5, offset: 1}} lg={{ span: 5 }}>
                              Logo :
                              <ImageUpload 
                                image={image} 
                                updateImage={updateImage} 
                                maxImage={1}
                                updateBase64Image={updateBase64Image}
                              />
                          </Col>
                        </Row> */}
                  </Form>
              </Content>
              <Footer style={{ textAlign: 'center' }} type="flex">
              <Row type="flex" justify="space-around">
                      <Button
                        loading={addLoading}
                        style={{margin:'5px',background:'#2EC17C'}}
                        disabled={!isNewTravel}
                        onClick={(e)=>{
                         handleSubmit(e,'add');
                        }}>
                        <Text>Add</Text>
                      </Button>
                      <Button type="primary"
                      loading={updateLoading}
                      style={{margin:'5px'}}
                      disabled={isNewTravel}
                       onClick={(e)=>{
                       handleSubmit(e,'update');
                       }}>
                        Update
                      </Button>
                        <Button type="danger"
                        loading={deleteLoading}
                        style={{margin:'5px'}}
                        disabled={isNewTravel}
                        onClick={()=>{
                           showConfirm(deleteLocationAction,'');
                        }}>
                        Delete
                      </Button>  
                      <Button 
                      type='danger'
                      style={{margin:'5px'}}
                      disabled={isNewTravel}
                      onClick={()=>{
                        setIntegrationModalOpen(true);
                      }}
                      >
                        Integration E-mail
                      </Button>
                      <Button type="block"
                        style={{margin:'5px'}}
                        onClick={()=>{
                            updateformData({});
                        }}>
                        Clear
                      </Button>
                  </Row>
              </Footer>
      </Layout>
    );
}
