import { 
    GET_AGENTS,
    CREATE_AGENT,
    UPDATE_AGENT,
    DELETE_AGENT,
    GET_AGENT_PRIVILEGES
} from './action-types';
import { 
    GET_AGENTS_URL ,
    CREATE_AGENT_URL,
    UPDATE_AGENT_URL,
    DELETE_AGENT_URL,
    GET_AGENT_PRIVILEGES_URL
  } from "../utils/api-url";
import { postCall , getCall  } from "../utils/network.util";


export const getAgentPrivileges = (operator_code,isAgentsPage)=>{
    return {
        type: GET_AGENT_PRIVILEGES,
        payload: {
            url: GET_AGENT_PRIVILEGES_URL+"?isAgentsPage="+isAgentsPage,
            api: getCall,
            apiPayload:{
                operatorCode:operator_code
            }
            }
        }
}


export const getAgentsDetails= (operator_code) => {
    return {
        type: GET_AGENTS,
        payload: {
            url: GET_AGENTS_URL,
            api: postCall,
            apiPayload:{
                operatorCode:operator_code,
                privilege:global.privilege
            }
        }
    }
}


export const createAgent= ({
    name,
    user_name,
    password,
    phone_number,
    privilege,
    location,
    commission,
    commission_percentage,
    ac_commission,
    ac_commission_percentage,
    hitech_commission,
    hitech_commission_percentage,
    operatorCode,
    agent_type,
    cancellation_penality_percentage,
    verify_otp_for_login,
    discount_percentage,
    discount_value
},onSuccess) => {

    return {
        type: CREATE_AGENT,
        payload: {
            url: CREATE_AGENT_URL,
            api: postCall,
            apiPayload:{
                agent:{
                name,
                user_name,
                password,
                phone_number,
                privilege,
                location,
                commission,
                commission_percentage,
                ac_commission,
                ac_commission_percentage,
                hitech_commission,
                hitech_commission_percentage,
                operator_code:operatorCode,
                agent_type,
                cancellation_penality_percentage,
                verify_otp_for_login,
                discount_percentage,
                discount_value
            }
            },
            onSuccess:onSuccess
        }
    }
}

export const updateAgent= ({
    id,
    name,
    user_name,
    password,
    phone_number,
    privilege,
    location,
    commission,
    commission_percentage,
    ac_commission,
    ac_commission_percentage,
    hitech_commission,
    hitech_commission_percentage,
    operatorCode,
    agent_type,
    cancellation_penality_percentage,
    verify_otp_for_login,
    discount_percentage,
    discount_value
},onSuccess) => {
    return {
        type: UPDATE_AGENT,
        payload: {
            url: UPDATE_AGENT_URL,
            api: postCall,
            apiPayload:{
                agent:{
                id:id,
                name,
                user_name,
                password,
                phone_number,
                privilege,
                location,
                commission,
                commission_percentage,
                ac_commission,
                ac_commission_percentage,
                hitech_commission,
                hitech_commission_percentage,
                operator_code:operatorCode,
                agent_type,
                cancellation_penality_percentage,
                verify_otp_for_login,
                discount_percentage,
                discount_value
            }
            },
            onSuccess:onSuccess
        }
    }
}


export const deleteAgent= (agentId,operatorCode,onSuccess) => {
    return {
        type: DELETE_AGENT,
        payload: {
            url: DELETE_AGENT_URL,
            api: postCall,
            apiPayload:{
                "agentId" :agentId,
                operator_code:operatorCode
            },
            onSuccess:onSuccess
        }
    }
}