import React   from 'react';
import "../assets/styles/main.scss";
import { BusOperatorsRoot } from '../components/bus-operators-component/bus-operators-root-component/bus-operatos-root.component';
import { Typography } from 'antd';
import { VerifyPagePermission } from '../components/verify-page-permission-component/verify-page-permission.component';
import Permissions from "../utils/permissions-constant";

const { Title } = Typography;


export const BusOperatorsPage = (props) => {

    const renderChildren= <div style={{position:'relative',top:'10px'  , margin: '30px'}}>
                            <Title level={4}>Bus Operator</Title>
                                <BusOperatorsRoot
                                
                                />
                            </div>


    return (
        <VerifyPagePermission 
            pagePermission={Permissions.pagePermission.BUS_OPERATOR_PAGE}
            renderChildren={renderChildren}
            onPermissionSuccess={()=>{

            }}
            />
    );
}
