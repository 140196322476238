import { Typography , Row , Table  } from 'antd';
import React from 'react';
import { isValid } from '../../../utils/utilities';
import { getFormattedTime } from '../../../utils/dates-util';

const {Text} = Typography;

export const DropPointTable = (props) => {

  const {
    dropPoints,
    isRowClickEnabled,
    dropFormData,
    setDropFormData,
    dropPointsLoading
  } = props;

  
const columns = [
  {
    title: 'Drop Point',
    dataIndex: 'location',
    key: 'location'
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city'
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    width:200
  },
  {
    title: 'Land mark',
    dataIndex: 'landmark',
    key: 'landmark'
  },
  {
    title: 'Day',
    dataIndex: 'route_destination_day',
    key: 'route_destination_day',
    render:(text,record)=>{
      let day = record.route_destination_day + record.destination_day;
      return(
        <div style={{width:'60px'}}>
          Day {day+1}
        </div>
      )
    }
  },
  // {
  //   title: 'Next Day',
  //   dataIndex: 'destination_day',
  //   key: 'destination_day',
  //   render:(text)=>(
  //     <span>
  //       {text?
  //           "yes"
  //           :"no"
  //       }
  //     </span>
  //   )
  // },
  {
    title: 'Drop time',
    dataIndex: 'timing',
    key: 'timing',
    render:(text,record) =>
    (
         <a>
             {getFormattedTime(text)}
         </a>
    )
  }
];


  return (
    <Row type='flex'>
        <Table 
        pagination={false}
        style={{margin:'10px'}}
        dataSource={dropPoints} 
        columns={columns} 
        loading={dropPointsLoading}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              if(isValid(isRowClickEnabled)&&isRowClickEnabled){
                setDropFormData(record);
              }
            }, // click row
            onDoubleClick: event => {

            }, // double click row
            onContextMenu: event => {

            }, // right button click row
            onMouseEnter: event => {

            }, // mouse enter row
            onMouseLeave: event => {

            }, // mouse leave row
          };
        }}
        rowClassName={(record, index)=>{
          let rowClassName="";
              if(record.id== dropFormData.id){
                  rowClassName=rowClassName+" TableRowClicked"
              }
              
              return  rowClassName
          }}
        />
    </Row>
  );
};