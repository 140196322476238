import React  from 'react';
import "../assets/styles/main.scss";

import { Typography } from 'antd';
import { VerifyPagePermission } from '../components/verify-page-permission-component/verify-page-permission.component';
import Permissions from "../utils/permissions-constant";
import { AgentsRootComponent } from "../components/agent-color-component/agent-color-root-component/agent-color-root.component";

const { Title } = Typography;

export const AgentsColorPage = (props) => {

    const renderChildren =     <div style={{position:'relative',top:'10px'  , margin: '20px'}}>
                                    <Title level={4}>Agent Portal Colors</Title>
                                    <AgentsRootComponent />
                                </div>

    return (
        <VerifyPagePermission 
        pagePermission={Permissions.pagePermission.ADMIN_VIEW_AGENTS_COLOR_PAGE}
        renderChildren={renderChildren}
        onPermissionSuccess={()=>{
  
        }}
        />
    );
}
