import React  from 'react';
import { Table , Row ,Col } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { getDateInReadableFormat , incrementDays , getDayOfDate} from '../../../utils/dates-util';
import { DonutChartComponent } from '../../donut-chart-component/donut-chart.component';
import { isValidNumber , isValidArray} from '../../../utils/utilities';


export const HomeTable = (props) => {

    const {
        dashBoardData,
        selectedDate,
        loading
    } = props;

    const columnsDashboardData=[
        {
        title:"Bus",
        align:'center',
        children: [{
        title:"",
        dataIndex:"description",
        width:200,
        render: (text,row) => (
            <Row align="middle">
              {text.origin!=undefined&&
                <Col>
                <span>{text.origin}</span>
                <span style={{padding:'5px'}}>
                <ArrowRightOutlined />
                </span>
                <span>{text.destination}</span>
                </Col>
              }
              {text.origin==undefined&&
                <Col>{text}</Col>
              }
            </Row>
            )
        }]
    },
    {
        title: getDayOfDate(selectedDate),
        align:'center',
        children: [{
        title: getDateInReadableFormat(selectedDate,false,false),
        align:'center',
        dataIndex: 'day1',
        key: 'day1',
        render: (text,row,index) => {
            return (
              <Row justify="center" align="middle" >
                  {isValidArray(text)&&isValidNumber(text.Blocked)?
                  <DonutChartComponent {...text} />
                  :
                    null
                  }
                </Row>
            )
        }
      }]
    },
    {
      title: getDayOfDate(incrementDays(selectedDate,1)),
      align:'center',
      children: [{
      title: getDateInReadableFormat(incrementDays(selectedDate,1),false,false),
      align:'center',
      dataIndex: 'day2',
      key: 'day2',
      render: (text,row,index) => {
          return (
              <Row justify="center" align="middle" >
              {isValidArray(text)&&isValidNumber(text.Blocked)?
              <DonutChartComponent {...text} />
              :
                 null
              }
             </Row>
          )
      }
    }]
  },
  {
    title: getDayOfDate(incrementDays(selectedDate,2)),
    align:'center',
    children: [{
    title: getDateInReadableFormat(incrementDays(selectedDate,2),false,false),
    align:'center',
    dataIndex: 'day3',
    key: 'day3',
    render: (text,row,index) => {
        return (
          <Row justify="center" align="middle" >
          {isValidArray(text)&&isValidNumber(text.Blocked)?
            <DonutChartComponent {...text} />
            :
               null
            }
        </Row>
        )
    }
  }]
},
{
    title: getDayOfDate(incrementDays(selectedDate,3)),
    align:'center',
    children: [{
     title: getDateInReadableFormat(incrementDays(selectedDate,3),false,false),
     align:'center',
     dataIndex: 'day4',
     key: 'day4',
     render: (text,row,index) => {
      return (
        <Row justify="center" align="middle" >
        {isValidArray(text)&&isValidNumber(text.Blocked)?
          <DonutChartComponent {...text} />
          :
             null
          }
      </Row>
      )
     }
  }]
},
{
    title: getDayOfDate(incrementDays(selectedDate,4)),
    align:'center',
    children: [{
    title: getDateInReadableFormat(incrementDays(selectedDate,4),false,false),
    align:'center',
    dataIndex: 'day5',
    key: 'day5',
    render: (text,row,index) => {
      return (
        <Row justify="center" align="middle" >
        {isValidArray(text)&&isValidNumber(text.Blocked)?
          <DonutChartComponent {...text} />
          :
             null
          }
      </Row>
      )
    }
  }]
}
]


    return (
      <div style={{padding:'10px'}}>
          <Table 
                pagination={false}
                dataSource={dashBoardData}
                columns={columnsDashboardData}
                loading={loading}
            />
      </div>
    )    
}
