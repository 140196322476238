/**
 * @author Rejo Varghese
 * @description Root Component related to admin driver mapping
 */
import React, { useState, useEffect } from 'react';
import { isValid } from '../../utils/utilities';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAdminDriver } from '../../actions/admin-driver-actions';
import { adminDriverSelector } from '../../selectors/admin-driver-selector';
import { AdminDriverMappingTable } from './admin-driver-mapping-table/admin-driver-mapping-table.component';
import { AdminDriverMappingForm } from './admin-driver-mapping-form/admin-driver-mapping-form.component';

const AdminDriverMappingRoot = (props) => {
    const {} = props;
    const [formData, updateFormData] = useState({});

    const dispatch = useDispatch();

    const adminDriverData = useSelector(adminDriverSelector)

    useEffect(() => {
        dispatch(getAllAdminDriver());
    },[])

    return (
        <div>
            {
                isValid(adminDriverData.adminDriver)&&
                <div>
                    <AdminDriverMappingTable
                        adminDriver = {adminDriverData.adminDriver}
                        formData = {formData}
                        updateFormData = {updateFormData}
                    />
                    <AdminDriverMappingForm
                        formData = {formData}
                        updateFormData = {updateFormData}
                    />
                </div>
            }
        </div>
    )
}

export default AdminDriverMappingRoot;
