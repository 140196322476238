import React, { useEffect, useState }  from 'react';
import { locationReducerSelector } from '../../../selectors/location-selectors';
import { useSelector , useDispatch } from 'react-redux';
import { Form , Select ,TimePicker , InputNumber , Button , Row , Col, message , Layout , Typography, Checkbox} from 'antd';
import {createRoutes , getRoutesByTripId , deleteRoutesById , updateRoutes } from '../../../actions/trip-actions';
import moment from 'moment';
import { isValid , isValidArray ,commaseperatedStringToArray , isValidString} from '../../../utils/utilities';
import { showConfirm } from '../../../utils/utilities';
import { getAmorPmofTime } from '../../../utils/dates-util';
import { ChangeRoutesTimingsComponent } from './../../change-routes-timings/change-routes-timings.component';
import { RedBusActivation } from './../../red-bus-activation/red-bus-activation.component';

const { Option } =Select;
const { Text } = Typography;
const {  Footer } = Layout;


const getFareJsonObject=(fareJson)=>{
  if(isValidString(fareJson)){
    return JSON.parse(fareJson);
  }else{
    return null
  }
}

const buildFareJson=(busSelected,values)=>{
  let fareJson=null , fare = values.fare , fareObject1stFare = null;
  let isSeatGroupIdsExits = isValidArray(busSelected)&&isValid(busSelected.seat_group_ids)?true:false;
  if(isSeatGroupIdsExits){
    let seatGroupIds=commaseperatedStringToArray(busSelected.seat_group_ids);
    seatGroupIds.forEach(element => {
      if(!isValidArray(fareJson)){
        fareJson={}
      }
      if(!isValid(fareObject1stFare)){
        fareObject1stFare = values[element];
      }
      fareJson[element]=values[element];
    });
    if(busSelected.supported_fare === 0){
      fare = fareObject1stFare;
    }
  }
  if(isValidArray(fareJson)){
    return {fareJson:JSON.stringify(fareJson),fare};
  }else{
    return {fareJson,fare};
  }
}

const renderPossibleFares=(formData,disableForm)=>{

  let fareComponents=[];
  let isSeatGroupIdsExits = isValidArray(formData)&&isValidArray(formData.bus)&&isValid(formData.bus.seat_group_ids)?true:false;
  if(isSeatGroupIdsExits){
    let busSelected=formData.bus;
    let seatGroupIds=commaseperatedStringToArray(busSelected.seat_group_ids);
    seatGroupIds.forEach(element => {
      fareComponents.push(<Form.Item
                                label={element}
                                name={element}
                                tooltip="without gst"
                                rules={[{ required: true, message: 'Please input '+ element+'!' }]}
                                >
                                  <InputNumber
                                        placeholder={element}
                                        // disabled={disableForm}
                                  />                                
                            </Form.Item>);
    });
  }
  return fareComponents;
}


export const RoutesForm = (props) => {

    const {
        tripId,
        setRoutesForm,
        routesForm,
        formData,
        routes
    } = props;


    const locationReducer = useSelector(locationReducerSelector);
    const dispatch = useDispatch();
    const [ disableForm ,setDisableForm ] = useState(false);

    useEffect(()=>{
      fillForm()
    },[routesForm]);

    const fillForm=()=>{
      if(isValidArray(routesForm)&&isValid(routesForm.id)){
        let{
          origin,
          destination,
          end_time,
          start_time,
          fare,
          fare_json,
          origin_day,
          destination_day
        }=routesForm;
        let fareJson=getFareJsonObject(fare_json);
        form.setFieldsValue({ 
              origin:origin,
              start_time:moment(start_time,'HH:mm') ,
              destination:destination,
              end_time:moment(end_time,'HH:mm'),
              fare:fare,
              origin_day:origin_day,
              destination_day:destination_day,
              ...fareJson
           });
           setDisableForm(true);
      }else{
        form.resetFields();
        setDisableForm(false);
      }
    }


    const {
        citiesData
      } = locationReducer;
      
      const [form] = Form.useForm();

      const printOption = (option) => {
        return <Option  value={option.name} data={option}>{option.name}</Option>
      };
     

      const deleteAction=()=>{
        showConfirm(
          ()=>{
            dispatch(deleteRoutesById(routesForm.id,onSuccess));
          },
          '',
          ''
        )
      }


      const getRoutes=()=>{
        dispatch(getRoutesByTripId(tripId));
      }

      const onSuccess=()=>{
          form.resetFields();
          getRoutes();
          setDisableForm(false);
          onClear();
      }
      
      const onClear=()=>{
        setRoutesForm({});
      }

      const handleSubmit=(e,buttonType)=>{
            if(isValid(tripId)){
              e.preventDefault();
              form.validateFields()
              .then(values => {
                let {fareJson,fare}=buildFareJson(formData.bus,values);
                values.start_time=moment(values.start_time, "h:mm a").format("HH:mm:ss");
                values.end_time=moment(values.end_time, "h:mm a").format("HH:mm:ss");
                values.schedule_id=tripId;
                let route = {
                  origin:values.origin,
                  destination:values.destination,
                  fare_json:fareJson,
                  start_time:values.start_time,
                  end_time:values.end_time,
                  fare:fare,
                  schedule_id:values.schedule_id,
                  origin_day :values.origin_day,
                  destination_day:values.destination_day
                };
                if(buttonType=='add'){
                  dispatch(createRoutes([route],tripId,onSuccess))
                }else if(buttonType=='update'){
                  route.id=routesForm.id;
                  dispatch(updateRoutes([route],onSuccess));
                }
              })
              .catch(errorInfo => {
                console.log({errorInfo});
              })
            }else{
              message.error("Select trip");
          }
        }

      const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      };

      
    return (
        <div>
        <Form
           {...layout} 
           className="form"
           form={form} 
           name="basic"
           initialValues={{origin_day:0, destination_day:0 }}
           >
                                <Form.Item
                                        label="Origin"
                                        name="origin"
                                        rules={[{ required: true, message: 'Please input your origin!' }]}
                                      >
                                        <Select
                                            showSearch
                                            // disabled={disableForm}
                                            placeholder="Select origin"
                                            style={{ width: 200 }}
                                        >
                                        {citiesData.map(printOption)}
                                        </Select>
                                </Form.Item>
                                <Form.Item
                                        label="Destination"
                                        name="destination"
                                        rules={[{ required: true, message: 'Please input your destination!' }]}
                                      >
                                        <Select
                                            showSearch
                                            // disabled={disableForm}
                                            placeholder="Select destination"
                                            style={{ width: 200 }}
                                        >
                                        {citiesData.map(printOption)}
                                        </Select>
                                </Form.Item>
                                <Form.Item
                                        label="Depature Time"
                                        name="start_time"
                                        rules={[{ required: true, message: 'Please input depature Time!' }]}
                                      >
                                         <TimePicker 
                                          popupClassName={"time-picker-to-not-display-footer"}
                                          onSelect={(value)=>{
                                             let selectedDepTime=moment(value, "h:mm a").format("HH:mm:ss");
                                             let selectedTimeAmorPm = getAmorPmofTime(selectedDepTime);
                                             let tripTimeAmorPm = getAmorPmofTime(formData.start_time);
                                             let originDay=0;
                                              if(isValidArray(formData)&&isValidString(formData.start_time)&&selectedTimeAmorPm!=tripTimeAmorPm){
                                                originDay=1;
                                              }
                                              form.setFieldsValue({
                                                start_time:value,
                                                origin_day:originDay
                                              })
                                            }}
                                         use12Hours 
                                         format='h:mm A'  
                                         minuteStep={5} 
                                        //  disabled={disableForm}
                                         />
                                </Form.Item>
                                <Form.Item
                                        label="Arival Time"
                                        name="end_time"
                                        rules={[{ required: true, message: 'Please input arival time!' }]}
                                      >
                                         <TimePicker 
                                          popupClassName={"time-picker-to-not-display-footer"}
                                          onSelect={(value)=>{
                                              form.setFieldsValue({
                                                end_time:value
                                              })
                                            }}
                                         use12Hours 
                                         format='h:mm A' 
                                          minuteStep={5} 
                                          // disabled={disableForm}
                                          />                         
                              </Form.Item>
                              {isValidArray(formData)&&isValidArray(formData.bus)&&formData.bus.supported_fare === 1 &&
                                <Form.Item
                                label="Fare"
                                name="fare"
                                tooltip="without gst"
                                rules={[{ required: true, message: 'Please input fare!' }]}
                                >
                                <InputNumber
                                    // disabled={disableForm}
                                    placeholder="Fare"
                                />                                
                                </Form.Item>
                              }
                              {renderPossibleFares(formData,disableForm)}
                              <Form.Item
                                // valuePropName="checked"
                                label="Origin day"
                                name="origin_day"
                                rules={[{ required: false, message: 'Please input origin day!' }]}
                              >
                                <Select
                                      showSearch
                                      // disabled={disableForm}
                                      placeholder=""
                                      style={{ width: 200 }}
                                  >
                                    <Option  value={0}>Day 1</Option>{/*same day*/}
                                    <Option  value={1}>Day 2</Option>{/*next day*/}
                                    <Option  value={2}>Day 3</Option>{/*next day*/}
                                    <Option  value={3}>Day 4</Option>{/*next day*/}
                                  </Select>                              
                              </Form.Item>
                              <Form.Item
                                label="Destination day"
                                name="destination_day"
                                rules={[{ required: false, message: 'Please input destination day!' }]}
                              >
                                  <Select
                                      showSearch
                                      // disabled={disableForm}
                                      placeholder=""
                                      style={{ width: 200 }}
                                  >
                                    <Option  value={0}>Day 1</Option>{/*same day*/}
                                    <Option  value={1}>Day 2</Option>{/*next day*/}
                                    <Option  value={2}>Day 3</Option>
                                    <Option  value={3}>Day 4</Option>
                                  </Select>                     
                              </Form.Item>
                              <Footer>
                                    <Row justify="space-around"> 
                                          <Col span={6}>
                                              <Button 
                                                  disabled={disableForm}
                                                  style={{margin:'5px',width:'100px',background:'#2EC17C'}}
                                                  onClick={(e)=>{
                                                  handleSubmit(e,'add');
                                                  }}>
                                                <Text>Add</Text>  
                                              </Button>
                                          </Col>
                                          <Col span={6}>
                                              <Button 
                                                  disabled={!disableForm}
                                                  style={{margin:'5px',width:'100px'}}
                                                  type="primary"
                                                  onClick={(e)=>{
                                                  handleSubmit(e,'update');
                                                  }}>
                                                <Text>Update</Text>  
                                              </Button>
                                          </Col>
                                          <Col span={6}>
                                              <Button type="danger"
                                                  disabled={isValidArray(routesForm)&&isValid(routesForm.id)?false:true}
                                                  style={{margin:'5px',width:'100px'}}
                                                  onClick={deleteAction}>
                                                  Delete
                                              </Button>
                                          </Col>
                                          <Col span={6}>
                                              <Button
                                                  style={{margin:'5px',width:'100px'}}
                                                  onClick={onClear}>
                                                  Clear
                                              </Button>
                                          </Col>
                                </Row>
                                <Row gutter={[16,16]} justify="center">
                                  <Col>
                                      <RedBusActivation 
                                           selectedRoutes={routes}
                                           tripId={tripId} 
                                      />
                                  </Col>
                                  <Col>
                                      <ChangeRoutesTimingsComponent 
                                      selectedRoutes={routes}
                                      tripId={tripId}
                                    />
                                  </Col>
                                </Row>
                              </Footer>
                </Form>
        </div>
    );
}
