import React , {useEffect, useState} from 'react';
import { TravelsManagementForm } from '../travel-management-form/travel-management.form';
import { TravelsManagementTable } from '../travel-managemnet-table/travel-managemnet.table';
import './travel-management.style.scss';
import { getTravelManagements } from './../../../actions/travel-management.actions';
import { useDispatch, useSelector } from 'react-redux';
import { travelManagementSelector } from './../../../selectors/travel-management-selectors';


export const TravelsManagementRoot = props => {
  const {

    }={...props};


  const [formData, updateformData] = useState({});

  const dispatch = useDispatch();

  const getTravelMangements=()=>{
    dispatch(getTravelManagements())
  }

  useEffect(()=>{
    getTravelMangements();
  },[]);

  const {
    travelManagements,
    dataLoading,
    addLoading,
    updateLoading,
    deleteLoading
  } = useSelector(travelManagementSelector)

  return (
      <div>
             <TravelsManagementTable
              dataSource={travelManagements}
              loading={dataLoading}
              selectedData={formData}
              updateformData={updateformData}
            />
             <TravelsManagementForm
             formData={formData}
             updateformData={updateformData}
             getTravelMangements={getTravelMangements}
             addLoading={addLoading}
             updateLoading={updateLoading}
             deleteLoading={deleteLoading}
             />
      </div>
  );
};
