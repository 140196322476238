const pagePermission = {
  ADMIN_VIEW_CITIES_PAGE:"ADMIN_VIEW_CITIES_PAGE",
  ADMIN_VIEW_ADDRESS_PAGE:"ADMIN_VIEW_ADDRESS_PAGE",
  ADMIN_VIEW_TRIPS_PAGE:"ADMIN_VIEW_TRIPS_PAGE",
  ADMIN_VIEW_SCHEDULES_PAGE:"ADMIN_VIEW_SCHEDULES_PAGE",
  ADMIN_VIEW_AGENTS_PAGE:"ADMIN_VIEW_AGENTS_PAGE",
  ADMIN_VIEW_AGENTS_COLOR_PAGE:"ADMIN_VIEW_AGENTS_COLOR_PAGE",
  ADMIN_VIEW_BUS_PAGE:"ADMIN_VIEW_BUS_PAGE",
  ADMIN_VIEW_GST_PAGE:"ADMIN_VIEW_GST_PAGE",
  ADMIN_VIEW_VEHICLE_MANAGEMENT_PAGE:"ADMIN_VIEW_VEHICLE_MANAGEMENT_PAGE",
  ADMIN_VIEW_VEHICLE_ALLOCATION_PAGE:"ADMIN_VIEW_VEHICLE_ALLOCATION_PAGE",
  ADMIN_VIEW_PRICE_PAGE:"ADMIN_VIEW_PRICE_PAGE",
  ADMIN_VIEW_MASTER_PRICE_PAGE:"ADMIN_VIEW_MASTER_PRICE_PAGE",
  ADMIN_VIEW_PRIVILAGES_PAGE:"ADMIN_VIEW_PRIVILAGES_PAGE",
  ADMIN_VIEW_HOME_PAGE:"ADMIN_VIEW_HOME_PAGE",
  BUS_OPERATOR_PAGE:"BUS_OPERATOR_PAGE",
  ADMIN_TO_VIEW_TRAVEL_MANAGEMENT_PAGE:"ADMIN_TO_VIEW_TRAVEL_MANAGEMENT_PAGE",
  ADMIN_VIEW_CANCELLATION_POLICY_PAGE:"ADMIN_VIEW_CANCELLATION_POLICY_PAGE",
  SHOW_MAKE_IT_LIVE_BUTTON:"SHOW_MAKE_IT_LIVE_BUTTON",
  ACTION_TO_VIEW_DEMAND_DAYS: "ACTION_TO_VIEW_DEMAND_DAYS",
  DAFIDY_USER_PAGE: "DAFIDY_USER_PAGE",
  SHOW_PASSWORD_ACTION: "SHOW_PASSWORD_ACTION"
}


const agentPrivileges={
  DAFIDY_USER:"Dafidy user"
}


const Permissions = {
  pagePermission,
  agentPrivileges
}

export default Permissions;
