import React  from 'react';
import {isValid, isValidArray, isValidBool,commaseperatedStringToArray,isValidString } from '../../utils/utilities'
import {  Row , Table , Button , InputNumber , Col, Select , TimePicker} from 'antd';
import { getFormattedTime , MintoHours} from '../../utils/dates-util';
import { showConfirm } from '../../utils/utilities';
import { deleteRoutesById , getRoutesByTripId } from '../../actions/trip-actions';
import { useDispatch } from 'react-redux';
import moment from 'moment';

const {
  Option
} = Select;

export const RouteTable = (props) => {

    let {
        tripId,
        dataSource,
        isRemoveRouteEnable,
        showFare,
        showDuration,
        routesForm , 
        setRoutesForm,
        isRowClickable,
        isEditFareEnabled,
        setEditedRoutes,
        editedRoutes,
        showDeleteRoute,
        routesLoading,
        oldRoutesLength,
        isUpdateRoutesAction,
        selectedTrip,
        showNextDay
    } = {...props};

    const dispatch = useDispatch();

      const columns = [
              {
                title: 'Origin',
                dataIndex: 'origin'
              },
              {
                title: 'Destination',
                dataIndex: 'destination'
              },
              {
                title: 'Start',
                dataIndex: 'start_time',
                render: (text, row, viewIndex) => {
                  if(isEditFareEnabled){
                    return <TimePicker 
                    value={moment(text, "h:mm a")}
                    use12Hours 
                    format='h:mm A'
                    popupClassName={"time-picker-to-not-display-footer"}
                    onSelect={(value)=>{
                    let selectedDepTime=moment(value, "h:mm a").format("HH:mm:ss");
                        let editrouteIndex=viewIndex;
                        if(!isUpdateRoutesAction){
                          editrouteIndex=viewIndex-oldRoutesLength;
                        }
                        editedRoutes[editrouteIndex].start_time=selectedDepTime;
                        setEditedRoutes([...editedRoutes]);
                      }}
                    />
                  }else{
                    return <a>{getFormattedTime(text)}</a>;
                  }
                }
              },
              {
                title: 'End',
                dataIndex: 'end_time',
                render: (text, row, viewIndex) => {
                  if(isEditFareEnabled){
                    return <TimePicker 
                    value={moment(text, "h:mm a")}
                    use12Hours 
                    format='h:mm A'
                    popupClassName={"time-picker-to-not-display-footer"}
                    onSelect={(value)=>{
                    let selectedArTime=moment(value, "h:mm a").format("HH:mm:ss");
                        let editrouteIndex=viewIndex;
                        if(!isUpdateRoutesAction){
                          editrouteIndex=viewIndex-oldRoutesLength;
                        }
                        editedRoutes[editrouteIndex].end_time=selectedArTime;
                        setEditedRoutes([...editedRoutes]);
                      }}
                    />
                  }else{
                    return <a>{getFormattedTime(text)}</a>;
                  }
                }
              }
            ];

      if(isValid(showDuration)&&showDuration){
        columns.push({
          title: 'Duration',
          dataIndex: 'duration',
          render: (text) => (
                  <div>
                        <div>{MintoHours(text)}</div>
                  </div>
              )
        })
      }

      if(showNextDay){  
        columns.push({
            title:'Origin Day',
            dataIndex:'origin_day',
            render:(text , rowIndex ,viewIndex)=>{
              if(isEditFareEnabled){
                return (
                  <Select
                      showSearch
                      // disabled={disableForm}
                      value={text} 
                      placeholder="Select day"
                      style={{ width: 200 }}
                      defaultValue={0}
                      onChange={(value)=>{
                        let editrouteIndex=viewIndex;
                        if(!isUpdateRoutesAction){
                          editrouteIndex=viewIndex-oldRoutesLength;
                        }
                        editedRoutes[editrouteIndex].origin_day=value;
                        setEditedRoutes([...editedRoutes]);
                      }}
                  >
                    <Option  value={0}>Day 1</Option>{/*same day*/}
                    <Option  value={1}>Day 2</Option>{/*next day*/}
                    <Option  value={2}>Day 3</Option>
                    <Option  value={3}>Day 4</Option>
                  </Select>   
                )
              }else{
                return <div>{"Day "}{text+1}</div>
              }
            }
        })
      }

      if(showNextDay){  
        columns.push({
            title:'Destination Day',
            dataIndex:'destination_day',
            render:(text , rowIndex ,viewIndex)=>{
              if(isEditFareEnabled){
                return (
                  <Select
                      showSearch
                      // disabled={disableForm}
                      value={text} 
                      placeholder=""
                      style={{ width: 200 }}
                      defaultValue={0}
                      onChange={(value)=>{
                        let editrouteIndex=viewIndex;
                        if(!isUpdateRoutesAction){
                          editrouteIndex=viewIndex-oldRoutesLength;
                        }
                        editedRoutes[editrouteIndex].destination_day=value;
                        setEditedRoutes([...editedRoutes]);
                      }}
                  >
                    <Option  value={0}>Day 1</Option>{/*same day*/}
                    <Option  value={1}>Day 2</Option>{/*next day*/}
                    <Option  value={2}>Day 3</Option>
                    <Option  value={3}>Day 4</Option>
                  </Select>   
                )
              }else{
                return <div>{"Day "}{text+1}</div>
              }
            }
        })
      }
      
      if(showFare){
        columns.push({
          title: 'Fare',
          dataIndex: 'fare',
          render: (text,rowIndex ,viewIndex) => {
            let dataIndex='fare';
            // let key = rowIndex.id;
            // const index = dataSource.findIndex(item => key === item.id);
            return renderComponentTable({text,viewIndex,dataIndex});
            }
        })
      }
      if(isValidArray(selectedTrip)&&isValidArray(selectedTrip.bus)&&isValidString(selectedTrip.bus.seat_group_ids)){
        let seatsArray=commaseperatedStringToArray(selectedTrip.bus.seat_group_ids);
        seatsArray.forEach(fareJsonKey => {
          columns.push({
            title:fareJsonKey,
            dataIndex:'fare_json',
            key: 'fare_json',
            render: (text,rowIndex ,viewIndex) => {
              let dataIndex='fare_json';
              return renderComponentTable({text,viewIndex,dataIndex,fareJsonKey});
            }
          })
        });
      }

      if(isValid(isRemoveRouteEnable)&&isRemoveRouteEnable){
        columns.push({
          title:'Remove',
          dataIndex:'isNewRoute',
          render: (text, row, index) => {
            if(oldRoutesLength<=index){
              return <Button onClick={()=>{
               let editrouteIndex=index-oldRoutesLength;
                editedRoutes.splice(editrouteIndex, 1); 
                setEditedRoutes([...editedRoutes]);
              }}>Remove</Button>;
            }else{
              return <div>Not allowed</div>;
            }
           
          }
        })
      }

      if(isValid(showDeleteRoute)&&showDeleteRoute){
        columns.push({
          title:'Delete',
          render: (text, row, index) => {
            return <Button 
            type="danger"
            onClick={()=>{
              showConfirm(
                ()=>{
                  dispatch(deleteRoutesById(row.id,()=>{
                    dispatch(getRoutesByTripId(tripId))
                  }))
                },
                '',
                ''
              )
            }}>Delete</Button>;
          }
        })
      }


      const renderComponentTable=({text,viewIndex,dataIndex,fareJsonKey})=>{
        let fareJsonObject;
        if(isValidString(fareJsonKey)&&isValidString(text)){
          fareJsonObject=JSON.parse(text);
          text=fareJsonObject[fareJsonKey];
        }
        return {
          props: {
            className:'',
          },
         children:  <span>
                        <Row type="flex" justify='space-between' >
                          {isValidBool(isEditFareEnabled)&&isEditFareEnabled&&(oldRoutesLength<=viewIndex||isUpdateRoutesAction)?
                            <Col>
                            <InputNumber value={text}
                                    onChange={(value)=>{
                                      let editrouteIndex=viewIndex;
                                      if(!isUpdateRoutesAction){
                                        editrouteIndex=viewIndex-oldRoutesLength;
                                      }
                                      if(isValidString(fareJsonKey)){
                                        if(isValidArray(fareJsonObject)){

                                        }else{
                                          fareJsonObject={}
                                        }
                                        fareJsonObject[fareJsonKey]=value;
                                        editedRoutes[editrouteIndex][dataIndex]=JSON.stringify(fareJsonObject);
                                      }else{
                                        editedRoutes[editrouteIndex][dataIndex]=value;
                                      }
                                      setEditedRoutes([...editedRoutes]);
                                    }}/>
                            </Col>
                            :
                            <Col>{text}</Col>
                          }
                        </Row>
                     </span>,
        };
      }

    return (
      <Row>
            <Table
            style={{margin:'10px'}}
            dataSource={dataSource}
            columns={columns}
            loading={routesLoading}
            rowKey={record => record.id}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => {
                  if(isValid(isRowClickable)&&isRowClickable){
                    setRoutesForm(record);
                  }
                }, // click row
                onDoubleClick: event => {

                }, // double click row
                onContextMenu: event => {

                }, // right button click row
                onMouseEnter: event => {

                }, // mouse enter row
                onMouseLeave: event => {

                }, // mouse leave row
              };
            }}
            rowClassName={(record, index)=>{
              let rowClassName="";
                  if(isValid(isRowClickable)&&isRowClickable&&record.id== routesForm.id){
                      rowClassName=rowClassName+" TableRowClicked"
                  }
                  
                  return  rowClassName
          }}
            />
      </Row>
    );
};
