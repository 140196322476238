import { API_BASE_URL } from "../../../app.config"

export const getAgentActions = (setActions) => {
    fetch(API_BASE_URL + '/api/getAgentActions',{
      method: "GET",
      headers: {'Content-Type':'application/json'}
    }).then(response => response.json())
    .then(data =>
      {
        if(data.Status == "SUCCESS"){
          setActions(data.Payload);
        }
      })
  }

 export const  getAgentPrivileges=(setAgentPrivileges)=>{
    fetch(API_BASE_URL + '/api/getAgentPrivileges',{
      method: "GET",
      headers: {'Content-Type':'application/json'}
    }).then(response => response.json())
    .then(data =>
      {
        if(data.Status == "SUCCESS"){
            setAgentPrivileges(data.Payload);
        }
      })
  }