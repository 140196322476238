import { message } from 'antd';
import { GOOGLE_MAP_API_KEY } from '../../utils/constant';
import { capitalizeFirstLetter , sortJsonObject } from './../../utils/utilities';

export const getUniqueCities = (data)=>{
  let uniqueCities=[],cities=[];
  data.forEach(element => {
    let formateCity = capitalizeFirstLetter(element.city);
    if(cities.indexOf(formateCity)==-1){
      uniqueCities.push({text:formateCity,value:formateCity});
      cities.push(formateCity);
    }
  });
  return sortJsonObject(uniqueCities,'text',false);
}
