import React  from 'react';
import { Layout ,Typography , Table} from 'antd';
import { sortJsonObject } from '../../../utils/utilities';

const { Header } = Layout;
const { Title , Text } = Typography;


  const columns = [
    {
      title: 'Operator Name',
      dataIndex: 'operator_name',
    },
    {
      title:'Operator code',
      dataIndex:'operator_code',
    },
    {
      title:'From Value',
      dataIndex:'fromValue',
    },
    {
      title:'To Value',
      dataIndex:'toValue',
    },
    {
      title:'Deduction Amount',
      dataIndex:'deductionAmount',
    },
    {
      title:'Policy Pattern',
      dataIndex:'policyPattern',
    },
    {
      title:'Is Percentage',
      dataIndex:'is_percentage',
    },
  ];


export const CancellationPolicyTable = (props) => {

  const {
      cancellationPolicy,
      formData,
      updateformData    
  } = props;
  

  return (
      <div>
          <Table 
          columns={columns}
          pagination={{ pageSize: 5 }}
           dataSource={cancellationPolicy}
           onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                console.log(record)
                updateformData(record);
              }, // click row
              onDoubleClick: event => {
  
              }, // double click row
              onContextMenu: event => {
  
              }, // right button click row
              onMouseEnter: event => {
  
              }, // mouse enter row
              onMouseLeave: event => {
  
              }, // mouse leave row
            };
          }}
          rowClassName={(record, index)=>{
            let rowClassName="";
                if(record.id== formData.id){
                    rowClassName=rowClassName+" TableRowClicked"
                }
                
                return  rowClassName
          }}
           bordered />
      </div>
  );
}

