/**
 * Address related actions can be created here
 */

 import { 
    GET_SEAT_GROUP_IDS_URL 
    } from "../utils/api-url";
import { 
    GET_SEAT_GROUP_IDS 
} from "./action-types";
import { postCall, getCall } from "../utils/network.util";



export const getSeatGroupIds=()=>{
    return {
        type:GET_SEAT_GROUP_IDS,
        payload:{
            url:GET_SEAT_GROUP_IDS_URL,
            api:getCall
        }
    }
}