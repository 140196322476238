import React, { useEffect }  from 'react';
import { Form , Checkbox , Button , Typography, Layout, Row ,Select} from 'antd';
import { isValid } from '../../../utils/utilities';
import { createPrivillege , deletePrivillege , updatePrivillege , getPrivilleges} from '../../../actions/user-privillege-actions';
import { useDispatch } from 'react-redux';
import { showConfirm } from '../../../utils/utilities';

const { Header, Content, Footer } = Layout;
const { Title , Text } = Typography;
const {Option}=Select;

export const UserPrivillegeForm = (props) => {

    const {
        formData,
        agentActions,
        setFormData,
        agentPrivileges
    }=props;    

    const [form]=Form.useForm();
    const dispatch = useDispatch();

    const printOptionPrivilege = (option) => {
        return <Option  value={option.privilege}>{option.privilege}</Option>
      };

    const  formateAgentActions=(agentActions)=> {
        if(agentActions!=undefined){
          var newLists = agentActions.map(item => {
            const newList = { ...item };
            newList.label = formateString(item.action_name);
            newList.value = item.action_name;
            return newList;
          })
          return newLists
        }else{
          return []
        }
    }

    const formateString=(string)=>{
        var newString = ''
        var currentWord = '';

        for (var j = 0; j <= string.length; j++) {
          if(string[j]=='_'&&currentWord.toLowerCase()!='allowed'){
            newString=newString+currentWord.charAt(0) + currentWord.substring(1).toLowerCase()+" ";
            currentWord='';
          }else {
            currentWord=currentWord+string[j];
          }
        }
        return newString;
      }

      useEffect(()=>{
        fillForm();
      },[formData]);

      const fillForm=()=>{
            if(isValid(formData)&&isValid(formData.agent_privilege)){
                form.setFieldsValue({
                    privilege:formData.agent_privilege,
                    agent_actions:formData.agent_actions
                })
            }else{
                form.resetFields();
            }
      }

      const handleSubmit = (e,buttonType) => {
        e.preventDefault();
        form.validateFields()
        .then(values => {
            let {privilege,agent_actions} = values;
            if(buttonType=='add'){
              dispatch(createPrivillege({privilege,agent_actions},onSuccess));
           }else if(buttonType == 'update'){
              let deleteAgentActions = formData.agent_actions.filter(d => !agent_actions.includes(d));
              let insertAgentActions = agent_actions.filter(d => !formData.agent_actions.includes(d));
              dispatch(updatePrivillege({privilege,insertAgentActions,deleteAgentActions},onSuccess));
           }
        })
    }

    const onSuccess=()=>{
        setFormData({});
        dispatch(getPrivilleges({}));
    }

    const deleteAction=()=>{
        showConfirm(
            ()=>{
                dispatch(deletePrivillege(formData.agent_privilege,onSuccess));
            },
            '',
            ''
          )
      }

      let isRowClicked = isValid(formData)&&isValid(formData.id)?true:false;

    return (
        <div>
                <Layout>
                        <Title level={4}>Agent Privileges Form</Title>
                        <Content className="form">
                                    <Form 
                                            form={form}
                                            initialValues={{}}
                                    >
                                      <Form.Item
                                        label="Select a agent"
                                        name="privilege"
                                        rules={[{ required: true, message: 'Please input your select agent!' }]}
                                      >
                                                <Select
                                                    showSearch
                                                    disabled={isRowClicked}
                                                    className="form-input-item"
                                                    placeholder="Select agent"
                                                    style={{ width: 200 }}
                                                >
                                                {agentPrivileges.map(printOptionPrivilege)}
                                                </Select>
                                        </Form.Item>
                                        <Form.Item
                                        label="Select a agent"
                                        name="agent_actions"
                                        rules={[{ required: true, message: 'Please input your select agent actions!' }]}
                                      >
                                              <Checkbox.Group options={formateAgentActions(agentActions)} />

                                      </Form.Item>
                                   </Form>
                        </Content>
                        <Footer style={{ textAlign: 'center' }} type="flex">
                        <Row type="flex" justify="space-around">
                                <Button
                                    style={{margin:'5px',background:'#2EC17C'}}
                                    disabled={isRowClicked}
                                    onClick={(e)=>{
                                     handleSubmit(e,'add');
                                    }}>
                                      <Text>Add</Text>
                                </Button>
                                <Button type="primary"
                                    style={{margin:'5px'}}
                                    disabled={!isRowClicked}
                                onClick={(e)=>{
                                  handleSubmit(e,'update');
                                }}>
                                    Update
                                </Button>
                        
                                    <Button type="danger"
                                    style={{margin:'5px'}}
                                    disabled={!isRowClicked}
                                    onClick={deleteAction}>
                                    Delete
                                </Button>
                        
                                <Button type="block"
                                    style={{margin:'5px'}}
                                    disabled={!isRowClicked}
                                    onClick={()=>{
                                          setFormData({});
                                    }}>
                                    Clear
                                </Button>
                            </Row>
                        </Footer>
                </Layout>
        </div>
    );
}
