import React  from 'react';
import { Table } from 'antd';
import { busColmuns } from './bus-table.module';

export const BusTable = (props) => {

    const{
        busesData,
        formData,
        setFormData,
        loading
    } =props;

    return (
        <div>
            <Table 
            loading={loading}
            pagination={{ pageSize: 5 }}
            dataSource={busesData}
            columns={busColmuns}
            onRow={(record, rowIndex) => {
                return {
                  onClick: event => {
                    setFormData(record);
                  }, // click row
                  onDoubleClick: event => {
      
                  }, // double click row
                  onContextMenu: event => {
      
                  }, // right button click row
                  onMouseEnter: event => {
      
                  }, // mouse enter row
                  onMouseLeave: event => {
      
                  }, // mouse leave row
                };
              }}
              rowClassName={(record, index)=>{
                let rowClassName="";
                    if(record.id== formData.id){
                        rowClassName=rowClassName+" TableRowClicked"
                    }
                    
                    return  rowClassName
              }}
            />
        </div>
    );
}
