import React , {useEffect,useState ,useRef } from 'react';
import { Table } from 'antd';
import { getUniqueCities  } from './address.module';
import { capitalizeFirstLetter } from './../../utils/utilities';
import useColumnSearch from './../../assets/ui-components/table-column-search';

export const AddressTable = props => {

  const { dataSource , updateformData  , formData,loading}={...props};

  const [uniqueCities , setUniqueCities] = useState([]);

  useEffect(()=>{
    setUniqueCities(getUniqueCities(dataSource));
  },[dataSource]);


  const columns = [
      {
        title: 'City',
        dataIndex: 'city',
        filters:uniqueCities,
        onFilter: (value, record) => capitalizeFirstLetter(record.city).indexOf(capitalizeFirstLetter(value)) === 0,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        ...useColumnSearch(
          {dataIndexExt:"name"}
        )
      },
      {
        title: 'LandMark',
        dataIndex: 'landmark'
      },
      {
        title:'Location type',
        dataIndex: 'is_office',
        render:(text)=>{
          if(text){
              return "Office"
          }else{
              return "Pickup/Drop Point"
          }
        }
      },
      {
        title: 'Address',
        dataIndex: 'address'
      },
      {
        title: 'Landline',
        dataIndex: 'landline_number'
      },
      {
        title: 'Phone Number',
        dataIndex: 'phone_number'
      },
      {
        title: 'Latitude',
        dataIndex: 'latitude'
      },
      {
        title: 'Longitude',
        dataIndex: 'longitude'
      }
   ];

  return (
      <div>
            <Table dataSource={dataSource!=undefined?dataSource:[]}
                   pagination={{ pageSize: 5 }}
                   columns={columns}
                   loading={loading}
                   rowKey={record => record.id}
                   onRow={(record, rowIndex) => {
                      return {
                        onClick: event => {
                          updateformData(record);
                        }, // click row
                        onDoubleClick: event => {

                        }, // double click row
                        onContextMenu: event => {

                        }, // right button click row
                        onMouseEnter: event => {

                        }, // mouse enter row
                        onMouseLeave: event => {

                        }, // mouse leave row
                      };
                    }}
                    rowClassName={(record, index)=>{
                      let rowClassName="";
                          if(record.id== formData.id){
                              rowClassName=rowClassName+" TableRowClicked"
                          }
                          
                          return  rowClassName
                    }}
                   />
      </div>
  );
};
