/**
 * All sagas for Address Saga must be defined here
 * @author Manne Hareesh
 */

import { takeLatest,put,call,all,fork } from 'redux-saga/effects'
import {  LOG_IN_ACTION , UPDATE_LOGIN_COMPONENT ,LOG_OUT_ACTION , RESET_ALL , SET_WEB_APP_READY} from '../actions/action-types';
import { message } from 'antd';
import { ACCESS_CODE_STORAGE_KEY } from '../app.config';
import { pageRedirect , isValid} from '../utils/utilities';

/**
 *
 * @param {Get Login Details} action
 */
export function* loginSaga(action) {
    try{
      const {
          api,
          url,
          apiPayload,
          navigationObject
      } = {...action.payload};
      const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status=="SUCCESS"){
          let payload=apiResult.payload;
          if(payload.Status=='SUCCESS'){
             let data=payload.Payload;
            yield put({
                type: UPDATE_LOGIN_COMPONENT,
                payload: data
            });
             localStorage.setItem(ACCESS_CODE_STORAGE_KEY , data.access_code);
             message.success(payload.Message);
             navigateToPage(data.privilege,navigationObject);
          }else{
            message.error(payload.Message);
          }
        }else{
          message.error("Unable to login Try Again");
        }
        yield put({
            type:SET_WEB_APP_READY,
            payload:true
        })
    }
    catch(error){
        console.error("Error in login saga",error);
        yield put({
            type:SET_WEB_APP_READY,
            payload:true
        })
    }
}


export function* logOutSaga(action) {
    try{
      localStorage.setItem(ACCESS_CODE_STORAGE_KEY ,'');
      yield put({
          type: RESET_ALL,
          payload: "data"
      });
    }
    catch(error){
        console.error("Error in resetting password",error);
    }
}


function* watchloginSaga () {
    yield takeLatest(LOG_IN_ACTION,loginSaga)
}

function* watchlogOutSaga () {
    yield takeLatest(LOG_OUT_ACTION,logOutSaga)
}

export function*  watchLoginPage() {
    yield all([
        fork(watchloginSaga),
        fork(watchlogOutSaga)
    ])
}


const navigateToPage=(userPrivilege,navigationObject)=>{
    let {
        navigate,
        location
    } = navigationObject;
    if(userPrivilege === 'Dafidy user' && (
        location.pathname !== '/agents' && 
        location.pathname !== '/bus-operators' &&
        location.pathname !== '/bus-operators' &&
        location.pathname !== '/agent-portal-colors' &&
        location.pathname !== '/travel-management' &&
        location.pathname !== '/cancellation-policy'
        )){
            pageRedirect(navigate,'bus-operators');
    }else{
        if(
            global.loginPathName === '/cities' ||
            global.loginPathName === '/home' ||
            global.loginPathName === '/address' ||
            global.loginPathName === '/trips' ||
            global.loginPathName === '/schedules' ||
            global.loginPathName === '/agents' ||
            global.loginPathName === '/buses' ||
            global.loginPathName === '/gst' ||
            global.loginPathName === '/vechicle-managemment' ||
            global.loginPathName === '/vechicle-allocation' ||
            global.loginPathName === '/price-list' ||
            global.loginPathName === '/master-price-list' ||
            global.loginPathName === '/user-privillege' ||
            global.loginPathName === '/bus-operators' ||
            global.loginPathName === '/agent-portal-colors' ||
            global.loginPathName === '/travel-management' ||
            global.loginPathName === '/newtrip'||
            ((isValid(global.loginPathName) && global.loginPathName.search('/newtrip') != -1))
            ){
                pageRedirect(navigate, global.loginPathName);
        }else{
            pageRedirect(navigate, '/home');
        }
    }
    return true;
}