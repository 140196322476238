/**
 * @author Sanjay
 * @description Reducer for the components in home page
 */
import {
    SET_DASH_BOARD_SCHEDULES,
    SET_DASH_BOARD_LOADING,
    RESET_ALL  } from '../actions/action-types';
  
  const initialState = {
      homeSchedules: [],
      dashBoardLoading:true
  }
  
  export const homeReducer = (state = initialState,action) => {
      let newState = Object.assign({},state);
      switch (action.type) {


         case SET_DASH_BOARD_LOADING:
             newState["dashBoardLoading"] = action.payload;
             return newState;

          case SET_DASH_BOARD_SCHEDULES:
              newState["homeSchedules"] = action.payload;
              return newState;

        case RESET_ALL :
              newState["homeSchedules"] = [];
              return newState;
  
          default:
              return state;
      }
  }
  