import React from 'react';
import { Calendar, Row, Col, Button, Typography, message } from "antd";
import './render-calender.style.scss';
import { getDate, getMonth, addMonth, subtractMonth, getDateMonthInReadable, getAPIDateFormat } from '../../../utils/dates-util';
import { useEffect } from 'react';
import { useState } from 'react';
import { formateDataForDayWise, getDayStatus } from './render-calender.module';
import { removeArrayIndexValue } from "../../../utils/utilities";
import { useDispatch } from 'react-redux';
import { insertDemandDaysAction } from '../../../actions/demand-days.action';

const {Text} = Typography;

export const ScheduleListCalender = (props) => {

    const {
        selectedDate,
        scheduleData,
        selectedOrigin,
        selectedDestination
    } = props;

    const dispatch = useDispatch();

    const isMobile = false;

    const [dayWiseData,setDayWiseData] = useState({});
    const [currentDate , setCurrentDate] = useState(selectedDate);
    const [nextDate , setNextDate] = useState(selectedDate);
    const [existingDemandDates, setExistingDemandDates] = useState([]);
    const [currentSelectedDates , setCurrentSelectedDates] = useState([]);

    let currentMonth = getMonth(selectedDate);
    let nextMonth = getMonth(nextDate);
    
    useEffect(()=>{
        setDayWiseData(formateDataForDayWise(scheduleData.dates));
        setExistingDemandDates(scheduleData.dates);
    },[scheduleData]);

    useEffect(()=>{
        setCurrentDate(selectedDate);
    },[selectedDate]);

    useEffect(()=>{
        let secondDate = addMonth(currentDate,1);
        setNextDate(secondDate);
    },[currentDate]);

    const onPreviousClick =()=>{
        if(isMobile){
            setCurrentDate(subtractMonth(currentDate,1));
        }else{
            setCurrentDate(subtractMonth(currentDate,2));
        }
    }

    const onNextClick =()=>{
        if(isMobile){
            setCurrentDate(addMonth(currentDate,1));
        }else{
            setCurrentDate(addMonth(currentDate,2));
        }
    }

    const addDemandDays = () => {
        let unique1 = existingDemandDates.filter((o) => currentSelectedDates.indexOf(o) === -1);
        let unique2 = currentSelectedDates.filter((o) => existingDemandDates.indexOf(o) === -1);
        
        let newDates = unique1.concat(unique2);
        newDates.sort(function(a,b) {
            a = a.split('-').reverse().join('');
            b = b.split('-').reverse().join('');
            return a > b ? 1 : a < b ? -1 : 0;
        });
        if (selectedOrigin.length == 1 && selectedDestination.length == 1) {
            dispatch(insertDemandDaysAction({originList: selectedOrigin, destinationList: selectedDestination, selectedDates: newDates}))
            setCurrentSelectedDates([]);   
        }
        else {
            message.warn("Add Demand days can be added with only single origin and destination city");
        }
    }

    return(
        <div>
            <Row gutter={[16,16]} justify="center" align="middle">
                        <Col>
                            <div className="site-extended-calendar">
                                <Calendar 
                                    value={currentDate}
                                    fullscreen={false}
                                    headerRender={()=>{
                                        return (
                                        <Row gutter={[16,16]} align="middle">
                                            <Col>
                                                <Button type="link" className="previous-calender-btn" onClick={onPreviousClick}>
                                                    <Text className="normal-txt">Previous</Text>
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Text strong>{getDateMonthInReadable(currentDate)}</Text>
                                            </Col>  
                                            {isMobile&&
                                                <Col>
                                                    <Button type="link" className="previous-calender-btn" onClick={onNextClick}>
                                                        <Text className="normal-txt">Next</Text>
                                                    </Button>
                                                </Col>
                                            }      
                                        </Row>
                                        )              
                                    }}
                                    dateFullCellRender={(date)=>{
                                        let day = getDate(date);
                                        let month = getMonth(date);
                                        if(month===currentMonth){
                                            let apidate = getAPIDateFormat(date);
                                            let className = getDayStatus(apidate,dayWiseData);
                                            let index = currentSelectedDates.indexOf(apidate);
                                            let selectedClassName = index!=-1?"schedule-date-selected":"";
                                            return (
                                                <div className={`current-date ${className}`}>
                                                    <div className={`${selectedClassName}`}>{day}</div>
                                                </div>
                                            )
                                        }else{
                                            return (
                                                <div className={`next-month-date`}>
                                                    <div>{day}</div>
                                                </div>
                                            )
                                        }
                                    }}
                                    mode="month"
                                    onSelect={(date)=>{
                                        let oldDates = currentSelectedDates;
                                        let apidate = getAPIDateFormat(date);
                                        let index = oldDates.indexOf(apidate);
                                        if(index === -1){
                                            oldDates.push(apidate);
                                        }else{
                                            oldDates = removeArrayIndexValue(currentSelectedDates,index);
                                        }
                                        setCurrentSelectedDates(oldDates);
                                    }}
                                />
                            </div>
                        </Col>
                        {!isMobile&&
                           <Col>
                                <div className="site-extended-calendar">
                                    <Calendar 
                                                value={nextDate}
                                                fullscreen={false}
                                                headerRender={()=>{
                                                    return (
                                                    <Row gutter={[16,16]} align="middle" justify="end">
                                                        <Col>
                                                            <Text strong>{getDateMonthInReadable(nextDate)}</Text>
                                                        </Col>
                                                        <Col>
                                                            <Button type="link" className="previous-calender-btn" onClick={onNextClick}>
                                                                <Text className="normal-txt">Next</Text>
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    )              
                                                }}
                                                dateFullCellRender={(date)=>{
                                                    let day = getDate(date);
                                                    let month = getMonth(date);
                                                    if(month===nextMonth){
                                                        let apidate = getAPIDateFormat(date);
                                                        let className = getDayStatus(apidate,dayWiseData);
                                                        let index = currentSelectedDates.indexOf(apidate);
                                                        let selectedClassName = index!=-1?"schedule-date-selected":"";
                                                        return (
                                                            <div className={`current-date ${className}`}>
                                                                <div className={`${selectedClassName}`}>{day}</div>
                                                            </div>
                                                        )
                                                    }else{
                                                        return (
                                                            <div className={`next-month-date`}>
                                                                <div>{day}</div>
                                                            </div>
                                                        )
                                                    }
                                                }}
                                                mode="month"
                                                onSelect={(date)=>{
                                                    let oldDates = currentSelectedDates;
                                                    let apidate = getAPIDateFormat(date);
                                                    let index = oldDates.indexOf(apidate);
                                                    if(index === -1){
                                                        oldDates.push(apidate);
                                                    }else{
                                                        oldDates = removeArrayIndexValue(currentSelectedDates,index);
                                                    }
                                                    setCurrentSelectedDates(oldDates);
                                                }}
                                            />
                                </div>
                            </Col>
                        }
                    </Row>

                    <Row justify="center" align="middle" style= {{marginTop: 20}}>
                        <Col>
                            <Button className="clear-button"
                                onClick={()=> setCurrentSelectedDates([])}>
                                Clear
                            </Button>
                        </Col>
                        <Col style= {{marginLeft: 10}}>
                            <Button className="schedule-activate-button"
                                onClick={addDemandDays}>
                                Add Demand Days
                            </Button>
                        </Col>
                    </Row>
        </div>
    );
};

ScheduleListCalender.defaultProps = {

};

ScheduleListCalender.propTypes = {

};
