/**
 * @author Rejo Varghese
 * @description Sagas related to demand days
 */

import { takeLatest,put,call,all,fork } from 'redux-saga/effects'
import { GET_CITIES, SET_CITIES, SET_DEMAND_DAYS, GET_DEMAND_DAYS, INSERT_DEMAND_DAYS } from '../actions/action-types';
import { message } from 'antd';
import { getDemandDaysAction } from '../actions/demand-days.action';


export function* getCitiesList(action) {
    try{
        const {
            api,
            url
        } = {...action.payload};
        const apiResult = yield call(api,url);
        if(apiResult.status=="SUCCESS"){
          let clientPayload = apiResult.payload;
          if(clientPayload.Status=='SUCCESS'){
            let serverPayload = clientPayload.Payload
            message.success(clientPayload.Message);
            yield put({
                type: SET_CITIES,
                payload: serverPayload
            });
          }else{
            message.error(clientPayload.Message);
          }
        }

    }
    catch(error){
        console.error("Error in getting cities",error);
    }
}

function* watchGetCitiesList () {
    yield takeLatest(GET_CITIES,getCitiesList)
}

export function* getDemandDays(action) {
    try{
        const {api, url, apiPayload} = {...action.payload};
        const apiResult = yield call(api, url, apiPayload);
        if (apiResult.status === "SUCCESS") {
            let clientPayload = apiResult.payload;
            if (clientPayload.Status === "SUCCESS") {
                let serverPayload = clientPayload.Payload;
                yield put ({
                    type: SET_DEMAND_DAYS,
                    payload: serverPayload
                });
            }
            else {
                message.error(clientPayload.Message);
            }
        }
    }
    catch(err) {
        console.error("Error in getting demand days", err);
    }
}

function* watchGetDemandDays () {
    yield takeLatest (GET_DEMAND_DAYS, getDemandDays);
}


export function* insertDemandDaysSaga(action) {
    try{
        const {api, url, apiPayload} = {...action.payload};
        const apiResult = yield call(api, url, apiPayload);
        let fetchDemandDays = getDemandDaysAction(apiPayload.originList, apiPayload.destinationList);
        if (apiResult.status === "SUCCESS") {
            let clientPayload = apiResult.payload;
            if (clientPayload.Status === "SUCCESS") {
                yield* getDemandDays(fetchDemandDays);
                message.success(clientPayload.Message);
            }
            else {
                message.error(clientPayload.Message);
            }
        }
    }
    catch(err) {
        console.error("Error in getting demand days", err);
    }
}

function* watchinsertDemandDaysSaga () {
    yield takeLatest (INSERT_DEMAND_DAYS, insertDemandDaysSaga);
}

export function*  watchDemandDaysSagas() {
    yield all([
        fork(watchGetCitiesList),
        fork(watchGetDemandDays),
        fork(watchinsertDemandDaysSaga),
    ])
}
