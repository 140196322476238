import React , {useEffect, useState} from 'react';
import { getTrips } from '../../../actions/trip-actions';
import { useDispatch , useSelector } from 'react-redux';
import { tripReducerSelector } from '../../../selectors/trip-selectors';
import { EditTripsTable } from '../edit-trip-table/edit-trip-table.component'
import { Menu } from'antd';
import { renderMenuContent } from './edit-trips-root-module';
import { getLocationDetailsAction } from '../../../actions/locations-actions';
import { getBusDetailsAction } from '../../../actions/bus-actions';
import { loginReducerSelector } from '../../../selectors/login-selectors';

const { SubMenu } = Menu;

export const EditTripsRoot = (props) => {

    const dispatch = useDispatch();
    const tripReducer = useSelector(tripReducerSelector);

    const [formData , updateformData] = useState({});
    const [current , setCurrent] = useState("tripDetails");

    const {
        userInfo
    } = useSelector(loginReducerSelector);

    useEffect(()=>{
        dispatch(getTrips(userInfo.operator_id));
        dispatch(getLocationDetailsAction());
        dispatch(getBusDetailsAction());
    },[]);

    const handleClick=(e)=>{
        setCurrent(e.key);
    }

    return (
        <div>
            <EditTripsTable 
            {...tripReducer}
            updateformData={updateformData}
            formData={formData}
            />
            <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal">
                <Menu.Item key="tripDetails" icon={null}>
                    Trip Details
                </Menu.Item>
                <Menu.Item key="routeDetails" icon={null}>
                    Route Details
                </Menu.Item>
                <Menu.Item key="boardingDetails" icon={null}>
                    Boarding Details
                </Menu.Item>
                <Menu.Item key="dropDetails" icon={null}>
                    Drop Details
                </Menu.Item>
            </Menu>
            {renderMenuContent(current,formData,updateformData)}
        </div>
    );
}
