/**
 * All sagas for Address Saga must be defined here
 * @author Manne Hareesh
 */

import { takeLatest,put,call,all,fork } from 'redux-saga/effects'
import { GET_ADDRESSES_ACTION ,
         ADDRESSES_INFO ,
         SET_BOARDING_POINTS_LOCATIONS,
         INSERT_ADDRESS_ACTION ,
         SET_DROP_POINTS_LOCATIONS,
         UPDATE_ADDRESS_ACTION ,
         GET_ADDRESS_LOCATIONS_BY_CITY,
         LOADING_ACTION,
         ADDRESS_TABLE_LOADING} from '../actions/action-types';
import { message } from 'antd';



/**
 * 
 * @param {get address by city} action 
 */

 export function* getAddressLocationsByCity(action){
    try{
        const {
            api,
            url,
            isForBoardingPoints,
            apiPayload
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload=apiResult.payload;
         if(clientPayload.Status=='SUCCESS'){
           let serverPayload=clientPayload.Payload;
            yield put({
                type: isForBoardingPoints?SET_BOARDING_POINTS_LOCATIONS:SET_DROP_POINTS_LOCATIONS,
                payload: {addressLocations:serverPayload.addressLocations}
            });
          }
        }
    }
    catch(error){
        console.error("Error in resetting password",error);
    }
 }

 function* watchGetAddressLocationsByCitySaga () {
    yield takeLatest(GET_ADDRESS_LOCATIONS_BY_CITY,getAddressLocationsByCity)
}

/**
 *
 * @param {Get Address Details} action
 */
export function* getAddressDetails(action) {
    try{
        const {
            api,
            url,
            apiPayload
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        yield put({
            type: ADDRESS_TABLE_LOADING,
            payload: true
        });
        if(apiResult.status=="SUCCESS"){
          let clientPayload=apiResult.payload;
         if(clientPayload.Status=='SUCCESS'){
           let serverPayload=clientPayload.Payload;
            yield put({
                type: ADDRESSES_INFO,
                payload: serverPayload.addressLocations
            });
          }
        }else{
          message.error(apiResult.payload.Message);
        }
        yield put({
          type: ADDRESS_TABLE_LOADING,
          payload: false
      });
    }
    catch(error){
      yield put({
        type: ADDRESS_TABLE_LOADING,
        payload: false
      });
       message.error("Unable to get addresses");
        console.error("Error in getting addresses",error);
    }
}

export function* insertAddressAction(action) {
   try{
      yield put({
        type:LOADING_ACTION,
        payload:true
    })
       const {
           api,
           url,
           apiPayload,
           onSuccess
       } = {...action.payload};
       const apiResult = yield call(api,url,apiPayload);
       if(apiResult.status=="SUCCESS"){
         let payload=apiResult.payload;
         if(payload.Status=='SUCCESS'){
           message.success(payload.Message);
           onSuccess();
         }else{
           message.error(payload.Message);
         }
       }
       yield put({
        type:LOADING_ACTION,
        payload:false
    })
   }
   catch(error){
        yield put({
          type:LOADING_ACTION,
          payload:false
        })
       console.error("Error in resetting password",error);
   }
}

export function* updateAddressAction(action) {
   try{
        yield put({
          type:LOADING_ACTION,
          payload:true
      })
       const {
           api,
           url,
           apiPayload,
           onSuccess
       } = {...action.payload};
       const apiResult = yield call(api,url,apiPayload);
       if(apiResult.status=="SUCCESS"){
         let payload=apiResult.payload;
         if(payload.Status=='SUCCESS'){
           message.success(payload.Message);
           onSuccess();
         }else{
           message.error(payload.Message);
         }
       }
       yield put({
        type:LOADING_ACTION,
        payload:false
    })
   }
   catch(error){
      yield put({
        type:LOADING_ACTION,
        payload:false
    })
       console.error("Error in resetting password",error);
   }
}

// export function* deleteAddressAction(action) {
//    try{
//        const {
//            api,
//            url,
//            apiPayload
//        } = {...action.payload};
//
//        const {
//           addressId,
//           schoolId
//        } = {...apiPayload};
//
//        const apiResult = yield call(api,url,{addressId:addressId});
//        if(apiResult.status=="SUCCESS"){
//          let payload=apiResult.payload;
//          if(payload.Status=='SUCCESS'){
//            message.success(payload.Message);
//            let addressDataAction=getAddressDetailsAction(schoolId);
//            yield *getAddressDetails(addressDataAction);
//            yield put({
//                type: ADDRESS_ACTION_SUCCESS,
//                payload:true
//            });
//          }else{
//            message.error(apiResult.payload.Message);
//          }
//        }
//    }
//    catch(error){
//        console.error("Error in resetting password",error);
//    }
// }

function* watchGetAddressDetails () {
    yield takeLatest(GET_ADDRESSES_ACTION,getAddressDetails)
}

function* watchInsertAddressDetails () {
    yield takeLatest(INSERT_ADDRESS_ACTION,insertAddressAction)
}

function* watchUpdateAddressDetails () {
    yield takeLatest(UPDATE_ADDRESS_ACTION,updateAddressAction)
}

// function* watchDeleteAddressDetails () {
//     yield takeLatest(DELETE_ADDRESS_ACTION,deleteAddressAction)
// }

export function*  watchAddressDetailsPage() {
    yield all([
        fork(watchGetAddressDetails),
        fork(watchInsertAddressDetails),
        fork(watchUpdateAddressDetails),
        fork(watchGetAddressLocationsByCitySaga)
      //  fork(watchDeleteAddressDetails)
    ])
}
