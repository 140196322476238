/**
 * @author Sanjay
 * @description Reducer for the components in home page
 */
import {
    SET_ACTIVE_SCHEDULES,
    SET_IN_ACTIVE_SCHEDULES,
    SET_ACTIVE_SCHEDULES_LOADING,
    SET_IN_ACTIVE_SCHEDULES_LOADING,
    RESET_ALL  } from '../actions/action-types';
  
  const initialState = {
      activeSchedules: [],
      inActiveSchedules:[],
      activeSchedulesLoading:true,
      inActiveSchedulesLoading:true
  }
  
  export const schedulesReducer = (state = initialState,action) => {
      let newState = Object.assign({},state);
      switch (action.type) {
  
          case SET_ACTIVE_SCHEDULES_LOADING:
            newState["activeSchedulesLoading"] = action.payload;
            return newState;

          case SET_IN_ACTIVE_SCHEDULES_LOADING:
             newState["inActiveSchedulesLoading"] = action.payload;
                return newState;

          case SET_ACTIVE_SCHEDULES:
              newState["activeSchedules"] = action.payload.schedules;
              return {
                ...newState,
                activeSchedules:[...newState.activeSchedules]
            }   

          case SET_IN_ACTIVE_SCHEDULES:
              newState["inActiveSchedules"] = action.payload.schedules;
              return {
                ...newState,
                inActiveSchedules:[...newState.inActiveSchedules]
            }  

        case RESET_ALL :
              newState["activeSchedules"] = [];
              newState["inActiveSchedules"] = [];
              return newState;
  
          default:
              return state;
      }
  }
  