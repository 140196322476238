import React  from 'react';
import { TripStep } from '../../trip-components/trip-step-1';
import { useDispatch , useSelector} from 'react-redux';
import { getTrips, resetTripsData } from '../../../actions/trip-actions';
import { loginReducerSelector } from '../../../selectors/login-selectors';

export const EditTripForm = (props) => {

    const {
        formData,
        updateformData
    } = props;

    const {
        userInfo
    } = useSelector(loginReducerSelector);

    const dispatch = useDispatch();
    return (
        <div style={{padding:'10px'}}>
                <TripStep 
                    showDeleteButton={true}
                    showCancelButton={true}
                    showVechicleInfo={true}
                    showUpdateButton={true}
                    showFareInfo={true}
                    showEditBlockSeats={true}
                    showEasyTripButton={true}
                    formData={formData}
                    onClear={()=>{
                        updateformData({});
                        dispatch(resetTripsData());
                    }}
                    onCreateSuccess={()=>{
                        updateformData({});
                        dispatch(resetTripsData());
                        dispatch(getTrips(userInfo.operator_id));
                    }}
                /> 
        </div>
    );
}
