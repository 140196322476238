/**
 * @author Rejo Varghese
 * @description Demand days table
 */
import React from 'react';
import { Row, Table } from 'antd';
import { getDemandDaysColumns } from './demand-days-table.module';

const DemandDaysTable = (props) => {

    const {
        data
    } = props;

    return (
        <div>
            {/* <Row justify ="center" align = "middle"> */}
                <Table
                    dataSource = {data}
                    columns = {getDemandDaysColumns()}
                    rowKey={record => record.id}
                />
            {/* </Row> */}
        </div>
    )
}

export default DemandDaysTable;