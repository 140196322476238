import React , { useEffect, useState} from 'react';
import { Row, DatePicker  , Col  } from 'antd';
import{ getAPIDateFormat , getDates  } from '../../../utils/dates-util';
import { DayPicker } from 'react-day-picker';
import './../edit-fare-component/edit-fare.style.scss';
import { getSchedules } from '../edit-fare-component/edit-fare.module';
import 'react-day-picker/dist/style.css';

const { RangePicker   } = DatePicker;


const modifiersStyles = {
    blockDates: {
      color: '#D0021B'
    },
      activeDates: {
        color: '#B8E986',
        pointerEvents: 'all'
      },
      disabledApiDates: {
        color: '#1890ff',
        pointerEvents: 'all'
      }
    };


export const DatesSelector = (props) => {

    const {
        record,
        scheduleId,
        setSelectedDays,
        selectedDays
    } = props;

    const today =  getAPIDateFormat(new Date());
    const [dates,setDates]=useState(null);

    const [ blockDates , setBlockDates] = useState([]);
    const [ activeDates , setActiveDates] = useState([]);
    const [ disabledApiDates , setDisabledApiDates] = useState([]);
    const [trips ,setTrips] =useState(record.trips);

    useEffect(()=>{
        formateDataForCalender(trips);
    },[trips]);

   const onChange=(date, dateString)=>{
        dateString  = date.length==0?null:dateString
        let rangeDates=[];
        if(date!=null){
          var fromDate=new Date(date[0]);
          var toDate=new Date(date[1]);
          rangeDates = getDates(fromDate,toDate);
        }
        setDates(date);
        setSelectedDays([...rangeDates]);
      }

    const  formateDataForCalender=(data)=>{
        let activeDates=[],blockDates=[],disabledApiDates=[];
        if(data!=undefined){
          data.forEach((trip)=>{
            let dateString=new Date(trip.start_date);
                dateString.setHours(0, 0, 0);
                if(trip.visible_outside==1&&trip.is_active==1){
                  activeDates.push(dateString);
                }else if (trip.visible_outside==0&&trip.is_active==1) {
                  disabledApiDates.push(dateString);
                }else if (trip.is_active==0) {
                  blockDates.push(dateString);
                }
          })
        }
        setBlockDates(blockDates);
        setActiveDates(activeDates);
        setDisabledApiDates(disabledApiDates);
      }

     const onChangeMonth=(date)=> {
       getSchedules(date,scheduleId,setTrips);
      }
 
    return (
     <div>
        <Row>
            <Col>
                <Row>
                    <RangePicker
                        disabledDate={d => !d || d.isBefore(today) || d.isSameOrBefore("1960-01-01") }
                        value={dates}
                        onChange={onChange} 
                    />
                </Row>
                <Row>
                    <DayPicker
                        mode='multiple'
                        className = 'popUpEditFareDatePicker'
                        selected={selectedDays}
                        onSelect={setSelectedDays}
                        onMonthChange={(month)=>{
                            onChangeMonth(month)
                        }}
                        modifiers={{blockDates:blockDates,activeDates:activeDates ,disabledApiDates:disabledApiDates }}
                        modifiersStyles={modifiersStyles}
                    />
                </Row>
            </Col>
        </Row>
      </div>
    );
}
