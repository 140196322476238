/**
 * Address related actions can be created here
 */

import { 
    INSERT_TRIP_URL , 
    GET_ROUTES_BY_TRIP_ID_URL , 
    GET_BOARDING_POINTS_BY_SCHEDULE_ID_URL,
    CREATE_ROUTES_URL,
    CREATE_BOARDING_POINTS_URL,
    GET_DROP_POINTS_BY_SCHEDULE_ID_URL,
    CREATE_DROP_POINTS_URL,
    GET_TRIPS_URL,
    DELETE_TRIP_URL,
    DELETE_ROUTE_BY_ID_URL,
    DELETE_BOARDING_POINT_URL,
    UPDATE_BOARDING_POINT_URL,
    UPDATE_DROP_POINT_URL,
    DELETE_DROP_POINT_URL,
    GET_SCHEDULE_BY_ID_URL,
    UPDATE_TRIP_URL,
    UPDATE_ROUTES_FARE_URL,
    CLONE_TRIP_URL,
    CLONE_AS_RETURN_TRIP_URL,
    INSERT_BOARDING_POINTS_INTO_TEMPLATE_URL,
    INSERT_DROP_POINTS_INTO_TEMPLATE_URL
} from "../utils/api-url";
import { 
    UPDATE_ROUTES_FARE,
    UPDATE_TRIP,
    INSERT_TRIP_ACTION  ,
    GET_BOARDING_POINTS_BY_SCHEDULE_ID,
    GET_ROUTES_BY_TRIP_ID_ACTION , 
    ADD_NEW_ROUTE , 
    CREATE_ROUTES_ACTION,
    CREATE_BOARDING_POINTS_ACTION,
    CREATE_DROP_POINTS,
    GET_DROP_POINTS_BY_SCHEDULE_ID,
    GET_TRIPS,
    DELETE_TRIP,
    DELETE_ROUTE_BY_ID,
    RESET_TRIPS_DATA,
    SET_SELECTED_TRIP,
    DELETE_BOARDING_POINT,
    UPDATE_BOARDING_POINT,
    DELETE_DROP_POINT,
    UPDATE_DROP_POINT,
    GET_SCHEDULE_BY_ID,
    CLEAR_SELECTED_TRIP,
    CLONE_TRIP,
    INSERT_BOARDING_POINTS_INTO_TEMPLATE,
    INSERT_DROP_POINTS_INTO_TEMPLATE,
    CLONE_AS_RETURN_TRIP
} from "./action-types";
import { getCall, postCall } from "../utils/network.util";
import { isValidArray } from "../utils/utilities";

export const insertDropPointsIntoTemplate=(scheduleId,templateName,city,onSuccess)=>{
    return {
        type:INSERT_DROP_POINTS_INTO_TEMPLATE,
        payload:{
            url:INSERT_DROP_POINTS_INTO_TEMPLATE_URL,
            api:postCall,
            apiPayload:{
                city:city,
                scheduleId:scheduleId,
                templateName:templateName,
                operatorCode:global.operator_code
            },
            onSuccess:onSuccess
        }
    }   
}


export const insertBoardingPointsIntoTemplate = (scheduleId,templateName,city,onSuccess) =>{
    return {
        type:INSERT_BOARDING_POINTS_INTO_TEMPLATE,
        payload:{
            url:INSERT_BOARDING_POINTS_INTO_TEMPLATE_URL,
            api:postCall,
            apiPayload:{
                city:city,
                scheduleId:scheduleId,
                templateName:templateName,
                operatorCode:global.operator_code
            },
            onSuccess:onSuccess
        }
    }
}


export  const  cloneTrip=(scheduleId,offset,busId,tripIdentifier,onSuccess)=>{
    return {
        type:CLONE_TRIP,
        payload:{
            url:CLONE_TRIP_URL,
            api:postCall,
            apiPayload:{
                scheduleId,offset,busId,tripIdentifier
            },
            onSuccess:onSuccess
        }
    }
}


export  const  cloneAsReturnTrip=(scheduleId,newStartTime,busId,tripIdentifier,onSuccess)=>{
    return {
        type:CLONE_AS_RETURN_TRIP,
        payload:{
            url:CLONE_AS_RETURN_TRIP_URL,
            api:postCall,
            apiPayload:{
                scheduleId,newStartTime,busId,tripIdentifier
            },
            onSuccess:onSuccess
        }
    }
}


export const updateRoutes =(routes , onSuccess)=>{
    return{
        type:UPDATE_ROUTES_FARE,
        payload:{
            url:UPDATE_ROUTES_FARE_URL,
            api:postCall,
            apiPayload:{
                routes,
                operator_code:global.operator_code
            },
            onSuccess:onSuccess
        }
    }
}

export const getTripById=(tripId)=>{
    return{
        type:GET_SCHEDULE_BY_ID,
        payload:{
            url:GET_SCHEDULE_BY_ID_URL+"?scheduleId="+tripId,
            api:getCall,
            apiPayload:{
                operator_code:global.operator_code
            }
        }
    }
}

export const setSelectedTrip=(selectedTrip)=>{
    return{
        type:SET_SELECTED_TRIP,
        payload:selectedTrip
    }
}

export const clearSelectedTrip = ()=>{
    return{
        type:CLEAR_SELECTED_TRIP,
        payload:{}
    }
}

export const resetTripsData=()=>{
    return{
        type:RESET_TRIPS_DATA   
    }
}

export const deleteRoutesById=(routeId,onSuccess)=>{
    return{
        type:DELETE_ROUTE_BY_ID,
        payload:{
            api:postCall,
            url:DELETE_ROUTE_BY_ID_URL,
            apiPayload:{
                routeId:routeId,
                operator_code:global.operator_code
            },
            onSuccess:onSuccess
        }   
    }
}

export const createDropPoint=(data,onSuccess)=>{
    return{
        type:CREATE_DROP_POINTS,
        payload:{
            url:CREATE_DROP_POINTS_URL,
            api:postCall,
            apiPayload:{
                dropLocation:data,
                operator_code:global.operator_code
            },
            onSuccess:onSuccess
        }
    }
}

export const updateDropPoint=({destination_day,dropLocationId,location,latitude,longitude,tripId,timing,address,phone_number,landmark,city,is_temporary},onSuccess)=>{
    return{
        type:UPDATE_DROP_POINT,
        payload:{
            url:UPDATE_DROP_POINT_URL,
            api:postCall,
            apiPayload:{
                dropLocation:{
                location:location,
                latitude:latitude,
                longitude:longitude,
                schedule_id:tripId,
                timing:timing,
                address:address,
                phone_number:phone_number,
                landmark:landmark,
                city:city,
                is_temporary:is_temporary,
                id:dropLocationId,
                destination_day:destination_day,
                updated_by:global.user_name,
                },
                operator_code:global.operator_code
            },
            onSuccess:onSuccess
        }
    }
}

export const deleteDropPoint = (id,onSuccess) =>{
    return{
        type:DELETE_DROP_POINT,
        payload:{
            url:DELETE_DROP_POINT_URL,
            api:postCall,
            apiPayload:{
                dropLocationId:id,
                operator_code:global.operator_code,
                updated_by:global.user_name
            },
            onSuccess:onSuccess
        }
    }
}


export const createBoardingPointsAction = (data,onSuccess) =>{
    return{
        type:CREATE_BOARDING_POINTS_ACTION,
        payload:{
            url:CREATE_BOARDING_POINTS_URL,
            api:postCall,
            apiPayload:{
                pickupLocation:data,
                operator_code:global.operator_code
            },
            onSuccess:onSuccess
        }
    }
}

export const updateBoardingPoints = ({origin_day,pickUpLocationId,location,latitude,longitude,tripId,timing,address,phone_number,landmark,city,route_close_point,trip_close_point,is_temporary},onSuccess) =>{
    return{
        type:UPDATE_BOARDING_POINT,
        payload:{
            url:UPDATE_BOARDING_POINT_URL,
            api:postCall,
            apiPayload:{
                pickupLocation:{ 
                timing:timing,
                address:address,
                phone_number:phone_number,
                landmark:landmark,
                city:city,
                trip_close_point:trip_close_point,
                route_close_point:route_close_point,
                is_temporary:is_temporary,
                schedule_id:tripId,
                location:location,
                latitude:latitude,
                longitude:longitude,
                id:pickUpLocationId,
                origin_day:origin_day,
                updated_by:global.user_name
                },
                operator_code:global.operator_code
            },
            onSuccess:onSuccess
        }
    }
}

export const deleteBoardingPoint = (id,onSuccess) =>{
    return{
        type:DELETE_BOARDING_POINT,
        payload:{
            url:DELETE_BOARDING_POINT_URL,
            api:postCall,
            apiPayload:{
                pickupLocationId:id,
                operator_code:global.operator_code,
                updated_by:global.user_name
            },
            onSuccess:onSuccess
        }
    }
}

export const createRoutes = (routes,scheduleId,onSuccess)=>{
    return {
        type:CREATE_ROUTES_ACTION,
        payload:{
            url:CREATE_ROUTES_URL,
            api:postCall,
            apiPayload:{
                routes:routes,
                operator_code:global.operator_code
            },
            scheduleId:scheduleId,
            onSuccess:onSuccess
        }
    }

}

export const insertTripAction = ({
    origin,
    start_time,
    name,
    destination,
    end_time,
    distance,
    bus_id,
    fare,
    description,
    origin_latitude,
    origin_longitude,
    destination_latitude,
    destination_longitude,
    travels_id,
    operator_name,
    fare_json,
    alert_phone_numbers,
    destination_day,
    qbcc_order,
    breakeven_revenue
},onSuccess) => {
    if(isValidArray(fare_json)){
        fare_json = JSON.stringify(fare_json);
    }
    return {
        type: INSERT_TRIP_ACTION,
        payload: {
            url: INSERT_TRIP_URL,
            api: postCall ,
            apiPayload:{
                schedule:{
                    origin,
                    start_time,
                    name,
                    destination,
                    end_time,
                    distance,
                    bus_id,
                    fare,
                    description,
                    origin_latitude,
                    origin_longitude,
                    destination_latitude,
                    destination_longitude,
                    travels_id,
                    operator_name,
                    fare_json,
                    alert_phone_numbers,
                    destination_day,
                    qbcc_order,
                    breakeven_revenue
                },
                operator_code:global.operator_code
            },
            onSuccess:onSuccess
        }
    }
}

export const getRoutesByTripId = (scheduleId) => {
  return {
      type: GET_ROUTES_BY_TRIP_ID_ACTION,
      payload: {
          url: GET_ROUTES_BY_TRIP_ID_URL,
          api: postCall ,
          apiPayload:{
              scheduleId:scheduleId,
              operator_code:global.operator_code
            }
      }
  }
}

export const addNewRoute =(city,time,isOrigin,isDestination,originDay)=>{
    return{
        type:ADD_NEW_ROUTE,
        payload:{
            city,time,isOrigin,isDestination,originDay
        }
    }
}

export const getPickupLocationsByScheduleId = (scheduleId)=>{
    return {
        type:GET_BOARDING_POINTS_BY_SCHEDULE_ID,
        payload:{
            url:GET_BOARDING_POINTS_BY_SCHEDULE_ID_URL,
            api:postCall,
            apiPayload:{
                scheduleId:scheduleId,
                operator_code:global.operator_code
            }
        }
    }
}

export const getDropLocationsByScheduleId = (scheduleId)=>{
    return {
        type:GET_DROP_POINTS_BY_SCHEDULE_ID,
        payload:{
            url:GET_DROP_POINTS_BY_SCHEDULE_ID_URL,
            api:postCall,
            apiPayload:{
                scheduleId:scheduleId,
                operator_code:global.operator_code
            }
        }
    }
}


export const getTrips=(travelsId)=>{
    return{
        type:GET_TRIPS,
        payload:{
            url:GET_TRIPS_URL,
            api:postCall,
            apiPayload:{
                travelsId:travelsId
            }
        }
    }
}

export const deleteTripById=(scheduleId,onSuccess)=>{
    return{
        type:DELETE_TRIP,
        payload:{
            url:DELETE_TRIP_URL,
            api:postCall,
            apiPayload:{
                "scheduleId" :scheduleId,
                operator_code:global.operator_code
            },
            onSuccess:onSuccess
        }
    }
}

export const updateTrip=(values,onSuccess)=>{
    if(isValidArray(values)&&isValidArray(values.fare_json)){
        values.fare_json = JSON.stringify(values.fare_json);
    }
    return {
        type:UPDATE_TRIP,
        payload:{
            url:UPDATE_TRIP_URL,
            api:postCall,
            apiPayload:{
                schedule:values,
                operator_code:global.operator_code
            },
            onSuccess:onSuccess
        }
    }
}
