  export const onFilteredChangeCustom=(origin,destination,data)=>{
    let filter_data=[],origins=[],destinations=[];
    
    data.forEach((row)=>{
        if(origins.indexOf(row.trip_origin)==-1){
            origins.push(row.trip_origin);
          }
          if(destinations.indexOf(row.trip_destination)==-1){
            destinations.push(row.trip_destination);
          }
       if((origin=='all'||origin==row.trip_origin)&&(destination=='all'||row.trip_destination==destination)){
         filter_data.push(row);
       }
    })
    return {destinations,origins,filter_data};
  }
