/**
 * @author Rejo Varghese
 * @description Sagas related to demand days
 */

import { takeLatest,put,call,all,fork } from 'redux-saga/effects'
import { GET_DRIVER_MANAGEMENT_BY_OPERATOR, SET_DRIVER_MANAGEMENT_BY_OPERATOR, ADD_ADMIN_DRIVER, GET_ALL_ADMIN_DRIVER, SET_ALL_ADMIN_DRIVER } from '../actions/action-types';
import { message } from 'antd';
import { getAllAdminDriver } from '../actions/admin-driver-actions';



export function* getDriverManagementByOperatorSaga(action) {
    try{
        const {api, url, apiPayload} = {...action.payload};
        const apiResult = yield call(api, url, apiPayload);
        if (apiResult.status === "SUCCESS") {
            let clientPayload = apiResult.payload;
            if (clientPayload.Status === "SUCCESS") {
                let serverPayload = clientPayload.Payload;
                yield put ({
                    type: SET_DRIVER_MANAGEMENT_BY_OPERATOR,
                    payload: serverPayload
                });
            }
            else {
                message.error(clientPayload.Message);
            }
        }
    }
    catch(err) {
        console.error("Error in getting driver management", err);
    }
}

function* watchGetDriverManagementByOperator () {
    yield takeLatest (GET_DRIVER_MANAGEMENT_BY_OPERATOR, getDriverManagementByOperatorSaga);
}


export function* getAllAdminDriverSaga(action) {
    try{
        const {api, url, apiPayload} = {...action.payload};
        const apiResult = yield call(api, url, apiPayload);
        if (apiResult.status === "SUCCESS") {
            let clientPayload = apiResult.payload;
            if (clientPayload.Status === "SUCCESS") {
                let serverPayload = clientPayload.Payload;
                yield put ({
                    type: SET_ALL_ADMIN_DRIVER,
                    payload: serverPayload
                });
            }
            else {
                message.error(clientPayload.Message);
            }
        }
    }
    catch(err) {
        console.error("Error in getting driver management", err);
    }
}

function* watchGetAllAdminDriver () {
    yield takeLatest (GET_ALL_ADMIN_DRIVER, getAllAdminDriverSaga);
}


export function* addAdminDriverSaga(action) {
    try{
        const {api, url, apiPayload, onSuccess} = {...action.payload};
        const apiResult = yield call(api, url, apiPayload);
        let fetchDriverManagement = getAllAdminDriver();
        if (apiResult.status === "SUCCESS") {
            let clientPayload = apiResult.payload;
            if (clientPayload.Status === "SUCCESS") {
                yield* getAllAdminDriverSaga(fetchDriverManagement);
                message.success(clientPayload.Message);
                onSuccess();
            }
            else {
                message.error(clientPayload.Message);
            }
        }
    }
    catch(err) {
        console.error("Error in adding driver management", err);
    }
}

function* watchAddAdminDriverSaga () {
    yield takeLatest (ADD_ADMIN_DRIVER, addAdminDriverSaga);
}

export function*  watchAdminDriverManagementSagas() {
    yield all([
        fork(watchGetDriverManagementByOperator),
        fork(watchAddAdminDriverSaga),
        fork(watchGetAllAdminDriver)
    ])
}
