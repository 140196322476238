import { isValidArray , getValuesOfJSonObject} from './../../../utils/utilities';

export const getRouteStartTime = (routes,city)=>{
    let startTime = null , originDay=false;
    if(isValidArray(routes)&&routes.length>0){
        routes.forEach((ele)=>{
                if(ele.origin===city){
                    startTime = ele.start_time;
                    originDay = ele.origin_day
                }
        })
    }
    return {startTime,originDay};
}

export const getBoadringPointCities=(routes)=>{
    var origin_cities=[],origin_cities_no_dup=[];


    for(var i=0;i<routes.length;i++){
        origin_cities[i]=routes[i].origin;
    }

    origin_cities_no_dup = [...new Set(origin_cities)];

    // origin_cities_comma_separated=origin_cities_no_dup.join(',');
    
    return origin_cities_no_dup;

}

export const getBoardingPointsInCityTree=(boardingPoints)=>{
    // const treeData = [
    //     {
    //       title: 'Node1',
    //       value: '0-0',
    //       children: [
    //         {
    //           title: 'Child Node1',
    //           value: '0-0-1',
    //         },
    //         {
    //           title: 'Child Node2',
    //           value: '0-0-2',
    //         },
    //       ],
    //     },
    //     {
    //       title: 'Node2',
    //       value: '0-1',
    //     },
    //   ];

        let formateTreeDate ={

        };

        if (isValidArray(boardingPoints)) {
            boardingPoints.forEach(element => {
                if(isValidArray(formateTreeDate[element.city])){
                    formateTreeDate[element.city].children.push({
                        title:element.name,
                        value:JSON.stringify(element)
                    })
                }else{
                    formateTreeDate[element.city]={
                        title:element.city,
                        value:element.city,
                        selectable:false,
                        // disabled:true,
                        children:[
                            {
                                title:element.name,
                                value:JSON.stringify(element)
                            }
                        ]
                    }
                }
            });
        }
      return getValuesOfJSonObject(formateTreeDate);
}


export const getBoardingPointsNameAsKey=(boardingPoints)=>{
    let boardingAddressLocationsNameasKey={}
    for (let index = 0; index < boardingPoints.length; index++) {
        const element = boardingPoints[index];
        boardingAddressLocationsNameasKey[element.name]=element;
    }
    return boardingAddressLocationsNameasKey;
}


export const validateTripClosePoint=(boardingPoints , selectedBoardingPoint)=>{
    let count_route_close_point=0,count_trip_close_point=0;
    for(var i=0;boardingPoints.length>i;i++){
                if(boardingPoints[i].id!=selectedBoardingPoint.pickUpLocationId){
                    var check=selectedBoardingPoint.city==boardingPoints[i].city&&(boardingPoints[i].route_close_point==1||boardingPoints[i].route_close_point==true);
                    if(check){
                        count_route_close_point=count_route_close_point+1;
                    }
                    if(boardingPoints[i].trip_close_point){
                        count_trip_close_point=count_trip_close_point+1;
                    }
            }
       }
        if(count_route_close_point==0&&count_trip_close_point==0){
            return true;
        }else{
            if (count_route_close_point>0) {
                if(selectedBoardingPoint.route_close_point){
                    return "Route close point already exits";
                }else{
                    return true;
                }
            }else{
                if(selectedBoardingPoint.trip_close_point){
                    return "Trip close point already exits";
                }else{
                    return true;
                }
            }
        }
}


// if(findresponce.Status=="SUCCESS"){
//                                         let Payload = findresponce.Payload;
//                                         this.setState({
//                                           addressLocations:DuplicateRemove_JSON(this.state.boarding_data,Payload.addressLocations)
//                                         },()=>{
//                                           var url=HOST_URL + "/api/getAddressLocations?City="+destination_cities_comma_separated;
//                                             fetch(url).
//                                             then((Responce)=>Responce.json()).
//                                             then((findresponce)=>
//                                             {
//                                               if(findresponce.Status=="SUCCESS"){
//                                                 let Payload = findresponce.Payload;
//                                                   this.setState({
//                                                   addressLocationsdrop:DuplicateRemove_JSON(this.state.drop_data,Payload.addressLocations)
//                                                   })
//                                                 }
//                                             })
      
//                                         })