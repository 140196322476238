import React  from 'react';
import "../assets/styles/main.scss";
import { MasterPriceListRoot } from '../components/master-price-list/master-price-list-root/master-price-list.root.component';
import { Typography } from 'antd';
import { VerifyPagePermission } from '../components/verify-page-permission-component/verify-page-permission.component';
import Permissions from "../utils/permissions-constant";

const { Title } = Typography;


export const MasterPriceListPage = (props) => {

    const renderChildren =   <div style={{position:'relative',top:'10px'  , margin: '20px'}}>
                                <Title level={4}>Schedules Groups</Title>
                                <MasterPriceListRoot
                                
                                />
                            </div>

    return (
        <VerifyPagePermission 
            pagePermission={Permissions.pagePermission.ADMIN_VIEW_MASTER_PRICE_PAGE}
            renderChildren={renderChildren}
            onPermissionSuccess={()=>{

            }}
            />
    );
}
