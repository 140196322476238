/**
 * @author Sanjay
 * @description Reducer for the components in home page
 */
import {
  BUSES_INFO, BUSES_TEMPALTE_INFO,
  BUS_ACTION_SUCCESS,
  BUS_TABLE_LOADING,
  SEATS_INFO,
  RE_NAME_SEAT_NAME,
  RESET_SEAT_INFO,
  SET_SEAT_TYPES,
  RESET_BUSES,
  SEAT_TYPES_LOADING,
  RESET_ALL,
  SET_SEATER_TO_SLEEPER,
  SET_AMENITIES} from '../actions/action-types';
import {sortJsonObject} from '../utils/utilities';

const initialState = {
    busesData: [],
    action:false,
    loading:true,
    seatsData:[],
    allowSeatRename:false,
    seatTypes:[],
    seatTypesLoading:false,
    busesTemplateData: [],
    amenities: []
}

export const busReducer = (state = initialState,action) => {
    let newState = Object.assign({},state);
    switch (action.type) {

        case SEAT_TYPES_LOADING:{
            let seatTypesLoading= action.payload;
            return {
                ...newState,
                seatTypesLoading:seatTypesLoading
            }  
        }

        case SET_SEAT_TYPES:{
           ; let{
                seatTypes
            } = action.payload
            newState["seatTypes"] = seatTypes;
            return {
                ...newState,
                seatTypes:seatTypes
            }     
        }

        case RE_NAME_SEAT_NAME :{
            let{
                reNameSeatNumber,seatObject,seatGroupId,onFailure,onSuccess
            } = action.payload;
            let seatsData  = [...newState["seatsData"]];
            let errFlag = false;
            for(var i=0;i<seatsData.length;i++){
                    if(reNameSeatNumber===seatsData[i].seat_number&&seatObject.id!=seatsData[i].id){
                        errFlag=true;
                        break;
                    }
                    if(seatsData[i].id==seatObject.id&&!errFlag){
                        seatsData[i].seat_number=reNameSeatNumber;
                        seatsData[i].seat_group_id=seatGroupId;
                    }
                    if(seatObject.seat_number==seatsData[i].adjacent_seat_number&&!errFlag){
                        seatsData[i].adjacent_seat_number=reNameSeatNumber;
                    }
                }
                if(errFlag){
                    onFailure();
                    return {
                        ...state
                    }
                }else{
                    onSuccess();
                    return {
                        ...newState,
                        seatsData:[...seatsData]
                    }  
                }
                  
        }

        case SET_SEATER_TO_SLEEPER :{
            let{
                seatType,seatObject,onFailure
            } = action.payload
            let intialSeats  = Object.assign({},state);
            let seatsData  = [...newState["seatsData"]];
            let errFlag = false;
            for(var i=0;i<seatsData.length;i++){
                if(seatType===seatsData[i].sleeper&&seatObject.id!=seatsData[i].id){
                    errFlag=false;
                }
                if(seatsData[i].id==seatObject.id&&!errFlag){
                    seatsData[i].sleeper=seatType;
                }
            }

            if(errFlag){
                onFailure();
                return {
                    ...newState,
                    seatsData:[...intialSeats]
                }
            }else{
                return {
                    ...newState,
                    seatsData:[...seatsData]
                }  
            }
        }

        case BUSES_INFO:
            newState["busesData"] = sortJsonObject(action.payload,'description',false);
            return newState;

        case BUSES_TEMPALTE_INFO:
            newState["busesTemplateData"] = action.payload;

        case BUS_ACTION_SUCCESS :
            newState["action"] = action.payload;
            return newState;

        case BUS_TABLE_LOADING :
            newState["loading"] = action.payload;
            return newState;

       case RESET_SEAT_INFO:
             newState["seatsData"] = [];
             return newState;

        case SET_AMENITIES:
            newState['amenities'] = action.payload;
            return newState;

      case SEATS_INFO :{
        newState["seatsData"] = action.payload.seatsData;
        newState["allowSeatRename"] = action.payload.allowSeatRename;
        return {
            ...newState,
            seatsData:[...newState.seatsData],
            allowSeatRename:newState.allowSeatRename
        };
      }

     case RESET_BUSES :{
        newState["seatsData"] = [];
        return {
            ...newState,
            seatsData:[...newState.seatsData],

        }   
     }
       

      case RESET_ALL :
            newState["busesData"] = [];
            newState["action"] = false;
            newState["loading"] = false;
            newState["seatsData"] = [];
            return newState;


        default:
            return state;
    }
}
