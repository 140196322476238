import React  from 'react';
import "../assets/styles/main.scss";
import { LoginComponent } from '../components/login-component/login-component';

export const LoginPage = (props) => {

    return (
        <div>
              <LoginComponent 
              />
        </div>
    );
}