import React , {useEffect} from 'react';
import "../assets/styles/main.scss";
import { loginAction, setAppReady } from  '../actions/login-actions';
import { useDispatch , useSelector} from 'react-redux';
import { ACCESS_CODE_STORAGE_KEY } from './../app.config';
import { isValid } from '../utils/utilities';
import { loginReducerSelector } from '../selectors/login-selectors';
import { LoginPage } from './login.page';
import { Spin , Row} from 'antd';
import { useNavigate , useLocation} from "react-router-dom";

export const SpalshPage = () => {

  const dispatch = useDispatch();
  let accessCode=localStorage.getItem(ACCESS_CODE_STORAGE_KEY);

  const navigate = useNavigate();
  const location = useLocation();

  const {
    isLoggedIn ,
    isWebAppReady
  } = useSelector (loginReducerSelector);

  useEffect(()=>{
    if(isValid(accessCode)&&!isLoggedIn){
      dispatch(loginAction(accessCode,null,null,{navigate,location}));
    }else{
      dispatch(setAppReady(true));
    }
  },[]);

    if(!isWebAppReady){
        return   <Row type="flex" justify="center" align="center" style={{padding:'30px'}}>
                      <Spin size="large" />
                 </Row>
    }

    return (
      <LoginPage />
   );
    
}
