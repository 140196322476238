/**
 * @author Rejo Varghese
 * @description Reducer for the components in demand days
 */
import { SET_CITIES, RESET_ALL, SET_DEMAND_DAYS, CLEAR_DEMAND_DAYS } from '../actions/action-types';

const initialState = {
  cities: null,
  demandDaysData: null
}
  
export const demandDaysReducer = (state = initialState,action) => {
  let newState = Object.assign({},state);
  switch (action.type) {
    case SET_CITIES:
      let cities = action.payload;
      newState["cities"] = cities;
      return newState;

    case SET_DEMAND_DAYS:
      let demandDaysData = action.payload;
      newState['demandDaysData'] = demandDaysData;
      return newState;

    case CLEAR_DEMAND_DAYS:
      newState['demandDaysData'] = null;
      return newState;
      
    case RESET_ALL :
      newState["cities"] = null;
      newState['demandDaysData'] = null;
      return newState;
      
    default:
      return state;
  }
}
  