import { Typography , Row , Form,Input , Button , Checkbox ,TreeSelect, TimePicker , Col, message , Layout , Select} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'
import { getPickupLocationsByScheduleId , createBoardingPointsAction , updateBoardingPoints , deleteBoardingPoint} from '../../../actions/trip-actions';
import { isValid , isValidArray } from '../../../utils/utilities';
import { getBoardingPointsInCityTree , validateTripClosePoint , getRouteStartTime} from './boarding-points.module';
import { get24HoursTime , getAmorPmofTime } from '../../../utils/dates-util';
import moment from 'moment';
import { showConfirm , removeDuplicates} from '../../../utils/utilities';
import { ExportBoardingPoints } from './../export-boarding-points/export-boarding-points';
import { ImportBoardingPoints } from './../import-boarding-points/import-boarding-points';

const {
    Option
} = Select;

const { Footer } = Layout;

const {Text} = Typography;

export const BoardingPointForm = (props) => {

    const {
        tripId ,
        boardingAddressLocations,
        boardingFormData,
        setBoardingFormData,
        boardingPoints,
        showDeleteUpdateButtons,
        selectedTrip,
        routes
    } = props;


    const [treeData,setTreeDate] = useState([]);
    // const [disableNextDay,setDisableNextDay] = useState(false);
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    useEffect(()=>{
        let filteredBoardingPoints=removeDuplicates(boardingPoints,boardingAddressLocations,'location','name')
        setTreeDate(getBoardingPointsInCityTree(filteredBoardingPoints));
    },[boardingPoints,boardingAddressLocations]);

    const handleSubmit=(e,buttonType)=>{
        e.preventDefault();
        form.validateFields()
        .then(values => {
            let {
                timing,
                is_temporary,
                route_close_point,
                trip_close_point,
                bordingPoint,
                phoneNo,
                origin_day
            } = values;
            timing=get24HoursTime(timing);
          if(buttonType=='add'){
            bordingPoint = JSON.parse(bordingPoint);
            let phone_number= isValid(phoneNo)?phoneNo:bordingPoint.phone_number;
            let address=bordingPoint.address;
            let landmark=bordingPoint.landmark;
            let city=bordingPoint.city;
            let location=bordingPoint.name;
            let latitude=bordingPoint.latitude;
            let longitude=bordingPoint.longitude;
            let address_id = bordingPoint.id;
            
            let boardingDetails={
                'updated_by' : global.user_name,
                location,latitude,longitude,schedule_id:tripId,timing,address,phone_number,landmark,city,route_close_point,trip_close_point,is_temporary,origin_day,address_id};
            let errorMessage = validateTripClosePoint(boardingPoints,boardingDetails);
            if(errorMessage==true){
               dispatch(createBoardingPointsAction([boardingDetails],onSuccess));
            }else{
                message.error(errorMessage);
            }
        }else if(buttonType=='update'){
            let pickUpLocationId = boardingFormData.id;
            let address=boardingFormData.address;
            let landmark=boardingFormData.landmark;
            let city=boardingFormData.city;
            let location=bordingPoint;
            let latitude=boardingFormData.latitude;
            let longitude=boardingFormData.longitude;
            let boardingDetails={
                'updated_by' : global.user_name,
                pickUpLocationId,location,latitude,longitude,tripId,timing,address,phone_number:phoneNo,landmark,city,route_close_point,trip_close_point,is_temporary,origin_day};
            let errorMessage = validateTripClosePoint(boardingPoints,boardingDetails);
            if(errorMessage==true){
                dispatch(updateBoardingPoints(boardingDetails,onSuccess));
            }else{
                message.error(errorMessage);
            }
        }
        })
        .catch(errorInfo => {
          console.log({errorInfo});
        })
    }

    const deleteAction=()=>{
        showConfirm(
            ()=>{
                dispatch(deleteBoardingPoint(boardingFormData.id,onSuccess));
            },
            '',
            ''
          )
    }

    useEffect(()=>{
        fillForm();
    },[boardingFormData])

    const fillForm=()=>{
        if(isValidArray(boardingFormData)&&isValid(boardingFormData.id)){
          let{
            is_temporary,
            timing,
            phone_number,
            route_close_point,
            trip_close_point,
            location,
            origin_day
          }=boardingFormData;
          form.setFieldsValue({ 
                bordingPoint:location,
                timing:moment(timing,'HH:mm A') ,
                is_temporary:is_temporary,
                phoneNo:phone_number,
                route_close_point:route_close_point,
                trip_close_point:trip_close_point,
                origin_day:origin_day
             });
         }else{
               form.resetFields();
         }
  }

      useEffect(()=>{
        getBoardingPoints();
      },[tripId]);

      const getBoardingPoints=()=>{
        if(isValid(tripId)){
            dispatch(getPickupLocationsByScheduleId(tripId));
        }
    }

      const onSuccess=()=>{
        getBoardingPoints();
        onReset();
      }

      const onReset = () => {
        form.resetFields();
        setBoardingFormData({});
      };

      let isBoardingSelected = isValid(boardingFormData)&&boardingFormData.id?true:false;
      const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      };

      const setPhoneNumber=(selectedBoardingPoint)=>{
          let {startTime} = getRouteStartTime(routes,selectedBoardingPoint.city);
          if(isValid(startTime)){
            if(isValid(selectedBoardingPoint.phone_number)){
                form.setFieldsValue({ 
                    phoneNo:selectedBoardingPoint.phone_number,
                    timing:moment(startTime,'HH:mm A')
                 });
            }else if(isValid(selectedBoardingPoint.landline_number)){
                form.setFieldsValue({ 
                    phoneNo:selectedBoardingPoint.landline_number,
                    timing:moment(startTime,'HH:mm A')
                 });
            }   
          }else{
            form.setFieldsValue({ 
                bordingPoint:null
             }); 
            message.error(`Pickup city is ${selectedBoardingPoint.city} but no such route origin!`)
          }
      }

  return (
    <Row type='flex'>
            <Form
                {...layout}
                className="form"
                form={form}
                name="basic"
                initialValues={{ origin_day:0,is_temporary:false,trip_close_point:false,route_close_point:false , timing:isValid(selectedTrip.start_time)?moment(selectedTrip.start_time,'HH:mm A'):null}}
                >
                <Form.Item
                    label="Boarding point"
                    name="bordingPoint"
                    rules={[{ 
                        required: true, 
                        message: 'Please input your boarding point!'
                     }]}
                >
                    <TreeSelect
                        // treeIcon={true}
                        showSearch
                        style={{ width: '100%' }}
                        dropdownStyle={{  overflow: 'auto' }}
                        treeData={treeData}
                        placeholder="Select boarding point"
                        treeDefaultExpandAll
                        onChange={(value)=>{
                          let parsedJson = JSON.parse(value);
                          setPhoneNumber(parsedJson);
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="Boarding time"
                    name="timing"
                    rules={[{ 
                        required: true, 
                        message: 'Please input your boarding point!'
                     }]}
                >
                       <TimePicker 
                       use12Hours 
                       format='h:mm A'
                       popupClassName={"time-picker-to-not-display-footer"}
                       onSelect={(value)=>{
                        let selectedDepTime=moment(value, "h:mm a").format("HH:mm:ss");
                        let selectedTimeAmorPm = getAmorPmofTime(selectedDepTime);
                        let tripTimeAmorPm = getAmorPmofTime(selectedTrip.start_time);
                        let originDay=false;
                         if(selectedTimeAmorPm!=tripTimeAmorPm){
                            originDay=true;
                         }
                           form.setFieldsValue({
                             timing:value,
                             origin_day:originDay
                           })
                         }}
                       />
                </Form.Item>
                <Form.Item
                    label="Phone no"
                    name="phoneNo"
                    rules={[{ required: true, message: 'Please input your phone no!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    valuePropName="checked"
                    label="Trip close point"
                    name="trip_close_point"
                    rules={[{ required: false, message: 'Please input your trip close point!' }]}
                >
                    <Checkbox  />
                </Form.Item>

                <Form.Item
                    valuePropName="checked"
                    label="Route close point"
                    name="route_close_point"
                    rules={[{ required: false, message: 'Please input your route close point!' }]}
                >
                    <Checkbox  />
                </Form.Item>

                <Form.Item
                    valuePropName="checked"
                    label="Temporary"
                    name="is_temporary"
                    rules={[{ required: false, message: 'Please input your temporary!' }]}
                >
                    <Checkbox />
                </Form.Item>
                {/* <Form.Item
                    valuePropName="checked"
                    label="Next day"
                    name="origin_day"
                    rules={[{ required: false, message: 'Please input next day!' }]}
                >
                                <Checkbox />                           
                </Form.Item> */}
                    <Footer>
                            <Row justify="space-between" type="flex" gutter={[16,16]}>
                                <Col>
                                    <Button 
                                    disabled={isBoardingSelected}
                                    style={{background:'#2EC17C'}}
                                    onClick={(e)=>{
                                        handleSubmit(e,'add');
                                    }}
                                    >
                                            <Text>Add</Text>
                                </Button>   
                                </Col>
                                {isValid(showDeleteUpdateButtons)&&showDeleteUpdateButtons?
                                <>
                                <Col>
                                    <Button type="primary"
                                    onClick={(e)=>{
                                        handleSubmit(e,'update');
                                    }}
                                    >
                                            Update
                                </Button>   
                                </Col>
                                <Col>
                                    <Button type="danger"
                                    disabled={!isBoardingSelected}
                                    onClick={()=>{
                                        deleteAction()
                                    }}
                                    >
                                            Delete
                                </Button>   
                                </Col>
                                <Col>
                                    <Button onClick={()=>{
                                        setBoardingFormData({});
                                    }}>
                                            Clear
                                    </Button>   
                                </Col>
                                </>
                                :
                                null
                                }
                            </Row>
                            <Row justify="space-around" type="flex">
                                <Col>
                                    <ExportBoardingPoints 
                                        selectedTrip={selectedTrip}
                                        routes={routes}
                                    />
                                </Col>
                                <Col>
                                    <ImportBoardingPoints 
                                        selectedTrip={selectedTrip} 
                                        routes={routes}
                                    />
                                </Col>
                            </Row>
                    </Footer>           
            </Form>
    </Row>
  );
};