import { getAPIDateFormat } from '../../../utils/dates-util';

export const formateDataForDayWise = (data)=>{
    let  formateData={};
      if(data!=undefined){
        data.forEach((row)=>{
          formateData[getAPIDateFormat(row.start_date)]=row;
        });
      }
      return formateData;
  }

  export const getRespectiveSchedule=(schedule,scheduleId)=>{
    let trips=[];
    for (var i = 0; i < schedule.length; i++) {
    if(schedule[i].id==scheduleId){
      trips=schedule[i].trips;
      break;
      }
    }
    return formateDataForDayWise(trips);
  }