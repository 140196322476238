import React  from 'react';
import { EditTripForm } from '../edit-trip-form/edit-trip-form.component';
import { RoutesComponent } from '../routes-component/routes.componet';
import { BoardingPointsComponent } from '../boarding-points-component/boarding-points.component';
import { DropPointsComponent} from '../drop-points-component/drop-points.component';

export const renderMenuContent = (key,formData,updateformData) => {
    switch(key) {

      case "tripDetails":   return <EditTripForm formData={formData} updateformData={updateformData} />;
      case "routeDetails":   return <RoutesComponent formData={formData} />;
      case "boardingDetails": return <BoardingPointsComponent />;
      case "dropDetails":  return <DropPointsComponent />;

      default:      return <h1>No project match</h1>
    }
  }
