/**
 * @author Rejo Varghese
 * @description Deman days table module
 */
import React  from 'react';
import {Typography} from 'antd';

const {Text} = Typography;

export const getDemandDaysColumns=()=>{

  const demandColumns =[
    { 
      title: 'Sl No.',
      dataIndex: 'id',
      width: '10%',
      render: (text,record,index)=> (<Text>{index+1}</Text>)
    },
    {
        title: 'ORIGIN',
        dataIndex: 'origin_city',
        key: 'origin_city',
        // filters:origins,
        // onFilter: (value, record) => record.origin_city.indexOf(value) === 0,
      },
      {
        title: 'DESTINATION',
        dataIndex: 'destination_city',
        key: 'destination_city',
        // filters : destinations,
        // onFilter: (value, record) => record.destination_city.indexOf(value) === 0,
      },
      {
        title: 'Dates',
        dataIndex: 'days',
        key: 'days',
        // render:(text,record) =>
        // (
        //      <a>
        //          {getFormattedTime(text)}
        //      </a>
        // )
      }
];

return demandColumns;

}

