import React,{useEffect, useState} from 'react';
import { Typography } from 'antd';
import { isValid } from './../../utils/utilities';

const { Text } = Typography;

const getTranformValue =(val)=>{
    if(isValid(val)){
        return val+'deg';
    }
    return '0deg';
}


const getReserveTranformValue =(val)=>{
    if(isValid(val)){
        return -val+'deg';
    }
    return '0deg';
}


export const Seat = (props) => {
    let {
        classname,
        object,
        onSeatCLick,
        currentSchedule,
        isSelected,
        selectedSeatNumber,
        isOnlyOneSeatSelected,
        disableSeat
    } = {...props};
    const [toogleSeat, updatetoogleSeat] = useState(isSelected);

    useEffect(()=>{
        updatetoogleSeat(isSelected);
    },[isSelected])

    const updateSelectedSeat = (e) => {
        let bool = onSeatCLick(object,currentSchedule);
        updatetoogleSeat(bool);
    };

    let className = classname;
    if(isOnlyOneSeatSelected&&selectedSeatNumber==object.seat_number||!isOnlyOneSeatSelected&&toogleSeat){
        className=className+ " selected";
    }

    if(disableSeat){
        className=className+" booked";
    }

    const styles = { 
        transform: `rotate(${getTranformValue(object.seat_orientation)})` ,
        transformOrigin: '0'
    };

    const seatNoStyles = { 
        transform: `rotate(${getReserveTranformValue(object.seat_orientation)})` ,
        // transformOrigin: '0'
    };

    if(object.seat_number==="Empty"){
        if(object.sleeper){
            return <div className='sleeperGap' style={styles}></div>
        }else{
            return <div className='seatGap' style={styles}></div>
        }
    }

    return (
        <div className={className}
        style={styles} 
        onClick={(e) => {
                updateSelectedSeat(e);
            }} data-testid = {object.seat_number} id={object.seat_number}>
                  <div className="seat-number" style={seatNoStyles}>{object.seat_number}</div>
                  <div className="seat-line" />
        </div>
    );
};
