import React , {useState}  from 'react';
import { Button , Modal,Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { busTemplateSelector } from '../../../selectors/bus-selectors';
import { isValidArrayWithValues } from '../../../utils/utilities';
import { fetchSeatLayoutByTemplateId } from './bus-template.module';
import { SeatLayoutContainer } from '../../trip-components/seat-layout-container.component';
import { loadingAction } from '../../../actions/loading-actions';
import './bus-template.style.scss';

const {
    Option
} = Select;


export const BusTemplateComponent = (props) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [templateSeatLayOut, setTemplateSeatLayout] = useState([]);
    const [selectedTemplateId, setSelectTemplateId] = useState(null);

    const busesTemplateData = useSelector(busTemplateSelector);

    const dispatch = useDispatch();

    const setLoading=(loading)=>{
        dispatch(loadingAction(loading));
    }  

    const {
        onChange
    } = props;

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        onChange(selectedTemplateId);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const busTemplateOptions = (option) => {
        return <Option key={option.id}>{option.template_name}</Option>
    }
    
    return (
        <div>
            <Button onClick={showModal} >Select Template</Button>
            <Modal title="Select Template" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className='seat-layout-block'>
                    {isValidArrayWithValues(busesTemplateData)?
                            <Select
                                className='select-template-btn'
                                showSearch
                                placeholder={"Select bus template"}
                                style={{minWidth:'350px'}}
                                onSelect={(value)=>{
                                    setLoading(true);
                                    setTemplateSeatLayout([]);
                                    fetchSeatLayoutByTemplateId(value,(seatLayout)=>{
                                        setLoading(false);
                                        setTemplateSeatLayout(seatLayout);
                                        setSelectTemplateId(value);
                                    },()=>{
                                        setLoading(false);
                                    });
                                }}>
                                {busesTemplateData.map(busTemplateOptions)}
                            </Select>:
                    null}
                    <SeatLayoutContainer 
                            seatsData={templateSeatLayOut}
                            selectSeat={null}
                            blockedSeats={[]}
                            selectedSeatNumber={null}
                            isOnlyOneSeatSelected={false}
                            isAllSeatsDisable={true}
                    />
                </div>
            </Modal>
        </div>
    );
}
