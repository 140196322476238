import React  from 'react';
import "../assets/styles/main.scss";
import { Typography } from 'antd';
import { connect } from 'react-redux';
import { loginReducerSelector } from "../selectors/login-selectors";
import { AddressComponent } from '../components/address-component/address-component';
import { getAddressDetailsAction } from '../actions/address-actions';
import { addressReducerSelector } from "../selectors/address-selectors";
import { VerifyPagePermission } from '../components/verify-page-permission-component/verify-page-permission.component';
import Permissions from "../utils/permissions-constant";

const { Title } = Typography;

export const AddressBook = (props) => {
  const {
          loginReducer ,
          addressReducer,
          getAddressDetailsAction
        } = {...props}

    
        const  renderChildren =  <div style={{position:'relative',top:'10px'  , margin: '30px'}}>
                                    <Title level={4}>Address Book </Title>
                                    <AddressComponent getAddressDetailsAction={getAddressDetailsAction}
                                                      addressReducer={addressReducer}/>
                                  </div>
    return (       
          <VerifyPagePermission 
          pagePermission={Permissions.pagePermission.ADMIN_VIEW_ADDRESS_PAGE}
          {...loginReducer}
          renderChildren={renderChildren}
          onPermissionSuccess={()=>{
   
          }}
          />
    );
}


const mapReduxStateToProps = reduxState => {
    return {
      loginReducer : loginReducerSelector(reduxState) ,
      addressReducer : addressReducerSelector(reduxState)
    }
}

const mapReduxActionsToProps = dispatch => {
return {
    getAddressDetailsAction : () => dispatch(getAddressDetailsAction())
  }
}

export const AddressBookPage = connect(mapReduxStateToProps,
    mapReduxActionsToProps)(AddressBook);
