import React  from 'react';
import { Table , Tree} from 'antd';
import {CarryOutOutlined}  from '@ant-design/icons';
const { TreeNode } = Tree;

export const UserPrivillegeTable = (props) => {

    const {
        userPrivilleges,
        formData ,
        setFormData
    } = props;

    const renderComponentTable=({record,rowIndex ,viewIndex,dataIndex})=>{
        return {
          props: {
            className:"",
          },
         children:
                   <Tree
                      showLine={true}
                      >
                        <TreeNode icon={<CarryOutOutlined />} title="" key={viewIndex}>
                                  {record.map(printTreePoint)}
                        </TreeNode>
                    </Tree>,
        };
      }

    const printTreePoint = (option) => {
        return  <TreeNode icon={<CarryOutOutlined />} title={option} key={option+Math.random()} />
      };

    const columns = [
      {
        title: 'User Type',
        dataIndex: 'agent_privilege',
        key: 'agent_privilege',
        align:'center',
        ellipsis: true,
      },
      {
        title: 'Actions',
        dataIndex: 'agent_actions',
        ellipsis: true,
        render: (record,rowIndex ,viewIndex) => {
          let dataIndex='pickUpDetais';
          return renderComponentTable({record,rowIndex ,viewIndex,dataIndex});
          }
      },
    ];

    return (
        <div>
            <Table dataSource={userPrivilleges}
                   columns={columns}
                   pagination={{ pageSize: 5 }}
                   rowClassName={(record, index)=>{
                    let rowClassName="";
                        if(record.id== formData.id){
                            rowClassName=rowClassName+" TableRowClicked"
                        }
                        return  rowClassName
                  }}                   
                  onRow={(record, rowIndex) => {
                      return {
                        onClick: event => {
                            setFormData(record);
                        }, // click row
                        onDoubleClick: event => {

                        }, // double click row
                        onContextMenu: event => {

                        }, // right button click row
                        onMouseEnter: event => {

                        }, // mouse enter row
                        onMouseLeave: event => {

                        }, // mouse leave row
                      };
                    }}
                   />
        </div>
    );
}
