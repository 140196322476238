/**
 * All sagas for Address Saga must be defined here
 * @author Manne Hareesh
 */

import { takeLatest,put,call,all,fork } from 'redux-saga/effects'
import { GET_BUSES_ACTION ,
         BUSES_INFO ,
         BUS_TABLE_LOADING ,
         GET_SEAT_LAYOUT_BY_BUS_ID_ACTION ,
         SEATS_INFO,
         CREATE_BUS,
         RESET_SEAT_INFO,
         DELETE_BUS,
         UPDATE_BUS_SEAT_LAYOUT,
         GET_SEAT_TYPES,
         SEAT_TYPES_LOADING,
         SET_SEAT_TYPES,
         LOADING_ACTION,
         UPDATE_BUS_DETAILS,
         VALIDATE_SEAT,
         GET_BUS_TEMPALTE, FETCH_AMENITIES_BY_BUS_ID,
         BUSES_TEMPALTE_INFO, FETCH_AMENITIES, SET_AMENITIES, ADD_AMENITIES_TO_BUS
      } from '../actions/action-types';
import { message } from 'antd';

/**
 *
 * @param {Get Bus Details} action
 */
export function* getBusDetails(action) {
    try{
        const {
            api,
            url,
            apiPayload
        } = {...action.payload};
        yield put({
            type: BUS_TABLE_LOADING,
            payload: true
        });
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload = apiResult.payload;
          if(clientPayload.Status=='SUCCESS'){
            let serverPayload = clientPayload.Payload
            yield put({
                type: BUSES_INFO,
                payload: serverPayload.buses
            });
            yield put({
                type: BUS_TABLE_LOADING,
                payload: false
            });
            message.success(clientPayload.Message);
          }else{
            message.error(clientPayload.Message);
          }
        }

    }
    catch(error){
        console.error("Error in buses",error);
    }
}


/**
 *
 * @param {Get seat types Details} action
 */
 export function* getSeatTypes(action) {
    try{
        const {
            api,
            url,
            apiPayload
        } = {...action.payload};
        yield put({
            type: SEAT_TYPES_LOADING,
            payload: true
        });
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload = apiResult.payload;
          if(clientPayload.Status=='SUCCESS'){
            let serverPayload = clientPayload.Payload;
            yield put({
                type: SET_SEAT_TYPES,
                payload: {seatTypes:serverPayload.seatTypes}
            });
            message.success(clientPayload.Message);
          }else{
            message.error(clientPayload.Message);
          }
        }
        yield put({
            type: SEAT_TYPES_LOADING,
            payload: false
        });

    }
    catch(error){
        yield put({
            type: SEAT_TYPES_LOADING,
            payload: false
        });
        console.error("Error in fetching seat types",error);
    }
}

export function* getBusSeatLayoutDetails(action) {
    try{

        yield ({
            type:RESET_SEAT_INFO,
        })
        const {
            api,
            url
        } = {...action.payload};
        const apiResult = yield call(api,url);
        if(apiResult.status=="SUCCESS"){
          let clientPayload = apiResult.payload;
          if(clientPayload.Status=='SUCCESS'){
            let serverPayload = clientPayload.Payload;
            yield put({
                type: SEATS_INFO,
                payload: {seatsData:serverPayload.seat_layout,allowSeatRename:serverPayload.allowSeatRename}
            });
          }else{
            message.error(clientPayload.Message);
          }
        }


    }
    catch(error){
        console.error("Error in getting bus layout",error);
    }
}

export function* createBus(action){
    try{
        yield put({
            type: LOADING_ACTION,
            payload: true
        });
        const {
            api,
            url ,
            apiPayload,
            onSuccess
            } = {...action.payload};
        const apiResults = yield call(api , url,apiPayload);
        if(apiResults.status=="SUCCESS"){
            let clientPayload = apiResults.payload;
            if(clientPayload.Status=='SUCCESS'){
               message.success(clientPayload.Message);
               onSuccess();
            }else{
              message.error(clientPayload.Message);
            }
          }
          yield put({
            type: LOADING_ACTION,
            payload: false
        });
       }catch(error){
        yield put({
            type: LOADING_ACTION,
            payload: false
        });
            console.error("Error in create bus",error);
        }  
    }
 
 function* watchCreateBusSaga () {
    yield takeLatest(CREATE_BUS,createBus)
 }


 export function* updateBus(action){
    try{
        yield put({
            type: LOADING_ACTION,
            payload: true
        });
        const {
            api,
            url ,
            apiPayload,
            onSuccess
            } = {...action.payload};
        const apiResults = yield call(api , url,apiPayload);
        if(apiResults.status=="SUCCESS"){
            let clientPayload = apiResults.payload;
            if(clientPayload.Status=='SUCCESS'){
               message.success(clientPayload.Message);
               onSuccess();
            }else{
              message.error(clientPayload.Message);
            }
          }
          yield put({
            type: LOADING_ACTION,
            payload: false
        });
       }catch(error){
        yield put({
            type: LOADING_ACTION,
            payload: false
        });
            console.error("Error in create bus",error);
        }  
    }
 
 function* watchUpdateBusSaga () {
    yield takeLatest(UPDATE_BUS_DETAILS,updateBus)
 }

 export function* updateBusSeatLayout(action){
    try{
        yield put({
            type: LOADING_ACTION,
            payload: true
        });
        const {
            api,
            url ,
            apiPayload,
            onSuccess
            } = {...action.payload};
        const apiResults = yield call(api , url,apiPayload);
        if(apiResults.status=="SUCCESS"){
            let clientPayload = apiResults.payload;
            if(clientPayload.Status=='SUCCESS'){
               message.success(clientPayload.Message);
               onSuccess();
            }else{
              message.error(clientPayload.Message);
            }
          }
          yield put({
            type: LOADING_ACTION,
            payload: false
        });
       }catch(error){
            yield put({
                type: LOADING_ACTION,
                payload: false
            });
            console.error("Error in update bus",error);
        }  
    }
 
 function* watchUpdateBusSeatLayout () {
    yield takeLatest(UPDATE_BUS_SEAT_LAYOUT,updateBusSeatLayout)
 }

 export function* deleteBus(action){
    try{
        yield put({
            type: LOADING_ACTION,
            payload: true
        });
        const {
            api,
            url ,
            apiPayload,
            onSuccess
            } = {...action.payload};
        const apiResults = yield call(api , url,apiPayload);
        if(apiResults.status=="SUCCESS"){
            let clientPayload = apiResults.payload;
            if(clientPayload.Status=='SUCCESS'){
               message.success(clientPayload.Message);
               onSuccess();
            }else{
              message.error(clientPayload.Message);
            }
          }
          yield put({
            type: LOADING_ACTION,
            payload: false
        });
       }catch(error){
            yield put({
                type: LOADING_ACTION,
                payload: false
            });
            console.error("Error in delete bus",error);
        }  
    }

export function* validateSeatSaga(action) {
    try{
        const {api, url, apiPayload} = {...action.payload};
        const apiResult = yield call(api,url, apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload = apiResult.payload;
          if(clientPayload.Status=='SUCCESS'){
           message.success(clientPayload.Message);
          }else{
            message.error(clientPayload.Message);
          }
        }
    }
    catch(error){
        console.error("Error in validating bus seat layout",error);
    }
}

export function* getBusTemplateSaga(action) {
    try{
        const {
            api,
            url,
            apiPayload
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload = apiResult.payload;
          if(clientPayload.Status=='SUCCESS'){
            let serverPayload = clientPayload.Payload
            yield put({
                type: BUSES_TEMPALTE_INFO,
                payload: serverPayload
            });
            message.success(clientPayload.Message);
          }else{
            message.error(clientPayload.Message);
          }
        }

    }
    catch(error){
        console.error("Error in buses template",error);
    }
}

export function* fetchAmenitiesSaga(action) {
    try{
        const {api, url, apiPayload} = {...action.payload};
        const apiResult = yield call(api,url, apiPayload);
        if(apiResult.status=="SUCCESS"){
            let clientPayload = apiResult.payload;
            if(clientPayload.Status=='SUCCESS'){
                let serverPayload = clientPayload.Payload;
                yield put({
                    type: SET_AMENITIES,
                    payload: serverPayload.amenities
                });
            }else{
                message.error(clientPayload.Message);
            }
        }
    }
    catch(error){
        console.error("Error in fetching amenities",error);
    }
}

export function* fetchAmenitiesByBusIdSaga(action) {
    try{
        const {api, url, apiPayload, onSuccess} = {...action.payload};
        const apiResult = yield call(api,url, apiPayload);
        if(apiResult.status=="SUCCESS"){
            let clientPayload = apiResult.payload;
            if(clientPayload.Status=='SUCCESS'){
                let serverPayload = clientPayload.Payload;
                onSuccess(serverPayload);
            }else{
                message.error(clientPayload.Message);
            }
        }
    }
    catch(error){
        console.error("Error in fetching amenities",error);
    }
}

export function* addAmenitiesToBusSaga(action) {
    try{
        const {api, url, apiPayload} = {...action.payload};
        const apiResult = yield call(api,url, apiPayload);
        if(apiResult.status=="SUCCESS"){
            let clientPayload = apiResult.payload;
            if(clientPayload.Status=='SUCCESS'){
                message.success(clientPayload.Message);
            }else{
                message.error(clientPayload.Message);
            }
        }
    }
    catch(error){
        console.error("Error in adding amenities",error);
    }
}
 
 function* watchDeleteBusSaga () {
    yield takeLatest(DELETE_BUS,deleteBus)
 }

 function* getSeatTypesSaga () {
    yield takeLatest(GET_SEAT_TYPES,getSeatTypes)
 }

function* watchGetBusDetails () {
    yield takeLatest(GET_BUSES_ACTION,getBusDetails)
}

function* watchGetBusSeatLayoutDetails () {
    yield takeLatest(GET_SEAT_LAYOUT_BY_BUS_ID_ACTION,getBusSeatLayoutDetails)
}

function* watchValidateSeatSagas() {
    yield takeLatest(VALIDATE_SEAT, validateSeatSaga)
}

function* watchGetBusTemplateSaga () {
    yield takeLatest(GET_BUS_TEMPALTE, getBusTemplateSaga)
}

function* watchFetchAmenitiesSagas() {
    yield takeLatest(FETCH_AMENITIES, fetchAmenitiesSaga)
}

function* watchFetchAmenitiesByBusIdSagas() {
    yield takeLatest(FETCH_AMENITIES_BY_BUS_ID, fetchAmenitiesByBusIdSaga)
}

function* watchAddAmenitiesToBusSagas() {
    yield takeLatest(ADD_AMENITIES_TO_BUS, addAmenitiesToBusSaga)
}

export function*  watchBusesDetailsPage() {
    yield all([
        fork(watchGetBusDetails),
        fork(watchGetBusSeatLayoutDetails),
        fork(watchDeleteBusSaga),
        fork(watchCreateBusSaga),
        fork(watchUpdateBusSeatLayout),
        fork(getSeatTypesSaga),
        fork(watchUpdateBusSaga),
        fork(watchValidateSeatSagas),
        fork(watchGetBusTemplateSaga),
        fork(watchFetchAmenitiesSagas),
        fork(watchFetchAmenitiesByBusIdSagas),
        fork(watchAddAmenitiesToBusSagas),
    ])
}
