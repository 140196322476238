/**
 * All sagas for Locations Saga must be defined here
 * @author Manne Hareesh
 */

import { takeLatest,put,call,all,fork } from 'redux-saga/effects'
import { 
    GET_LOCATIONS_ACTION , 
    LOCATIONS_INFO, 
    INSERT_LOCATION_ACTION , 
    UPDATE_LOCATION_ACTION , 
    DELETE_LOCATION_ACTION  , 
    LOCATION_TABLE_LOADING ,
    LOADING_ACTION,
    SET_MASTER_CITIES,
    GET_MASTER_CITIES
} from '../actions/action-types';
import { message } from 'antd';

/**
 *
 * @param {Get location Details} action
 */
export function* getLocationsDetails(action) {
    try{
        const {
            api,
            url,
            apiPayload
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        yield put({
            type: LOCATION_TABLE_LOADING,
            payload: true
        });
        if(apiResult.status=="SUCCESS"){
          let clientPayload=apiResult.payload;
          if(clientPayload.Status=='SUCCESS'){
            let serverPayload=clientPayload.Payload;
            yield put({
                type: LOCATIONS_INFO,
                payload: serverPayload.cities
            });
            yield put({
                type: LOCATION_TABLE_LOADING,
                payload: false
            });
          }
        }
    }
    catch(error){
        console.error("Error in getting locations ",error);
    }
}

export function* insertLocationsAction(action) {
   try{
    yield put({
        type: LOADING_ACTION,
        payload: true
    });
       const {
           api,
           url,
           apiPayload,
           onSuccess
       } = {...action.payload};
       const apiResult = yield call(api,url,apiPayload);
       if(apiResult.status=="SUCCESS"){
         let payload=apiResult.payload;
         if(payload.Status=='SUCCESS'){
           message.success(payload.Message);
           onSuccess();
         }else{
           message.error(payload.Message);
         }
       }else{
        message.error("Some thing went wrong while create city, try again");
       }
       yield put({
            type: LOADING_ACTION,
            payload: false
         });
   }
   catch(error){
    message.error("Some thing went wrong while create city, try again");
    console.error("Error in inserting location",error);
       yield put({
        type: LOADING_ACTION,
        payload: false
     });
   }
}

export function* updateLocationsAction(action) {
   try{
    yield put({
        type: LOADING_ACTION,
        payload: true
     });
       const {
           api,
           url,
           apiPayload,
           onSuccess
       } = {...action.payload};
       const apiResult = yield call(api,url,apiPayload);
       if(apiResult.status=="SUCCESS"){
         let payload=apiResult.payload;
        if(payload.Status=='SUCCESS'){
           message.success(payload.Message);
           onSuccess();
         }else{
            message.error(payload.Message);
         }
       }else{
        message.error("Some thing went wrong while update city, try again");
       }
       yield put({
        type: LOADING_ACTION,
        payload: false
     });
   }
   catch(error){
       console.error("Error in updating location",error);
       message.error("Some thing went wrong while update city, try again");
       yield put({
        type: LOADING_ACTION,
        payload: false
     });
   }
}

export function* deleteLocationsAction(action) {
   try{
      yield put({
          type: LOADING_ACTION,
          payload: true
      });
       const {
           api,
           url,
           apiPayload,
           onSuccess
       } = {...action.payload};

       const apiResult = yield call(api,url,apiPayload);
       if(apiResult.status=="SUCCESS"){
         let payload=apiResult.payload;
         if(payload.Status=='SUCCESS'){
           message.success(payload.Message);
           onSuccess();
         }else{
           message.error(apiResult.payload.Message);
         }
       }else{
        message.error("Some thing went wrong while delete city, try again");
       }
       yield put({
          type: LOADING_ACTION,
          payload: false
       });
   }
   catch(error){
        yield put({
            type: LOADING_ACTION,
            payload: false
        });
        message.error("Some thing went wrong while delete city, try again");
        console.error("Error in deleting location",error);
   }
}

export function* fetchMasterCities(action){
  try{
      const {
          api,
          url,
          apiPayload
      } = {...action.payload};
      const apiResult = yield call(api,url,apiPayload);
      if(apiResult.status==="SUCCESS"){
        let clientPayload=apiResult.payload;
       if(clientPayload.Status==='SUCCESS'){
         let serverPayload=clientPayload.Payload;
          yield put({
              type: SET_MASTER_CITIES,
              payload: serverPayload.cities
          });
        }
      }
  }
  catch(error){
      console.error("Error in getting trip expene",error);
  }
}

function* watchMasterCities () {
  yield takeLatest(GET_MASTER_CITIES,fetchMasterCities)
}

function* watchGetLocationsDetails () {
    yield takeLatest(GET_LOCATIONS_ACTION,getLocationsDetails)
}

function* watchInsertLocationsDetails () {
    yield takeLatest(INSERT_LOCATION_ACTION,insertLocationsAction)
}

function* watchUpdateLocationsDetails () {
    yield takeLatest(UPDATE_LOCATION_ACTION,updateLocationsAction)
}

function* watchDeleteLocationsDetails () {
    yield takeLatest(DELETE_LOCATION_ACTION,deleteLocationsAction)
}

export function*  watchLocationsDetailsPage() {
    yield all([
        fork(watchGetLocationsDetails),
        fork(watchInsertLocationsDetails),
        fork(watchUpdateLocationsDetails),
        fork(watchDeleteLocationsDetails),
        fork(watchMasterCities)
    ])
}
