/**
 * @author Sanjay
 * @description Reducer for the components in home page
 */
import {
        ADDRESSES_INFO ,
        ADDRESS_ACTION_SUCCESS ,
        ADDRESS_TABLE_LOADING  ,
        SET_DROP_POINTS_LOCATIONS,
        SET_BOARDING_POINTS_LOCATIONS,
        RESET_ALL  } from '../actions/action-types';
import {sortJsonObject} from '../utils/utilities';

const initialState = {
    addressData: [],
    action:false,
    loading:true,
    boardingAddressLocations:[],
    dropAddressLocations:[]

}

export const addressReducer = (state = initialState,action) => {
    let newState = Object.assign({},state);
    switch (action.type) {

        case SET_DROP_POINTS_LOCATIONS:{
            newState["dropAddressLocations"] = sortJsonObject(action.payload.addressLocations,'name',false);
            return newState;
        }

        case SET_BOARDING_POINTS_LOCATIONS:{
            newState["boardingAddressLocations"] = sortJsonObject(action.payload.addressLocations,'name',false);
            return newState;
        }
        case ADDRESSES_INFO:
            newState["addressData"] = action.payload;
            return newState;

        case ADDRESS_ACTION_SUCCESS :
            newState["action"] = action.payload;
            return newState;

        case ADDRESS_TABLE_LOADING :
            newState["loading"] = action.payload;
            return newState;

      case RESET_ALL :
            newState["addressData"] = [];
            newState["action"] = false;
            newState["loading"] = false;
            return newState;


        default:
            return state;
    }
}
