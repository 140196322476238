import { Typography , Row , Form , Button , Checkbox ,Select,TreeSelect, TimePicker ,Col , Layout, message} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'
import { getDropLocationsByScheduleId , createDropPoint,updateDropPoint,deleteDropPoint} from '../../../actions/trip-actions';
import { isValid ,isValidArray } from '../../../utils/utilities';
import { getDropPointsInCityTree , getRouteEndTime} from './drop-points.module';
import { get24HoursTime } from '../../../utils/dates-util';
import moment from 'moment';
import { showConfirm ,removeDuplicates} from '../../../utils/utilities';
import { ImportDropPoints } from './../import-drop-points/import-drop-points';
import { ExportDropPoints } from './../export-drop-points/export-drop-points';

const { Footer } = Layout;

const {Text} = Typography;
const {Option} = Select;

export const DropPointForm = (props) => {

    const {
        tripId ,
        dropAddressLocations,
        showDeleteUpdateButtons,
        dropFormData,
        setDropFormData,
        dropPoints,
        selectedTrip,
        routes
    } = props;


    const [treeData,setTreeData] = useState([]);
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    useEffect(()=>{
      let filteredDropPoints = removeDuplicates(dropPoints,dropAddressLocations,'location','name');
      setTreeData(getDropPointsInCityTree(filteredDropPoints));
  },[dropPoints,dropAddressLocations]);

    useEffect(()=>{
        if(isValid(selectedTrip.end_time)){
            form.setFieldsValue({
                timing:moment(selectedTrip.end_time,'HH:mm A')
            })
        }
    },[selectedTrip]);


    const handleSubmit=(e,buttonType)=>{
      e.preventDefault();
      form.validateFields()
      .then(values => {
          let {
            timing,is_temporary,
            destination_day,
            dropPoint
         } = values;
          timing=get24HoursTime(timing);
        if(buttonType=='add'){
          dropPoint=JSON.parse(dropPoint);
          let phone_number =dropPoint.phone_number
          let address=dropPoint.address;
          let landmark=dropPoint.landmark;
          let city=dropPoint.city;
          let location=dropPoint.name;
          let latitude=dropPoint.latitude;
          let longitude=dropPoint.longitude;
          let address_id = dropPoint.id;
          dispatch(createDropPoint({
            updated_by:global.user_name,address_id,
            location,latitude,longitude,schedule_id:tripId,timing,address,phone_number,landmark,city,is_temporary,destination_day},onSuccess));
      }else if(buttonType=='update'){
          let phone_number =dropFormData.phone_number;
          let dropLocationId = dropFormData.id;
          let address=dropFormData.address;
          let landmark=dropFormData.landmark;
          let city=dropFormData.city;
          let location=dropPoint;
          let latitude=dropFormData.latitude;
          let longitude=dropFormData.longitude;
          dispatch(updateDropPoint({
            updated_by:global.user_name,
            dropLocationId,location,latitude,longitude,tripId,timing,address,phone_number,landmark,city,is_temporary,destination_day},onSuccess));
      }
      })
      .catch(errorInfo => {
        console.log({errorInfo});
      })
  }

  const deleteAction=()=>{
    showConfirm(
        ()=>{
            dispatch(deleteDropPoint(dropFormData.id,onSuccess));
        },
        '',
        ''
      )
}

      const getDropPoints=()=>{
        if(isValid(tripId)){
          dispatch(getDropLocationsByScheduleId(tripId));
        }
      }

      useEffect(()=>{
         getDropPoints();
      },[tripId]);


      const onSuccess=()=>{
        getDropPoints();
        setDropFormData({})
      }

      const printOption = (option) => {
        return <Option  value={option.name} data={option}>{option.name}</Option>
      };

      useEffect(()=>{
        fillForm();
    },[dropFormData])

    const fillForm=()=>{
        if(isValidArray(dropFormData)&&isValid(dropFormData.id)){
          let{
            is_temporary,
            timing,
            location,
            destination_day
          }=dropFormData;
          form.setFieldsValue({
                dropPoint:location,
                timing:moment(timing,'HH:mm') ,
                is_temporary:is_temporary,
                destination_day:destination_day
             });
         }else{
               form.resetFields();
         }
  }


      let isDropSelected = isValid(dropFormData)&&dropFormData.id?true:false;

      const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      };

  return (
    <Row type='flex'>
            <Form
                {...layout}
                form={form}
                name="basic"
                initialValues={{ is_temporary:false,trip_close_point:false,route_close_point:false,timing:isValid(selectedTrip.end_time)?moment(selectedTrip.end_time,'HH:mm A'):null}}
                className="form"
                >
                <Form.Item
                    label="Drop point"
                    name="dropPoint"
                    rules={[{
                        required: true,
                        message: 'Please input your drop point!'
                     }]}
                >
                   <TreeSelect
                        showSearch
                        style={{ width: '100%' }}
                        dropdownStyle={{ overflow: 'auto' }}
                        treeData={treeData}
                        placeholder="Select drop point"
                        treeDefaultExpandAll
                        onChange={(value)=>{
                          let parseJson = JSON.parse(value);
                          let endTime=getRouteEndTime(routes,parseJson.city);
                          if(isValid(endTime)){
                            form.setFieldsValue({
                              timing:moment(endTime,'HH:mm A')
                            })
                          }else{
                            form.setFieldsValue({ 
                              dropPoint:null
                            }); 
                            message.error(`Drop city is ${parseJson.city} but no such route destination!`)
                          }
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="Drop time"
                    name="timing"
                    rules={[{
                        required: true,
                        message: 'Please input your drop time!'
                     }]}
                >
                       <TimePicker
                       use12Hours
                       format='h:mm A'
                       popupClassName={"time-picker-to-not-display-footer"}
                       onSelect={(value)=>{
                           form.setFieldsValue({
                             timing:value
                           })
                         }}
                       />
                </Form.Item>
                {/* <Form.Item
                    valuePropName="checked"
                    label="Next day"
                    name="destination_day"
                    rules={[{ required: false, message: 'Please input destination day!' }]}
                >
                          <Checkbox />                                 
                </Form.Item> */}
                <Form.Item
                    valuePropName="checked"
                    label="Temporary"
                    name="is_temporary"
                    rules={[{ required: false, message: 'Please input your trip close point!' }]}
                >
                    <Checkbox />
                </Form.Item>
                <Footer>
                  <Row justify="space-between" type="flex" gutter={[16,16]}>
                      <Col>
                                    <Button
                                    style={{background:'#2EC17C'}}
                                    disabled={isDropSelected}
                                    onClick={(e)=>{
                                        handleSubmit(e,'add');
                                    }}
                                    >
                                       <Text>Add</Text>
                                </Button>
                       </Col>
                        {isValid(showDeleteUpdateButtons)&&showDeleteUpdateButtons?
                                <>
                                <Col>
                                    <Button
                                    type="primary"
                                    onClick={(e)=>{
                                        handleSubmit(e,'update');
                                    }}
                                    >
                                            Update
                                </Button>
                                </Col>
                                <Col>
                                    <Button type="danger"
                                    disabled={!isDropSelected}
                                    onClick={()=>{
                                        deleteAction()
                                    }}
                                    >
                                            Delete
                                </Button>
                                </Col>
                                <Col>
                                    <Button onClick={()=>{
                                        setDropFormData({});
                                    }}>
                                            Clear
                                </Button>
                                </Col>
                                </>
                                :
                                null
                          }
                       </Row>
                       <Row justify="space-around" type="flex">
                                <Col>
                                    <ExportDropPoints 
                                        selectedTrip={selectedTrip}
                                        routes={routes}
                                    />
                                </Col>
                                <Col>
                                    <ImportDropPoints 
                                        selectedTrip={selectedTrip} 
                                        routes={routes}
                                    />
                                </Col>
                            </Row>
                  </Footer>
            </Form>
    </Row>
  );
};
