import React  from 'react';
import "../assets/styles/main.scss";
import { VerifyPagePermission } from '../components/verify-page-permission-component/verify-page-permission.component';
import Permissions from "../utils/permissions-constant";
import { TravelsManagementRoot} from '../components/travel-management-component/travel-management-component/travel-management.component';
import { Typography } from 'antd';

const { Title } = Typography;


export const TravelManagementPage = (props) => {

    const renderChildren=  <div style={{position:'relative',top:'10px'  , margin: '20px'}}>
                                <Title level={4}>Travel Management</Title>
                                <TravelsManagementRoot 
                                
                                />
                            </div>

    return (
         <VerifyPagePermission 
            pagePermission={Permissions.pagePermission.ADMIN_TO_VIEW_TRAVEL_MANAGEMENT_PAGE}
            renderChildren={renderChildren}
            onPermissionSuccess={()=>{

            }}
            />
    );
}
