import { isValidArray } from "../../../utils/utilities"


export const getUnqiueDestionationCities=(routes)=>{
    let cities=[],unqiueCities=[];
    if(isValidArray(routes)&&routes.length>0){
        routes.forEach(element => {
            if(unqiueCities.indexOf(element.destination)===-1){
                unqiueCities.push(element.destination);
                cities.push({label:element.destination,value:element.destination});
            }
        });
    }    
    return cities;
}