import React  from 'react';
import { Table } from 'antd';
import { incrementDays , getDateInReadableFormat} from '../../../utils/dates-util';
import { DonutChartComponent } from '../../donut-chart-component/donut-chart.component';
import { isValidArray ,isValidNumber  } from '../../../utils/utilities';

export const PriceListTable = (props) => {

    const {
        selectedDate,
        priceList
    } = props;    

  const priceListColumns = [
        {
            title: '',
            dataIndex: 'description0',
            key: 'description0',
        },
        {
            title: getDateInReadableFormat(selectedDate,false,true),
            align:'center',
            children: [
                {
                    title: 'Occupancy',
                    dataIndex: 'Occupancy0',
                    key: 'Occupancy0',
                    align:'center',
                    render: (text,row,index) => {
                        return (
                            <span>
                            {isValidArray(text)&&isValidNumber(text.Blocked)?
                            <DonutChartComponent {...text} />
                            :
                            <div>{text}</div>
                            }
                        </span>
                        )
                    }
                },
                {
                    title: 'Base Fare',
                    dataIndex: 'baseFare0',
                    key: 'baseFare0',
                }
            ],
        },
        {
            title: getDateInReadableFormat(incrementDays(selectedDate,1),false,true),
            align:'center',
            children: [
                {
                    title: 'Occupancy',
                    dataIndex: 'Occupancy1',
                    key: 'Occupancy1',
                    align:'center',
                    render: (text,row,index) => {
                        return (
                            <span>
                            {isValidArray(text)&&isValidNumber(text.Blocked)?
                            <DonutChartComponent {...text} />
                            :
                            <div>{text}</div>
                            }
                        </span>
                        )
                    }
                },
                {
                    title: 'Base Fare',
                    dataIndex: 'baseFare1',
                    key: 'baseFare1',
                }
            ]
        },
        {
            title: getDateInReadableFormat(incrementDays(selectedDate,2),false,true),
            align:'center',
            children: [
                {
                    title: 'Occupancy',
                    dataIndex: 'Occupancy2',
                    key: 'Occupancy2',
                    align:'center',
                    render: (text,row,index) => {
                        return (
                            <span>
                            {isValidArray(text)&&isValidNumber(text.Blocked)?
                            <DonutChartComponent {...text} />
                            :
                            <div>{text}</div>
                            }
                        </span>
                        )
                    }
                },
                {
                    title: 'Base Fare',
                    dataIndex: 'baseFare2',
                    key: 'baseFare2',
                }
            ],
        }
  ];


    return (
        <div>
                <Table 
                    columns={priceListColumns}
                    dataSource={priceList}
                    pagination={false}
                />  
        </div>
    );
}