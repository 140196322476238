/**
 * @author Rejo Varghese
 * @description Demand Days related action
 */

import { GET_CITIES, GET_DEMAND_DAYS, INSERT_DEMAND_DAYS, CLEAR_DEMAND_DAYS } from './action-types';
import { GET_CITIES_URL, GET_DEMAND_DAYS_URL, INSERT_DEMAND_DAYS_URL } from "../utils/api-url";
import { getCall, postCall } from "../utils/network.util";


export const getCitiesAction= () => {
    return {
        type: GET_CITIES,
        payload: {
            url: GET_CITIES_URL,
            api: postCall
        }
    }
}

export const getDemandDaysAction = (originList, destinationList) => {
    return {
        type: GET_DEMAND_DAYS,
        payload: {
            url: GET_DEMAND_DAYS_URL,
            api: postCall,
            apiPayload:{
                originList,
                destinationList
            },
        }
    }
}

export const clearDemandDays = () => {
    return {
        type: CLEAR_DEMAND_DAYS,
        payload: {}
    }
}

// export const clearSelectedTrip = ()=>{
//     return{
//         type:CLEAR_SELECTED_TRIP,
//         payload:{}
//     }
// }

export const insertDemandDaysAction = ({originList, destinationList, selectedDates}) => {
    return {
        type: INSERT_DEMAND_DAYS,
        payload: {
            url: INSERT_DEMAND_DAYS_URL,
            api: postCall,
            apiPayload:{
                originList, destinationList, selectedDates
            },
        }
    }
}
