import React, { useState } from 'react';
import { Row , Table} from 'antd';
import { TableCalenderCell } from '../schedule-calender-cell-component/schedule-calender-cell.component';
import { ExpandableScheduleCell } from '../expandable-cell-component/expandable-cell.component';

export const SchedulesTable = (props) => {

    const{
        dataSource,
        loading,
        selectedDate,
        rowSelection
    } = props;

    const [expandedRowKeys , setExpandedRowKeys] = useState([]);

    const columns = [
        {
          title: 'Trip Description',
          dataIndex: 'description',
          align:'center'
        },
        {
          title: 'Schedule',
          align:'center',
          render: (text, record) => (
                                <TableCalenderCell
                                  data={record.trips}
                                  scheduleId={record.id}
                                  className="table_calender"
                                  selectedDate={selectedDate}
                                  showNavigation={false}
                                />
               ),
        }
      ];
      
    return (
        <div>
                        <Table
                         rowSelection={rowSelection}
                         dataSource={dataSource!=undefined?dataSource:[]}
                         columns={columns}
                         pagination={false}
                         loading={loading}
                         rowKey={record => record.id}
                         expandedRowRender={record =>
                          <ExpandableScheduleCell
                          record={record}
                          scheduleId={record.id}
                          selectedDate={selectedDate}
                          inactiveExtender={false}
                           />}
                           expandedRowKeys={expandedRowKeys}
                           onRow={(record) => ({ onClick: () => {
                                  let newExpandedRowKeys=[record.id],oldExpandedKeys=expandedRowKeys;
                                  if(oldExpandedKeys.indexOf(record.id)!=-1){
                                    newExpandedRowKeys=[];
                                  }
                                    setExpandedRowKeys(newExpandedRowKeys);
                                  }
                                }
                              )}
                              expandIconColumnIndex={-1}
                              expandIconAsCell={false}
                        />
        </div>
    );
}
