import { Modal } from 'antd';

const { confirm } = Modal;

export function showConfirm(func,message,params,title='Do you want to delete these items?') {
  confirm({
    title: title,
    content: message ,
    onOk() {
        func(params);
    },
    onCancel() {},
    width:800
  });
}
