import React, { useEffect, useState }  from 'react';
import { Row,Col  , Typography ,Button ,InputNumber , Table , message} from 'antd';
import { DayPicker } from 'react-day-picker';
import { isSameDay } from 'date-fns';
import './master-price-list-table.style.scss';
import { getHolidays,getDataForGroup ,updateTripFare , updateHolidaysDays} from './master-price-list-table.module';
import { isValid } from '../../../utils/utilities';
import { ExpanderRoutesTable } from '../master-price-list-routes-table/master-price-list-routes-table.component';

const { Text , Title} = Typography;

export const MasterPriceListTable = (props) => {

    const {
        groupId
    } = props;
    
    // const dispatch = useDispatch();
    const [ calenderDate , setCalenderDate]=useState(new  Date());
    let [ selectedDays , setSelectedDays] = useState([]);
    const [expandedRowKeys , setExpandedRowKeys] = useState([]);
    const [tripsData , setTripsData] = useState([]);
    const [changedTripIds,setChangedTripIds] = useState([]);
    const [changedTripIdsWithFare,setChangedTripIdsWithFare] = useState({});
    const [changedTripRouteIdsWithFare,setChangedTripRouteIdsWithFare]=useState({});

    const handleDayClick=(day, { selected })=> {
        if (selected) {
          const selectedIndex = selectedDays.findIndex(selectedDay =>
            isSameDay(selectedDay, day)
          );
          selectedDays.splice(selectedIndex, 1);
        } else {
          selectedDays.push(day);
        }
        setSelectedDays([...selectedDays]);
      }


     const formValidation=()=>{
          updateTripFare({
            changedTripRouteIdsWithFare , changedTripIdsWithFare , changedTripIds,
            setChangedTripIdsWithFare,setChangedTripIds,setChangedTripRouteIdsWithFare
          });
      }

      useEffect(()=>{
          if(isValid(groupId)){
            getHolidays(groupId,setSelectedDays);
            getDataForGroup(calenderDate,groupId,setTripsData);
          }
      },[groupId]);

      const onChange=(date)=> {
        setCalenderDate(date);
        setExpandedRowKeys([]);
      }

     const onChangeTripRouteIds=(tripRoute)=>{
        changedTripRouteIdsWithFare[tripRoute.id]=tripRoute;
        setChangedTripRouteIdsWithFare(changedTripRouteIdsWithFare);
        let tripId=tripRoute.trip_id;
        if(changedTripIds.indexOf(tripId)==-1){
          changedTripIds.push(tripId);
          setChangedTripIds([...changedTripIds]);
        }
      }
      const renderComponentTable=({record,rowIndex ,viewIndex,dataIndex})=>{
        return {
          props: {
            className:record!=undefined&&record['isImportant']==1?"holiday":"",
          },
         children:  <span>
                        {record!=undefined&&record['trip']!=undefined&&record['trip']['fare']!=undefined&&
                        <Row type="flex" justify='space-between' >
                          <Col span={11}>
                              <InputNumber value={record['trip']['fare']}
                                      onChange={(value)=>{
                                          tripsData[viewIndex][dataIndex]['trip']['fare']=value;
                                          let tripId =  tripsData[viewIndex][dataIndex]['trip']['trip_id'];
                                          changedTripIdsWithFare[tripId]=tripsData[viewIndex][dataIndex]['trip'];
                                          setChangedTripIdsWithFare(changedTripIdsWithFare);
                                          if(changedTripIds.indexOf(tripId)==-1){
                                            changedTripIds.push(tripId);
                                            setChangedTripIds(changedTripIds);
                                          }
                                          setTripsData([...tripsData]);
                                      }}
                                      className='priceListTableInput'
                                      style={{width:'70px'}}
                                      />
                          </Col>
                        </Row>
                        }
                        {record!=undefined&&record['trip']==undefined&&
                        <Row type="flex" justify='center' >
                          <Col>
                              <Text strong>{record.date}</Text>
                          </Col>
                        </Row>
                        }
                     </span>,
        };
      }

       const headerRender=(name)=>{
        return <Text strong>
                {name}
                    <div>
                    <Text>(Fare)</Text>
                    </div>
               </Text>
        }

      const columns = [
        { title: <Text strong >DESCRIPTION</Text>,
         dataIndex: 'description' ,
         align:'center',
         ellipsis: true,
        },
        { title: headerRender('SUNDAY'),
         dataIndex: '0',
         align:'center',
         ellipsis: true,
         render: (record,rowIndex ,viewIndex) => {
           let dataIndex=0;
           return renderComponentTable({record,rowIndex ,viewIndex,dataIndex});
           },
         },
        {
          title: headerRender('MONDAY'),
          dataIndex: '1',
          align:'center',
          ellipsis: true,
          render: (record,rowIndex ,viewIndex) => {
            let dataIndex=1;
            return renderComponentTable({record,rowIndex ,viewIndex,dataIndex});
            },
        },
        {
          title: headerRender('THUESDAY'),
          dataIndex: '2',
          align:'center',
          ellipsis: true,
          render: (record,rowIndex ,viewIndex) => {
            let dataIndex=2;
            return renderComponentTable({record,rowIndex ,viewIndex,dataIndex});
            },
        },
        {
          title:  headerRender('WEDNESDAY'),
          dataIndex: '3',
          align:'center',
          ellipsis: true,
          render: (record,rowIndex ,viewIndex) => {
            let dataIndex=3;
            return renderComponentTable({record,rowIndex ,viewIndex,dataIndex});
            },
        }, {
           title: headerRender('THURSDAY'),
           dataIndex: '4',
           align:'center',
           ellipsis: true,
           render: (record,rowIndex ,viewIndex) => {
             let dataIndex=4;
             return renderComponentTable({record,rowIndex ,viewIndex,dataIndex});
             },
         },
         {
           title:  headerRender('FRIDAY'),
           dataIndex: '5',
           align:'center',
           ellipsis: true,
           render: (record,rowIndex ,viewIndex) => {
             let dataIndex=5;
             return renderComponentTable({record,rowIndex ,viewIndex,dataIndex});
             },
         },
         {
           title: headerRender('SATURDAY'),
           dataIndex: '6',
           align:'center',
           ellipsis: true,
           render: (record,rowIndex ,viewIndex) => {
             let dataIndex=6;
             return renderComponentTable({record,rowIndex ,viewIndex,dataIndex});
             },
         }
      ];
      
      const onTableRowExpand = (expanded,record)=>{
        var keys = [];
              if(expanded){
                  keys.push(record.uid);
              }
              setExpandedRowKeys(keys)
    //   this.setState({expandedRowKeys: keys});
      }

      let showUpdateButton = changedTripIds.length>0?true:false;

    return (
        <div>
         <Row gutter={[16, 16]} type="flex" justify="space-between" style={{padding:'10px'}}>
              <Col>
                  <Title>  {calenderDate.toLocaleString('default', { month: 'long' }) +"  "+calenderDate.getFullYear()}</Title>
               </Col>

              <Col>
                    <DayPicker
                      selectedDays={selectedDays}
                      onDayClick={handleDayClick}
                      onMonthChange={(month)=>{
                                                onChange(month);
                                              }}
                    />
              </Col>
          </Row>  
          <Row gutter={[16, 16]} type="flex" justify="end" style={{padding:'10px'}}>
              <Col>
              <Button type="primary"  style={{background:'#de4f4a'}}
               onClick={(e) =>{
               updateHolidaysDays(selectedDays,groupId,()=>{
                   getDataForGroup(calenderDate,groupId,setTripsData);
               });
               }}
               > Mark as Holiday</Button>
              </Col>
          </Row>
          <Row>
                <Table
                          columns={columns}
                          dataSource={tripsData}
                          pagination={false}
                          rowClassName={(record) =>{
                            return record.description=="Date"?"hideExpenderIcon":''
                            }}
                            expandedRowRender={(record,viewIndex) =>{
                                 return(
                                        <div>
                                            <ExpanderRoutesTable 
                                            data={tripsData[viewIndex]}
                                            onChangeTripRouteIds={onChangeTripRouteIds}
                                            />
                                        </div>
                                      );

                            }}
                            bordered
                            className={'priceListTable'}
                            expandedRowKeys={expandedRowKeys}
                            onExpand={onTableRowExpand}
                            defaultExpandedRowKeys={expandedRowKeys}
                            rowKey={record => record.uid}
                  />
          </Row>
            <Row gutter={[16, 16]} style={{padding:'10px'}}  type="flex" justify="end">
                <Col>
                    {showUpdateButton?
                        <Button type="primary"
                        onClick={formValidation}
                        style={{position:'fixed',top:'90vh',left:'50vw'}}
                        > Update</Button>
                        :
                        null
                    }
                </Col>
            </Row>
        </div>
    );
}
