import React  from 'react';
import './navbar.component.scss';
import { NavbarMenu } from '../navbar-menu/navbar-menu.component';
import { Typography ,Dropdown  , Row ,Col , Button , Avatar  } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
// import { OPERATOR_NAME } from '../../../app.config';
import { UserOutlined } from '@ant-design/icons';
import { AccountMenu } from '../account-menu-component/account-menu.component';
import { useSelector  } from 'react-redux';
import { loginReducerSelector } from '../../../selectors/login-selectors';
import packageJson from '../../../../../package.json';
import { isValid, pageRedirect } from '../../../utils/utilities';
import { useNavigate } from "react-router-dom";
import { refreshCache } from '../../../actions/home.actions';
import { useDispatch } from 'react-redux';
import  Permissions  from './../../../utils/permissions-constant';

const { Title , Text } = Typography;

const showMakeItLive =(userInfo)=>{
  if(userInfo.privilages.indexOf(Permissions.pagePermission.SHOW_MAKE_IT_LIVE_BUTTON) !== -1){
    return true;
  }else{
    return false;
  }
}

export const NavMenu = props => {

  let {
    userInfo,
    isLoggedIn
  } = useSelector(loginReducerSelector);
  let history = useNavigate();

  let dispatch = useDispatch();

  return (
          <Row className="top-header" justify="center" align="middle" >
            <Col span={4} offset={2}>
              {isLoggedIn?
              <Dropdown style={{float:'right'}}
                   overlay={  <NavbarMenu userInfo={userInfo}></NavbarMenu>} placement="bottomLeft">
                   <MenuOutlined />
               </Dropdown>
                :null            
            }
            </Col>
            <Col className="app-name-content" span={8} offset={2}>
                      <Row justify="center">
                          <Col>
                                  <Row>
                                      <Button type="link" 
                                      onClick={()=>{
                                        pageRedirect(history,'home');
                                      }}
                                      >
                                          <Title level={4} style={{color:'#00a5ff'}}>{isValid(userInfo.operator_name)?userInfo.operator_name:""}</Title>
                                      </Button>
                                  </Row>
                                  <Row justify="center">
                                      <Button type="text">
                                          <Text style={{color:'white'}} strong>(Admin Portal)</Text>
                                      </Button>
                                  </Row>
                            </Col>
                            <Col offset={2} justify="flex-end">
                                   <Row>
                                      <Button type="text">
                                      </Button>
                                  </Row>
                                  <Row>
                                      <Col>
                                           <Text style={{color:'white'}} strong>{"Version " + packageJson.version}</Text>
                                      </Col>
                                  </Row>
                            </Col>
                      </Row>
                       
           </Col>
           <Col span={4}>
              {isLoggedIn && showMakeItLive(userInfo)?
                <Button
                onClick={()=>{
                  dispatch(refreshCache());
                }}
                >
                  Make it live
                </Button>
                :
                null
              }           
            </Col>
            <Col span={4}>
            {isLoggedIn?
              <Dropdown style={{float:'right'}}
                overlay={<AccountMenu userInfo={userInfo} />} 
                placement="bottomLeft">
                  <Avatar size={64} icon={<UserOutlined />} />
              </Dropdown>
              :
              null
             }    
            </Col> 
          </Row>
  );
};


