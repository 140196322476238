import React , {useEffect, useState} from 'react';
import { VechicleManagementTable } from '../vechicle-management-table/vechicle-management-table.component';
import { useDispatch , useSelector } from 'react-redux';
import { getVechicles } from '../../../actions/vechicle-actions';
import { getBusDetailsAction } from '../../../actions/bus-actions';
import { busReducerSelector } from '../../../selectors/bus-selectors';
import { vechiclesReducerSelector } from '../../../selectors/vechicle-selectors';
import { VechicleManagementForm } from '../vechicle-management-form/vechicle-management-form.component';

export const VechicleManagementRoot = (props) => {


    const dispatch = useDispatch();
    const vechiclesReducer = useSelector(vechiclesReducerSelector);
    const busReducer=useSelector(busReducerSelector);

    const [formData,updateFormData] = useState({});

    useEffect(()=>{
        dispatch(getVechicles());
        dispatch(getBusDetailsAction());
    },[]);

    return (
        <div>
            <VechicleManagementTable 
            {...vechiclesReducer}
            {...busReducer}
            formData={formData}
            updateFormData={updateFormData}
            />
            <VechicleManagementForm 
            formData={formData}
            updateFormData={updateFormData}
            {...busReducer}
            />
        </div>
    );
}
