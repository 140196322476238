/**
 * @author Sanjay
 * @description Reducer for the components in home page
 */
import {
    SET_AGENTS,
    SET_AGENT_PRIVILLEGES,
    RESET_ALL  } from '../actions/action-types';
  
  const initialState = {
      agents: [],
      agentPrivilieges:[]
  }
  
  export const agentsReducer = (state = initialState,action) => {
      let newState = Object.assign({},state);
      switch (action.type) {
  
        case SET_AGENT_PRIVILLEGES:{
            newState["agentPrivilieges"] = action.payload;
            return newState;
         }

          case SET_AGENTS:
              newState["agents"] = action.payload;
              return newState;

        case RESET_ALL :
              newState["agents"] = [];
              return newState;
  
          default:
              return state;
      }
  }
  