import React , {useEffect,useState} from 'react';
import { WrappedLocationForm } from './location-form';
import { LocationTable } from './location-table';
import './location.component.scss';


export const LocationComponent = props => {
  const {
     getLocationDetailsAction , 
     locationReducer 
    }={...props};

    
  useEffect(() => {
     getLocationDetailsAction();
  },[]);

  const [formData, updateformData] = useState({});
  return (
      <div>
             <LocationTable
             loading={locationReducer.loading}
             dataSource={locationReducer.citiesData}
             updateformData={updateformData}
             selectedData={formData}/>
             <WrappedLocationForm
             formData={formData}
             updateformData={updateformData}
             getLocationDetailsAction={getLocationDetailsAction}
             />
      </div>
  );
};
