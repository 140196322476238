import React , { useEffect, useState} from 'react';
import { Row, DatePicker , Typography   , Button , Col , Table } from 'antd';
import {  incrementDays , decrementDays } from '../../../utils/dates-util';
import { getViacitiesDetails , tripColumns} from './edit-fare.module';
import {
    LeftOutlined,
    RightOutlined
   } from '@ant-design/icons';
import { EditFareDetailsPopUp }  from './edit-fare-pop-up.component';

const { Title } = Typography;
const { Text } = Typography;


export const EditFareDetails= (props) => {

    const {
        record,
        scheduleId,
        selectedDate
    } = props;
    
    const [viaCitiesDetails , setViaCitiesDetails]=useState([]);
    const [date,setDate]=useState(selectedDate);

    useEffect(()=>{
        getDetailsOfFare();
    },[]);

    const getDetailsOfFare=()=>{
        getViacitiesDetails(scheduleId,date,setViaCitiesDetails);
    }

    return (
     <div>
         <Row type="flex" style={{padding:'10px'}}>
                 <Col>
                        <Title level={4}> Via Cities</Title>
                 </Col>
                 <Col >
                    <Row type="flex" >
                            <Col>
                                <Button type="" onClick={()=>{
                                        setDate(decrementDays(date,1));
                                }}>
                                            <LeftOutlined />
                                </Button>
                            <DatePicker
                                    onChange={(date)=>{
                                        setDate(date);
                                    }}
                                    value={date}
                                    />
                                <Button type="" onClick={()=>{
                                            setDate(incrementDays(date,1));
                                    }}>
                                    <RightOutlined />
                                </Button>
                            </Col>      
                            <Col>
                             <Button type="danger" onClick={()=>{
                                 getDetailsOfFare();
                            }} >
                               <Text strong style={{color:'white'}}>Get</Text>
                             </Button>
                        </Col>
                    </Row>
                 </Col>
        </Row>
        <Row style={{padding:'10px'}}>
                    <Col>
                          <Table
                          dataSource={viaCitiesDetails}
                          columns={tripColumns(record.bus.seat_group_ids,record.bus.supported_fare)}
                          pagination={{ pageSize: 4 }}
                          />
                    </Col>
        </Row>
        <EditFareDetailsPopUp
        record={record}
        scheduleId={scheduleId}
        selectedDate={date}
        viaCitiesDetails={viaCitiesDetails}
        setViaCitiesDetails ={setViaCitiesDetails}
        />
      </div>
    );
}
