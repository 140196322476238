/**
 * @author Sanjay
 * @description Reducer for the components in home page
 */
import {
    SET_GST,
    RESET_ALL 
  } from '../actions/action-types';
  
  const initialState = {
        acCGST:null,
        acSGST:null,
        acIGST:null,
        nonacCGST:null,
        nonacSGST:null,
        nonacIGST:null
  }
  
  export const gstReducer = (state = initialState,action) => {
      let newState = Object.assign({},state);
      switch (action.type) {
  
          case SET_GST:
              let {
                acCGST,
                acSGST,
                acIGST,
                nonacCGST,
                nonacSGST,
                nonacIGST
              } = action.payload
              newState["acCGST"] = acCGST;
              newState["acSGST"] = acSGST;
              newState["acIGST"] = acIGST;
              newState["nonacCGST"] = nonacCGST;
              newState["nonacSGST"] = nonacSGST;
              newState["nonacIGST"] = nonacIGST;
              return newState;

        case RESET_ALL :
            newState["acCGST"] = null;
            newState["acSGST"] = null;
            newState["acIGST"] = null;
            newState["nonacCGST"] = null;
            newState["nonacSGST"] = null;
            newState["nonacIGST"] = null;
            return newState;
  
          default:
              return state;
      }
  }
  