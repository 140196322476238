import React, { useEffect, useState }  from 'react';
import { Row, Col , Typography , Select , Button , DatePicker , Radio} from 'antd';
import {
   LeftOutlined,
   RightOutlined
  } from '@ant-design/icons';
import { addMonth , subtractMonth} from '../../../utils/dates-util';
import { inactiveschedules , onFilteredChangeCustom} from './schedules-header.module';
 
const { Title } = Typography;
const { Option } = Select;
const { Text } = Typography;
const dateFormat = 'YYYY-MM-DD';
const { MonthPicker } = DatePicker;

export const ScheduleHeader = (props) => {

    const {
        selectedDate,
        setDate,
        rowKeys,
        reloadSchdules,
        setRowKeys,
        unFilteredData,
        setActiveSchedules
    } = props;

    const [origin,setOrigin]=useState('all');
    const [destination,setDestination]=useState('all');
    const [originsData,seOriginsData]=useState([]);
    const [destinationsData,setDestinationsData] =useState([]);
  
 const printOption = (option) => {
    return <Option key={option} value={option}>{option}</Option>;
  };

  const onSuccess=()=>{
    reloadSchdules();
    setRowKeys([]);
  }

  useEffect(()=>{
      let {
        destinations,origins,filter_data
      } = onFilteredChangeCustom(origin,destination,unFilteredData,1);
    setActiveSchedules(filter_data);
    seOriginsData(origins);
    setDestinationsData(destinations);
  },[unFilteredData]);

  useEffect(()=>{
    setData(1);
},[origin,destination]);

const setData=(onlyActive)=>{
    let {
        filter_data
    } = onFilteredChangeCustom(origin,destination,unFilteredData,onlyActive);
    setActiveSchedules(filter_data);
}

    return (
        <div>
            <Row justify="space-around"> 
            <Col>
                  <Title level={4}> Active Schedules</Title>
            </Col>
            <Col span={12}>
                    <div>
                        <Row gutter={[0, 16]}>
                                    <Col>
                                    <Text>Origin</Text>
                                    </Col>
                                    <Col offset={1}>
                                        <Select showSearch placeholder="Select Origin"
                                                        style={{width:200}}
                                                        value={origin}
                                                        optionFilterProp="children"
                                                        onChange={(value)=>{
                                                            setOrigin(value)

                                                    }}>
                                                    <Option key={"all"} value={"all"}>all</Option>
                                                {originsData.map(printOption)}
                                        </Select>
                                    </Col>
                                    <Col offset={1}>
                                                <Text>Destination</Text>
                                    </Col>
                                    <Col offset={1}>
                                                <Select showSearch placeholder="Select Destination"
                                                         style={{width:200}}
                                                         value={destination}
                                                         optionFilterProp="children"
                                                         onChange={(value)=>{
                                                            setDestination(value)
                                                }}>
                                                    <Option key={"all"} value={"all"}>all</Option>
                                                    {destinationsData.map(printOption)}
                                                </Select>
                                    </Col>
                            </Row>

                    </div>
            </Col>
          
            <Col >
                  <Button type="danger" onClick={()=>{
                        inactiveschedules(rowKeys,onSuccess);
                  }}>Make In active</Button>
            </Col>
            <Col>
                  <Button type="" onClick={()=>{
                          setDate(subtractMonth(selectedDate,1));
                  }}>
                            <LeftOutlined />
                  </Button>
                     <MonthPicker
                      onChange={()=>{

                      }}
                      value={selectedDate}
                      />
                  <Button type="" onClick={()=>{
                            setDate(addMonth(selectedDate,1));
                    }}>
                    <RightOutlined />
                  </Button>
            </Col>
        </Row>
        <Row gutter={[16,16]} justify="center">
            <Radio.Group 
            onChange={(e)=>{
                setData(e.target.value);
            }} 
            defaultValue={1}>
                <Radio value={1}>Active</Radio>
                <Radio value={0}>In Acive</Radio>
                <Radio value={-1}>All</Radio>
            </Radio.Group>
        </Row>
        </div>
    );
}
