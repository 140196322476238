import React  from 'react';
import { Layout ,Typography , Table} from 'antd';
import { sortJsonObject } from './../../../utils/utilities';

const { Header } = Layout;
const { Title , Text } = Typography;

const getUniqueOperatorCodes=(dataSource)=>{
  let operatorCodesInfo=[] , operatorCodes=[];
  dataSource.forEach(element => {
      if(operatorCodes.indexOf(element.operator_code)==-1){
        operatorCodes.push(element.operator_code);
        operatorCodesInfo.push({
          text:element.operator_code,
          value:element.operator_code
        });
    }
  });
  operatorCodesInfo = sortJsonObject(operatorCodesInfo,'text',false);
  return operatorCodesInfo;
}

export const TravelsManagementTable = props => {

  const { 
    dataSource , 
    updateformData , 
    selectedData , 
    loading
  }={...props};

  const columns = [
    {
      title: 'Phone number',
      dataIndex: 'phone_number',
    },
    {
      title:'Name',
      dataIndex:'name',
    },
    // {
    //   title:'Admin server url',
    //   dataIndex:'admin_server_url',
    // },
    {
      title:'Operator code',
      dataIndex:'operator_code',
      filters:getUniqueOperatorCodes(dataSource),
      onFilter: (value, record) => record.operator_code.indexOf(value) === 0,
    }
  ];

  return (
      <div>
            <Table dataSource={dataSource==undefined?[]:dataSource}
                    pagination={{ pageSize: 5 }}
                   columns={columns}
                   rowClassName={ (record, index) => {return record.id== selectedData.id?"TableRowClicked":""}   }
                   loading={loading}
                   rowKey={record => record.id}
                   onRow={(record, rowIndex) => {
                      return {
                        onClick: event => {
                          updateformData(record);
                        }, // click row
                        onDoubleClick: event => {

                        }, // double click row
                        onContextMenu: event => {

                        }, // right button click row
                        onMouseEnter: event => {

                        }, // mouse enter row
                        onMouseLeave: event => {

                        }, // mouse leave row
                      };
                    }}
                   />
      </div>
  );
};