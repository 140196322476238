import React, { useEffect }  from 'react';
import { Form , Button , Row , Layout  , Typography, InputNumber} from 'antd';
import { getGstsDetails , updateGsts} from '../../actions/gst-actions';
import { useDispatch , useSelector} from 'react-redux';
import { gstReducerSelector } from '../../selectors/gst-selectors';
import './gst.style.scss';

;const { Header, Content, Footer } = Layout;
const { Title , Text } = Typography;

export const GstComponent = (props) => {

    const [ form ] =Form.useForm();

    const dispatch = useDispatch();
    const gstReducer = useSelector(gstReducerSelector);
    console.log({gstReducer});

    useEffect(()=>{
        dispatch(getGstsDetails());
    },[]);

    useEffect(()=>{
        fillForm()
    },[gstReducer]);

    const fillForm=()=>{
        let{
            acCGST,
            acSGST,
            acIGST,
            nonacCGST,
            nonacSGST,
            nonacIGST
        } =gstReducer;
        form.setFieldsValue({
            acCGST:acCGST,
            acSGST:acSGST,
            acIGST:acIGST,
            nonacCGST:nonacCGST,
            nonacSGST:nonacSGST,
            nonacIGST:nonacIGST
        })    
    }

    const onFinish =(values)=>{
        dispatch(updateGsts({...values}));
    }
    
    return (
        <Layout>
                <Row justify="center" align="middle" style={{marginTop:'20px'}} >
                     <Title level={4}>GST Details Form</Title>
                </Row>
            <Content style={{marginTop:'20px'}}>
                <Row justify="center" align="middle" >
                        <Form 
                         form={form}
                         className="gst-form"
                         onFinish={onFinish}
                         >
                            <Form.Item
                                    label="AC CGST(%)"
                                    name="acCGST"
                                    rules={[{ required: true, message: 'Please input your ac cgst!' }]}
                                >
                                    <InputNumber />
                            </Form.Item>
                            <Form.Item
                                    label="AC SGST(%)"
                                    name="acSGST"
                                    rules={[{ required: true, message: 'Please input your ac sgst!' }]}
                                >
                                    <InputNumber />
                            </Form.Item>
                            <Form.Item
                                    label="NON AC CGST(%)"
                                    name="nonacCGST"
                                    rules={[{ required: true, message: 'Please input your non ac cgst!' }]}
                                >
                                    <InputNumber />
                            </Form.Item>
                            <Form.Item
                                    label="NON AC SGST(%)"
                                    name="nonacSGST"
                                    rules={[{ required: true, message: 'Please input your non ac sgst!' }]}
                                >
                                    <InputNumber />
                            </Form.Item>
                            <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                Update
                            </Button>
                            </Form.Item>
                        </Form>
                </Row>
            </Content>
        </Layout>
    );
}
