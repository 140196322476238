/**
 * @description Network util methods
 */

import { FAILURE_STATUS, SUCCESS_STATUS } from './constant';

function status(response) {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(new Error(response.statusText));
    }
}

function json(response) {
    return response.json();
}


export const postCall = (URL, payload) => {
    return new Promise((succeed, fail) => {
        fetch(URL, {
            method: "POST",
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify(payload)
        })
        .then(status)
        .then(json)
        .then(response => {
            succeed({
                "status": SUCCESS_STATUS,
                "payload": response
            });
        })
        .catch(error=>{
            fail({
                "status": FAILURE_STATUS,
                "error": error
            });
        })
    });
}

export const getCall = (URL, payload) => {
    return new Promise((succeed, fail) => {
        fetch(URL, {
            method: "GET",
            headers: {'Content-Type':'application/json'}
        })
        .then(status)
        .then(json)
        .then(response => {
            succeed({
                "status": SUCCESS_STATUS,
                "payload": response
            });
        })
        .catch(error => {
            fail({
                "status": FAILURE_STATUS,
                "error": error
            });
        })
    });
}
