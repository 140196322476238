import React from 'react';
import "../assets/styles/main.scss";
import { Typography } from 'antd';
import { TripHomeComponent } from '../components/trip-components/trip-home-component';
import { connect } from 'react-redux';
import {
  useLocation
} from "react-router-dom";
import {
  getUrlVars
} from '../utils/utilities';
import { VerifyPagePermission } from '../components/verify-page-permission-component/verify-page-permission.component';
import Permissions from "../utils/permissions-constant";

const { Title } = Typography;

export const Trips = (props) => {

        let location = useLocation();
         let {
          tripId
         } = getUrlVars(location.search);

  const renderChildren =     <div style={{position:'relative',top:'10px'  , margin: '20px',marginBottom:'100px'}}>
                                <Title level={4}>Trip Details</Title>
                                <TripHomeComponent
                                  tripId={tripId}
                                />
                              </div>
         

    return (
      <VerifyPagePermission 
      pagePermission={Permissions.pagePermission.ADMIN_VIEW_TRIPS_PAGE}
      renderChildren={renderChildren}
      onPermissionSuccess={()=>{

      }}
      />
    );
}



const mapReduxStateToProps = reduxState => {
    return {

    }
}

const mapReduxActionsToProps = dispatch => {
return {

  }
}

export const NewTripPage = connect(mapReduxStateToProps,
    mapReduxActionsToProps)(Trips);
