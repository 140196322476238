/**
 * Address related actions can be created here
 */

import { 
    CREATE_USER_PRIVILLEGE_URL,
    DELETE_USER_PRIVILLEGE_URL, 
    UPDATE_USER_PRIVILLEGE_URL,
    GET_USER_PRIVILLEGE_URL
} from "../utils/api-url";
import { 
    GET_USER_PRIVILLEGE,
    CREATE_USER_PRIVILLEGE ,
    DELETE_USER_PRIVILLEGE,
    UPDATE_USER_PRIVILLEGE
} from "./action-types";
import { postCall, getCall } from "../utils/network.util";

export const getPrivilleges = () => {
    return {
    type: GET_USER_PRIVILLEGE,
    payload: {
        url: GET_USER_PRIVILLEGE_URL,
        api: getCall,
        apiPayload:{
            operator_code:global.operator_code
        }
        }
    }
}

export const createPrivillege = ({privilege,agent_actions},onSuccess) => {
    return {
    type: CREATE_USER_PRIVILLEGE,
    payload: {
        url: CREATE_USER_PRIVILLEGE_URL,
        api: postCall,
        apiPayload:{
            privilege,
            actions:agent_actions,
            operator_code:global.operator_code
         },
         onSuccess:onSuccess
        }
    }
}

export const deletePrivillege = (privilege,onSuccess) => {
    return {
    type: DELETE_USER_PRIVILLEGE,
    payload: {
        url: DELETE_USER_PRIVILLEGE_URL,
        api: postCall,
        apiPayload:{
            privilege,
            operator_code:global.operator_code
            },
        onSuccess:onSuccess
        }
    }
}

export const updatePrivillege = ({privilege,insertAgentActions,deleteAgentActions},onSuccess) => {
    return {
    type: UPDATE_USER_PRIVILLEGE,
    payload: {
        url: UPDATE_USER_PRIVILLEGE_URL,
        api: postCall,
        apiPayload:{
            privilege,
            insertActions:insertAgentActions,deleteActions:deleteAgentActions,
            operator_code:global.operator_code
         },
         onSuccess:onSuccess
        }
    }
}
