
import { message } from "antd";
import { API_BASE_URL } from "../../../app.config";
import { isValidArray } from "../../../utils/utilities";

let GET_BOARDING_POINTS_TEMPLATES_BY_CITIES_URL= `${API_BASE_URL}/api/getBoardingPointsTemplatesByCities`;
let GET_BOARDING_POINTS_BY_TEMPLATE_NAME_URL= `${API_BASE_URL}/api/getBoardingPointsByTemplateName`;


export const getUnqiueOriginCities=(routes)=>{
    let cities=[]
    if(isValidArray(routes)&&routes.length>0){
        routes.forEach(element => {
            cities.push(element.origin);
        });
    }    
    return cities;
}

export const getBoardingPointsTemplatesByCities=(cities,onSuccess)=>{
    fetch(GET_BOARDING_POINTS_TEMPLATES_BY_CITIES_URL, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            cities:cities,
            operatorCode:global.operator_code
        })
      }).then(response => response.json())
      .then(data => {
        if(data.Status=="SUCCESS"){
          onSuccess(data.Payload);
        }else{
            message.error(data.Message);
        }
      })
  }

  
export const getBoardingPoints=(templateName,scheduleId,onSuccess)=>{
    fetch(GET_BOARDING_POINTS_BY_TEMPLATE_NAME_URL, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            templateName:templateName,
            operatorCode:global.operator_code,
            scheduleId:scheduleId
        })
      }).then(response => response.json())
      .then(data => {
        if(data.Status=="SUCCESS"){
          if(data.Payload.length>0){
            onSuccess(data.Payload);
          }else{
            onSuccess([]);
            message.success("All boarding points for template already inserted!")
          }        
        }else{
            message.error(data.Message);
        }
      })
  }