
import { API_BASE_URL } from '../../../app.config';
import { message , Tooltip} from 'antd';
import { getFormattedTime , Alldates, getAPIDateFormat } from '../../../utils/dates-util';
import { isValid } from './../../../utils/utilities';
import React  from 'react';
import {getDuplicatesComparingTwostrings , removeDuplicatesComparingTwostrings , array_of_sub_strings} from './../../../utils/utilities';

export const boardingColumns = [
    {
      title: 'BoardingTime',
      dataIndex: 'timing',
      align:'center',
      render:(text,record) =>
      (
           <div>
               {getFormattedTime(text)}
               {isValid(record.trip_start_date)?
                  "("+getAPIDateFormat(record.trip_start_date)+")"
                  :
                  null
               }
           </div>
        )
    },
    {
      title: 'BoardingAddress',
      dataIndex: 'location',
      align:'center',
      render:(text,record) =>
      (
           <div>
                 <div>{text}
                 {
                 record.is_temporary==1&&
                         <sub>(temporary)</sub>
                 }
                 </div>
           </div>
        )
    },
    {
      title : 'Status',
      dataIndex : 'status',
      align:'center',
      render: (text, record) => (
           <Tooltip placement="topLeft" title={record.days}>
                  {text}
          </Tooltip>
           )
    }
  ];

  export const dropColumns = [
    {
      title: 'DropTime',
      dataIndex: 'timing',
      align:'center',
      render:(text,record) =>
      (
           <div>
               {getFormattedTime(text)}
               {isValid(record.trip_start_date)?
                  "("+getAPIDateFormat(record.trip_start_date)+")"
                  :
                  null
              }
           </div>
        )
    },
    {
      title: 'DropAddress',
      dataIndex: 'location',
      align:'center',
      render:(text,record) =>
      (
           <div>
                 <div>{text}
                 {
                 record.is_temporary==1&&
                         <sub>(temporary)</sub>
                 }
                 </div>
           </div>
        )
    },
    {
      title : 'Status',
      dataIndex : 'status',
      align:'center',
      render: (text, record) => (
           <Tooltip placement="topLeft" title={record.days}>
                  {text}
          </Tooltip>
           )
    }
  ];

export const fetchBoardAndDropPoints=(scheduleId,updateBoardAndDropPoints)=>{
    fetch(API_BASE_URL + '/api/getScheduleById?scheduleId='+scheduleId+'&&withDaySpecific=true')
    .then((Responce)=>Responce.json())
    .then((findresponce)=>
    {
      if(findresponce.Status=="SUCCESS"){
          let scheduleDetails= findresponce.payload.schedule_details;
        updateBoardAndDropPoints(scheduleDetails.boardingPoints,scheduleDetails.dropPoints);
      }else{
        message.error("Unable to fecth Boarding and Drop Points");
      }
    })
  }

  export const getBoardingDropDetailsStatus =(startDate,endDate,bPoints,dPoints)=>{
    var alldates=null;
    if(isValid(startDate) && isValid(endDate)){
      alldates=Alldates(startDate,endDate).toString();
    }

    bPoints = statusUpdate(bPoints,alldates);
    dPoints = statusUpdate(dPoints,alldates);
    return { bPoints , dPoints}
  }

  const getUpdateRecord=(record,status)=>{
    return {...record, status}
  }

  export const statusUpdate=(data,alldates)=>{
    let formatedData = [];

    for(var index=0;index<data.length;index++){
        if(isValid(alldates)){
            if(data[index].is_temporary==0)
            {
              var statusRange=getDuplicatesComparingTwostrings(alldates,data[index].exclude_days);
              var statusRange1=removeDuplicatesComparingTwostrings(alldates,statusRange);
              var statusRange2=array_of_sub_strings(statusRange1,5,10);
              data[index]['days']=statusRange2;
            if((data[index].include_days==null)&&(data[index].exclude_days==null))
                {
                  data[index]=getUpdateRecord(data[index],'Active');
                }
                else if(statusRange.length==alldates.length)
                {
                    data[index]=getUpdateRecord(data[index],'In Active');
                }
                else if(statusRange.length==0)
                {
                  data[index]=getUpdateRecord(data[index],'Active');
                }
                else if(statusRange.length!=alldates.length)
                {
                  data[index]=getUpdateRecord(data[index],'Multiple');
                }
            }
            else{
              var statusRange=getDuplicatesComparingTwostrings(alldates,data[index].include_days);
              var statusRange2=array_of_sub_strings(statusRange,5,10);
              data[index] = {
                ...data[index],
                days:statusRange2
              }
              if((data[index].include_days==null)&&(data[index].exclude_days==null))
                      {
                        data[index]=getUpdateRecord(data[index],'In Active');
                      }
                      else if(statusRange.length==alldates.length)
                      {
                        data[index]=getUpdateRecord(data[index],'Active');
                      }
                      else if(statusRange.length==0)
                      {
                        data[index]=getUpdateRecord(data[index],'In Active');
                      }
                      else if(statusRange.length!=alldates.length)
                      {
                        data[index]=getUpdateRecord(data[index],'Multiple');
                      }
              }
          }

          if(!isValid(alldates) && !isValid(data[index].trip_start_date)){
            formatedData.push(data[index]);
          }else if(isValid(alldates)){
            if(!isValid(data[index].trip_start_date)){
              formatedData.push(data[index]);
            }else if(alldates.indexOf(getAPIDateFormat(data[index].trip_start_date)) != -1){
              formatedData.push(data[index]);
            }
          }
      }

      return formatedData;
  }

  export const handleBoardDropClick =(isAdd,startDate,endDate,selectedBoardingpoints,selectedDroppoints,onSuccess)=>{

    let pickupLocations = [] , dropLocations=[];

    selectedBoardingpoints.forEach((boarding)=>{
      pickupLocations.push({
        id:boarding.id,
        exclude_days:boarding.exclude_days,
        include_days:boarding.include_days,
        is_temporary:boarding.is_temporary
      });
    });

    selectedDroppoints.forEach((drop)=>{
      dropLocations.push({
        id:drop.id,
        exclude_days:drop.exclude_days,
        include_days:drop.include_days ,
        is_temporary:drop.is_temporary     
      });
    })

    var alldates=Alldates(startDate,endDate).toString();
                        var pickupanddropLocations={
                                                      pickupLocations: pickupLocations,
                                                      dropLocations: dropLocations,
                                                      alldates:alldates,
                                                      isAdd:isAdd
                                                    };
                        fetch(API_BASE_URL + '/api/bulkupdatePickupandDropLocation', {
                                 method: "POST",
                                 headers: {'Content-Type':'application/json'},
                                 body: JSON.stringify({"pickupanddropLocations" :pickupanddropLocations})
                               }). then(response => response.json())
                         .then(data =>{
                          if(data.Status=="SUCCESS"){
                            message.success('Updated boarding and drop points!');
                              onSuccess();
                            }else{
                              message.error('Unable to update boarding and drop points!');
                            }
              })
}