import React  from 'react';
import "../assets/styles/main.scss";

import { Typography, Divider } from 'antd';
import { VerifyPagePermission } from '../components/verify-page-permission-component/verify-page-permission.component';
import Permissions from "../utils/permissions-constant";
import AdminDriverMappingRoot from '../components/admin-driver-mapping-component/admin-driver-mapping-component.root';

const { Title } = Typography;

export const AdminDriverMappingPage = (props) => {

    const renderChildren =     <div style={{position:'relative',top:'10px' , margin: '20px'}} className= "page-root">
                                    <Title level={4}>Admin Driver Mapping</Title>
                                    <div className= "title-underline" />
                                    <Divider style= {{borderColor: '#817D7D'}}/>
                                    <AdminDriverMappingRoot />
                                </div>

    return (
        <VerifyPagePermission 
        pagePermission={Permissions.pagePermission.DAFIDY_USER_PAGE}
        renderChildren={renderChildren}
        onPermissionSuccess={()=>{
  
        }}
        />
    );
}
