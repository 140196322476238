import React , {useEffect , useState} from "react";
import { pageRedirect } from '../../utils/utilities';
import { Spin } from 'antd';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { loginReducerSelector } from '../../selectors/login-selectors';

export const VerifyPagePermission = (props) => {

  const {
        pagePermission,
        renderChildren,
        onPermissionSuccess
  } = props;

  let history = useNavigate();
  let {
    userInfo
  }=useSelector(loginReducerSelector);

  const [pageLoading , setPageLoading]=useState(true);

    useEffect(() => {
      if(userInfo.privilages !== undefined){
          if(userInfo.privilages.indexOf(pagePermission) === -1){
            pageRedirect(history,'error')
          }else{
            onPermissionSuccess();     
          }
          setPageLoading(false);
        }
  },[userInfo]);

  if(pageLoading){
    return <Spin />
  }

  return (
             <div>{renderChildren}</div>
     );
}

