import React , {useEffect, useState} from 'react';
import Calendar from 'react-calendar';
//link about react calender https://www.npmjs.com/package/react-calendar
// import 'react-calendar/dist/Calendar.css';
import "./schedule-calender-cell.style.scss";
import { formateDataForDayWise , getRespectiveSchedule} from './schedule-calender-cell.module';
import { getAPIDateFormat } from '../../../utils/dates-util';
import { API_BASE_URL } from '../../../app.config';
import { isValid } from '../../../utils/utilities';
import {message} from 'antd';

export const TableCalenderCell = (props) => {


  const {
    selectedDate,
    className,
    scheduleId,
    selectRange,
    rangeValue,
    data,
    showNavigation,
    reload
  } = props;


  const [ formatedData , setFormatedData] = useState({});
  const [ calenderDate,setCalenderDate] = useState(selectedDate._d);

  useEffect(() => {
    setFormatedData(formateDataForDayWise(data));
  },[data]);

  useEffect(()=>{
    setCalenderDate(selectedDate._d);
  },[selectedDate]);

  
  useEffect(()=>{
    if(isValid(reload)&&reload!=0){
      reloadCalender(calenderDate);
    }
  },[reload]);

  const reloadCalender=(activeStartDate)=>{
       var month=activeStartDate.getMonth()+1;
              var url=API_BASE_URL + "/api/getSchedules";
              fetch(url,{
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  operator_code:global.operator_code,
                  onlyTripsCreated:true,
                  year:activeStartDate.getFullYear(),
                  month:month,
                  travelsId:global.operator_id
                })
              }).
              then((Responce)=>Responce.json()).
              then((findresponce)=>
              {
                if(findresponce!=false){
                  setFormatedData(getRespectiveSchedule(findresponce.payload.schedules,scheduleId));
                }else{
                    message.error("Unable to load Schedules active Details")
                }
              })
  }


    return (
        <div>
           <Calendar
           className={className}
           activeStartDate={calenderDate}
           tileClassName={
            ({ date, view }) =>{
              date = getAPIDateFormat(date);
              if(formatedData[date]!=undefined&&formatedData[date].visible_outside==1&&formatedData[date].is_active==1){
                    return "green"
              }else if (formatedData[date]!=undefined&&formatedData[date].visible_outside==0&&formatedData[date].is_active==1) {
                    return "blue"
              }else if (formatedData[date]!=undefined&&formatedData[date].is_active==0) {
                    return "red"
              }
              else{
                    return "yellow"
              }
            }
          }
          showNavigation={showNavigation}
          onChange={setCalenderDate}
          onActiveStartDateChange={({ activeStartDate,view  }) =>
            {
              setCalenderDate(activeStartDate);
              reloadCalender(activeStartDate);
            }}
            selectRange={selectRange}
            value={rangeValue}
           />
        </div>
    );
}
