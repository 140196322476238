import React ,{useEffect , useState} from 'react';
import {SeatLayoutContainer} from './../../trip-components/seat-layout-container.component';
import {getBusSeatLayoutAction, reNameSeat , updateBusSeatLayout, deleteSeat, seaterToSleeper} from '../../../actions/bus-actions';
import { busReducerSelector } from '../../../selectors/bus-selectors';
import {useDispatch, useSelector } from 'react-redux';
import { Button , Modal , Row ,Typography ,Input, Col,Radio , Divider, message, Form} from 'antd';
import { isValid , isValidArray , commaseperatedStringToArray, isValidString , showConfirm} from '../../../utils/utilities';
import { QWIK_BUS_USER } from './../../../utils/constant';

const {
    Text
}=Typography;

const getSeatGroupOptions=(seatGroupsIds)=>{
    let formatedArray=[];
    seatGroupsIds=commaseperatedStringToArray(seatGroupsIds);
    if(isValidArray(seatGroupsIds)&&seatGroupsIds.length>0){
        seatGroupsIds.forEach(element => {
            formatedArray.push({ label: element, value: element })
        });
    }
    return formatedArray;
}

export const EditsSeatsComponent = (props) => {

    const {
        busSelected
    } = props;

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const busReducer = useSelector(busReducerSelector);
    const [seatGroupIdsOptions,setSeatGroupIdsOptions]=useState([]);
    const [seatGroupId,setSeatGroupId]=useState(null);
    const [seatType,setSeatType]=useState(null);
    
    const onSuccess=()=>{
        resetForm();
        dispatch(getBusSeatLayoutAction(busSelected.id));
    }

    const deleteAction=()=>{
        let busId = busSelected.id;
        showConfirm(
            ()=>{
               dispatch(deleteSeat(busId,seatNumber,onSuccess));
            },

            '',
            ''
          )
      }

    useEffect(() => {
        if (isValid(busSelected)&&isValid(busSelected.id)) {
            dispatch(getBusSeatLayoutAction(busSelected.id));
           setSeatGroupIdsOptions(getSeatGroupOptions(busSelected.seat_group_ids));
        }
      },[busSelected]);
  
      const [modal, updateModal] = useState(0);
      let [selectedSeatObject,setSelectedSeatObject]=useState({});
      let [seatNumber,setSeatNumber]=useState('');
      let qwikBusUser=QWIK_BUS_USER==global.name?true:true;

      const isRowClicked = isValid(seatNumber)?true:false;

      const onChange = e => {
        setSeatType(e.target.value);
      };

      const selectSeat =(object)=>{
            if(qwikBusUser){
                updateInReducer(object,false);
            }
            return true;
        }

        const updateInReducer=async (object,callUpdateAction)=>{
            await dispatch(reNameSeat(seatNumber,selectedSeatObject,seatGroupId,onFailure,()=>{
                if(isValidArray(object)){
                    setSeatType(object.sleeper);
                    setSelectedSeatObject({...object});
                    setSeatNumber(object.seat_number);
                    setSeatGroupId(object.seat_group_id);
                }
            }));
            if(callUpdateAction){
                dispatch(updateBusSeatLayout(busReducer.seatsData,busSelected.id,()=>{}));
            }
            dispatch(seaterToSleeper(seatType,selectedSeatObject,onFailure));
        }   

      const resetForm=()=>{
        setSelectedSeatObject({})
        setSeatNumber(null);
        setSeatGroupId(null); 
        setSeatType(null);
      }
      
      const onFailure =()=>{
          message.error("Seat already exits.Renaming not allowed")
      }

      const onCancel=()=>{
        updateModal(false);
        resetForm();
      }
      
    return (
        <div>
              <Button type="primary" 
                    disabled={isValid(busSelected)?false:true}
                    onClick={()=>{
                        updateModal(true);
                    }}
                    // style={{bottom:'20px',left:'100px'}}
                    >
                        Config Seats
                    </Button>
                    <Modal
                        title="Seat layout"
                        visible={modal}
                        footer={null}
                        okText="Update"
                        cancelText="Cancel"
                        onCancel={onCancel}
                        width={1000}
                        destroyOnClose
                    >
                        {busReducer.seatsData.length!=0 &&
                        <Row>
                            <Col>
                                <SeatLayoutContainer 
                                    isAllSeatsDisable={false}
                                    seatsData={busReducer.seatsData} 
                                    blockedSeats={[]} 
                                    selectSeat={selectSeat}
                                    isOnlyOneSeatSelected={true}
                                    selectedSeatNumber={selectedSeatObject.seat_number}
                                    />
                            </Col>
                            {qwikBusUser&&
                            <Col>
                                <Row style={{margin:5}}>
                                    <Text>Config seat</Text>
                                </Row>
                                <Row style={{margin:5}}>
                                    <Input 
                                    style={{width:50}}
                                    value={seatNumber}
                                    onChange={(e)=>{
                                        setSeatNumber(e.target.value);
                                    }}
                                    />
                                </Row>
                                <Row>
                                    <Radio.Group
                                        options={seatGroupIdsOptions}
                                        onChange={(e)=>{
                                            setSeatGroupId(e.target.value);
                                        }}
                                        value={seatGroupId}
                                        />
                                </Row>
                                {isValidString(selectedSeatObject.adjacent_seat_number)&&
                                <Row >
                                    Adjacent seat : {selectedSeatObject.adjacent_seat_number}
                                </Row>
                                }  
                                <Row>
                                    <Divider />
                                    <h3>Mark seat as Seater/Sleeper.</h3>
                                </Row>
                                <Col span={6}>
                                              <Radio.Group
                                               onChange={onChange} 
                                               value={seatType}
                                               disabled={!isRowClicked}>
                                                  <Radio value={0}>Seater</Radio>
                                                  <Radio value={1}>Sleeper</Radio>
                                              </Radio.Group>
                                  </Col>
                                {/* <Row style={{margin:5}}>
                                    <Button
                                    onClick={()=>{
                                        dispatch(reNameSeat(seatNumber,selectedSeatObject,seatGroupId,onFailure));
                                        dispatch(seaterToSleeper(seatType,selectedSeatObject,onFailure));
                                    }}
                                    >Save</Button>
                                </Row> */}
                                <Row>
                                <Button
                                        type="danger"
                                        onClick={deleteAction}>
                                        Delete seat 
                                        </Button>
                                </Row>
                            </Col>    
                            }
                        </Row>
                        }
                        <Divider />
                        <Row gutter={16} justify="end">
                            <Col>
                                <Button 
                                onClick={onCancel}
                                >Cancel</Button>
                            </Col>
                            {qwikBusUser?
                            <Col>
                                <Button 
                                type="primary" 
                                onClick={()=>{
                                    updateInReducer(selectedSeatObject,true);
                                }}
                                >Update</Button>
                            </Col>
                            :null
                            }
                            
                        </Row>
                    </Modal>
        </div>
    );
}
