import { isValidArray , getValuesOfJSonObject} from './../../../utils/utilities';


export const getRouteEndTime = (routes,city)=>{
    let endTime = null;
    if(isValidArray(routes)&&routes.length>0){
        routes.forEach((ele)=>{
                if(ele.destination===city){
                    endTime = ele.end_time;
                }
        })
    }
    return endTime;
}

export const getDropPointCities=(routes)=>{
    var destination_cities=[],destination_cities_no_dup=[];


    for(var i=0;i<routes.length;i++){
        destination_cities[i]=routes[i].destination;
    }

    destination_cities_no_dup = [...new Set(destination_cities)];

    // destination_cities_comma_separated=destination_cities_no_dup.join(',');
    
    return destination_cities_no_dup;

}


export const getDropPointsNameAsKey=(dropPoints)=>{
    let dropAddressLocationsNameasKey={}
    for (let index = 0; index < dropPoints.length; index++) {
        const element = dropPoints[index];
        dropAddressLocationsNameasKey[element.name]=element;
    }
    return dropAddressLocationsNameasKey;
}


export const getDropPointsInCityTree=(dropPoints)=>{
    // const treeData = [
    //     {
    //       title: 'Node1',
    //       value: '0-0',
    //       children: [
    //         {
    //           title: 'Child Node1',
    //           value: '0-0-1',
    //         },
    //         {
    //           title: 'Child Node2',
    //           value: '0-0-2',
    //         },
    //       ],
    //     },
    //     {
    //       title: 'Node2',
    //       value: '0-1',
    //     },
    //   ];

        let formateTreeDate ={

        };

        if (isValidArray(dropPoints)) {
            dropPoints.forEach(element => {
                if(isValidArray(formateTreeDate[element.city])){
                    formateTreeDate[element.city].children.push({
                        title:element.name,
                        value:JSON.stringify(element)
                    })
                }else{
                    formateTreeDate[element.city]={
                        title:element.city,
                        value:element.city,
                        children:[
                            {
                                title:element.name,
                                value:JSON.stringify(element)
                            }
                        ]
                    }
                }
            });
        }
      return getValuesOfJSonObject(formateTreeDate);
}