import { GET_SEAT_LAYOUT_BY_TEMPLATE_ID_URL } from './../../../utils/api-url';
import { postCall } from "./../../../utils/network.util";

export const fetchSeatLayoutByTemplateId=(busId,onSuccess=()=>{},onFailure=()=>{})=>{
      var updated = postCall(GET_SEAT_LAYOUT_BY_TEMPLATE_ID_URL, {
        templateId : busId
      });
      updated.then((data) => {
        if(data.status=="SUCCESS"&&data.payload.Status=="SUCCESS"){
          onSuccess(data.payload.Payload.seatLayout);
        }else{
          onFailure();
        }
      })
      .catch((reason)=> {
        onFailure();
      })
  }