import React , {useEffect, useState} from 'react';
import { useDispatch , useSelector } from 'react-redux';
import { getVechicles } from '../../../actions/vechicle-actions';
import { vechiclesReducerSelector } from '../../../selectors/vechicle-selectors';
import { VechicleAllocationTable } from '../vechicle-allocation-table/vechicle-allocation-table.component';
import moment from 'moment';
import { VechicleAllocationHeader } from '../vechicle-allocation-header/vechicle-allocation-header.component';

export const VechicleAllocationRoot = (props) => {


    const dispatch = useDispatch();
    const vechiclesReducer = useSelector(vechiclesReducerSelector);

    const [selectedDate,setDate] = useState(moment());

    useEffect(()=>{
        dispatch(getVechicles());
    },[]);

    return (
        <div>
            <VechicleAllocationHeader
            selectedDate={selectedDate}
            setDate={setDate}
            />
            <VechicleAllocationTable 
                {...vechiclesReducer}
                selectedDate={selectedDate}
            />
        </div>
    );
}
