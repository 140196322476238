  export const getOriginDestinations=(schedules)=>{
      let originsList=[],destinationsList=[];
    schedules.forEach(element => {
        schedules.push({label:element.description,value:element.id,origin:element.origin,destination:element.destination})
        if(originsList.indexOf(element.origin)==-1){
            originsList.push(element.origin);
        }
        if(destinationsList.indexOf(element.destination)==-1){
            destinationsList.push(element.destination);
        }
      })
      return{
          originsList,
          destinationsList
      }
  }