import moment from 'moment';
import { getDateInReadableFormat , incrementDays } from '../../../utils/dates-util';


export const getFormatedDashBoardData = (data,selectedDate)=>{


    var a = getDateInReadableFormat(selectedDate,false,true);

    var b = getDateInReadableFormat(incrementDays(selectedDate,1),false,true);

    var c = getDateInReadableFormat(incrementDays(selectedDate,2),false,true);

    var d = getDateInReadableFormat(incrementDays(selectedDate,3),false,true);

    var e = getDateInReadableFormat(incrementDays(selectedDate,4),false,true);

    let formateData=[];
    let origin="",destination="";
    let originCities=[],destinationCities =[];

    for (var i = 0; i < data.length; i++) {
        if(data[i].trips!=undefined){
          if((data[i].origin+data[i].destination)!=(origin+destination)){
            origin=data[i].origin;
            destination=data[i].destination;
            var description ={description:{origin:origin,destination:destination},origin:origin,destination:destination};
            formateData.push(description);

            if(originCities.indexOf(origin)==-1){
              originCities.push(origin);
            }

            if(destinationCities.indexOf(destination)==-1){
              destinationCities.push(destination);
            }
          }
           let row={
             description:data[i].description,
             origin:origin,
             destination:destination
           }
           for (var j = 0; j < data[i].trips.length; j++) {
             let cur_trip=data[i].trips[j];
             var curr_date=new Date(data[i].trips[j].start_date);
             var curr_date_formate=getDateInReadableFormat(moment(curr_date),false,true);
             var attribute=(curr_date_formate==a?"day1":
                           (curr_date_formate==b?"day2":
                           (curr_date_formate==c?"day3":
                           (curr_date_formate==d?"day4":
                           (curr_date_formate==e?"day5":"invalidDate")))));
               row[attribute]={
                 Vacant:cur_trip.available_seats,
                 Admin_Blocked:cur_trip.blocked_by_admin,
                 Blocked:cur_trip.blocked_seats,
                 Booked:cur_trip.booked_seats,
                 basefare:cur_trip.ticket_cost
               };
           }
           formateData.push(row);
        }
      }
      return {
        formateData:formateData,
        originCities:originCities,
        destinationCities:destinationCities
       }
}