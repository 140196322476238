import { Form, Input, Button, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch , useSelector} from 'react-redux';
import { agentColorReducerSelector } from '../../../selectors/agent-color-selectors';
import { getAgentColorDetails, updateAgentColor, createAgentColor } from '../../../actions/agent-color.actions';
import { create, values } from 'underscore';
import { isValidArray } from '../../../utils/utilities';

let addButton = 'Create', updateButton = 'Update';

export const AgentColorForm = (buttonType) => {

    const [ form ] =Form.useForm();
    const [buttonName, setbuttonName] = useState(addButton)
    const dispatch = useDispatch();
    const agentColorReducer = useSelector(agentColorReducerSelector);

    useEffect(()=>{
      
      dispatch(getAgentColorDetails());
  },[]);

  const onFinish =(values)=>{
    if(buttonName==addButton){
      dispatch(createAgentColor({...values}))
    }
    else{
      dispatch(updateAgentColor({...values}));
    }
  }

  useEffect(()=>{
    fillForm()
  },[agentColorReducer]);

  const fillForm=()=>{
    if(isValidArray(agentColorReducer)&& isValidArray(agentColorReducer.agentColor)){
      setbuttonName(updateButton)
      let{
        operator_code,
        primary_color,
        secondary_color,
        default_button_bg_color,
        default_button_txt_color,
        header_bg_color,
        header_txt_color,
        drop_down_menu_bg_color,
        drop_down_menu_txt_color,
        drop_down_selected_bg_color,
        drop_down_selected_txt_color,
    } = agentColorReducer.agentColor;

    form.setFieldsValue({
      operatorCode:operator_code,
      primaryColor:primary_color,
      secondaryColor:secondary_color,
      defaultButtonBgColor:default_button_bg_color,
      defaultButtonTxtColor:default_button_txt_color,
      headerBgColor:header_bg_color,
      headerTxtColor:header_txt_color,
      dropDownMenuBgColor:drop_down_menu_bg_color,
      dropDownMenuTxtColor:drop_down_menu_txt_color,
      dropDownSelectedBgColor:drop_down_selected_bg_color,
      dropDownSelectedTxtColor:drop_down_selected_txt_color
    })    
    } 
}
  

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onclick={onclick}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 8 }}
      initialValues={{ operatorCode: global.operator_code }}
    >
      <Form.Item
        label="Operator code"
        name="operatorCode"
        rules={[{ required: true, message: 'Please input the operator code!' }]}
      >
        <Input 
        disabled={true}
        />
      </Form.Item>

      <Form.Item
        label="Primary color"
        name="primaryColor"
        rules={[{ required: true, message: 'Please input the primary color!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Secondary color"
        name="secondaryColor"
        rules={[{ required: true, message: 'Please input the secondary color!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Default button bg color"
        name="defaultButtonBgColor"
        rules={[{ required: true, message: 'Please input the default button bg color!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Default button txt color"
        name="defaultButtonTxtColor"
        rules={[{ required: true, message: 'Please input the default button txt color!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Header bg color"
        name="headerBgColor"
        rules={[{ required: true, message: 'Please input the header bg color!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Header txt color"
        name="headerTxtColor"
        rules={[{ required: true, message: 'Please input the header txt color!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Drop down menu bg color"
        name="dropDownMenuBgColor"
        rules={[{ required: true, message: 'Please input the drop down menu bg color!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Drop down menu txt color"
        name="dropDownMenuTxtColor"
        rules={[{ required: true, message: 'Please input the drop down menu txt color!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Drop down selected bg color"
        name="dropDownSelectedBgColor"
        rules={[{ required: true, message: 'Please input the drop down selected bg color!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Drop down selected txt color"
        name="dropDownSelectedTxtColor"
        rules={[{ required: true, message: 'Please input the drop down selected txt color!' }]}
      >
        <Input />
      </Form.Item>
      
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="update">
          {buttonName}
        </Button>
      </Form.Item>
      
    </Form>
  );
};
