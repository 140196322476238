/**
 * All sagas for Home Saga must be defined here
 * @author Manne Hareesh
 */

import { takeLatest,put,call,all,fork } from 'redux-saga/effects'
import { 
    GET_SCHEDULES_BY_DATE,
    SET_DASH_BOARD_SCHEDULES,
    REFRESH_CACHE,
    SET_DASH_BOARD_LOADING
} from '../actions/action-types';
import { message } from 'antd';

/**
 *
 * @param {Get schedules by date Details} action
 */
export function* getSchedulesByDate(action) {
    try{
        yield put({
            type: SET_DASH_BOARD_LOADING,
            payload: true
        });
        const {
            api,
            url,
            apiPayload
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload = apiResult.payload;
          if(clientPayload.Status=='SUCCESS'){
            let serverPayload = clientPayload.payload
            message.success(clientPayload.Message);
            yield put({
                type: SET_DASH_BOARD_SCHEDULES,
                payload: serverPayload
            });
          }else{
            message.error(clientPayload.Message);
          }
        }
        yield put({
            type: SET_DASH_BOARD_LOADING,
            payload: false
        });

    }
    catch(error){
        console.error("Error in getting dash board data ",error);
        yield put({
            type: SET_DASH_BOARD_LOADING,
            payload: false
        });
    }
}



function* watchGetSchedulesByDate() {
    yield takeLatest(GET_SCHEDULES_BY_DATE,getSchedulesByDate)
}

export function* refreshCache(action){
    try{
        const {
            api,
            url,
            apiPayload
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload = apiResult.payload;
          if(clientPayload.Status=='SUCCESS'){
            message.success(clientPayload.Message);
          }else{
            message.error(clientPayload.Message);
          }
        }
    }
    catch(error){
        console.error("Error in refresh cache",error);
    }
}

function* watchRefreshCache() {
    yield takeLatest(REFRESH_CACHE,refreshCache)
}


export function*  watchHomePage() {
    yield all([
        fork(watchGetSchedulesByDate),
        fork(watchRefreshCache)
    ])
}
