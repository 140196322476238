/**
 * @author Sanjay
 * @description Reducer for the components in home page
 */
import {SET_MASTER_CITIES, DELETE_LOCATION_LOADING , UPDATE_LOCATION_LOADING , ADD_LOCATION_LOADING ,  LOCATIONS_INFO , RESET_ALL , LOCATION_ACTION_SUCCESS ,LOCATION_TABLE_LOADING } from '../actions/action-types';
import {sortJsonObject} from '../utils/utilities';

const initialState = {
    citiesData: [],
    action:false,
    loading:true,
    addLoading:false,
    updateLoading:false,
    deleteLoading:false,
    masterCities:[]
} 

export const locationReducer = (state = initialState,action) => {
    let newState = Object.assign({},state);
    switch (action.type) {

        case SET_MASTER_CITIES:
            newState['masterCities'] = action.payload;
            return newState;

        case LOCATIONS_INFO:
            newState["citiesData"] = sortJsonObject(action.payload,'name',false);
            return newState;

        case DELETE_LOCATION_LOADING:
            newState["deleteLoading"] = action.payload;
            return newState;
            
        case ADD_LOCATION_LOADING:
            newState["addLoading"] = action.payload;
            return newState;

        case UPDATE_LOCATION_LOADING:
            newState["updateLoading"] = action.payload;
            return newState;

        case LOCATION_ACTION_SUCCESS :
            newState["action"] = action.payload;
            return newState;

        case LOCATION_TABLE_LOADING :
            newState["loading"] = action.payload;
            return newState;

      case RESET_ALL :
            newState["citiesData"] = [];
            newState["action"] = false;
            newState["loading"] = false;
            return newState;


        default:
            return state;
    }
}
