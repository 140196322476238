import React  from 'react';
import { Row, Col , Typography   } from 'antd';
import { TableCalenderCell } from '../schedule-calender-cell-component/schedule-calender-cell.component';
import { EditActivateDays } from '../edit-days-for-schedules/edit-days-for-schedules.component';
import { EditBoardingPoints } from '../edit-boarding-points-component/edit-boarding-points.component';
import { EditFareDetails } from '../edit-fare-component/edit-fare.component';

const { Title } = Typography;
const { Text } = Typography;

export const ExpandableScheduleCell = (props) => {

    const {
        record,
        scheduleId,
        selectedDate,
        inactiveExtender
    } = props;

    return (
        <div  style={{padding:'30px',background:'#dee2e6'}}>
        <div style={{ background: '#f3f3f3' }}>
            <Row>
              <Col  span={10} offset={1} >
                  <Row>
                    <Title level={4} >  Schedule Details </Title>
                  </Row>
                  <Row style={{margin:"10px"}}>
                  <Col span={6} style={{background:"#007bff"}}  >
                    <Text>Trip Description</Text>
                  </Col>
                  <Col span={16} offset={1} style={{border: '1px solid black'}}>
                      {record.description}
                  </Col>
                  </Row>
                  <Row type="flex" justify="space-around" align="middle">
                          <EditBoardingPoints 
                          record={record}
                          selectedDate={selectedDate}
                          scheduleId={scheduleId}
                          />
                  </Row>
              </Col>
              <Col  span={10} offset={1} >
                  <Row>
                    <Title level={4} > Active Days </Title>
                  </Row>
                  <Row>
                        <TableCalenderCell
                          data={record.trips}
                          className="table_calender_internal"
                          scheduleId={scheduleId}
                          selectedDate={selectedDate}
                          showNavigation={true}
                        />
                  </Row>
                  <Row type="flex" justify="space-around" align="middle" style={{margin:'10px'}}>
                      <EditActivateDays
                          record={record}
                          scheduleId={scheduleId}
                          selectedDate={selectedDate}
                        />
                  </Row>
              </Col>
            </Row>
            {inactiveExtender==false &&
              <Row style={{margin:'10px'}}>
                  <Col offset={1}>
                    <EditFareDetails
                    scheduleId={scheduleId}
                    record={record}
                    selectedDate={selectedDate}
                    />
                  </Col>
              </Row>
            }
        </div>
      </div>
    );
}
