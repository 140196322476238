import React , {useState} from 'react';
import { Button, message, Row } from 'antd';
import { informTripChanges } from './inform-trip-changes-to-ota.module';
import { isValid , showConfirm } from '../../utils/utilities';


export const InformTripChangesToOTA = (props) => {


    const {
        scheduleId
    } = props;


    const [isModalVisible, setIsModalVisible] = useState(false);

    const onSuccess=()=>{
     
    }

    const onClick = () => {
        if(isValid(scheduleId)){
            showConfirm(()=>{
                informTripChanges(scheduleId,onSuccess);
            },"",null,"Are you sure to inform OTA'S About Trip Changes?");
        }else{
            message.warning("Please select a trip");
        }
    };

  return (
      <div>
            <Row justify="center" align="middle">
                <Button 
                    type="primary" 
                    onClick={onClick}
                    style={{margin:'5px',background:'#2EC17C',color:'#FFFFFF',borderColor:'#2EC17C'}}
                >
                    Inform trip change
                </Button>
            </Row>
      </div>
  );
};