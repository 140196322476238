import React  from 'react';
import { Row, Col , Typography , Select , Button , DatePicker } from 'antd';
import {
   LeftOutlined,
   RightOutlined
  } from '@ant-design/icons';
import { incrementDays , decrementDays} from '../../../utils/dates-util';

const { Title } = Typography;
const { Option } = Select;
const { Text } = Typography;
const dateFormat = 'YYYY-MM-DD';
const { MonthPicker } = DatePicker;

export const VechicleAllocationHeader = (props) => {

    const {
        selectedDate,
        setDate
    } = props;
  

    return (
        <Row justify="end"> 
            <Col>
                    <Button type="" 
                    onClick={()=>{
                                setDate(decrementDays(selectedDate,1));
                    }}>
                                <LeftOutlined />
                    </Button>
                     <DatePicker
                      onChange={(date)=>{
                        setDate(date);
                      }}
                      value={selectedDate}
                      />
                    <Button type="" 
                    onClick={()=>{
                                    setDate(incrementDays(selectedDate,1));
                            }}>
                        <RightOutlined />
                    </Button>
            </Col>
        </Row>
    );
}
