/**
 * Address related actions can be created here
 */

import { GET_LOCATIONS_INFO_URL , INSERT_LOCATION_URL , UPDATE_LOCATION_URL , DELETE_LOCATION_URL, GET_MASTER_CITIES_URL} from "../utils/api-url";
import { 
    GET_LOCATIONS_ACTION , INSERT_LOCATION_ACTION , UPDATE_LOCATION_ACTION , DELETE_LOCATION_ACTION ,
    GET_MASTER_CITIES
} from "./action-types";
import { postCall } from "../utils/network.util";

export const getLocationDetailsAction = () => {
    return {
        type: GET_LOCATIONS_ACTION,
        payload: {
            url: GET_LOCATIONS_INFO_URL,
            api: postCall,
            apiPayload:{
                operatorCode:global.operator_code,
                travelsId:global.operator_id
            }
        }
    }
}


export const insertLocationDetailsAction = (data,onSuccess) => {
    return {
        type: INSERT_LOCATION_ACTION,
        payload: {
            url: INSERT_LOCATION_URL ,
            api: postCall,
            apiPayload:{
               city:{
                ...data,
                operatorCode:global.operator_code,
                travelsId:global.operator_id
               }
            },
            onSuccess:onSuccess
        }
    }
}

export const deleteLocationDetailsAction = (travelMappingId,onSuccess) => {
    return {
        type: DELETE_LOCATION_ACTION,
        payload: {
            url: DELETE_LOCATION_URL ,
            api: postCall,
            apiPayload:{
                travelMappingId
            },
            onSuccess:onSuccess
        }
    }
}

export const updateLocationDetailsAction = (data,onSuccess) => {
    return {
        type: UPDATE_LOCATION_ACTION,
        payload: {
            url: UPDATE_LOCATION_URL ,
            api: postCall,
            apiPayload:{
                cities:{ 
                ...data,
                operator_code:global.operator_code,
                travelsId:global.operator_id
                }
            },
            onSuccess:onSuccess
        }
    }
}

export const getMasterCities = () => {
    return {
        type: GET_MASTER_CITIES,
        payload: {
            url: GET_MASTER_CITIES_URL ,
            api: postCall,
            apiPayload:{}
        }
    }
}
