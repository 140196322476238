/**
 * Address related actions can be created here
 */

import {
    GET_IN_ACTIVE_SCHEDULES_URL ,
    GET_ACTIVE_SCHEDULES_URL,
    ACTIVATE_SCHEDULES_URL,
    BLOCK_SCHEDULES_URL,
    REMOVE_SCHEDULES_URL
} from "../utils/api-url";
import {
    GET_ACTIVE_SCHEDULES ,
    GET_IN_ACTIVE_SCHEDULES ,
    BLOCK_SCHEDULES ,
    ACTIVATE_SCHEDULES,
    REMOVE_SCHEDULES
 } from "./action-types";
import { postCall } from "../utils/network.util";
import { format } from 'date-fns';

const getDaysAlone =(days)=>{
    let dates = [];
    days.forEach(element => {
      dates.push(format(element,'yyyy-MM-dd'));
    });
    return dates;
  }

export const removeSchedules=(scheduleId,onSuccess)=>{
    return {
        type:REMOVE_SCHEDULES,
        payload:{
            url:REMOVE_SCHEDULES_URL,
            api:postCall,
            apiPayload:{
                scheduleId:scheduleId,
                operator_code:global.operator_code
            },
            onSuccess:onSuccess
        }
    }
}

export const blockSchedules =(scheduleId,selectedDays,comment,confirmBlockingValue,onSuccess,onBlockFailure)=>{
    return {
        type: BLOCK_SCHEDULES,
        payload: {
            url: BLOCK_SCHEDULES_URL,
            api: postCall,
            apiPayload:{
                scheduleId:scheduleId,
                dates:getDaysAlone(selectedDays),
                comment:comment,
                confirmBlocking:confirmBlockingValue,
                operator_code:global.operator_code,
                updatedBy:global.user_name
            },
            onSuccess:onSuccess,
            onBlockFailure:onBlockFailure
         }
    }
}

export const activateSchedules =(scheduleId,visibleOutside,dates,onSuccess)=>{
return {
    type: ACTIVATE_SCHEDULES,
    payload: {
        url: ACTIVATE_SCHEDULES_URL,
        api: postCall,
        apiPayload:{
            scheduleId:scheduleId,
            visibleOutside:visibleOutside,
            dates:getDaysAlone(dates),
            operatorCode: global.operator_code,
            updatedBy:global.user_name
        },
        onSuccess:onSuccess
     }
    }
}


export const getActiveSchedules = (year,month) => {
return {
 type: GET_ACTIVE_SCHEDULES,
 payload: {
     url: GET_ACTIVE_SCHEDULES_URL,
     api: postCall,
     apiPayload:{
        operator_code:global.operator_code,
        onlyTripsCreated:true,
        year:year,
        month:month,
        travelsId:global.operator_id
     }
  }
 }
}

export const getInActiveSchedules = () => {
return {
 type: GET_IN_ACTIVE_SCHEDULES,
 payload: {
        url: GET_IN_ACTIVE_SCHEDULES_URL,
        api: postCall,
        apiPayload:{
            operator_code:global.operator_code,
            travelsId:global.operator_id,
            onlyWithoutTrips:true
        }
     }
    }
}
