import React, { useEffect , useState}  from 'react';
import { getSchedulesByDate } from '../../../actions/home.actions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { HomeHeader } from '../home-header-compponent/home-header.component';
import { HomeTable } from '../home-table/home-table.component';
import { homeReducerSelector } from '../../../selectors/home-selector';
import { getFormatedDashBoardData } from './home.module';

export const HomeComponent = (props) => {

    const dispatch = useDispatch();
    const [selectedDate,setDate]=useState(moment());
    const homeReducer =useSelector(homeReducerSelector);
    const [ dashBoardData, setDashBoardData] = useState([]);
    const [ filteredData , setFilteredData] = useState([]);
    const [unFilteredData,setUnFilteredData] = useState([]);
    
    const {
      homeSchedules,
      dashBoardLoading
    } = homeReducer

    useEffect(()=>{
      var date=moment(selectedDate).format('YYYY-MM-DD');
       dispatch(getSchedulesByDate(date,4))
    },[selectedDate]);

    useEffect(()=>{
      setFilteredData(homeSchedules);
      setUnFilteredData(homeSchedules);
    },[homeSchedules]);

    useEffect(()=>{
      let {
        formateData
      }=getFormatedDashBoardData(filteredData,selectedDate);
      setDashBoardData(formateData);
    },[filteredData])

    return (
      <div style={{padding:'10px'}}>
          <HomeHeader 
            originsData={[]}
            destinationsData={[]}
            selectedDate={selectedDate}
            setDate={setDate}
            unFilteredData={unFilteredData}
            setFilteredData ={setFilteredData}
            />
            <HomeTable
            selectedDate={selectedDate}
            dashBoardData={dashBoardData}
            loading={dashBoardLoading}
            />

      </div>
    )    
}
