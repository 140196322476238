/**
 * @author Sanjay
 * @description Reducer for the components in home page
 */
 import { 
     SET_TRAVEL_MANAGEMENT,
     RESET_ALL , 
     SET_TRAVEL_MANAGEMENT_LOADING ,
     SET_UPDATE_TRAVEL_MANAGEMENT_RUNNING,
     SET_ADD_TRAVEL_MANAGEMENT_RUNNING,
     SET_DELETE_TRAVEL_MANAGEMENT_RUNNING
    } from '../actions/action-types';
 
 const initialState = {
     travelManagements: [],
     dataLoading:true,
     addLoading:false,
     updateLoading:false,
     deleteLoading:false
 } 
 
 export const travelManagementReducer = (state = initialState,action) => {
     let newState = Object.assign({},state);
     switch (action.type) {
 
         case SET_TRAVEL_MANAGEMENT:
             newState["travelManagements"] = action.payload;
             return newState;
 
         case SET_TRAVEL_MANAGEMENT_LOADING :
             newState["dataLoading"] = action.payload;
             return newState;

         case SET_ADD_TRAVEL_MANAGEMENT_RUNNING :
             newState["addLoading"] = action.payload;
             return newState;

         case SET_UPDATE_TRAVEL_MANAGEMENT_RUNNING :
             newState["updateLoading"] = action.payload;
             return newState;

         case SET_DELETE_TRAVEL_MANAGEMENT_RUNNING :
            newState["deleteLoading"] = action.payload;
            return newState;
 
       case RESET_ALL :
             newState["citiesData"] = [];
             newState["action"] = false;
             newState["loading"] = false;
             return newState;
 
 
         default:
             return state;
     }
 }
 