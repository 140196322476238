
import { postCall } from '../../utils/network.util';
import {DE_REGISTER_DP_URL, REGISTER_DP_URL } from './../../utils/api-url';

export const informRedBusDPAboutScheduleRegister = (scheduleId,onSuccess=()=>{},onFailure=()=>{}) =>{
    var informed = postCall(REGISTER_DP_URL, {
        scheduleId : scheduleId
    });
    informed.then((data) => {
        if(data.status=="SUCCESS"&&data.payload.Status=="SUCCESS"){
            onSuccess();
        }else{
            onFailure("Failed to inform red bus about data access.");
        }
    })
    .catch((reason)=> {
        onFailure();
    })
}

export const informRedBusDPAboutScheduleDeRegister = (scheduleId,onSuccess=()=>{},onFailure=()=>{}) =>{
    var informed = postCall(DE_REGISTER_DP_URL, {
        scheduleId : scheduleId
    });
    informed.then((data) => {
        if(data.status=="SUCCESS"&&data.payload.Status=="SUCCESS"){
            onSuccess();
        }else{
            onFailure("Failed to inform red bus about deregister.");
        }
    })
    .catch((reason)=> {
        onFailure();
    })
}