import { Button, Input, Modal,Row,Col } from 'antd';
import React , {useState } from 'react';
import './integration.style.scss';
import { sendOTAIntegrationMail } from './integration.module';
import { loadingAction } from '../../../actions/loading-actions';
import { useDispatch } from 'react-redux';

const {
    TextArea
} = Input;

export const IntegrationEmailComponent = (props) => {

    const {
        integrationModalOpen,
        setIntegrationModalOpen,
        selectedData
    } = props;

    const [toAddress, setToAddress] = useState(null);
    const [ccAddress, setCCAddress] = useState(null);

    const dispatch = useDispatch();

    const setLoading=(loading)=>{
        dispatch(loadingAction(loading));
    }  

    const onSuccess =()=>{
        setLoading(false);
    }

    const onFailure =()=>{
        setLoading(false);
    }

    return (
        <div>
            <Modal
            title="Send Integration Email" 
            visible={integrationModalOpen} 
            onOk={()=>{
                setIntegrationModalOpen(false);
            }} 
            onCancel={()=>{
                setIntegrationModalOpen(false);
            }} 
            okText="Ok" 
            footer ={null}
            >
                <div>
                    <Row gutter={[16,16]} className='inputTxtBlock'>
                        <Col>
                            <TextArea
                                style={{width:'350px'}}
                                placeholder='To Mail'
                                onChange={(e)=>{
                                    setToAddress(e.target.value);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[16,16]} className='inputTxtBlock'>
                        <Col>
                            <TextArea 
                                style={{width:'350px'}}
                                placeholder='CC Mail'
                                onChange={(e)=>{
                                    setCCAddress(e.target.value);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[16,16]}>
                        <Col>
                            <Button
                            type='primary'
                                onClick={()=>{
                                    setLoading(true);
                                    sendOTAIntegrationMail(toAddress,ccAddress,'redbus',selectedData.operator_code,onSuccess,onFailure);
                                }}
                            >Redbus</Button>
                        </Col>
                        <Col>
                            <Button
                            type='primary'
                             onClick={()=>{
                                setLoading(true);
                                sendOTAIntegrationMail(toAddress,ccAddress,'paytm',selectedData.operator_code,onSuccess,onFailure);
                            }}
                            >Paytm</Button>
                        </Col>
                        <Col>
                            <Button
                            type='primary'
                             onClick={()=>{
                                setLoading(true);
                                sendOTAIntegrationMail(toAddress,ccAddress,'abhibus',selectedData.operator_code,onSuccess,onFailure);
                            }}
                            >Abhibus</Button>
                        </Col>
                    </Row>
                </div>

            </Modal>
           
        </div>
    );
}
