import React , {useEffect,useState} from 'react';
import { WrappedAddressForm } from './address-form';
import { AddressTable } from './address-table';
import './address.component.scss';
import { useSelector } from 'react-redux';
import { locationReducerSelector } from '../../selectors/location-selectors';
import { useDispatch } from 'react-redux';
import { getLocationDetailsAction } from '../../actions/locations-actions';
import { getAgentsDetails } from '../../actions/agent-actions';
import {agentsReducerSelector } from '../../selectors/agents-selectors';

export const AddressComponent = props => {
  const {
          getAddressDetailsAction ,
          addressReducer
        } = {...props};

    
  const dispatch = useDispatch();

  useEffect(() => {
      getAddressDetailsAction();
      dispatch(getLocationDetailsAction());
      dispatch(getAgentsDetails(global.operator_code));
  },[]);

  const locationReducer = useSelector(locationReducerSelector);
  const agentsReducer = useSelector(agentsReducerSelector);

  const [formData, updateformData] = useState({});
  return (
      <div>
             <AddressTable 
                       dataSource={addressReducer.addressData}
                       updateformData={updateformData}
                       formData={formData}
                       loading={addressReducer.loading}
                       />
            <WrappedAddressForm
             updateformData={updateformData}
             formData={formData} {...locationReducer} 
             {...agentsReducer}/>
      </div>
  );
};
