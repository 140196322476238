import {EmptyColumn,SeatColumn} from "./seat-layout.component"
import React from 'react';
import { isValid } from './../../utils/utilities';

export const generateSeatLayout=(seatGrid,maximumRowNumberByColumn,col_count)=>{
    const seatArray =[]
    for (var rowIndex = 1;rowIndex<=col_count;rowIndex++){
        let seatObject;
        const tableRowData = [];
        let isColumnEmpty = true;
        let rowCount=isValid(maximumRowNumberByColumn[rowIndex])&&isValid(maximumRowNumberByColumn[rowIndex].maximumRowNumber)?maximumRowNumberByColumn[rowIndex].maximumRowNumber:0;

        for (var columnIndex = 1;columnIndex<=rowCount;columnIndex++){
            if(seatGrid[columnIndex] !== undefined)
            {
                seatObject = seatGrid[columnIndex][rowIndex];
                isColumnEmpty = (isColumnEmpty && seatObject === undefined);
                let seat = seatObject === undefined ? <div className='seatGap'></div> : seatObject;
                tableRowData.push(seat)
            }
        }
        if(isColumnEmpty){
            seatArray.push(<EmptyColumn seats={tableRowData} key={rowIndex} />);
        }else{
            seatArray.push(<SeatColumn seats={tableRowData} key={rowIndex} />);
        }
    }
    return seatArray
}
