import {Row, Button , Modal , message ,Typography,Input ,Col} from 'antd';
import React, { useState }  from 'react';
import { handleCloneBusAsTemplate } from './clone-template.module';
import { useDispatch } from 'react-redux';
import { getBusTemplateAction } from '../../../actions/bus-actions';
import { loadingAction } from '../../../actions/loading-actions';

const { Text } = Typography;

export const CloneBusTemplate = (props) => {


    const {
        busId
    } = props;
    

    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [identifierValue, setIdentifierValue] = useState(null);

    const showModal = () => {
        setIsModalVisible(true);
      };

      const setLoading=(loading)=>{
        dispatch(loadingAction(loading));
    }  

    
      const handleOk = () => {
        setLoading(true);
        handleCloneBusAsTemplate(busId,identifierValue,
            ()=>{
              handleCancel();
              dispatch(getBusTemplateAction());
              setLoading(false);
            },
            ()=>{
               handleCancel();
               message.error("Failed to clone bus template!");
               setLoading(false);
            }
        )
      };
    
      const handleCancel = () => {
        setIsModalVisible(false);
      };
      
    return (
        <div>
            <Button
            onClick={showModal}
            style={{margin:'5px' , background:'orange' , borderColor:'orange' ,color:'#FFFFFF'}}
            >
                    Create Template
            </Button>
            <Modal title="Clone bus as template" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} okText="Clone">
              <Row style={{marginTop:'10px'}}>
                <Col>
                  <Text>Template Identifier :</Text>
                    <Input 
                      placeholder="Template Identifier"
                      value={identifierValue}
                      onChange={(e)=>{
                        setIdentifierValue(e.target.value);
                      }}
                    />
                </Col>
                
              </Row>
            </Modal>
        </div>
    );
}
