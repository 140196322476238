import { Typography , Row  , Modal, Button , Radio , Form , TimePicker , Table} from 'antd';
import React , {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import { isValid, isValidArray } from '../../../utils/utilities';
import { getUnqiueOriginCities , getDropPointsTemplatesByCities ,getDropPoints  } from './import-drop-points.module';
import {addMin,get24HoursTime} from './../../../utils/dates-util';
import { createDropPoint , getDropLocationsByScheduleId} from './../../../actions/trip-actions';
import { QWIK_BUS_USER } from './../../../utils/constant';

const getFormatedTemplates=(templates)=>{
  let formatedData=[] , addedTemplate=[];
  if(isValidArray(templates)){
    templates.forEach(element => {
      if(addedTemplate.indexOf(element)==-1){
        formatedData.push({
          label:element,
          value:element
        })
        addedTemplate.push(element);
      }
    });
  }
  return formatedData;
}

const getFormateDropPoints=(dropPoints,scheduleId)=>{
  let formatedBoardingPoints=[];
 if(isValidArray(dropPoints)&&dropPoints.length>0){
  dropPoints.forEach(element => {
      formatedBoardingPoints.push({ 
        location:element.location,
        latitude:element.latitude,
        longitude:element.longitude,
        schedule_id:scheduleId,
        timing:get24HoursTime(element.dropTime),
        address:element.address,
        phone_number:element.phone_number,
        landmark:element.landmark,
        city:element.city,
        is_temporary:element.is_temporary
        });
   });
 } 
 return formatedBoardingPoints;
}

export const ImportDropPoints = (props) => {

  const {
    selectedTrip,
    routes
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dropPoints, setDropPoints] = useState([]);
  const [startTimeOfBp , setStartTimeOfBp] = useState(null);
  const [templates ,setTemplates]=useState([]);

  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    handleCancel();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setDropPoints([]);
    setStartTimeOfBp(null);
  };

  useEffect(()=>{
    let uniqueOrigins=getUnqiueOriginCities(routes);
    getDropPointsTemplatesByCities(uniqueOrigins,(data)=>{
        setTemplates([...data]);
    });
  },[routes]);

  const dispatch=useDispatch();

  const onFinish=(values)=>{
    let {
      templateName,
      startTime
    } = values;
    setStartTimeOfBp(startTime);
    dispatch(getDropPoints(templateName,selectedTrip.id,(data)=>{
      setDropPoints(data);
    }));
  }

  const dropPointColumns=[
    {
      title:'Address',
      dataIndex:'address',
      key:'address'
    },
    {
      title:'Start time',
      dataIndex:'dropTime',
      key:'dropTime',
      render:(text,rowIndex,viewIndex)=>{
        let row=dropPoints[viewIndex];
        let timing;
        if(!isValid(text)){
           timing = addMin(startTimeOfBp,row.off_set);
           dropPoints[viewIndex].dropTime = timing;
           setDropPoints([...dropPoints]);
        }else{
          timing=text;
        }
          return <TimePicker 
                    value={timing}
                    use12Hours 
                    format='h:mm A'
                    popupClassName={"time-picker-to-not-display-footer"}
                    onSelect={(value)=>{
                        dropPoints[viewIndex].dropTime=value;
                        setDropPoints([...dropPoints]);
                      }}
                />
      }
    }
  ]

    if(isValidArray(selectedTrip)&&isValid(selectedTrip.id)&&QWIK_BUS_USER===global.name){
        return (
            <Row type='flex' justify="center" align="middle">
                <Button type="primary" onClick={showModal}>
                    Import
                </Button>
                <Modal title="Import Drop Points" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
                    <Form
                    // {...layout}
                    className="form"
                    form={form}
                    name="basic"
                    initialValues={{}}
                    onFinish={onFinish}
                    >
                      <Form.Item
                      label="Template"
                      name="templateName"
                      rules={[{ 
                            required: true, 
                            message: 'Please input your template!'
                        }]}
                      >
                      <Radio.Group
                          options={getFormatedTemplates(templates)}
                        />
                      </Form.Item>
                      <Form.Item
                      label="Drop point start time"
                      name="startTime"
                      rules={[{ 
                        required: true, 
                        message: 'Please input your drop point start time!'
                      }]}
                      >
                        <TimePicker 
                        use12Hours 
                        format='h:mm A'
                        popupClassName={"time-picker-to-not-display-footer"}
                        onSelect={(value)=>{
                            form.setFieldsValue({
                              startTime:value
                            })
                          }}
                        />
                      </Form.Item>
                      <Form.Item>
                          <Button htmlType="submit" type="primary">Get</Button>
                      </Form.Item>
                    </Form>
                    {isValidArray(dropPoints)&&dropPoints.length>0?
                    <div>
                        <Table 
                            pagination={false}
                            style={{margin:'10px'}}
                            dataSource={dropPoints} 
                            columns={dropPointColumns} 
                        />
                        <Button type="primary" onClick={()=>{
                          let data=getFormateDropPoints(dropPoints,selectedTrip.id);
                            dispatch(createDropPoint(data,()=>{
                                dispatch(getDropLocationsByScheduleId(selectedTrip.id));
                                handleCancel()
                            }));
                        }}>Import</Button>
                    </div>
                    :null
                    }
                </Modal>
            </Row>
        );
    }else{
        return null;
    }
};