import React  from 'react';
import "../assets/styles/main.scss";
import { Typography } from 'antd';
import { PriceListRoot } from '../components/price-list-component/price-list-root/price-list-root.component';
import { VerifyPagePermission } from '../components/verify-page-permission-component/verify-page-permission.component';
import Permissions from "../utils/permissions-constant";

const { Title } = Typography;

export const PriceListPage = (props) => {

    const renderChildren =    <div style={{position:'relative',top:'10px'  , margin: '20px',marginBottom:'100px'}}>
                                    <Title level={4}>Price list</Title>
                                    <PriceListRoot 
                                    
                                    />
                                </div>

    return (
        <VerifyPagePermission 
        pagePermission={Permissions.pagePermission.ADMIN_VIEW_PRICE_PAGE}
        renderChildren={renderChildren}
        onPermissionSuccess={()=>{

        }}
        />
    );
}
