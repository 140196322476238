import { isValidArray } from "../../../utils/utilities"


export const getUnqiueOriginCities=(routes)=>{
    let cities=[] ,addedCity=[];
    if(isValidArray(routes)&&routes.length>0){
        routes.forEach(element => {
            if(addedCity.indexOf(element.origin)==-1){
                cities.push({label:element.origin,value:element.origin});
                addedCity.push(element.origin);
            }
        });
    }   
    return cities;
}