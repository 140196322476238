import { Typography , Row , Modal , Button } from 'antd';
import { RouteTable } from './routes-table';
import React, { useEffect , useState } from 'react';
import { createRoutes , getRoutesByTripId} from '../../actions/trip-actions';
import { useDispatch } from 'react-redux'

const {Text} = Typography;

export const ShowPossibleRoutes = (props) => {

  let {
    routes,
    isModalVisible,
    setIsModalVisible,
    setRoutes,
    tripId,
    routesLoading,
    oldRoutes,
    selectedTrip
  } = {...props};

  const dispatch = useDispatch();

  const [editedRoutes,setEditedRoutes] = useState([]);


  useEffect(()=>{
    setEditedRoutes(routes);
  },[routes])

  const handleOk = () => {
    dispatch(createRoutes(editedRoutes,tripId ,()=>{
      dispatch(getRoutesByTripId(tripId));
      setIsModalVisible(false);
    }))
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Row type='flex'>
        <Row>
              <Button type="primary" htmlType="submit">
                                 Create Routes
              </Button>
        </Row>
        <Row>
                <Modal 
                title="All Routes" 
                visible={isModalVisible}
                okText="Create routes"
                onOk={handleOk} 
                onCancel={handleCancel}
                width	={'1500px'}
                >          
                Routes
                <RouteTable 
                    dataSource={oldRoutes.concat(editedRoutes)} 
                    isRemoveRouteEnable={true}
                    setRoutes={setRoutes}
                    showFare={true}
                    setEditedRoutes={setEditedRoutes}
                    editedRoutes={editedRoutes}
                    isEditFareEnabled={true}
                    routesLoading={routesLoading}
                    oldRoutesLength={oldRoutes.length}
                    selectedTrip={selectedTrip}
                    showNextDay={true}
                />
                </Modal>
        </Row>
    </Row>
  );
};