import React, { useEffect, useState }  from 'react';
import { Table } from 'antd';
// import { columnsVechicleManagement } from './vechicle-management-table.module';;
import { formateBusesBusidasKey } from './vechicle-management-table.module';
import { isValid } from "../../../utils/utilities";

export const VechicleManagementTable = (props) => {

    const {
        vechicles,
        busesData,
        updateFormData,
        formData
    } = props;

    const [busesWithIdasKey , setBusesWithIdAsKey]=useState({});

    useEffect(()=>{
        setBusesWithIdAsKey(formateBusesBusidasKey(busesData));
    },[busesData]);

    const columns= [{
        title: 'Bus Description',
        dataIndex: 'bus_id',
        key: 'bus_id',
        render: (text) => (
          <span>
              { isValid(busesWithIdasKey)&&isValid(busesWithIdasKey[text])?busesWithIdasKey[text].description:""}
          </span>
        )
      }, {
        title: 'Vehicle No',
        dataIndex: 'vechicle_no',
        key: "vechicle_no"
      }];

    return (
        <div>
            <Table 
                dataSource={vechicles}
                columns={columns}
                onRow={(record, rowIndex) => {
                    return {
                      onClick: event => {
                        updateFormData(record);
                      }, // click row
                      onDoubleClick: event => {
          
                      }, // double click row
                      onContextMenu: event => {
          
                      }, // right button click row
                      onMouseEnter: event => {
          
                      }, // mouse enter row
                      onMouseLeave: event => {
          
                      }, // mouse leave row
                    };
                  }}
                  rowClassName={(record, index)=>{
                    let rowClassName="";
                        if(record.id== formData.id){
                            rowClassName=rowClassName+" TableRowClicked"
                        }
                        
                        return  rowClassName
                  }}
            />
        </div>
    );
}
