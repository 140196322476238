import { Form , Input, Button  , Typography , Layout, Row , Col, Menu, Radio } from 'antd';
import React, { useEffect, useState }  from 'react';
import { useDispatch } from 'react-redux';
import { createCancellationPolicy, updateCancellationPolicy, deleteCancellationPolicy } from '../../../actions/cancellation-policy.actions';
import {
  showConfirm ,isValid
} from '../../../utils/utilities';


const { Content , Footer } = Layout;
const { Title , Text } = Typography;

export const  CancellationPolicyForm = (props) => {

const [form] = Form.useForm();
const dispatch = useDispatch();
const [travelsId,setTravelsId] = useState(null);

const{
    formData,
    updateformData,
    addLoading,
    updateLoading,
    deleteLoading,
    operatorCode
} = {...props};
   
const handleSubmit = (e,buttonType) => {
    e.preventDefault();
    form.validateFields()
    .then(values => {
      let {
        operatorName,
        operatorCode,
        fromValue,
        deductionAmount,
        policyPattern,
        is_percentage,
        toValue,
      } = values;
      console.log("1000")
      console.log(values)
      if(buttonType==="add"){
        dispatch(createCancellationPolicy({
          fromValue,
          operatorCode,
          operatorName,
          deductionAmount,
          policyPattern,
          is_percentage,
          toValue
        },onSuccess))
      }
      else
      {
        dispatch(updateCancellationPolicy({
          fromValue,
          operatorCode,
          operatorName,
          deductionAmount,
          policyPattern,
          is_percentage,
          toValue,
          id : formData.id
         },onSuccess))
        }
    })
    .catch(errorInfo => {
      console.log({errorInfo});
    })
};

const onSuccess = ()=>{
  // getTravelMangements();
  resetForm();
}

const resetForm = () =>{
  form.resetFields();
  setTravelsId(null);
  updateformData({});
}

let defaultPostFixUserName = operatorCode;

useEffect(()=>{
  fillForm();
},[formData]);

const fillForm=()=>{
  if(isValid(formData)&&isValid(formData.id)){
    console.log("v")
    console.log(formData)
    let values={
      deductionAmount:formData.deductionAmount,
      fromValue : formData.fromValue,
      is_percentage : formData.is_percentage,
      operatorCode : formData.operator_code,
      operatorName : formData.operator_name,
      policyPattern : formData.policyPattern,
      toValue : formData.toValue,
      id : formData.id
    };
    form.setFieldsValue(values);
  }else{
    form.resetFields();
  }
}

const onFinishFailed=(error)=>{
  console.log({error});
}

const deleteCancellationPolicyAction=()=>{
  showConfirm(
      ()=>{
          dispatch(deleteCancellationPolicy(formData.id,formData.operator_code,onSuccess));
      },
      '',
      ''
    )
}

    return (
      <Layout>
            <Title level={4}>Cancellation Policy Form</Title>
              <Content className='form'>
                        <Form layout="inline" 
                                 form={form}
                                 name="basic"
                                 initialValues={{}}
                                 onFinish={handleSubmit}
                                 onFinishFailed={onFinishFailed}
                        >
                          <Row>
                          <Col span={7}>
                                <Form.Item
                                        label="Operator Name"
                                        name="operatorName"
                                        rules={[{ required: true, message: 'Please input your operator name!' }]}
                                      >
                                        <Input
                                          placeholder="Operator Name"
                                        />
                                </Form.Item>
                                </Col>
                                <Col span={6}>
                                <Form.Item
                                        label="Operator Code"
                                        name="operatorCode"
                                        rules={[{ required: true, message: 'Please input your operator code!' }]}
                                      >
                                        <Input 
                                          disabled={formData.id==undefined?false:true}
                                          placeholder="Operator Code"
                                          addonAfter={defaultPostFixUserName}
                                        />
                                </Form.Item>
                                </Col>
                                
                               
                                <Col span={4}>
                                <Form.Item
                                        label="From Value"
                                        name="fromValue"
                                        rules={[{ required: true, message: 'Please input your from value!' }]}
                                      >
                                        <Input 
                                          placeholder="From Value"
                                        />
                                </Form.Item>
                                
                                </Col>
                              
                                <Col span={6}>
                                <Form.Item
                                        label="Deduction Amount"
                                        name="deductionAmount"
                                        rules={[{ required: true, message: 'Please input your deduction amount!' }]}
                                      >
                                        <Input 
                                          placeholder="Deduction Amount"
                                        />
                                </Form.Item>
                                </Col>
                                </Row>
                                  <Col >
                                  <Row>
                                <Form.Item
                                        label="Policy Pattern"
                                        name="policyPattern"
                                        rules={[{ required: true, message: 'Please input your policy pattern!' }]}
                                      >
                                        <Input 
                                          placeholder="Policy Pattern"
                                        />
                                        
                                </Form.Item>
                                <Form.Item
                                                label="Is Percentage"
                                                name="is_percentage"
                                                rules={[{ required: true, message: 'Please input your is percentage!' }]}
                                              >
                                              <Radio.Group>
                                                  <Radio value={1} >Yes</Radio>
                                                  <Radio value={0} >No</Radio>
                                              </Radio.Group>
                                        </Form.Item>
                                <Form.Item
                                        label="To Value"
                                        name="toValue"
                                        rules={[{ required: true, message: 'Please input your to value!' }]}
                                      >
                                        <Input 
                                          placeholder="To Value"
                                        />
                                </Form.Item>
                                </Row>
                                </Col>
                                
                  </Form>
              </Content>
              <Footer style={{ textAlign: 'center' }} type="flex">
              <Row type="flex" justify="space-around">
                      <Button
                        loading={addLoading}
                        style={{margin:'5px',background:'#2EC17C'}}
                        disabled={formData.id==undefined?false:true}
                        onClick={(e)=>{
                         handleSubmit(e,'add');
                        }}
                        >
                        <Text>Add</Text>
                      </Button>
                      <Button type="primary"
                      loading={updateLoading}
                      style={{margin:'5px'}}
                      disabled={formData.id==undefined?true:false}
                       onClick={(e)=>{
                        handleSubmit(e,'update');
                       }}
                       >
                        Update
                      </Button>
                        <Button type="danger"
                        loading={deleteLoading}
                        style={{margin:'5px'}}
                        disabled={formData.id==undefined?true:false}
                        onClick={deleteCancellationPolicyAction}>
                        Delete
                      </Button>  
                      <Button type="block"
                        style={{margin:'5px'}}
                        onClick={()=>{
                            updateformData({});
                        }}>
                        Clear
                      </Button>
                  </Row>
              </Footer>
      </Layout>
    );
}




