import React , { useEffect, useState} from 'react';
import { AgentsTableComponent } from '../agent-table-component/agent-table.component';
import {  useSelector} from 'react-redux';
import {agentsReducerSelector} from '../../../selectors/agents-selectors';
import { AgentForm } from '../agents-form-component/agents-form.component';

export const AgentsRootComponent = (props) => {

    const {
        defaultLoadAgents,
        operatorCode,
        loadData
    } = props;

    const agentsReducer = useSelector(agentsReducerSelector);
    const [ formData,updateformData  ] = useState({});

    useEffect(()=>{
        if(defaultLoadAgents){
            loadData(operatorCode);
        }
    },[])

    return (
        <div>
                <AgentsTableComponent 
                {...agentsReducer}
                formData={formData}
                updateformData={updateformData}
                />
                <AgentForm
                {...agentsReducer}
                formData={formData}
                updateformData={updateformData}
                operatorCode={operatorCode}
                />
        </div>
    );
}
