/**
 * Address related actions can be created here
 */

import { 
    GET_SEAT_LAYOUT_BY_BUS_ID_URL , 
    GET_BUS_URL,
    CREATE_BUS_URL,
    DELETE_BUS_URL,
    UPDATE_BUS_SEAT_LAYOUT_URL,
    GET_SEAT_TYPES_URL,
    UPDATE_BUS_DETAILS_URL,
    DELETE_SEAT_URL, ADD_AMENITIES_TO_BUS_URL,
    VALIDATE_SEAT_URL, GET_BUS_TEMPALTE_URL, FETCH_AMENITIES_URL, FETCH_AMENITIES_BY_BUS_ID_URL
    } from "../utils/api-url";
import { 
    GET_BUSES_ACTION ,
    GET_SEAT_LAYOUT_BY_BUS_ID_ACTION ,
    CREATE_BUS,
    DELETE_BUS,
    UPDATE_BUS_SEAT_LAYOUT,
    RE_NAME_SEAT_NAME,
    RESET_BUSES,
    GET_SEAT_TYPES,
    UPDATE_BUS_DETAILS,
    DELETE_SEAT,
    SET_SEATER_TO_SLEEPER,
    VALIDATE_SEAT, ADD_AMENITIES_TO_BUS,
    GET_BUS_TEMPALTE, FETCH_AMENITIES, FETCH_AMENITIES_BY_BUS_ID
} from "./action-types";
import { postCall, getCall } from "../utils/network.util";
import { isValid } from "date-fns";

export const getSeatTypes=()=>{
    return{
        type:GET_SEAT_TYPES,
        payload:{
            url:GET_SEAT_TYPES_URL,
            api:postCall,
            apiPayload:{

            }
        }
    }   
}

export const createBus=({
        coach_type,
        description,
        extra_seats_in_last_row,
        isAc,
        name,
        number_of_columns,
        number_of_levels,
        number_of_rows,
        number_of_seats,
        number_of_upper_columns,
        number_of_upper_rows,
        seat_type,
        cgst_percentage,
        sgst_percentage,
        igst_percentage,
        seat_group_ids,
        supported_fare,
        bus_template
    },onSuccess)=>{
    return{
        type:CREATE_BUS,
        payload:{
            url:CREATE_BUS_URL,
            api:postCall,
            apiPayload:{
                bus:{
                    coach_type,
                    description,
                    extra_seats_in_last_row,
                    isAc,
                    name,
                    number_of_columns,
                    number_of_levels,
                    number_of_rows,
                    number_of_seats,
                    number_of_upper_columns,
                    number_of_upper_rows,
                    seat_type,
                    registration_number:'',
                    cgst_percentage,
                    sgst_percentage,
                    igst_percentage,
                    travels_id:global.operator_id,
                    seat_group_ids,
                    supported_fare,
                    bus_template
                }
            },
            onSuccess:onSuccess

        }
    }
}

export const updateBus=({
    id,
    coach_type,
    description,
    extra_seats_in_last_row,
    isAc,
    name,
    number_of_columns,
    number_of_levels,
    number_of_rows,
    number_of_seats,
    number_of_upper_columns,
    number_of_upper_rows,
    seat_type,
    cgst_percentage,
    sgst_percentage,
    igst_percentage,
    seat_group_ids,
    supported_fare
},onSuccess)=>{
return{
    type:UPDATE_BUS_DETAILS,
    payload:{
        url:UPDATE_BUS_DETAILS_URL,
        api:postCall,
        apiPayload:{
            bus:{
                id,
                coach_type,
                description,
                extra_seats_in_last_row,
                isAc,
                name,
                number_of_columns,
                number_of_levels,
                number_of_rows,
                number_of_seats,
                number_of_upper_columns,
                number_of_upper_rows,
                seat_type,
                registration_number:'',
                cgst_percentage,
                sgst_percentage,
                igst_percentage,
                travels_id:global.operator_id,
                seat_group_ids,
                supported_fare
            }
        },
        onSuccess:onSuccess

    }
}
}

export const deleteBus=(busId,onSuccess)=>{
    return{
        type:DELETE_BUS,
        payload:{
            url:DELETE_BUS_URL,
            api:postCall,
            apiPayload:{
                busId:busId,
                operator_code:global.operator_code
            },
            onSuccess:onSuccess
        }
    }
}

export const deleteSeat=(busId,seatNumber,onSuccess)=>{
    return{
        type:DELETE_SEAT,
        payload:{
            url:DELETE_SEAT_URL,
            api:postCall,
            apiPayload:{
                busId:busId,
                seatNumber:seatNumber
            },
            onSuccess:onSuccess
        }
    }
}

export const updateBusSeatLayout=(seatLayout,busId,onSuccess)=>{
    return{
        type:UPDATE_BUS_SEAT_LAYOUT,
        payload:{
            url:UPDATE_BUS_SEAT_LAYOUT_URL,
            api:postCall,
            apiPayload:{
                seatLayout:seatLayout,
                operator_code:global.operator_code,
                busId:busId
            },
            onSuccess:onSuccess
        }
    }
}

export const getBusDetailsAction = () => {
    return {
        type: GET_BUSES_ACTION,
        payload: {
            url: GET_BUS_URL,
            api: postCall,
            apiPayload:{
                travelsId:global.operator_id
            }
        }
    }
}

export const getBusTemplateAction = () => {
    return {
        type: GET_BUS_TEMPALTE,
        payload: {
            url: GET_BUS_TEMPALTE_URL,
            api: postCall
        }
    }
}

export const getBusSeatLayoutAction = (busId) => {
    return {
        type: GET_SEAT_LAYOUT_BY_BUS_ID_ACTION,
        payload: {
            url: GET_SEAT_LAYOUT_BY_BUS_ID_URL+"?busId="+busId,
            api: getCall,
            apiPayload:{
                operator_code:global.operator_code
            }
        }
    }
}

export const reNameSeat=(reNameSeatNumber,seatObject,seatGroupId,onFailure,onSuccess)=>{
    return {
        type: RE_NAME_SEAT_NAME,
        payload: {
            reNameSeatNumber,seatObject,seatGroupId,onFailure,onSuccess
        }
    }
}

export const seaterToSleeper=(seatType,seatObject,onFailure)=>{
    return {
        type: SET_SEATER_TO_SLEEPER,
        payload: {
            seatType,seatObject,onFailure
        }
    }
}

export const resetBuses=()=>{
    return{
        type:RESET_BUSES,
    }
}


export const validateSeatAction=(busId)=>{
    return{
        type:VALIDATE_SEAT,
        payload:{
            url:VALIDATE_SEAT_URL,
            api:postCall,
            apiPayload:{
                busId:busId,
            }
        }
    }
}

export const fetchAmenitiesAction = () => {
    return {
        type: FETCH_AMENITIES,
        payload: {
            url: FETCH_AMENITIES_URL,
            api: postCall,
            apiPayload: {}
        }
    }
}

export const fetchAmenitiesByBusId = (busId, onSuccess, onFailure) => {
    return {
        type: FETCH_AMENITIES_BY_BUS_ID,
        payload: {
            url: FETCH_AMENITIES_BY_BUS_ID_URL,
            api: postCall,
            apiPayload: {
                busId: busId
            },
            onSuccess: onSuccess,
            onFailure: onFailure
        }
    }
}

export const addAmenitiesToBusAction = (busId, amenities) => {
    return {
        type: ADD_AMENITIES_TO_BUS,
        payload: {
            url: ADD_AMENITIES_TO_BUS_URL,
            api: postCall,
            apiPayload: {
                busId: busId,
                amenities: amenities
            }
        }
    }
}