import React, {useState,useEffect} from 'react';
import {SeatLayout} from "./seat-layout.component";
import {getSeatGrid} from "./seat-layout-container.module";


export const SeatLayoutContainer = props => {

    const{
        seatsData,
        selectSeat,
        blockedSeats,
        selectedSeatNumber,
        isOnlyOneSeatSelected,
        isAllSeatsDisable
    } = {...props};

    const [showSeatLayout, updateShowSeatLayout] = useState(false);
    const [seatGrid, updateSeatGrid] = useState([[]]);

    const onSeatCLick = (object) => {
        let bool= selectSeat(object);
        return bool;
    };

    useEffect(() => {
        if(seatsData !== undefined && seatsData.length > 0) {
            const {
                seatGridLayout
            } = getSeatGrid(seatsData,onSeatCLick,blockedSeats,selectedSeatNumber,isOnlyOneSeatSelected,isAllSeatsDisable);
            const seatGridSeats = seatGridLayout.lowerSeatGrid;
            if(seatGridSeats.length > 1) {
                updateSeatGrid(seatGridLayout);
                updateShowSeatLayout(true);
            }
        }
        else {
            updateSeatGrid([[]]);
            updateShowSeatLayout(false);
        }
    },[seatsData,blockedSeats]);
    
    return (
        <div>
            <div className="seat-layout-parent-container" id="seat-layout">
                {showSeatLayout &&
                    <SeatLayout 
                    seatGridLayout={seatGrid} 
                    blockedSeats={blockedSeats} 
                    isOnlyOneSeatSelected={isOnlyOneSeatSelected} 
                    selectedSeatNumber={selectedSeatNumber}
                    />
                }
            </div>
        </div>
    );
};

SeatLayoutContainer.defaultProps = {

};

SeatLayoutContainer.propTypes = {

};
