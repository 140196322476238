import { Form , Input, Button , Row} from 'antd';
import React  from 'react';
import { loginAction } from '../../actions/login-actions';
import { useDispatch } from 'react-redux';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useNavigate , useLocation} from "react-router-dom";

export const  WrappedLoginForm = (props) => {

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate=useNavigate();
    const location = useLocation();

    const  handleSubmit = (e) => {
      e.preventDefault();
      form.validateFields()
      .then(values => {
          dispatch(loginAction(null,values.username,values.password,{navigate,location}));
      })
      .catch(errorInfo => {
        console.log({errorInfo});
      })
    };

    return (
      <Row type="flex" justify="space-around" style={{margin:'10px'}}>
          <Form className="login-form" form={form}>
            <Form.Item
                    label="User Name"
                    name="username"
                    rules={[{ required: true, message: 'Please input your user name!' }]}
                  >
                    <Input />
            </Form.Item>
            <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input your Password!' }]}
                  >
                    <Input.Password
                       iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button"
              onClick={(e)=>{
                handleSubmit(e);
              }}
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
      </Row>
    );
}
