import { 
    GET_GST,
    UPDATE_GST
} from './action-types';
import { 
    GET_GST_URL,
    UPDATE_GST_URL
  } from "../utils/api-url";
import { getCall, postCall } from "../utils/network.util";


export const getGstsDetails= () => {
    return {
        type: GET_GST,
        payload: {
            url: GET_GST_URL,
            api: getCall,
            apiPayload:{
                operator_code:global.operator_code
            }
        }
    }
}


export const updateGsts= ({acCGST,acSGST,nonacCGST,nonacSGST}) => {
    return {
        type: UPDATE_GST,
        payload: {
            url: UPDATE_GST_URL,
            api: postCall,
            apiPayload:{
                "acCGST" : acCGST,
                "acSGST" : acSGST,
                "nonacCGST" : nonacCGST,
                "nonacSGST" : nonacSGST,
                "acIGST" : 0,
                "nonacIGST" : 0,
                operator_code:global.operator_code
              }
        }
    }
}