import React, { useEffect, useState } from 'react';
import { useSelector , useDispatch} from 'react-redux';
import { getActiveSchedules , getInActiveSchedules} from '../../../actions/schedule-actions';
import { ScheduleHeader } from '../schedules-header-compponent/schedules-header.component';
import moment from 'moment';
import {getYear,getMonth} from '../../../utils/dates-util';
import {schedulesReducerSelector } from '../../../selectors/schedule-selector';
import {SchedulesTable} from '../schedules-table-component/schedules-table.component';
import { SchedulesInActiveTable } from '../schedule-in-active-table-component/schedule-in-active-table.component';
import { Typography } from 'antd';
import { loginReducerSelector } from '../../../selectors/login-selectors';

const { Title } = Typography;


export const SchedulesComponent = (props) => {

    const dispatch = useDispatch();
    const [selectedDate,setDate]=useState(moment());
    const [rowKeys,setRowKeys] = useState([]);
    let schedulesReducer =useSelector(schedulesReducerSelector);
    let {
      isLoggedIn
    } =useSelector(loginReducerSelector);

    useEffect(()=>{
      if(isLoggedIn){
        reloadSchdules();
      }
    },[selectedDate,isLoggedIn]);

    const reloadSchdules = () =>{
      let year=getYear(selectedDate);
      let month=getMonth(selectedDate)+1;
      dispatch(getActiveSchedules(year,month));
      dispatch(getInActiveSchedules());
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          setRowKeys([selectedRowKeys])
        },
        getCheckboxProps: record => ({
          disabled: record.name === 'Disabled User', // Column configuration not to be checked
          name: record.name,
        }),
      };

      const [activeSchedules,setActiveSchedules] = useState(schedulesReducer.activeSchedules);

    return (
        <div>
            <ScheduleHeader 
            selectedDate={selectedDate}
            setDate={setDate}
            rowKeys={rowKeys}
            reloadSchdules={reloadSchdules}
            setRowKeys={setRowKeys}
            activeSchedules={activeSchedules}
            setActiveSchedules={setActiveSchedules}
            unFilteredData={schedulesReducer.activeSchedules}
            />
            <SchedulesTable 
            dataSource={activeSchedules}
            selectedDate={selectedDate}
            rowSelection={rowSelection}
            loading={schedulesReducer.activeSchedulesLoading}
            />
          <Title level={4}> In Active Schedules</Title>
          <SchedulesInActiveTable 
            dataSource={schedulesReducer.inActiveSchedules}
            selectedDate={selectedDate}
            loading={schedulesReducer.inActiveSchedulesLoading}
            />
          
        </div>
    );
}
