import React, { useState }  from 'react';
import {RouteTable } from './../../trip-components/routes-table';
import { tripReducerSelector } from '../../../selectors/trip-selectors';
import { useSelector } from 'react-redux';
import { RoutesForm } from './routes-form.component';
import { Col , Row ,Layout} from 'antd';


export const RoutesComponent = (props) => {

    const {
        formData
    } = props;

    let tripReducer=useSelector(tripReducerSelector);
    const [ routesForm , setRoutesForm] = useState({});

    let {
        routes,
        routesLoading
    }=tripReducer;

    return (
        <Layout>
            <Row justify="space-around">
                    <Col>
                            <RoutesForm 
                               tripId={formData.id}
                               routesForm={routesForm}
                               setRoutesForm={setRoutesForm}
                               formData={formData}
                               routes={routes}
                            />
                    </Col>
                    <Col offset={2} style={{  margin: '10px',padding: '10px'}}>
                    <RouteTable 
                        tripId={formData.id}
                        dataSource={routes} 
                        showFare={true}
                        isRemoveRouteEnable={false}
                        showDuration={true}
                        isRowClickable={true}
                        routesForm={routesForm}
                        setRoutesForm={setRoutesForm}
                        routesLoading={routesLoading}
                        showNextDay={false}
                        />
                    </Col>
            </Row>     
        </Layout>
    );
}
