/**
 * All sagas for price list Saga must be defined here
 * @author Manne Hareesh
 */

import { takeLatest,put,call,all,fork } from 'redux-saga/effects'
import { 
    GET_SCHDULES_BY_DATE_FOR_PRICE_LIST,
    SET_SCHDULES_BY_DATE_FOR_PRICE_LIST,
    GET_SCHEDULE_GROUP,
    CREATE_SCHEDULE_GROUP ,
    SET_SCHEDULE_GROUP,
    UPDATE_SCHEDULE_GROUP,
    DELETE_SCHEDULE_GROUP,
    LOADING_ACTION
} from '../actions/action-types';
import { SUCCESS_STATUS } from '../utils/constant';
import { pageRedirect } from '../utils/utilities';
import { message } from 'antd';


export function* createSchedulesGroup(action){
    try{
        yield put({
            type: LOADING_ACTION,
            payload: true
        });
        const {
            api,
            url ,
            apiPayload,
            onSuccess
            } = {...action.payload};
        const apiResults = yield call(api , url,apiPayload);
        if(apiResults.status=="SUCCESS"){
            let clientPayload = apiResults.payload;
            if(clientPayload.Status=='SUCCESS'){
               message.success(clientPayload.Message);
               onSuccess();
            }else{
              message.error(clientPayload.Message);
            }
          }
          yield put({
            type: LOADING_ACTION,
            payload: false
        });
       }catch(error){
            yield put({
                type: LOADING_ACTION,
                payload: false
            });
            console.error("Error in create schedule group",error);
        }  
    }
 
 function* watchCreateSchedulesGroupSaga () {
    yield takeLatest(CREATE_SCHEDULE_GROUP,createSchedulesGroup)
 }

 export function* updateSchedulesGroup(action){
    try{
        yield put({
            type: LOADING_ACTION,
            payload: true
        });
        const {
            api,
            url ,
            apiPayload,
            onSuccess
            } = {...action.payload};
        const apiResults = yield call(api , url,apiPayload);
        if(apiResults.status=="SUCCESS"){
            let clientPayload = apiResults.payload;
            if(clientPayload.Status=='SUCCESS'){
               message.success(clientPayload.Message);
               onSuccess();
            }else{
              message.error(clientPayload.Message);
            }
          }
          yield put({
            type: LOADING_ACTION,
            payload: false
        });
       }catch(error){
            yield put({
                type: LOADING_ACTION,
                payload: false
            });
            console.error("Error in create schedule group",error);
        }  
    }
 
 function* watchUpdateSchedulesGroupSaga () {
    yield takeLatest(UPDATE_SCHEDULE_GROUP,updateSchedulesGroup)
 }

 export function* deleteSchedulesGroup(action){
    try{
        yield put({
            type: LOADING_ACTION,
            payload: true
        });
        const {
            api,
            url ,
            apiPayload,
            onSuccess
            } = {...action.payload};
        const apiResults = yield call(api , url,apiPayload);
        if(apiResults.status=="SUCCESS"){
            let clientPayload = apiResults.payload;
            if(clientPayload.Status=='SUCCESS'){
               message.success(clientPayload.Message);
               onSuccess();
            }else{
              message.error(clientPayload.Message);
            }
          }
          yield put({
            type: LOADING_ACTION,
            payload: false
        });
       }catch(error){
            yield put({
                type: LOADING_ACTION,
                payload: false
            });
            console.error("Error in create schedule group",error);
        }  
    }
 
 function* watchDeleteSchedulesGroupSaga () {
    yield takeLatest(DELETE_SCHEDULE_GROUP,deleteSchedulesGroup)
 }

export function* getSchedulesGroup(action){
    try{
        const {
            api,
            url
        } = {...action.payload};
        const apiResult = yield call(api,url);
        if(apiResult.status=="SUCCESS"){
          let clientPayload=apiResult.payload;
         if(clientPayload.Status=='SUCCESS'){
           let serverPayload=clientPayload.payload;
            yield put({
                type: SET_SCHEDULE_GROUP,
                payload: serverPayload
            });
          }else{
            message.error(clientPayload.Message);
          }
        }else{
            message.error("Unable to get schedule group");
          }
    }
    catch(error){
        console.error("Error in schedule groups",error);
    }
}

function* watchGetSchedulesGroupSaga() {
    yield takeLatest(GET_SCHEDULE_GROUP,getSchedulesGroup)
 }
/**
 * 
 * @param { get price list} action 
 */

export function* getPriceListForDate(action){
    try{
        const {
            api,
            url,
            apiPayload
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload=apiResult.payload;
         if(clientPayload.Status=='SUCCESS'){
           let serverPayload=clientPayload.payload;
            yield put({
                type: SET_SCHDULES_BY_DATE_FOR_PRICE_LIST,
                payload: serverPayload
            });
          }else{
            message.error(clientPayload.Message);
          }
        }else{
            message.error("Unable to get price list");
        }
    }
    catch(error){
        console.error("Error in getting price list",error);
    }
 }

 function* watchGetPriceListForDateSaga() {
    yield takeLatest(GET_SCHDULES_BY_DATE_FOR_PRICE_LIST,getPriceListForDate)
 }
 
 
export function*  watchPriceListsDetailsPage() {
    yield all([
        fork(watchGetPriceListForDateSaga),
        fork(watchGetSchedulesGroupSaga),
        fork(watchCreateSchedulesGroupSaga),
        fork(watchUpdateSchedulesGroupSaga),
        fork(watchDeleteSchedulesGroupSaga)
    ])
}