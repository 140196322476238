/**
 * All sagas for Bus operators Saga must be defined here
 * @author Manne Hareesh
 */

 import { takeLatest,put,call,all,fork } from 'redux-saga/effects'
 import { 
     GET_BUS_OPERATORS,
     INSERT_BUS_OPERATOR,
     DELETE_BUS_OPERATOR,
     UPDATE_BUS_OPERATOR,
     SET_BUS_OPERATORS,
     SET_BUS_OPERATORS_LOADING,
     SET_ADD_BUS_OPERATOR_RUNNING,
     SET_UPDATE_BUS_OPERATOR_RUNNING,
     SET_DELETE_BUS_OPERATOR_RUNNING,
     LOADING_ACTION,
     CREATE_OPERATOR_MAPPING
 } from '../actions/action-types';
 import { message } from 'antd';
 
 /**
  * 
  * @param { get bus operators} action 
  */
 
 export function* getBusOperators(action){
     try{
         const {
             api,
             url,
             apiPayload
         } = {...action.payload};
         yield put({
             type:SET_BUS_OPERATORS_LOADING,
             payload:true
         })
         const apiResult = yield call(api,url,apiPayload);
         if(apiResult.status=="SUCCESS"){
           let clientPayload=apiResult.payload;
          if(clientPayload.Status=='SUCCESS'){
            let serverPayload=clientPayload.Payload;
             yield put({
                 type: SET_BUS_OPERATORS,
                 payload: {
                    busOperators:serverPayload.travelsInfo
                    }
             });
           }else{
            message.error(clientPayload.Message);
           }
         }else{
            message.error("Failed to load bus operators.");
         }
         yield put({
            type:SET_BUS_OPERATORS_LOADING,
            payload:false
        })
     }
     catch(error){
         console.error("Error in getting bus operators",error);
         yield put({
            type:SET_BUS_OPERATORS_LOADING,
            payload:true
        })
     }
  }
 
  function* watchGetBusOperatorsSaga () {
     yield takeLatest(GET_BUS_OPERATORS,getBusOperators)
  }

  
 /**
  * 
  * @param { create bus operators} action 
  */
 
 export function* createBusOperator(action){
    try{
        const {
            api,
            url,
            apiPayload,
            onSuccess
        } = {...action.payload};
        yield put({
            type:LOADING_ACTION,
            payload:true
        })
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload=apiResult.payload;
         if(clientPayload.Status=='SUCCESS'){
           let serverPayload=clientPayload.Payload;
           onSuccess();
           message.success("Added bus operator.");
          }else{
           message.error(clientPayload.Message);
          }
        }else{
           message.error("Failed to load bus operators.");
        }
        yield put({
           type:LOADING_ACTION,
           payload:false
       })
    }
    catch(error){
        console.error("Error in getting bus operators",error);
        yield put({
           type:LOADING_ACTION,
           payload:false
       })
    }
 }

 function* watchCreateBusOperatorSaga () {
    yield takeLatest(INSERT_BUS_OPERATOR,createBusOperator)
 }


 
 /**
  * 
  * @param { update bus operators} action 
  */
 
  export function* updateBusOperator(action){
    try{
        const {
            api,
            url,
            apiPayload,
            onSuccess
        } = {...action.payload};
        yield put({
            type:LOADING_ACTION,
            payload:true
        })
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload=apiResult.payload;
         if(clientPayload.Status=='SUCCESS'){
           let serverPayload=clientPayload.Payload;
           message.success("Updated bus operator.");
           onSuccess();
          }else{
           message.error(clientPayload.Message);
          }
        }else{
           message.error("Failed to load bus operators.");
        }
        yield put({
           type:LOADING_ACTION,
           payload:false
       })
    }
    catch(error){
        console.error("Error in getting bus operators",error);
        yield put({
           type:LOADING_ACTION,
           payload:false
       })
    }
 }

 function* watchUpdateBusOperatorSaga () {
    yield takeLatest(UPDATE_BUS_OPERATOR,updateBusOperator)
 }


 
 /**
  * 
  * @param { delete bus operators} action 
  */
 
  export function* deleteBusOperator(action){
    try{
        const {
            api,
            url,
            apiPayload,
            onSuccess
        } = {...action.payload};
        yield put({
            type:LOADING_ACTION,
            payload:true
        })
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload=apiResult.payload;
         if(clientPayload.Status=='SUCCESS'){
           let serverPayload=clientPayload.Payload;
           message.success("Deleted bus operator.");
           onSuccess();
          }else{
           message.error(clientPayload.Message);
          }
        }else{
           message.error("Failed to load bus operators.");
        }
        yield put({
           type:LOADING_ACTION,
           payload:false
       })
    }
    catch(error){
        console.error("Error in getting bus operators",error);
        yield put({
           type:LOADING_ACTION,
           payload:false
       })
    }
 }

 function* watchDeleteBusOperatorSaga () {
    yield takeLatest(DELETE_BUS_OPERATOR,deleteBusOperator)
 }

 export function* createOperatorMapping(action){
    try{
        const {
            api,
            url,
            apiPayload,
        } = {...action.payload};
        yield call(api,url,apiPayload);
    }
    catch(error){
        console.error("Error in creating Operator Mapping",error);
    }
 }

 function* watchcreateOperatorMappingSaga () {
    yield takeLatest(CREATE_OPERATOR_MAPPING,createOperatorMapping)
 }

  
export function*  watchBusOperatorsPage() {
    yield all([
        fork(watchGetBusOperatorsSaga),
        fork(watchDeleteBusOperatorSaga),
        fork(watchUpdateBusOperatorSaga),
        fork(watchCreateBusOperatorSaga),
        fork(watchcreateOperatorMappingSaga)
    ])
}