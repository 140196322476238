import React, { useEffect, useState }  from 'react';
import { Form , Input , Button , Typography, Layout, Row ,Select , Col, InputNumber , Checkbox , Divider, message} from 'antd';
import { isValid, isValidIndex } from '../../../utils/utilities';
import { createBus,deleteBus,getBusDetailsAction , resetBuses , updateBus, validateSeatAction, fetchAmenitiesByBusId, addAmenitiesToBusAction} from '../../../actions/bus-actions';
import { useDispatch, useSelector } from 'react-redux';
import { showConfirm , isValidArray ,arrytoCommaSeparetedString , commaseperatedStringToArray} from '../../../utils/utilities';
import { EditsSeatsComponent } from '../edit-seats-component/edit-seats.component';
import { CloneBusComponent } from './../clone-bus-component/clone-bus.component';
import { SLEEPER_SEATER_TYPE , SLEEPER_GROUP_ID, QWIK_BUS_USER} from './../../../utils/constant';
import { busReducerSelector } from '../../../selectors/bus-selectors';
import { BusAmenitiesModal } from './bus-amenities-modal';
import { BusTemplateComponent } from '../bus-template/bus-template.component';
import { CloneBusTemplate } from '../clone-template/clone-template.component';

const { Content, Footer } = Layout;
const { Title , Text } = Typography;
const { Option } = Select;

const getSeatGroupOptions=(seatGroupsIds)=>{
    let formatedArray=[];
    if(isValidArray(seatGroupsIds)&&seatGroupsIds.length>0){
        seatGroupsIds.forEach(element => {
            formatedArray.push({ label: element.seat_group_id, value: element.seat_group_id })
        });
    }
    return formatedArray;
}

const validateSeatsAsSeatGroupIds=(seats)=>{
    let flag = false;
    if(isValidArray(seats)){
        seats.forEach((seat)=>{
            // console.log(seat.seat_group_id);
            if(!isValid(seat.seat_group_id)&&seat.seat_number != "Empty"){
                flag=true;
            }
        })
    }
    return flag;
}

export const BusForm = (props) => {

    const {
        formData,
        setFormData,
        seatTypes,
        seatTypesLoading,
        seatGroupsIds,
        busesTemplateData
    }=props;

    const [form] = Form.useForm();
    const dispatch=useDispatch();
    const [busTemplateId, setBusTemplateId] = useState(null);
    const [busTemplateObject, setBusTemplateObject] = useState({});
    const [busAmenity, setBusAmenity] = useState([]);
    const busReducer = useSelector(busReducerSelector);


    const isRowClicked = isValid(formData)&&isValid(formData.id)?true:false;

    const handleSubmit=(e,buttonType)=>{
        e.preventDefault();
        form.validateFields()
        .then(values => {
            let {
                seat_group_ids
            } = values;
            if(isValidArray(seat_group_ids)){
                values['seat_group_ids']=arrytoCommaSeparetedString(seat_group_ids);
            }
            if(isValid(busTemplateId)) {
                values['bus_template'] = busTemplateId;
            }
            values.coach_type = isValid(values.coach_type)?values.coach_type:'';
            if(buttonType==='add'){
                dispatch(createBus({...values},onSuccess))
            }else if(buttonType==='update'){
                dispatch(updateBus({
                    ...values,
                    id:formData.id
                },onSuccess))
            }
        })
    }

    const fetchBusAmenities = () => {
        dispatch(fetchAmenitiesByBusId(formData.id,setBusAmenity));
    }

    const validateSeat = () => {
        dispatch(validateSeatAction(formData.id))
    }

    const addAmenitiesToBus = (selectedAmenity) => {
        dispatch(addAmenitiesToBusAction(formData.id, selectedAmenity))
    }

    const onSuccess=()=>{
        setFormData({});
        getBusDetails();
        setBusTemplateId(null);
    }

    const getBusDetails=()=>{
        dispatch(getBusDetailsAction());
    }

    useEffect(()=>{
            fillForm(formData);
            if (isValid(formData.id)) {
                fetchBusAmenities();
            }
    },[formData]);

    useEffect(() => {
        let tempObject = {};
        busesTemplateData.map((templateData) => {
            tempObject[templateData.id] = templateData;
        })
        setBusTemplateObject(tempObject);
    },[busesTemplateData])

    const fillForm=(formValues,isFillByTemplate = false)=>{
        if(isValid(formValues)&&isValid(formValues.id)){
            form.setFieldsValue({
                description:formValues.description,
                seat_type:formValues.seat_type,
                number_of_seats:formValues.number_of_seats,
                number_of_levels:formValues.number_of_levels,
                extra_seats_in_last_row:formValues.extra_seats_in_last_row,
                number_of_upper_columns: formValues.number_of_upper_columns,
                number_of_upper_rows: formValues.number_of_upper_rows,
                number_of_rows:formValues.number_of_rows,
                number_of_columns:formValues.number_of_columns,
                seat_group_ids:commaseperatedStringToArray(formValues.seat_group_ids),
                supported_fare:formValues.supported_fare
            })
            if (!isFillByTemplate) {
                form.setFieldsValue({
                    coach_type:formValues.coach_type,
                    isAc:formValues.isAc,
                    name:formValues.name,
                    supported_fare:formValues.supported_fare
                })
            }
        }else{
            form.resetFields();
        }
    }   

    const deleteAction=()=>{
        showConfirm(
            ()=>{
                dispatch(deleteBus(formData.id,onSuccess));
            },
            '',
            ''
          )
      }

    const layout = {
        wrapperCol: { span: 16 },
      };

    const printOption = (option) => {
        return <Option  value={option.name} data={option}>{option.name}</Option>
      };

    // const busTemplateOptions = (option) => {
    //     return <Option key={option.id}>{option.template_name}</Option>
    // }

    const addDescriptionParams = (description, params) => {
        description = description.trim()
        if (params.length>0) {
            return (description+" "+params);
        }
        else {
            return description;
        }
    }

    const getDescriptionName = (coach_type,isAc,seat_type,name) => {
        let description="";
        if(isValid(coach_type)){
            description = addDescriptionParams(description,coach_type.trim());
        }
        if(isValidIndex(isAc)){
            let acDesc=(isAc==1?"A/C":"Non-A/C");
            description = addDescriptionParams(description,acDesc.trim());
        }
        if(isValid(seat_type)){
            description = addDescriptionParams(description,seat_type.trim());
        }
        if(isValid(name)){
            description = addDescriptionParams(description,name.trim());
        }
        return description;
    }

    const setFormByBusTemplate=(value)=>{
        let formValues = busTemplateObject[value];
        let{
            coach_type,
            isAc,
            name
        }=form.getFieldValue();
        let seat_type = formValues.seat_type;
        let description=getDescriptionName(coach_type,isAc,seat_type,name);
        formValues.description = description
        formValues.supported_fare = 1
        setBusTemplateId(value);
        fillForm(formValues,true);
    }

    let  isBusTemplateSelected = isValid(busTemplateId)?true:false;

    return (
        <Layout>
                <Title level={4}>Buses Form</Title>
                <Content className="form">
                            <Form 
                                     {...layout} 
                                    form={form}
                                    initialValues={{coach_type:null,supported_fare:1}}
                                    name="basic"
                                    onValuesChange={(changedValues, allValues)=>{
                                        if(isValid(changedValues.coach_type)||isValidIndex(changedValues.isAc)||isValid(changedValues.seat_type)||isValid(changedValues.name)){
                                            let{
                                                coach_type,
                                                isAc,
                                                seat_type,
                                                name
                                            }=allValues;
                                            let description=getDescriptionName(coach_type,isAc,seat_type,name);

                                            form.setFieldsValue({
                                                    description:description
                                                })
                                        }
                                    }}

                            >
                                <Row type="flex" gutter= {16}>
                                    <Col span={11}>
                                        <Form.Item
                                            label="Description"
                                            name="description"
                                            rules={[{ required: false, message: 'Please input description!' }]}
                                        >
                                            <Input
                                                disabled={true}
                                                style={{width:500}}
                                                placeholder="Auto generation field proceed from Coach Type"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span = {5}>
                                        <Form.Item
                                            label="Bus Identifier"
                                            name="name"
                                            rules={[{ required: false, message: 'Please input your bus name!' }]}
                                        >
                                            <Input
                                                placeholder="Bus name"
                                            />
                                      </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                        <Form.Item
                                                        label="Coach type"
                                                        name="coach_type"
                                                        rules={[{ required: false, message: 'Please input your coach Type!' }]}
                                                    >
                                                        <Select
                                                        showSearch
                                                        placeholder="Coach type"
                                                        >
                                                            <Option value={null}>Select a coach type</Option>
                                                            <Option value={"HI-TECH"}>HI-TECH</Option>
                                                            <Option value={"MULTI AXLE"}>MULTI AXLE</Option>
                                                            <Option value={"SCANIA MULTI AXLE"}>SCANIA MULTI AXLE</Option>
                                                            <Option value={"VOLVO MULTI AXLE"}>VOLVO MULTI AXLE</Option>
															<Option value={"VOLVO"}>VOLVO</Option>
                                                            <Option value={"BHARAT BENZ"}>BHARAT BENZ</Option>
                                                        </Select>
                                        </Form.Item>
                                </Col>
                                <Col span={3}>
                                        <Form.Item
                                                        label="A/C"
                                                        name="isAc"
                                                        rules={[{ required: true, message: 'Please input your ac/nonac!' }]}
                                                    >
                                                       <Select
                                                        showSearch
                                                        placeholder="AC or Non-Ac"
                                                        >
                                                            <Option value={1}>A/C</Option>
                                                            <Option value={0}>NonA/C</Option>
                                                        </Select>
                                        </Form.Item>
                                 </Col>
                                 </Row>
                                <Row gutter= {16}>
                                 <Col span= {8}>
                                    <BusTemplateComponent 
                                        onChange={setFormByBusTemplate}
                                    />
                                 </Col>
                                 <Col span={8}>
                                     {isValidArray(seatTypes)?
                                            <Form.Item
                                            label="Seat Type"
                                            name="seat_type"
                                            rules={[{ required: true, message: 'Please input your seat type!' }]}
                                            >
                                            <Select 
                                            showSearch
                                            placeholder={"Seat type?"}
                                            loading={seatTypesLoading}
                                            disabled={isBusTemplateSelected}
                                            onSelect={(value)=>{
                                                if(value===SLEEPER_SEATER_TYPE){
                                                    form.setFieldsValue({
                                                        seat_group_ids:[SLEEPER_GROUP_ID]
                                                    })
                                                }else{
                                                    form.setFieldsValue({
                                                        seat_group_ids:[]
                                                    })
                                                }
                                            }}
                                            >
                                                {seatTypes.map(printOption)}
                                            </Select>
                                            </Form.Item>
                                            :
                                            null
                                     }
                                         
                                 </Col>
                                 <Col span={8}>
                                           <Form.Item
                                                        label="No Of Seats"
                                                        name="number_of_seats"
                                                        rules={[{ required: true, message: 'Please input no of seats!' }]}
                                                    >
                                                        <InputNumber 
                                                            placeholder="Number"
                                                            disabled={isBusTemplateSelected}
                                                        />
                                            </Form.Item>
                                </Col>
                                </Row>
                                 <Row gutter= {16}>
                                <Col span={8}>
                                            <Form.Item
                                                label="Upper Berth?"
                                                name="number_of_levels"
                                                rules={[{ required: true, message: 'Please input upper berth!' }]}
                                            >
                                                  <Select
                                                  showSearch
                                                  placeholder="Upper berth"
                                                  disabled={isRowClicked||isBusTemplateSelected}
                                                    >
                                                            <Option value={2}>Yes</Option>
                                                            <Option value={1}>No</Option>

                                                 </Select>
                                              </Form.Item>
                                </Col>
                                <Col span={8}>
                                              <Form.Item
                                                        label="Last Row Aisle Seat"
                                                        name="extra_seats_in_last_row"
                                                        rules={[{ required: true, message: 'Please input aisle seat!' }]}
                                                    >
                                                         <Select
                                                         showSearch
                                                         placeholder={"Extra seat?"}
                                                         disabled={isRowClicked||isBusTemplateSelected}
                                                         >
                                                                    <Option value={1}>Yes</Option>
                                                                    <Option value={0}>No</Option>

                                                        </Select>
                                            </Form.Item>
                                 </Col>
                                 <Col span={8}>
                                              <Form.Item
                                                label="Use Default Fare"
                                                name="supported_fare"
                                                rules={[{ required: true, message: 'Please use Default Fare!' }]}
                                                >
                                                         <Select
                                                         showSearch
                                                         disabled={!isRowClicked&&!isBusTemplateSelected}
                                                         placeholder={"Use Default Fare"}
                                                         onChange={(value)=>{
                                                            if(value==0){
                                                                if(validateSeatsAsSeatGroupIds(busReducer.seatsData)){
                                                                    message.error("All seats should have a seat group before making default fare to no");
                                                                    form.setFieldsValue({supported_fare:1});
                                                                }
                                                            }
                                                         }}
                                                         >
                                                                    <Option value={1}>Yes</Option>
                                                                    <Option value={0}>No</Option>

                                                        </Select>
                                            </Form.Item>
                                 </Col>
                                 </Row>
                                 <Row>
                                        <Col span={6}>
                                                <Form.Item
                                                                label="Lower Berth No of rows"
                                                                name="number_of_rows"
                                                                rules={[{ required: true, message: 'Please input lower berth rows!' }]}
                                                            >
                                                                <InputNumber 
                                                                placeholder="Number"
                                                                disabled={isRowClicked||isBusTemplateSelected}
                                                                />
                                                    </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                                    <Form.Item
                                                        label="Lower Berth No of columns"
                                                        name="number_of_columns"
                                                        rules={[{ required: true, message: 'Please input lower berth colums!' }]}
                                                    >
                                                        <InputNumber 
                                                        placeholder="Number"
                                                        disabled={isRowClicked||isBusTemplateSelected}
                                                        />
                                                    </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                                    {isRowClicked?
                                                        <EditsSeatsComponent 
                                                        busSelected={formData}
                                                        />
                                                        :
                                                        null
                                                    }
                                        </Col>
                                        <Col span= {6}>
                                                <BusAmenitiesModal
                                                    isRowClicked = {isRowClicked}
                                                    amenities = {busReducer.amenities}
                                                    busAmenity = {busAmenity}
                                                    addAmenitiesToBus = {addAmenitiesToBus}
                                                />
                                        </Col>
                                 </Row>
                                 <Row>
                                            <Col span={6}>
                                                    <Form.Item
                                                                    label="Upper Berth No of rows"
                                                                    name="number_of_upper_rows"
                                                                    rules={[{ required: true, message: 'Please input upper berth rows!' }]}
                                                                >
                                                                    <InputNumber 
                                                                    placeholder="Number"
                                                                    disabled={isRowClicked||isBusTemplateSelected}
                                                                    />
                                                        </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                        <Form.Item
                                                            label="Upper Berth No of columns"
                                                            name="number_of_upper_columns"
                                                            rules={[{ required: true, message: 'Please input upper berth columns!' }]}
                                                        >
                                                            <InputNumber 
                                                            placeholder="Number"
                                                            disabled={isRowClicked||isBusTemplateSelected}
                                                            />
                                                        </Form.Item>
                                            </Col>
                                 </Row>
                                 <Row>
                                     <Divider />
                                 </Row>
                                  <Row>
                                        <Col>
                                            <Form.Item
                                                label="Seat Groups"
                                                name="seat_group_ids"
                                                rules={[{ required: false }]}
                                            >
                                                <Checkbox.Group options={getSeatGroupOptions(seatGroupsIds)} defaultValue={[]} disabled={isBusTemplateSelected}/>
                                            </Form.Item>
                                        </Col>
                                  </Row>
                           
                        </Form>
                </Content>
                <Footer style={{ textAlign: 'center' }} type="flex">
                <Row type="flex" justify="space-around">
                        <Button
                            style={{margin:'5px',background:'#2EC17C'}}
                            disabled={isRowClicked}
                            onClick={(e)=>{
                               handleSubmit(e,'add');
                            }}>
                            <Text>Add</Text>
                        </Button>
                        <Button type="primary"
                            style={{margin:'5px'}}
                            disabled={!isRowClicked}
                        onClick={(e)=>{
                            handleSubmit(e,'update');
                        }}>
                            Update
                        </Button>
                        {/* <ActivateSeatGroups 
                          busId={formData.id}
                        /> */}
                        <Button type="danger"
                            style={{margin:'5px'}}
                            disabled={!isRowClicked}
                            onClick={deleteAction}
                            >
                            Delete
                        </Button>
                        <Button
                            style={isRowClicked?{margin:'5px',background:'#2EC17C'}:{margin:'5px'}}
                            disabled={!isRowClicked}
                            onClick={validateSeat}>
                            <Text>Validate Seats</Text>
                        </Button>
                        <CloneBusComponent 
                          isAc={formData.isAc}
                          busId={formData.id}
                          name={formData.name}
                          description={formData.description}
                          getBusDetails={getBusDetails}
                        />
                        {global.privilege==QWIK_BUS_USER&&
                        <CloneBusTemplate 
                            busId={formData.id}
                        />}
                        <Button type="block"
                            style={{margin:'5px'}}
                            onClick={()=>{
                                setFormData({});
                                dispatch(resetBuses());
                                setBusTemplateId(null);
                            }}>
                            Clear
                        </Button>
                    </Row>
                </Footer>
        </Layout>
    );
}
