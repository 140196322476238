import React  from 'react';
import { useSelector } from 'react-redux';
import { DropPointComponent } from '../../trip-components/drop-points/drop-points.component';
import { tripReducerSelector } from '../../../selectors/trip-selectors';

export const DropPointsComponent = (props) => {

    let tripReducer = useSelector(tripReducerSelector);

    return (
        <div style={{padding:'10px'}}>
             <DropPointComponent 
             {...tripReducer}
             isRowClickEnabled={true}
             showDeleteUpdateButtons={true}
             />  
        </div>
    );
}
