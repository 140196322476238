import React from "react";
import { Navigate ,useLocation } from 'react-router-dom';



export const PrivateRoute = ({ isLoggedIn,children}) => {

  const location = useLocation();
  const pathName = location.pathname;
  const search = location.search;
  global.loginPathName=pathName+search;//setting last path name on refresh to work
  
  if (isLoggedIn ) {
    return children
  }
  return <Navigate to="/" replace/>
}
