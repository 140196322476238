import {Row, Button , Modal ,Checkbox, message ,Typography,Input ,Col} from 'antd';
import React, { useEffect, useState }  from 'react';
import { handleCloneBus } from './clone-bus.module';

const { Text } = Typography;

export const CloneBusComponent = (props) => {


    const {
        isAc,
        busId,
        getBusDetails
    } = props;

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [ac, setIsAc] = useState(isAc);
    const [identifierValue, setIdentifierValue] = useState(null);

    
    useEffect(()=>{
        setIsAc(isAc);
    },[isAc])

    const showModal = () => {
        setIsModalVisible(true);
      };
    
      const handleOk = () => {
        handleCloneBus(busId,ac,identifierValue,
            ()=>{
              handleCancel();
              getBusDetails();
            },
            ()=>{
               handleCancel();
               message.error("Failed to clone bus!")
            }
        )
      };
    
      const handleCancel = () => {
        setIsModalVisible(false);
      };

      const onChange=(e)=> {
        setIsAc(e.target.checked);
      }
      
    return (
        <div>
            <Button
            onClick={showModal}
            style={{margin:'5px' , background:'orange' , borderColor:'orange' ,color:'#FFFFFF'}}
            >
                    Clone
            </Button>
            <Modal title="Clone bus" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} okText="Clone">
              <Row>
                  <Checkbox onChange={onChange} checked={ac}>Is Ac</Checkbox>
              </Row>
              <Row style={{marginTop:'10px'}}>
                <Col>
                  <Text>Bus Identifier :</Text>
                    <Input 
                      placeholder="Bus Identifier"
                      value={identifierValue}
                      onChange={(e)=>{
                        setIdentifierValue(e.target.value);
                      }}
                    />
                </Col>
                
              </Row>
            </Modal>
        </div>
    );
}
