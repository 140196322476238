
import { message } from "antd";
import { API_BASE_URL } from "../../../app.config";
import { isValidArray } from "../../../utils/utilities";

let GET_DROP_POINTS_TEMPLATES_BY_CITIES_URL= `${API_BASE_URL}/api/getDropPointsTemplatesByCities`;
let GET_DROP_POINTS_BY_TEMPLATE_NAME_URL= `${API_BASE_URL}/api/getDropPointsByTemplateName`;

export const getUnqiueOriginCities=(routes)=>{
    let cities=[]
    if(isValidArray(routes)&&routes.length>0){
        routes.forEach(element => {
          if(cities.indexOf(element.destination)===-1){
            cities.push(element.destination);
          }
        });
    }    
    return cities;
}

export const getDropPointsTemplatesByCities=(cities,onSuccess)=>{
    fetch(GET_DROP_POINTS_TEMPLATES_BY_CITIES_URL, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            cities:cities,
            operatorCode:global.operator_code
        })
      }).then(response => response.json())
      .then(data => {
        if(data.Status=="SUCCESS"){
          onSuccess(data.Payload);
        }else{
            message.error(data.Message);
        }
      })
  }

  
export const getDropPoints=(templateName,scheduleId,onSuccess)=>{
    fetch(GET_DROP_POINTS_BY_TEMPLATE_NAME_URL, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            templateName:templateName,
            operatorCode:global.operator_code,
            scheduleId:scheduleId
        })
      }).then(response => response.json())
      .then(data => {
        if(data.Status=="SUCCESS"){
          if(data.Payload.length>0){
            onSuccess(data.Payload);
          }else{
            onSuccess([]);
            message.success("All drop points for template already inserted!")
          }
        }else{
            message.error(data.Message);
        }
      })
  }