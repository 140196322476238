import React  from 'react';
import "../assets/styles/main.scss";
import { HomeComponent } from '../components/home-component/home-root/home-component'
import { VerifyPagePermission } from '../components/verify-page-permission-component/verify-page-permission.component';
import Permissions from "../utils/permissions-constant";

export const HomePage = (props) => {

  const renderChildren=  <div>
                            <HomeComponent />
                          </div>

    return (
      <VerifyPagePermission 
      pagePermission={Permissions.pagePermission.ADMIN_VIEW_HOME_PAGE}
      renderChildren={renderChildren}
      onPermissionSuccess={()=>{

      }}
      />
    )    
}
