/**
 * @author Sanjay
 * @description Reducer for the components in Login page
 */
import { UPDATE_LOGIN_COMPONENT , RESET_ALL , SET_WEB_APP_READY } from '../actions/action-types';
import { isValid, isValidArray } from '../utils/utilities';

const initialState = {
    userInfo: {},
    isLoggedIn: false,
    isWebAppReady:false
}

const  setOperatorCodeToGlobal=(userInfo)=>{
    if(isValidArray(userInfo)&&isValid(userInfo.operator_code)){
        global.travels_phone_number = userInfo.travels_details.phone_number;
        global.dynamic_pricing_provider = userInfo.travels_details.dynamic_pricing_provider;
        global.dynamic_pricing_enabled = userInfo.travels_details.dynamic_pricing_enabled;

        global.operator_code=userInfo.operator_code;
        global.operator_id=userInfo.operator_id;
        global.name=userInfo.name;
        global.user_name=userInfo.user_name;
        global.privilege = userInfo.privilege;
    }else{
        global.operator_code=null;
        global.operator_id=null;
        global.travels_phone_number=null;
        global.name=null;
    }
    if(isValidArray(userInfo)){
        global.privilages = userInfo.privilages;
    }
}

export const loginReducer = (state = initialState,action) => {
    let newState = Object.assign({},state);
    switch (action.type) {
        case UPDATE_LOGIN_COMPONENT:
            newState["userInfo"] = action.payload;
            newState["isLoggedIn"] = true;
            setOperatorCodeToGlobal(action.payload);
            return newState;
        
        case SET_WEB_APP_READY :
            newState["isWebAppReady"]=action.payload;
            return newState;
            
        case RESET_ALL :
              newState["userInfo"] = {};
              newState["isLoggedIn"] = false;
              setOperatorCodeToGlobal();
              return newState;
        default:
            return state;
    }
}
