import { API_BASE_URL } from "../../../app.config";
import { message } from 'antd';

export const getHolidays=(groupId,setSelectedDays)=>{
  fetch(API_BASE_URL + '/api/getImportantDaysByGroupId', {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({groupId:groupId})
    }).then(response => response.json())
    .then(data => {
      if(data.Status=="SUCCESS"&&data.payload.important_days!=null){
        let important_days=JSON.parse(data.payload.important_days);
        let selectedDays=[];
        important_days.forEach((date)=>{
          selectedDays.push(new Date(date));
          setSelectedDays([...selectedDays]);
        })
      }else{
          setSelectedDays([])
      }
    })
}

export const getDataForGroup=(dateObject,selectedGroupId,setTripsData)=>{
  let month=Number(dateObject.getMonth())+1;
  let year=dateObject.getFullYear();
  if(selectedGroupId!=null){
    fetch(API_BASE_URL + '/api/getScheduleGroupPriceList',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        groupId:selectedGroupId,
        month:month,
        year:year,
        travelsId:global.operator_id
      }) // body data type must match "Content-Type" header
     }
    ).
    then((Responce)=>Responce.json()).
    then((findresponce)=>
    {
      console.log({findresponce});
      if(findresponce.Status="SUCCESS"){
        let tripsData=findresponce.payload.alldateTrips;
        setTripsData([...tripsData]);
      }else{
        let tripsData=[];
        setTripsData([...tripsData]);
      }
    })
  }else{
    let tripsData=[];
    setTripsData([...tripsData]);
  }
}

export const updateTripFare=({
  changedTripRouteIdsWithFare , changedTripIdsWithFare , changedTripIds,
  setChangedTripIdsWithFare,setChangedTripIds,setChangedTripRouteIdsWithFare
})=>{
    fetch(API_BASE_URL + '/api/bulkUpdateTripRoutesFareByTripId', {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({tripRoutesInfo:changedTripRouteIdsWithFare,tripsInfo:changedTripIdsWithFare,trip_ids:changedTripIds})
      }).then(response => response.json())
      .then(data => {
          if (data.Status == "SUCCESS") {
            message.success('Fare Updated Successfully');
            setChangedTripIdsWithFare({});
            setChangedTripIds([]);
            setChangedTripRouteIdsWithFare({});
          }else{
            message.warning('Unable to Update Fare Try again!');
          }
      })
}

export const updateHolidaysDays=(selectedDays,groupId,onSuccess)=>{
    fetch(API_BASE_URL + '/api/updateImportantDaysByGroupId', {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({important_days:selectedDays,groupId:groupId})
      }).then(response => response.json())
      .then(data => {
        if (data.Status == "SUCCESS") {
          onSuccess();
          message.success('Marking as a  holiday Successfully');
        }else{
          message.warning('Unable to Marking as a  holiday Try again!');
        }
      })
}