import React from 'react';
import { Provider } from 'react-redux';
// import { SpalshPage } from "./pages/spalsh.page";
// import { NavMenu } from "./components/header/navbar/navbar.component";
import { LoadingComponent } from './components/loading-component/loading.component';
import { RouteRoot } from './routes/route.root';

export const mountApp = (store = {}) => {
    return(
      <Provider store={store}>
          <RouteRoot />
          <LoadingComponent />
      </Provider>
    );
}
