import { Typography , Row  , Col , Layout} from 'antd';
import React , { useEffect, useState} from 'react';
import { isValid } from '../../../utils/utilities';
import { BoardingPointForm } from './boarding-point-form.component';
import { getBoadringPointCities } from './boarding-points.module';
import { useDispatch } from 'react-redux'
import { getAddressLocationsByCity } from '../../../actions/address-actions';
import { useSelector } from 'react-redux';
import { addressReducerSelector } from '../../../selectors/address-selectors';
import { BoardingPointTable } from './boarding-point-table.component';

const {Text} = Typography;
const { Header, Content, Footer } = Layout;


export const BoardingPointComponent = (props) => {

  const {
    routes,
    selectedTrip,
    boardingPoints,
    isRowClickable,
    showDeleteUpdateButtons,
    boardingPointsLoading
  } = props;

  const {
    id
  }=selectedTrip;

  const dispatch = useDispatch();
  const addressReducer = useSelector(addressReducerSelector)
  const [boardingFormData , setBoardingFormData] = useState({});

  useEffect(()=>{
    if(isValid(routes)){
      dispatch(getAddressLocationsByCity(getBoadringPointCities(routes),true));
    }
  },[routes]);

  return (
    <Layout>
      <Content>
             <Row type='flex' justify="space-around">
                      <Col span={8}>
                          <BoardingPointForm 
                            tripId={id}
                            {...addressReducer}
                            boardingFormData={boardingFormData}
                            setBoardingFormData={setBoardingFormData}
                            boardingPoints={boardingPoints}
                            showDeleteUpdateButtons={showDeleteUpdateButtons}
                            selectedTrip={selectedTrip}
                            routes={routes}
                          />
                      </Col>
                      <Col span={16}>
                          <BoardingPointTable 
                          boardingPointsLoading={boardingPointsLoading}
                          boardingPoints={boardingPoints}
                          isRowClickable={isRowClickable}
                          boardingFormData={boardingFormData}
                          setBoardingFormData={setBoardingFormData}
                          />
                      </Col>
              </Row>
      </Content>
    </Layout>
   
  );
};