import React  from 'react';
import "../assets/styles/main.scss";
import { UserPrivillegeRoot } from '../components/user-privillege-component/user-privillege-root/user-privillege-root.component';
import { Typography } from 'antd';
import { VerifyPagePermission } from '../components/verify-page-permission-component/verify-page-permission.component';
import Permissions from "../utils/permissions-constant";

const { Title } = Typography;

export const UserPrivillegePage = (props) => {

    const renderChildren =     <div style={{position:'relative',top:'10px'  , margin: '20px'}}>
                                    <Title level={4}>Agent Privileges</Title>
                                    <UserPrivillegeRoot 
                                    
                                    />
                                </div>

    return (
        <VerifyPagePermission 
        pagePermission={Permissions.pagePermission.ADMIN_VIEW_PRIVILAGES_PAGE}
        renderChildren={renderChildren}
        onPermissionSuccess={()=>{
  
        }}
        />
    );
}
