
    export const busColmuns = [
        {
          title: 'Bus Description',
          dataIndex: 'description',
          key: "center"  
        },
        {
        title: 'A/C',
        dataIndex: 'isAc',
        key: "ac_field",
  
            //   Cell: props => <span className='number'>{}</span> // Custom cell components!
      },{
        title: 'Coach Type',
        dataIndex: 'coach_type',
        key: "center",
      },{
        title: 'Seat Type',
        dataIndex: 'seat_type',
        key: "center",
      },
      {
        title: 'Seats',
        dataIndex: 'number_of_seats',
        key: "Seats_field",
      }
     ]