import { Form , Input, Button  , Typography , Layout, Row } from 'antd';
import React, { useEffect, useState }  from 'react';
import { useDispatch } from 'react-redux';
import  {
  updateTravelManagement , 
  createTravelManagement ,
  deleteTravelManagement 
} from './../../../actions/travel-management.actions';
 import {
  showConfirm ,isValid
 } from '../../../utils/utilities';
import { TravelsSelect } from './../../travels-select-component/travels-select.component';

const { Content, Footer } = Layout;
const { Title , Text } = Typography;

export const  TravelsManagementForm = (props) => {

const [form] = Form.useForm();
const dispatch = useDispatch();
const [travelsId,setTravelsId] = useState(null);

const{
    formData,
    updateformData,
    addLoading,
    updateLoading,
    deleteLoading,
    getTravelMangements
} = {...props};
   
const handleSubmit = (e,buttonType) => {
    e.preventDefault();
    form.validateFields()
    .then(values => {
      let {
        adminServerUrl,
        phoneNumber,
        name,
        operatorCode
      } = values;
      if(buttonType==="add"){
        dispatch(createTravelManagement({
          adminServerUrl,
          phoneNumber,
          name,
          travelsId,
          operatorCode
        },onSuccess))
      }else{
      dispatch(updateTravelManagement({
        travelManagementId:formData.id,
        adminServerUrl,
        phoneNumber,
        name,
        travelsId,
        operatorCode
      },onSuccess))
      }
    })
    .catch(errorInfo => {
      console.log({errorInfo});
    })
};

const onSuccess = ()=>{
  getTravelMangements();
  resetForm();
}

const resetForm = () =>{
  form.resetFields();
  setTravelsId(null);
  updateformData({});
}


useEffect(()=>{
  fillForm();
},[formData]);

const fillForm=()=>{
  if(isValid(formData)&&isValid(formData.id)){
    let values={
      phoneNumber:formData.phone_number,
      name : formData.name,
      adminServerUrl : formData.admin_server_url,
      operatorCode : formData.operator_code
    };
    form.setFieldsValue(values);
    setTravelsId(formData.travels_id);
  }else{
    form.resetFields();
  }
}


const onFinishFailed=(error)=>{
  console.log({error});
}

const deleteTravelManagementAction = ()=>{
  dispatch(deleteTravelManagement({
    travelManagementId:formData.id
  },onSuccess));
}

    return (
      <Layout>
            <Title level={4}>Travel Management Form</Title>
              <Content className='form'>
                        <Form layout="inline" 
                                 form={form}
                                 name="basic"
                                 initialValues={{}}
                                 onFinish={handleSubmit}
                                 onFinishFailed={onFinishFailed}
                        >
                                <Form.Item
                                        label="Phone number"
                                        name="phoneNumber"
                                        rules={[{ required: true, message: 'Please input your phone number!' }]}
                                      >
                                        <Input 
                                          placeholder="Phone number"
                                        />
                                </Form.Item>
                                <Form.Item
                                        label="Name"
                                        name="name"
                                        rules={[{ required: false, message: 'Please input your user name!' }]}
                                      >
                                        <Input 
                                          placeholder="Name"
                                        />
                                </Form.Item>
                                {/* <Form.Item
                                        label="Admin server url"
                                        name="adminServerUrl"
                                        rules={[{ required: true, message: 'Please input your admin server url!' }]}
                                      >
                                        <Input 
                                          placeholder="Admin server url"
                                        />
                                </Form.Item> */}
                                  <TravelsSelect 
                                      onSelect={(data)=>{
                                        setTravelsId(data.id);
                                      }}
                                      isFormItem={true}
                                      showingFiled="operator_code"
                                  />
                  </Form>
              </Content>
              <Footer style={{ textAlign: 'center' }} type="flex">
              <Row type="flex" justify="space-around">
                      <Button
                        loading={addLoading}
                        style={{margin:'5px',background:'#2EC17C'}}
                        disabled={formData.id==undefined?false:true}
                        onClick={(e)=>{
                         handleSubmit(e,'add');
                        }}>
                        <Text>Add</Text>
                      </Button>
                      <Button type="primary"
                      loading={updateLoading}
                      style={{margin:'5px'}}
                      disabled={formData.id==undefined?true:false}
                       onClick={(e)=>{
                        handleSubmit(e,'update');
                       }}>
                        Update
                      </Button>
                        <Button type="danger"
                        loading={deleteLoading}
                        style={{margin:'5px'}}
                        disabled={formData.id==undefined?true:false}
                        onClick={()=>{
                           showConfirm(deleteTravelManagementAction,'');
                        }}>
                        Delete
                      </Button>  
                      <Button type="block"
                        style={{margin:'5px'}}
                        onClick={()=>{
                            updateformData({});
                        }}>
                        Clear
                      </Button>
                  </Row>
              </Footer>
      </Layout>
    );
}

