import {
    GET_AGENT_COLOR,
    UPDATE_AGENT_COLOR,
    CREATE_AGENT_COLOR
} from './action-types';
import {
    GET_AGENT_COLOR_URL,
    UPDATE_AGENT_COLOR_URL,
    CREATE_AGENT_COLOR_URL
} from '../utils/api-url';
import { postCall , getCall  } from "../utils/network.util";


export const getAgentColorDetails= (operator_code) => {
    return {
        type: GET_AGENT_COLOR,
        payload: {
            url: GET_AGENT_COLOR_URL,
            api: postCall,
            apiPayload:{
                operatorCode:operator_code,
            }
        }
    }
}

export const updateAgentColor= ({operatorCode,primaryColor,secondaryColor,defaultButtonBgColor,defaultButtonTxtColor,headerBgColor,headerTxtColor,dropDownMenuBgColor,dropDownMenuTxtColor,dropDownSelectedBgColor,dropDownSelectedTxtColor}) => {
    return {
        type: UPDATE_AGENT_COLOR,
        payload: {
            url: UPDATE_AGENT_COLOR_URL,
            api: postCall,
            apiPayload:{
                operatorCode : operatorCode,
                data:{
                primaryColor : primaryColor,
                secondaryColor : secondaryColor,
                defaultButtonBgColor : defaultButtonBgColor,
                defaultButtonTxtColor : defaultButtonTxtColor,
                headerBgColor : headerBgColor,
                headerTxtColor : headerTxtColor,
                dropDownMenuBgColor : dropDownMenuBgColor,
                dropDownMenuTxtColor : dropDownMenuTxtColor,
                dropDownSelectedBgColor : dropDownSelectedBgColor,
                dropDownSelectedTxtColor : dropDownSelectedTxtColor,
                }
                
              }
        }
    }
}

export const createAgentColor= ({operatorCode,primaryColor,secondaryColor,defaultButtonBgColor,defaultButtonTxtColor,headerBgColor,headerTxtColor,dropDownMenuBgColor,dropDownMenuTxtColor,dropDownSelectedBgColor,dropDownSelectedTxtColor}) => {
    return {
        type: CREATE_AGENT_COLOR,
        payload: {
            url: CREATE_AGENT_COLOR_URL,
            api: postCall,
            apiPayload:{
                operatorCode : operatorCode,
                data:{
                primaryColor : primaryColor,
                secondaryColor : secondaryColor,
                defaultButtonBgColor : defaultButtonBgColor,
                defaultButtonTxtColor : defaultButtonTxtColor,
                headerBgColor : headerBgColor,
                headerTxtColor : headerTxtColor,
                dropDownMenuBgColor : dropDownMenuBgColor,
                dropDownMenuTxtColor : dropDownMenuTxtColor,
                dropDownSelectedBgColor : dropDownSelectedBgColor,
                dropDownSelectedTxtColor : dropDownSelectedTxtColor,
                }
                
              }
        }
    }
}

