import {Button, message } from 'antd';
import React, {  }  from 'react';
import { informRedBusDPAboutScheduleRegister } from './red-bus-dp.module';
import { loadingAction } from '../../actions/loading-actions';
import { useDispatch } from 'react-redux';

export const RegisterDPBtn = (props) => {


    const {
        scheduleId
    } = props;

    const dispatch = useDispatch();

    const setLoading=(loading)=>{
        dispatch(loadingAction(loading));
    }  

    const onSuccess=()=>{
        setLoading(false);
        message.success("Data access request successfully completed.")
    }

    const onFailure=(errMsg)=>{
        message.error(errMsg);
        setLoading(false);
    }
 
    if(global.dynamic_pricing_provider == "Redbus"&& global.dynamic_pricing_enabled == 1){
        return (
            <div>
                <Button
                style={{margin:'5px' , background:'orange' , borderColor:'orange' ,color:'#FFFFFF'}}
                onClick={()=>{
                    setLoading(true);
                    informRedBusDPAboutScheduleRegister(scheduleId,onSuccess,onFailure);
                }}
                >
                        Data access grant
                </Button>
            </div>
        );
    }else{
        return null
    }
}
