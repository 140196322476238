import React, {Fragment, useEffect, useState} from 'react';
import {generateSeatLayout} from "./seat-layout.module";

export const SeatLayout = props => {
    const{
        seatGridLayout
    } ={...props}
    const{
        lowerSeatGrid,
        upperSeatGrid,
        row_count,
        col_count,
        maximumRowNumberByColumnForLower,
        maximumRowNumberByColumnForUpper
    } ={...seatGridLayout}

    const [lowerSeatList,updateLowerSeatList] = useState([]);
    const [upperSeatList,updateUpperSeatList] = useState([]);
    const [showLowerSeatLayout, updateShowLowerSeatLayout] = useState(false);
    const [showUpperSeatLayout, updateShowUpperSeatLayout] = useState(false);

    useEffect(() => {
        let seatArray = [];
        if(lowerSeatGrid !== undefined){
            if(lowerSeatGrid.length > 1){
                seatArray = generateSeatLayout(lowerSeatGrid,maximumRowNumberByColumnForLower,col_count);
                if(seatArray.length > 1){
                    updateLowerSeatList(seatArray);
                    updateShowLowerSeatLayout(true)
                }else{
                  updateShowLowerSeatLayout(false);
                }
            }else{
              updateShowLowerSeatLayout(false);
            }
        }
        seatArray = [];
        if(upperSeatGrid !== undefined){
            if(upperSeatGrid.length > 1){
                seatArray = generateSeatLayout(upperSeatGrid,maximumRowNumberByColumnForUpper,col_count);
                if(seatArray.length > 1){
                    updateUpperSeatList(seatArray);
                    updateShowUpperSeatLayout(true);
                }else{
                  updateShowUpperSeatLayout(false);
                }
            }else{
              updateShowUpperSeatLayout(false);
            }
        }
    },[seatGridLayout]);
    return (
        <Fragment>
        {
            showLowerSeatLayout &&
            <SeatContainer deck="lower deck" seats={lowerSeatList}/>
        }
        {
            showUpperSeatLayout &&
            <SeatContainer deck="upper deck" seats={upperSeatList}/>
        }
        </Fragment>
    );
};

SeatLayout.defaultProps = {

};

SeatLayout.propTypes = {

};

/* To render container */
export const SeatContainer = (props) => {
    const {
        seats
    } = {...props};

    const [showContainer, updateContainer] = useState(seats.length > 0 ? true : false);
    useEffect(() => {
        if(seats.length > 0){
           updateContainer(true);
         }
    },[seats]);

    const {deck} = props;

    return (
        <div className="layout-container">
            <div className="deck-info">
                {deck}
            </div>
            {
                showContainer &&
                <div className="seats-container">
                    {seats}
                </div>
            }
        </div>
    );
};

/* To render seats column */
export const SeatColumn = (props) => {
    return (
        <div className="seats-column">
            {props.seats}
        </div>
    );
};

/* To render empty column */
export const EmptyColumn = (props) => {
    return (
        <div className="seats-column-empty"/>
    );
};
