import React, { useEffect, useState }  from 'react';
import { getActivetrips , updatePhoneNoandVechicleNo} from '../vechicle-allocation-table/vechicle-allocation-table.module';
import { Button, InputNumber, Select, Table ,Row} from 'antd';
import { loadingAction } from "../../../actions/loading-actions";
import { useDispatch } from 'react-redux';
const { Option } = Select;

export const VechicleAllocationTable = (props) => {
    const {
        selectedDate,
        vechicles
    } = props;

    let [tableData,setTableData]=useState([]);
    const [page,setPage] = useState(1);
    const dispatch=useDispatch();
    
    const loadingFuncCall=(value)=>{
        dispatch(loadingAction(value));
    }
    let pageSize=10;

        useEffect(()=>{
            getActivetrips(selectedDate,setTableData);
    },[selectedDate]);

    const printOption = (option) => {
        return <Option  value={option.id} data={option}>{option.vechicle_no}</Option>
      };

    const columnsVechicleAllocation= [{
        title: 'Trip Description',
        dataIndex: 'description',
        key: "description",
      }, {
        title: 'Vehicle No',
        dataIndex: 'vechicle_id',
        key: "vechicle_id",
        render: (text,row,index) => (
            <span>
                    <Select 
                        showSearch
                        style={{width:200}}
                        placeholder={"Select vechicle"}
                        value={text==null?row.schedule_vechicle_no:text}
                        onChange={(value)=>{
                            let indexWithPage = (page-1)*pageSize + index;
                            tableData[indexWithPage].vechicle_id=value;
                            setTableData([...tableData]);
                        }}
                        maxTagPlaceholder={"Select vechicle"}
                    >
                            {vechicles.map(option => {
                                        if (row.schedule_bus_id === option.bus_id||text==option.bus_id){
                                            return printOption(option);
                                        }else{
                                            return null;
                                        }
                                    })}
                    </Select>
            </span>
          )
      }, {
        title: 'Phone No',
        key: "phone_no",
        dataIndex:'phone_no',
        render: (text,row,index) => (
            <span>
                <InputNumber
                 value={(text==null||text=="")?row.schedule_phone_no:text}
                 style={{width:200}}
                 onChange={(value)=>{
                     let indexWithPage = (page-1)*pageSize + index;
                     tableData[indexWithPage].phone_no=value;
                     setTableData([...tableData]);
                  }}
                />
            </span>
        )
      }];

    return (
        <div>
                <Table 
                   columns={columnsVechicleAllocation}
                   dataSource={tableData}
                   pagination={{
                    pageSize:pageSize,
                    onChange(current) {
                      setPage(current);
                    }
                  }}
                   rowKey={record => record.id}
                   />
                <Row justify="center">
                        <Button 
                        type="primary"
                        onClick={()=>{
                            updatePhoneNoandVechicleNo(tableData,loadingFuncCall);
                        }}>Update</Button>
                </Row>
        </div>
    );
}
