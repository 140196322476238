/**
 * @author Sanjay
 * @description Reducer for the components in home page
 */
import {
  UPDATE_RECENT_SCHEDULE_ID,
  UPDATE_ROUTES ,
  UPDATE_DROP_POINTS,
  ADD_NEW_ROUTE,
  UPDATE_BOARDING_POINTS,
  SET_TRIPS,
  SET_SELECTED_TRIP,
  RESET_TRIPS_DATA,
  CLEAR_SELECTED_TRIP,
  SET_TRIPS_LOADING,
  SET_DROP_LOADING,
  SET_BOARDING_LOADING,
  SET_ROUTES_LOADING,
  RESET_ALL  } from '../actions/action-types';
import {
  get24HoursTime
} from '../utils/dates-util';
import {
  isValid
} from '../utils/utilities';

const initialState = {
    selectedTrip: {},
    routes:[],
    boardingPoints:[],
    dropPoints:[],
    trips:[],
    tripsLoading:true,
    routesLoading:false,
    boardingPointsLoading:false,
    dropPointsLoading:false
}

const findOrigin = (route,routes)=>{
  let time = get24HoursTime(route.time);
  route.destination=route.city;
  route.end_time=time;
  routes.forEach(element => {
    if(time<element.end_time){
      route.start_time=element.end_time;
      route.origin = element.origin;
      routes.push(route);
    }
  });
  return route;
}

const findDestination = (route,routes)=>{
  let time = get24HoursTime(route.time);
  route.origin=route.city;
  route.start_time=time;
  routes.forEach(element => {
    if(time>element.start_time){
      route.end_time=element.end_time;
      route.destination = element.destination;
      routes.push(route);
    }
  });

  return routes;
}

export const tripReducer = (state = initialState,action) => {
    let newState = Object.assign({},state);
    switch (action.type) {


      case SET_TRIPS_LOADING:{
        newState["tripsLoading"] = action.payload;
        return newState; 
      }

      case SET_BOARDING_LOADING:{
        newState["boardingPointsLoading"] = action.payload;
        return newState; 
      }

      case SET_DROP_LOADING:{
        newState["dropPointsLoading"] = action.payload;
        return newState; 
      }

      case SET_ROUTES_LOADING:{
        newState["routesLoading"] = action.payload;
        return newState; 
      }


      case CLEAR_SELECTED_TRIP:{
           newState["selectedTrip"] = {};
           return newState; 
      }

      case UPDATE_RECENT_SCHEDULE_ID:
            newState["scheduleId"] = action.payload;
            return newState;

      case UPDATE_ROUTES:
            newState["routes"] = action.payload;
            return newState;

      case ADD_NEW_ROUTE:{
        let {
          city,time,isOrigin,isDestination,originDay 
        } = action.payload;
        let routes = newState["routes"];
        if(isValid(isOrigin)&&isOrigin){
          newState["routes"] = findDestination({city,time,isOrigin,isDestination,originDay },routes);
        }else{
          newState["routes"] = findOrigin({city,time,isOrigin,isDestination,originDay },routes);
        }
        return newState;
      }
            
      case UPDATE_BOARDING_POINTS:{
        let {
          boardingPoints
        } = action.payload;
        newState["boardingPoints"] = boardingPoints;
        return newState;
      }
      
      case UPDATE_DROP_POINTS:{
        let {
          dropPoints
        } = action.payload;
        newState["dropPoints"] = dropPoints;
        return newState;
      }

      case RESET_TRIPS_DATA:{
        newState["routes"] = [];
        newState["boardingPoints"] = [];
        newState["dropPoints"] = [];
        newState["selectedTrip"] = {}
        return newState;
      }

      case SET_SELECTED_TRIP:{
        newState["selectedTrip"] = action.payload;
        return newState;
      }

      case SET_TRIPS :{
        let {
          trips
        } = action.payload;
        newState["trips"] = trips;
        return newState;
      }

      case RESET_ALL :
            newState["selectedTrip"] = {};
            return newState;

        default:
            return state;
    }
}
