import { Form, Input, Button , Row , Col , Select , TimePicker, InputNumber , Layout , Typography, Switch, Checkbox, Tooltip} from 'antd';
import React , {useEffect, useState} from 'react';
import { EditBlockedSeats } from './edit-block-seats';
import { insertTripAction , deleteTripById , getTrips , updateTrip}  from './../../actions/trip-actions';
import { useDispatch , useSelector } from 'react-redux';
import { getBusSeatLayoutAction } from '../../actions/bus-actions';
import { busReducerSelector } from '../../selectors/bus-selectors';
import { locationReducerSelector } from '../../selectors/location-selectors';
import { isValid, isValidArray, isValidNumber, isValidString } from '../../utils/utilities';
import { roundNumber } from '../../utils/math.util';
import moment  from 'moment';
import { getVechicles , updateVechiclesForSchedule} from '../../actions/vechicle-actions';
import { vechiclesReducerSelector } from '../../selectors/vechicle-selectors';
import { showConfirm , pageRedirect} from '../../utils/utilities';
import { useNavigate } from "react-router-dom";
import { loginReducerSelector } from '../../selectors/login-selectors';
import { CloneScheduleComponent } from './clone-schedule-component/clone-schedule.component';
import { ReturnScheduleComponent } from './return-schedule-component/return-schedule.component';
import { commaseperatedStringToArray } from './../../utils/utilities';
import { ValidateTripButton } from './../trip-components/validate-trip-button/validate-trip-button.component';
import { InformTripChangesToOTA } from '../inform-trip-changes-to-ota/inform-trip-changes-to-ota.component';
import { RegisterDPBtn } from '../red-bus-dp-btns/register-dp';
import { DeRegisterDPBtn } from '../red-bus-dp-btns/de-activate-dp';

//removing ok button antd
// https://medium.com/wesionary-team/customizing-antd-timepicker-3d49ccc8db16


const { TextArea } = Input;
const { Option } = Select;
const { Text } = Typography;

const { Content , Footer } = Layout;

const getTravelContantNumbers=()=>{
  return global.travels_phone_number
}

const getFareJsonObject=(fareJson)=>{
  if(isValidString(fareJson)){
    return JSON.parse(fareJson);
  }else{
    return null
  }
}

const buildFareJson=(busSelected,values)=>{
  let fareJson=null , fare = values.fare , fareObject1stFare = null;
  let isSeatGroupIdsExits = isValidArray(busSelected)&&isValid(busSelected.seat_group_ids)?true:false;
  if(isSeatGroupIdsExits){
    let seatGroupIds=commaseperatedStringToArray(busSelected.seat_group_ids);
    seatGroupIds.forEach(element => {
      if(!isValidArray(fareJson)){
        fareJson={}
      }
      if(!isValid(fareObject1stFare)){
        fareObject1stFare = values[element];
      }
      fareJson[element]=values[element];
    });
    if(busSelected.supported_fare === 0){
      fare = fareObject1stFare;
    }
  }
  return {fareJson,fare};
}

const renderPossibleFares=(busSelected)=>{

  let fareComponents=[];
  let isSeatGroupIdsExits = isValidArray(busSelected)&&isValid(busSelected.seat_group_ids)?true:false;
  if(isSeatGroupIdsExits){
    let seatGroupIds=commaseperatedStringToArray(busSelected.seat_group_ids);
    seatGroupIds.forEach(element => {
      fareComponents.push(<Col span={6}>
                            <Form.Item
                                label={element}
                                name={element}
                                tooltip="without gst"
                                rules={[{ required: true, message: 'Please input '+ element+'!' }]}
                                >
                                  <InputNumber
                                        placeholder={element}
                                        disabled={isValid(busSelected)?false:true}                 
                                  />                                
                            </Form.Item>
                          </Col>);
    });
  }
  return (
        <Row>
          {fareComponents}
        </Row>
        );
}

export const TripStep = (props) => {

    const {
      showUpdateButton,
      showDeleteButton,
      showCancelButton,
      showVechicleInfo,
      showFareInfo,
      formData,
      showEditBlockSeats,
      onClear,
      onCreateSuccess,
      showEasyTripButton
    } = props;

    const history = useNavigate();
    const dispatch = useDispatch();
    const vechiclesReducer  = useSelector(vechiclesReducerSelector);
    const busReducer= useSelector(busReducerSelector);
    const locationReducer = useSelector(locationReducerSelector);

    
    const {
      citiesData
    } = locationReducer;

    const {
      busesData 
    } = busReducer;

    const {
      vechicles
    }=vechiclesReducer;

    useEffect(()=>{
        fillForm();
    },[formData]);

    useEffect(()=>{
      dispatch(getVechicles());
    },[]);

    const {
      userInfo
    } = useSelector(loginReducerSelector);

    const fillForm=()=>{
          if(isValidArray(formData)&&isValid(formData.id)){
            let{
              origin,
              destination,
              bus_id,
              name,
              bus,
              end_time,
              start_time,
              distance,
              blocked_seat_numbers,
              fare,
              vechicle_id,
              phone_no,
              fare_json,
              alert_phone_numbers,
              destination_day,
              qbcc_order,
              cut_route_enabled,
              bp_check_enabled,
              breakeven_revenue
            }=formData;
            let fareJson=getFareJsonObject(fare_json);
            setBusSelected(bus);
            form.setFieldsValue({ 
                  origin:origin,
                  start_time:moment(start_time,'HH:mm') ,
                  name:name,
                  destination:destination,
                  end_time:moment(end_time,'HH:mm'),
                  distance:distance,
                  bus_id:bus_id,
                  blocked_seat_numbers:blocked_seat_numbers,
                  fare:fare,
                  vechicle_id:vechicle_id,
                  phone_no:phone_no,
                  alert_phone_numbers:alert_phone_numbers,
                  destination_day:destination_day,
                  qbcc_order:qbcc_order,
                  cut_route_enabled: cut_route_enabled,
                  bp_check_enabled: bp_check_enabled,
                  breakeven_revenue:breakeven_revenue,
                  ...fareJson
               });
               if(isValid(blocked_seat_numbers)){
                let bSeats = blocked_seat_numbers.split(",");
                setBlockedSeats([...bSeats]);
               }else{
                setBlockedSeats([]);
               }
               if (cut_route_enabled) {
                 setSeatSharing(true);
               }
               else {
                 setSeatSharing(false);
               }
               reCalcFare(fare);
               setIsEditForm(true);
          }else{
            resetForm();
          }
    }
    
    const resetForm=()=>{
      form.resetFields();
      setBusSelected(null)
      setIsEditForm(false);
    }

    const updateTripAction=(onSuccessUpdate)=>{
      formData.blocked_seat_numbers=blockedSeats.join(',');
      dispatch(updateTrip(formData,onSuccessUpdate))
    }
  const insertAction=(values)=>{
      dispatch(insertTripAction({
      ...values,
      travels_id:userInfo.operator_id,
      operator_name:userInfo.operator_name
      },onCreateSuccess));
  }
  const getBusLayOut=(busId)=>{
    dispatch(getBusSeatLayoutAction(busId));
  }

  const [form] = Form.useForm();
  const [blockedSeats,setBlockedSeats] = useState([]);
  const [busSelected,setBusSelected] = useState(null);
  const [originSelected,setOriginSelected] = useState(null);
  const [destinationSelected,setDestinationSelected] = useState(null);
  const [ vechicleFiltered, setVechiclesFiltered ] =  useState(vechicles);
  const [ vechiclesSelected , setVechicleSelected] = useState({});
  const [ isEditForm , setIsEditForm]=useState(false);
  const [isSeatSharing, setSeatSharing] = useState(false);

 const handleSubmit = (e,buttonType) => {
    e.preventDefault();
    form.validateFields()
    .then(values => {
        formateAndInsertTrip(values,buttonType);
    })
    .catch(errorInfo => {
      console.log({errorInfo});
    })
  };

  const selectSeat =(object)=>{
    let flag = false;
    let index = blockedSeats.indexOf(object.seat_number);
    if(index==-1){
      blockedSeats.push(object.seat_number);
      flag=true;
    }else{
      blockedSeats.splice(index,1);
    }
    setBlockedSeats([...blockedSeats]);
    return flag;
  }
  useEffect(()=>{
    form.setFieldsValue({ blocked_seat_numbers: blockedSeats.toString().replace("[", "").replace("]", "")});
  },[blockedSeats]);


const deleteAction=()=>{
  let tripId=formData.id;
  showConfirm(
    ()=>{
      dispatch(deleteTripById(tripId,onSuccess))
    },
    '',
    ''
  )}

const onSuccess=()=>{
  resetForm();
  setIsEditForm(false);
  onClear();  
  dispatch(getTrips(userInfo.operator_id));
}


const formateAndInsertTrip=(values,buttonType)=>{

  values['description'] = values.origin + " " + values.destination + " " + values.start_time.format("hh:mm A") +" " + busSelected.description + " " + values.name;
  values['start_time'] = values.start_time.format("HH:mm:ss");
  values['end_time'] = values.end_time.format("HH:mm:ss");
  values['bus_id'] = busSelected.id;
  let {fareJson,fare}=buildFareJson(busSelected,values);
  values['fare_json'] = fareJson;
  values['fare'] = fare;
  if(buttonType=='add'){
    values['origin_latitude'] = originSelected.latitude;
    values['origin_longitude'] = originSelected.longitude;
    values['destination_latitude'] = destinationSelected.latitude;
    values['destination_longitude'] = destinationSelected.longitude;
    insertAction(values);
  }else{
    values['id']=formData.id;
    dispatch(updateTrip(values,onCreateSuccess));
  }
}

  const printOption = (option) => {
      return <Option  value={option.name} data={option}>{option.name}</Option>
    };

 const printBusesOption = (option) => {
        return <Option  value={option.id} data={option} key={option.description}>{option.description}</Option>
  };

  const printVechiclesOption = (option) => {
    return <Option  value={option.id} data={option} key={option.vechicle_no}>{option.vechicle_no}</Option>
    };

  useEffect(()=>{
      let fare = form.getFieldValue('fare');
      reCalcFare(fare);
      resetVechicles();
  },[busSelected]);

  const resetVechicles = ()=>{
    if(isValidArray(busSelected)&&isValidArray(vechicles)&&vechicles.length>0){
    let data=[];
    vechicles.map((entry)=>{
        if(entry.bus_id==busSelected.id){
          data.push(entry);
        }
      })
      setVechiclesFiltered(data);
    }
  }

  const reCalcFare = (fare)=>{
    if(isValidArray(busSelected)){
      let {
        cgst_percentage,
        sgst_percentage,
        igst_percentage
      }=busSelected;

      if(isValidNumber(fare)){
          let tripFareCgst=(fare*cgst_percentage)/100;
          let tripFareSgst=(fare*sgst_percentage)/100;
          let tripFareIgst=(fare*igst_percentage)/100;
          let apiFare = tripFareCgst+tripFareSgst+tripFareIgst+fare;
    
          form.setFieldsValue({ 
            total_fare : roundNumber(apiFare),
            sgst: roundNumber(tripFareSgst),
            cgst: roundNumber(tripFareCgst)
          });
      }
    }
  }


  let isTripSelected = isValid(formData)&&formData.id?true:false;

    const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
          };
    return (
      <Layout>
          <Content className="form" >
                            <Form
                            {...layout}
                            form={form}
                            name="basic"
                            initialValues={{name:'',alert_phone_numbers:getTravelContantNumbers(),destination_day:0}}
                            >    
                              <Row>
                                <Col span={6}>
                                              <Form.Item
                                                      label="Origin"
                                                      name="origin"
                                                      rules={[{ required: true, message: 'Please input your origin!' }]}
                                                    >
                                                      <Select
                                                          showSearch
                                                          // disabled={isEditForm}
                                                          placeholder="Select origin"
                                                          style={{ width: 200 }}
                                                          optionFilterProp="children"
                                                          onChange={(value,componentData)=>{
                                                            setOriginSelected(componentData.props.data);
                                                          }}
                                                      >
                                                      {citiesData.map(printOption)}
                                                      </Select>
                                              </Form.Item>
                                </Col>
                                <Col span={6}>
                                              <Form.Item
                                                      label="Depature Time"
                                                      name="start_time"
                                                      rules={[{ required: true, message: 'Please input depature Time!' }]}
                                                    >
                                                      <TimePicker 
                                                      use12Hours format='h:mm A'  minuteStep={5} 
                                                      // disabled={isEditForm}
                                                      popupClassName={"time-picker-to-not-display-footer"}
                                                      onSelect={(value)=>{
                                                          form.setFieldsValue({
                                                            start_time:value
                                                          })
                                                        }}
                                                      />
                                              </Form.Item>
                                </Col>
                                <Col span={6}>
                                              <Form.Item
                                                      label="Trip Identifier"
                                                      name="name"
                                                      rules={[{ required: false, message: 'Please input identifier!' }]}
                                                    >
                                                      <Input
                                                        // disabled={isEditForm}
                                                          placeholder="Trip identifier"
                                                      />                                
                                              </Form.Item>
                                            
                                </Col>
                                <Col span={6}>
                                        <Form.Item
                                                      label="Total Distance"
                                                      name="distance"
                                                      rules={[{ required: true, message: 'Please input distance value!' }]}
                                                    >
                                                    <Input
                                                      // disabled={isEditForm}
                                                      placeholder="Total distance"
                                                    />                              
                                        </Form.Item>
                                </Col>    
                            </Row>
                            <Row>
                                <Col span={6}>
                                              <Form.Item
                                                      label="Destination"
                                                      name="destination"
                                                      rules={[{ required: true, message: 'Please input destination!' }]}
                                                    >
                                                      <Select
                                                          showSearch
                                                          // disabled={isEditForm}
                                                          placeholder="Select destination"
                                                          style={{ width: 200 }}
                                                          onChange={(value,componentData)=>{
                                                            setDestinationSelected(componentData.props.data);
                                                          }}
                                                      >
                                                      {citiesData.map(printOption)}
                                                      </Select>                              
                                            </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                          <Form.Item
                                                      label="Arival Time"
                                                      name="end_time"
                                                      rules={[{ required: true, message: 'Please input arival time!' }]}
                                                    >
                                                      <TimePicker 
                                                      popupClassName={"time-picker-to-not-display-footer"}  
                                                      onSelect={(value)=>{
                                                        form.setFieldsValue({
                                                          end_time:value
                                                        })
                                                      }}
                                                      use12Hours format='h:mm A'  minuteStep={5} 
                                                      // disabled={isEditForm}
                                                      />                         
                                            </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                          <Form.Item
                                            label="Destination Day"
                                            name='destination_day'
                                            rules={[{ required: false, message: 'Please input day' }]}
                                            initialValue={false}
                                          >
                                                  <Select
                                                      showSearch
                                                      placeholder="Select day"
                                                      style={{ width: 200 }}
                                                      defaultValue={0}
                                                  >
                                                    <Option  value={0}>Day 1</Option>{/*same day*/}
                                                    <Option  value={1}>Day 2</Option>{/*next day*/}
                                                    <Option  value={2}>Day 3</Option>
                                                    <Option  value={3}>Day 4</Option>
                                                  </Select>         
                                          </Form.Item>
                                </Col>
                                <Col span={6}>
                                          <Form.Item
                                            label="Qbcc order"
                                            name='qbcc_order'
                                            rules={[{ required: false, message: 'Please input qbcc order' }]}
                                            initialValue={0}
                                          >
                                                  <Input />         
                                          </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                            <Form.Item
                                                      label="Select bus"
                                                      name="bus_id"
                                                      rules={[{ required: true, message: 'Please input select bus!' }]}
                                                    >
                                                    <Select
                                                      showSearch
                                                      // disabled={isEditForm}
                                                      placeholder="Select bus"
                                                      style={{ width: 200 }}
                                                      onChange={(value , componentData)=>{
                                                          setBusSelected(componentData.props.data);
                                                      }}
                                                      optionFilterProp="children"
                                                      filterOption={(input, option) =>  
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                                        || option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                      }
                                                  >
                                                  {busesData.map(printBusesOption)}
                                                  </Select>                            
                                            </Form.Item>
                                </Col>
                                <Col span={6}>
                                        <Form.Item
                                                label="Alert Phone No"                                       
                                                name="alert_phone_numbers"
                                                rules={[{ required: false, message: 'Please input alert phone no!' }]}
                                        >
                                                <Input
                                                    placeholder="Alert Phone No"
                                                />
                                        </Form.Item>
                                </Col>
                            </Row>                            
                            {isValid(showFareInfo)&&showFareInfo?
                            <div>
                              {isValidArray(busSelected) && busSelected.supported_fare === 1 &&
                                  <Row>
                                    <Col span={6}>
                                        <Form.Item
                                                label="Fare"
                                                name="fare"
                                                tooltip="without gst"
                                                rules={[{ required: true, message: 'Please input fare!' }]}
                                              >
                                                <InputNumber
                                                    placeholder="Fare"
                                                    disabled={isValid(busSelected)?false:true}
                                                    onChange={(value)=>{
                                                        reCalcFare(value);
                                                    }}
                                                />                                
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                                label="Cgst"
                                                name="cgst"
                                                rules={[{ required: false, message: 'Please input cgst!' }]}
                                              >
                                                <Input
                                                    disabled={true}
                                                    placeholder="Cgst"
                                                />                                
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                                label="Sgst"
                                                name="sgst"
                                                rules={[{ required: false, message: 'Please input sgst!' }]}
                                              >
                                                <Input
                                                    disabled={true}
                                                    placeholder="Sgst"
                                                />                                
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                                label="Total Fare"
                                                name="total_fare"
                                                rules={[{ required: false, message: 'Please input total fare!' }]}
                                              >
                                                <Input
                                                    disabled={true}
                                                    placeholder="Total fare"
                                                />                                
                                        </Form.Item>
                                    </Col>
                                </Row>
                              }
                              {renderPossibleFares(busSelected)}
                            </div>
                            :null
                            }
                            {isValid(showVechicleInfo)&&showVechicleInfo&&isEditForm?
                            <Row>
                                <Col span={6}>
                                {isValid(showEditBlockSeats)&&showEditBlockSeats&&isEditForm?
                                            <div>
                                                  <Form.Item
                                                        label="Edit Blocked Seats"                                       
                                                        name="blocked_seat_numbers"
                                                        rules={[{ required: false, message: 'Please input select bus!' }]}
                                                        >
                                                        <TextArea rows={4} 
                                                        />
                                                  </Form.Item>
                                                    <EditBlockedSeats 
                                                    getBusLayOut={getBusLayOut}
                                                    busSelected={busSelected}
                                                    busReducer={busReducer}
                                                    blockedSeats={blockedSeats}
                                                    selectSeat={selectSeat}
                                                    updateTripAction={updateTripAction}
                                                    setBlockedSeats={setBlockedSeats}
                                                    />
                                            </div>
                                                :
                                                null
                                            }
                                          
                                </Col>
                                <Col span={6}>
                                        <Form.Item
                                                      label="Vechicle no"
                                                      name="vechicle_id"
                                                      rules={[{ required: false, message: 'Please input your vechicle no!' }]}
                                                    >
                                                      <Select
                                                          showSearch
                                                          allowClear={true}
                                                          placeholder="Select vechicle"
                                                          style={{ width: 200 }}
                                                          onChange={(value,componentData)=>{
                                                            setVechicleSelected(componentData.props.data);
                                                          }}
                                                          optionFilterProp="children"
                                                          filterOption={(input, option) =>  
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                                            || option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                          }
                                                      >
                                                      {vechicleFiltered.map(printVechiclesOption)}
                                                      </Select>
                                              </Form.Item>
                                </Col>
                                <Col span={6}>
                                        <Form.Item
                                                      label="Phone no"
                                                      name="phone_no"
                                                      rules={[{ required: false, message: 'Please input your phone no!' }]}
                                                    >
                                                      <Input placeholder="phone number" />
                                              </Form.Item>
                                </Col>
                                <Col>
                                      <Button type="primary" onClick={()=>{
                                        let phone_no= form.getFieldValue('phone_no');
                                        let vechicleId = null;
                                        if(isValid(vechiclesSelected.id)){
                                          vechicleId = vechiclesSelected.id;
                                        }
                                        dispatch(updateVechiclesForSchedule(formData.id,vechicleId,phone_no,()=>{
                                          dispatch(getTrips(userInfo.operator_id));
                                        }));
                                      }}>
                                              Update
                                      </Button>
                                </Col>
                            </Row>
                            :
                            null
                            }
                            <Row>
                              <Col span= {6}>
                                <Tooltip title = "Enables the same seat to be booked by a second passenger after the first passenger gets down.">
                                  <Form.Item
                                    label = "Seat Sharing"
                                    name = "cut_route_enabled"
                                    valuePropName = "checked"
                                  >
                                    <Switch onChange={(checked) => {setSeatSharing(checked)}}/>
                                  </Form.Item>
                                </Tooltip>
                              </Col>
                              <Col span={6}>
                                  <Form.Item
                                    label = "Breakeven Revenue"
                                    name = "breakeven_revenue"
                                  >
                                    <Input />
                                  </Form.Item>
                              </Col>
                              {isSeatSharing&&<Col span= {12}>
                                <Tooltip title = "A second passenger’s boarding point can only be after the first passenger’s dropping point.">
                                  <Form.Item
                                    label = "Avoid Standing Passengers"
                                    name = "bp_check_enabled"
                                    valuePropName = "checked"
                                  >
                                    <Checkbox />
                                  </Form.Item>
                                </Tooltip>
                              </Col>}
                          </Row>
                        </Form>
                      </Content>
                        <Footer style={{ textAlign: 'center' }} type="flex">
                            <Row justify="space-around"> 
                                      <Col>
                                        <Button 
                                            style={{margin:'5px',background:'#2EC17C',color:'#FFFFFF',borderColor:'#2EC17C'}}
                                            disabled={isTripSelected}
                                            onClick={(e)=>{
                                              handleSubmit(e,'add');
                                            }}>
                                          Create Trip   
                                        </Button>
                                        </Col>
                                        {isValid(showUpdateButton)&&showUpdateButton?
                                          <Col span={3}>
                                              <Button type="primary"
                                                  disabled={!isTripSelected}
                                                  style={{margin:'5px'}}
                                                  onClick={(e)=>{
                                                    handleSubmit(e,'update');
                                                  }}>
                                                  Update
                                              </Button>
                                        </Col>
                                        :
                                        null
                                        }
                                        {isValid(showDeleteButton)&&showDeleteButton?
                                          <Col>
                                              <Button type="danger"
                                                  disabled={!isTripSelected}
                                                  style={{margin:'5px'}}
                                                  onClick={deleteAction}>
                                                  Delete
                                              </Button>
                                        </Col>
                                        :
                                        null
                                        }
                                        {isValid(showCancelButton)&&showCancelButton?
                                        <Col>
                                        <Button
                                            style={{margin:'5px'}}
                                            onClick={(e)=>{
                                              resetForm();
                                              setIsEditForm(false);
                                              setBusSelected(null);
                                              onClear();
                                            }}>
                                            Clear
                                        </Button>
                                        </Col>
                                        :
                                        null
                                        }
                                        {isValid(showEasyTripButton)&&showEasyTripButton?
                                          <Col>
                                        <Button
                                          style={{margin:'5px' , background:'orange' , borderColor:'orange' ,color:'#FFFFFF'}}
                                          onClick={(e)=>{
                                              if(isValid(formData)&&isValid(formData.id)){
                                                pageRedirect(history,'/newtrip?tripId='+formData.id);
                                              }else{
                                                pageRedirect(history,'/newtrip');
                                              }
                                            }}>
                                            Easy trip
                                        </Button>
                                        </Col>
                                        :
                                        null
                                        }
                                        <Col>
                                           <CloneScheduleComponent 
                                           busesData={busesData}
                                           printBusesOption={printBusesOption}
                                           formData={formData}
                                           isCloneEnable={!isTripSelected}
                                           onCloneSuccess={onSuccess}
                                           />
                                        </Col>
                                        <Col>
                                           <ReturnScheduleComponent 
                                           busesData={busesData}
                                           printBusesOption={printBusesOption}
                                           formData={formData}
                                           isCloneEnable={!isTripSelected}
                                           onCloneSuccess={onSuccess}
                                           />
                                        </Col>
                                        <Col>
                                          <ValidateTripButton 
                                          formData={formData}
                                          />
                                        </Col>
                                        <Col>
                                            <InformTripChangesToOTA 
                                              scheduleId={formData.id} 
                                            />
                                        </Col>
                                        <Col>
                                          <RegisterDPBtn 
                                              scheduleId={formData.id} 
                                          />
                                        </Col>
                                        <Col>
                                          <DeRegisterDPBtn 
                                            scheduleId={formData.id} 
                                          />
                                        </Col>
                                      </Row>
                            </Footer>
      </Layout>
    )}

// export const WrappedStepComponent = Form.create({ name: 'normal_login' })(StepComponent);
// https://morioh.com/p/ac8765188f3a