import React   from 'react';
import "../assets/styles/main.scss";
import { BusRoot } from '../components/bus-component/bus-root-component/bus-root.component';
import { Typography } from 'antd';
import { VerifyPagePermission } from '../components/verify-page-permission-component/verify-page-permission.component';
import Permissions from "../utils/permissions-constant";
import { getSeatTypes } from '../actions/bus-actions';
import { useDispatch } from 'react-redux';
import { getSeatGroupIds } from './../actions/seat-group.actions';

const { Title } = Typography;


export const BusPage = (props) => {

    const renderChildren= <div style={{position:'relative',top:'10px'  , margin: '30px'}}>
                            <Title level={4}>Buses</Title>
                                <BusRoot
                                
                                />
                            </div>

    const dispatch =  useDispatch();

    return (
        <VerifyPagePermission 
            pagePermission={Permissions.pagePermission.ADMIN_VIEW_BUS_PAGE}
            renderChildren={renderChildren}
            onPermissionSuccess={()=>{
                dispatch(getSeatTypes());
                dispatch(getSeatGroupIds());
            }}
            />
    );
}
