import React , {useEffect,useState } from 'react';
import { Row, DatePicker , Typography  , Modal , Button , Col , Table } from 'antd';
import { fetchBoardAndDropPoints  , boardingColumns , dropColumns , getBoardingDropDetailsStatus , handleBoardDropClick} from './edit-boarding-points.module';
import { subtractDays , addDays} from '../../../utils/dates-util';
import { TableCalenderCell  } from '../schedule-calender-cell-component/schedule-calender-cell.component';

const {Text} = Typography;

export const EditBoardingPoints = (props) => {

    const {
        record,
        selectedDate,
        scheduleId
    } = props;

    const [ visible , setVisible] = useState(false);
    const [ boardingPoints , setBoardingPoints] = useState([]);
    const [ dropPoints , setDropPoints] = useState([]);
    const [ apiBoardingPoints , setApiBoardingPoints] = useState([]);
    const [ apiDropPoints , setApiDropPoints] = useState([]);
    const [ startDate , setStartDate] = useState(null);
    const [ endDate , setEndDate] = useState(null);
    const [ disableFeatureValue , setDisableFeatureValue] = useState(-1);
    const [ selectedBoardingpoints , setSelectedBoardingpoints] = useState([]);
    const [ selectedDroppoints , setSelectedDroppoints] = useState([]);

    const showModal = () => {
        setVisible(true)
     };

    const handleOk = async (e) => {
        setVisible(false)
    };

    const handleCancel = e => {
        setVisible(false);
    };

    const onApiSuccess=(baPoints,drPoints)=>{
        setApiBoardingPoints(baPoints);
        setApiDropPoints(drPoints);
        updateBoardAndDropPoints(baPoints,drPoints,true);
    }

    const updateBoardAndDropPoints=(baPoints,drPoints,isUpdateStatus)=>{
        if(isUpdateStatus==true){
             let {bPoints,dPoints}=getBoardingDropDetailsStatus(startDate,endDate,baPoints,drPoints);
             setBoardingPoints([...bPoints]);
             setDropPoints([...dPoints]);
        }else{
            setBoardingPoints([...baPoints]);
            setDropPoints([...drPoints]);
        }

    }

    useEffect(()=>{
        fetchBoardAndDropPoints(scheduleId,onApiSuccess);
    },[]);


    const rowSelectionBoarding = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedBoardingpoints(selectedRows);
        },
        onSelect : (record, selected, selectedRows, nativeEvent)=>{
          if(disableFeatureValue!=-1&&selectedRows.length==0&&selectedDroppoints.length==0){
            setDisableFeatureValue(-1);
          }else if(disableFeatureValue==-1){
            setDisableFeatureValue(record.is_temporary);
          }
        },
        getCheckboxProps: record => ({
          disabled: (record.is_temporary === disableFeatureValue || disableFeatureValue == -1)?false:true, 
          // Column configuration not to be checked
        })
      };

      const rowSelectionDrop = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedDroppoints(selectedRows);
        },
        onSelect : (record, selected, selectedRows, nativeEvent)=>{
          if(disableFeatureValue!=-1&&selectedRows.length==0&&selectedBoardingpoints.length==0){
            setDisableFeatureValue(-1);
          }else if(disableFeatureValue==-1){
            setDisableFeatureValue(record.is_temporary);
          }
        },
        getCheckboxProps: record => ({
          disabled: (record.is_temporary === disableFeatureValue || disableFeatureValue == -1)?false:true, // Column configuration not to be checked
        })
      };


    return (
        <div>
            <Button type="primary" onClick={showModal} >
                 Edit boarding points
            </Button>
             <Modal
            title={record.description}
            visible={visible}
            onOk={handleOk}
            okText = {''}
            onCancel={handleCancel}
            width	={'85vw'}
            okButtonProps={''}
            >
              <Row type="flex">
                <Col>
                    <Row>
                            <Col span={4}>
                                <Text>From Date</Text>
                            </Col>
                            <Col  span={10} offset={1}>
                                <DatePicker onChange={setStartDate} value={startDate}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4}>
                                <Text>To Date</Text>
                            </Col>
                            <Col  span={10} offset={1}>
                                <DatePicker
                                onChange={setEndDate}
                                value={endDate}
                                disabledDate={d => !d || d.isBefore(startDate) || d.isSameOrBefore("1960-01-01") }
                                />
                            </Col>
                            <Col span={6} >
                                    <Button type="danger" onClick={()=>{
                                        updateBoardAndDropPoints(apiBoardingPoints,apiDropPoints,true);
                                    }} disabled={(startDate==null||endDate==null?true:false)}>
                                    <Text strong style={{color:'white'}}>Get</Text>
                                    </Button>
                            </Col>
                        </Row>
                        <Row type="flex" align="middle">
                            <Col >
                                <TableCalenderCell
                                    showNavigation={true}
                                    data={record.trips}
                                    className="table_calender_internal"
                                    scheduleId={scheduleId}
                                    selectRange={false}
                                    rangeValue={[startDate!=null?subtractDays(startDate._d,1):null,
                                        endDate!=null?addDays(endDate._d,1):null]}
                                    selectedDate={selectedDate}
                                />
                            </Col>
                      </Row>
                      <Row type="flex"  >
                        <Col span={8} offset={4}>
                                <Button type="primary"
                                onClick={()=>{
                                    handleBoardDropClick(true,startDate,endDate,selectedBoardingpoints,selectedDroppoints,()=>{
                                        fetchBoardAndDropPoints(scheduleId,onApiSuccess,true);
                                    });
                                }}
                                disabled={(disableFeatureValue==-1||startDate==null||endDate==null?true:false)}>
                                    <Text strong style={{color:'white'}}>Add</Text>
                                </Button>
                        </Col>
                        <Col span={8}>
                                <Button type="danger"
                                onClick={()=>{
                                   handleBoardDropClick(false,startDate,endDate,selectedBoardingpoints,selectedDroppoints,()=>{
                                        fetchBoardAndDropPoints(scheduleId,onApiSuccess,true);
                                    });
                                }}
                                disabled={(disableFeatureValue==-1||startDate==null||endDate==null?true:false)}>
                                            <Text strong style={{color:'white'}}>Remove</Text>
                                </Button>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Table
                            rowSelection={rowSelectionBoarding}
                            columns={boardingColumns}
                            dataSource={boardingPoints}
                            pagination={{ pageSize: 4 }}
                            tableLayout = "fixed"
                            rowKey={(record) => {
                                return record.id;
                            }}
                        />
                    </Row>
                    <Row>
                            <Table 
                                rowSelection={rowSelectionDrop}
                                columns={dropColumns}
                                dataSource={dropPoints}
                                pagination={{ pageSize: 4 }}
                                tableLayout = "fixed"
                                rowKey={(record) => {
                                    return record.id;
                                }}
                            />
                    </Row>
                </Col>
              </Row>

            </Modal>
        </div>
    );
}
