import React from "react";


export const ErrorPage = () => {

    return <div style={{textAlign:'center'}}>
       
            <h3>Page Not Found</h3>
    </div>
};

