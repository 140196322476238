import { isValid, isValidArray, isValidIndex , isValidBool ,sortJsonObject, isValidNumber} from '../../utils/utilities';
import { 
    PER_DAY_MINS,
    MIN_DURATION_TO_AUTO_CREATE_ROUTE 
} from './../../utils/constant';
import moment from 'moment';
import { getMinsBetweenTwoTimes } from './../../utils/dates-util';

export const getFilteredCities=(routes,cities)=>{
    let filteredCities=[];
    let existingCities=[];
    if(isValidArray(routes)&&routes.length>0){
        routes.forEach(element => {
           existingCities.push(element.city);
        });
    }
    if(isValidArray(cities)&&cities.length>0){
        cities.forEach(element => {
            if(existingCities.indexOf(element.name)==-1){
                filteredCities.push(element);
            }
        });
    }
    return filteredCities;
}

export const getRoutes=(cities,tripId,oldRoutes,tripFare,selectedTrip)=>{
    let routes=[] , oldRoutesObject={} ;
    oldRoutes.forEach((element)=>{
        oldRoutesObject[element.origin+element.destination]=element;
    })
    for (let i = 0; i < cities.length; i++) {
        const outerCity = cities[i];
        for (let j = 0; j < cities.length; j++) {
            const innerCity = cities[j];
            if(outerCity.city!=innerCity.city&&i!=j){
                if(isValidBool(outerCity.isOrigin)&&outerCity.isOrigin
                &&isValidBool(innerCity.isDestination)&&innerCity.isDestination
                &&!isValid(oldRoutesObject[outerCity.city+innerCity.city])){
                    let isForwardRoute = (outerCity.time<innerCity.time && (outerCity.originDay == innerCity.originDay))
                      ||((isValidNumber(outerCity.originDay)&&isValidNumber(innerCity.originDay)&&(outerCity.originDay<innerCity.originDay))) ? true:false;
                  let startTime = moment(outerCity.time, "h:mm a").format("HH:mm:ss");
                  let endTime = moment(innerCity.time, "h:mm a").format("HH:mm:ss");
                  let duration = getMinsBetweenTwoTimes(startTime,endTime);
                  let dayMins = 0;
                  if(innerCity.originDay>0){
                    dayMins = dayMins + PER_DAY_MINS*innerCity.originDay;
                  }
                  duration = dayMins + duration;
                      if(isForwardRoute&&duration>MIN_DURATION_TO_AUTO_CREATE_ROUTE){
                        routes.push({
                            origin:outerCity.city,
                            destination:innerCity.city,
                            start_time:startTime,
                            end_time:endTime,
                            schedule_id:tripId,
                            fare:tripFare,
                            fare_json:selectedTrip.fare_json,
                            origin_day:outerCity.originDay,
                            destination_day : innerCity.originDay
                        })
                        oldRoutesObject[outerCity.city+innerCity.city]={};
                    }
                }
                if(isValidBool(outerCity.isDestination)&&outerCity.isDestination
                &&isValidBool(innerCity.isOrigin)&&innerCity.isOrigin
                &&!isValid(oldRoutesObject[innerCity.city+outerCity.city])){
                    let isForwardRoute = (innerCity.time<outerCity.time && (outerCity.originDay == innerCity.originDay))
                      ||(isValidNumber(innerCity.originDay)&&isValidNumber(outerCity.originDay)&&(outerCity.originDay>innerCity.originDay)) ? true:false;
                      let startTime = moment(innerCity.time, "h:mm a").format("HH:mm:ss");
                      let endTime = moment(outerCity.time, "h:mm a").format("HH:mm:ss");
                      let duration = getMinsBetweenTwoTimes(startTime,endTime);
                      let dayMins = 0;
                      if(outerCity.originDay>0){
                        dayMins = dayMins + PER_DAY_MINS*outerCity.originDay;
                      }
                      duration = dayMins + duration;
                      if(isForwardRoute&&duration>MIN_DURATION_TO_AUTO_CREATE_ROUTE){
                        routes.push({
                            origin:innerCity.city,
                            destination:outerCity.city,
                            start_time:startTime,
                            end_time:endTime,
                            schedule_id:tripId,
                            fare:tripFare,
                            fare_json:selectedTrip.fare_json,
                            origin_day:innerCity.originDay,
                            destination_day : outerCity.originDay
                        })
                        oldRoutesObject[innerCity.city+outerCity.city]={};
                    }
                }
            }
        }
    }
    return routes;
}

export const getSortedRoutesList=(routes) =>{
    let newArray = [],citiesAdded={};
    if(isValidArray(routes)){
        for (let i = 0; i < routes.length; i++) {
            let route = routes[i];
            if(!isValid(citiesAdded[route.origin])&&isValid(route.start_time)){
                citiesAdded[route.origin]=route;
                newArray.push({
                    city:route.origin,
                    time:route.start_time
                })
            }
            if (!isValid(citiesAdded[route.destination])&&isValid(route.start_time)) {
                newArray.push({
                    city:route.destination,
                    time:route.end_time
                })
            }
      }
    }
    return newArray;
}


export const getAddedCities = (routes)=>{

    let cities=[] , citiesObject={};
    if(isValidArray(routes)){
        routes.forEach(element => {
            if(isValidIndex(citiesObject[element.origin])){
                cities[citiesObject[element.origin]].isOrigin=true;
            }else{
                citiesObject[element.origin]=cities.length;
                cities.push({
                    city:element.origin,
                    time:moment(element.start_time,'HH:mm'),
                    isOrigin:true,
                    originDay:element.origin_day
                })
            }
            if(isValidIndex(citiesObject[element.destination])){
                cities[citiesObject[element.destination]].isDestination=true;
            }else{
                citiesObject[element.destination]=cities.length;
                cities.push({
                    city:element.destination,
                    time:moment(element.end_time,'HH:mm'),
                    isDestination:true,
                    originDay:element.destination_day
                })
            }
        });
    }
    let sortByTime= sortJsonObject(cities,'time',true); 
    let finalSort= sortJsonObject(sortByTime,'originDay',true);
    return finalSort;
}
