import { Radio, Form , Input, Button , Select , Typography , Layout, Row , Col } from 'antd';
import React  , { useEffect, useState }from 'react';
import  CreateMap   from './create_map';
import { commaseperatedStringToArray, isValid, isValidArray } from '../../utils/utilities';
import { getAddressDetailsAction , insertAddressDetailsAction , updateAddressDetailsAction} from '../../actions/address-actions';
import { useDispatch, useSelector } from 'react-redux';
import { LocationForm } from '../location-component/location-form';
import { getLocationDetailsAction } from '../../actions/locations-actions';
import { getCoordinates } from './../../utils/constant';
import { locationReducerSelector } from '../../selectors/location-selectors';


const { Option } = Select;
const {  Content, Footer } = Layout;
const { Title , Text } = Typography;

export const AddressForm = (props) => {

  const [form] = Form.useForm();

  const{
    formData,
    agents,
    updateformData
  } = props;

const [values,setValues]=useState({});
const {masterCities} = useSelector(locationReducerSelector)

const dispatch = useDispatch();

  useEffect(()=>{
    fillForm();
  },[formData]);

const fillForm=()=>{
  if(isValid(formData)&&isValid(formData.id)){
    let values={
      city:formData.city,
      name : formData.name,
      latitude : formData.latitude,
      longitude : formData.longitude,
      landmark:formData.landmark,
      is_office:formData.is_office,
      address:formData.address,
      precedence_number:formData.precedence_number,
      landline_number:formData.landline_number,
      phone_number:formData.phone_number,
      agent_user_name:formData.agent_user_name
    };
    form.setFieldsValue(values)
    setValues(values);
  }else{
    form.resetFields();
    setValues({});
  }
}


const onSuccess=()=>{
  updateformData({});
  dispatch(getAddressDetailsAction());
  dispatch(getLocationDetailsAction);
}
const printOption = (option) => {
  return <Option  value={option.city_name} data={option}>{option.city_name}</Option>
};

const printAgentOption = (option) => {
  return <Option  value={option.name} data={option}>{option.name}</Option>
};

const onMarkerDragend=(coord)=>{
  const { latLng } = coord;
    form.setFieldsValue({
      latitude:latLng.lat(),
      longitude:latLng.lng()
      })
}

const handleSubmit = (e,buttonType) => {
  e.preventDefault();
  form.validateFields()
  .then(values => {
    if(buttonType=='add'){
        dispatch(insertAddressDetailsAction({...values},onSuccess));
    }else if(buttonType=='update'){
      values.id=formData.id;
         dispatch(updateAddressDetailsAction({...values},onSuccess));
    }
  })
  .catch(errorInfo => {
    console.log({errorInfo});
  })
};

const handleCreateMap=()=>{
  let allValues = form.getFieldsValue()
  setValues({...allValues});
  let mapAddress = form.getFieldValue('map_address');
  getCoordinates(mapAddress,(lat,lng)=>{
          allValues.latitude=lat;
          allValues.longitude=lng;
          setValues({...allValues});
  });
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

let arrayValues = commaseperatedStringToArray (global.travels_phone_number)
let phone_number = null;
let landline_number = null;
if(isValidArray(arrayValues)){
  phone_number=arrayValues[0];
  landline_number=arrayValues[1];
}

useEffect(()=>{
    form.setFieldsValue({
      phone_number: phone_number,
      landline_number:landline_number
    })
}, [])


    return (
      <Layout>
        <Form>
        <LocationForm
            updateformData={updateformData}
            formData={formData}
            isFromAddress={true}
          />
        </Form>
            <Title level={4}>Address Details Form</Title>
              <Content className="form" >
                    <Form
                    initialValues={{phone_number:phone_number,landline_number:landline_number,is_office:0}}
                    {...layout}
                    form={form}
                    name="basic"
                    onValuesChange={(changedValues, allValues)=>{
                        setValues({...allValues});
                    }}
                    >
                         <Row>
                                  <Col span={6}>
                                    <Form.Item
                                            label="Select a City"
                                            name="city"
                                            rules={[{ required: true, message: 'Please input your select city!' }]}
                                          >
                                          <Select
                                                showSearch
                                                placeholder="Select city"
                                            >
                                            {masterCities.map(printOption)}
                                            </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                      <Form.Item
                                                label="Name"
                                                name="name"
                                                rules={[{ required: true, message: 'Please input your  name!' }]}
                                              >
                                                <Input
                                                placeholder="Name"
                                            />
                                        </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                      <Form.Item
                                            label="Landmark"
                                            name="landmark"
                                            rules={[{ required: true, message: 'Please input your landmark!' }]}
                                          >
                                            <Input
                                            placeholder="landmark"
                                          />
                                      </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                      <Form.Item
                                                label="Location type"
                                                name="is_office"
                                                rules={[{ required: true, message: 'Please input your location type!' }]}
                                              >
                                              <Radio.Group>
                                                  <Radio value={0} >Pickup/Drop Point</Radio>
                                                  <Radio value={1} >Office</Radio>
                                              </Radio.Group>
                                        </Form.Item>
                                  </Col>
                            </Row>
                            <Row>
                                    <Col span={6}>
                                              <Form.Item
                                                      label="Address"
                                                      name="address"
                                                      rules={[{ required: true, message: 'Please input address!' }]}
                                                    >
                                                      <Input
                                                      placeholder="Address"
                                                      />
                                              </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                              <Form.Item
                                                      label="Display no"
                                                      name="precedence_number"
                                                      rules={[{ required: false, message: 'Please input display no!' }]}
                                                    >
                                                      <Input
                                                      placeholder="Display no"
                                                      />
                                              </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                            <Form.Item
                                                      label="Landline"
                                                      name="landline_number"
                                                      rules={[{ required: false, message: 'Please input landline!' }]}
                                                    >
                                                      <Input
                                                      placeholder="Landline"
                                                      />
                                              </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                              <Form.Item
                                                      label="Phone no"
                                                      name="phone_number"
                                                      rules={[{ required: true, message: 'Please input phone no!' }]}
                                                    >
                                                      <Input
                                                      placeholder="phone no"

                                                      />
                                              </Form.Item>
                                    </Col>
                            </Row>
                               <Row>
                                 <Col span={6}>
                                     <Form.Item
                                              label="Agent User Name"
                                              name="agent_user_name"
                                              rules={[{ required: false, message: 'Please input Agent User Name!' }]}
                                            >
                                                <Select
                                                  showSearch
                                                  placeholder="Select agent"
                                                  style={{ width: 200 }}
                                              >
                                              {agents.map(printAgentOption)}
                                              </Select>
                                      </Form.Item>
                                 </Col>
                                  <Col span={6}>

                                  </Col>
                                  <Col span={6}>
                                          <Form.Item
                                                label="Latitude"
                                                name="latitude"
                                                rules={[{ required: false, message: 'Please input latitude!' }]}
                                              >
                                                <Input
                                                placeholder="Latitude"
                                              />
                                        </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                        <Form.Item
                                              label="Longitude"
                                              name="longitude"
                                              rules={[{ required: false, message: 'Please input longitude!' }]}
                                            >
                                              <Input
                                              placeholder="Longitude"
                                            />
                                        </Form.Item>
                                  </Col>
                               </Row>
                     <Row>
                              <Col span={6}>
                                  <Form.Item
                                            label="Map Address"
                                            name="map_address"
                                            rules={[{ required: false, message: 'Please input map address!' }]}
                                          >
                                            <Input
                                            placeholder="Map Address"
                                            />
                                    </Form.Item>
                              </Col>

                              <Col span={2} offset={1}>
                                      <Button
                                       type="danger" size='small'
                                      onClick={()=>{
                                        let address = form.getFieldValue('address');
                                        form.setFieldsValue({
                                          map_address:address
                                        })
                                      }}
                                      >Fetch Address</Button>
                                </Col>
                                <Col span={2}>
                                <Button type="primary" size='small'
                                onClick={handleCreateMap}>Create map</Button>
                                </Col>
                                <Col span={12}>
                                     {isValid(values) &&  isValid(values.latitude) && isValid(values.longitude) &&
                                        <Row>
                                                    <CreateMap
                                                    location={values.map_address}
                                                    longitude={values.longitude}
                                                    latitude={values.latitude}
                                                    onMarkerDragend={onMarkerDragend}
                                                    draggable={true}
                                                    />
                                        </Row>
                                    } 
                                </Col>
                     </Row>
                    </Form>
              </Content>
              <Footer style={{ textAlign: 'center' }}>
                  <Row justify="space-around">
                      <Button type="primary"
                      style={{margin:'5px',background:'#2EC17C'}}
                      disabled={props.formData.id==undefined?false:true}
                      onClick={(e)=>{
                            handleSubmit(e,'add');
                      }}>
                                                <Text>Add</Text>
                      </Button>
                      <Button type="primary"
                      style={{margin:'5px'}}
                      disabled={props.formData.id==undefined?true:false}
                        onClick={(e)=>{
                              handleSubmit(e,'update');
                        }}>
                        Update
                      </Button>
                      {/* <Button type="danger"
                      style={{margin:'5px'}}
                      disabled={props.formData.id==undefined?true:false}
                       onClick={()=>{
                           // showConfirm(deleteBusAction,'');
                      }}>
                        Delete
                      </Button> */}
                      <Button type="block"
                      style={{margin:'5px'}}
                      onClick={()=>{
                         updateformData({});
                      }}>
                        Clear
                      </Button>
                  </Row>
              </Footer>
      </Layout>
    );
}


export const WrappedAddressForm = AddressForm;