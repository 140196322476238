/**
 * Address related actions can be created here
 */

import { LOG_IN_ACTION_URL } from "../utils/api-url";
import { LOG_IN_ACTION ,SET_WEB_APP_READY,  LOG_OUT_ACTION} from "./action-types";
import { postCall } from "../utils/network.util";


export const loginAction = (access_code,username,password,navigationObject) => {
    return {
        type: LOG_IN_ACTION,
        payload: {
            url: LOG_IN_ACTION_URL ,
            api: postCall,
            apiPayload:{
                loginInfo:{
                    access_code:access_code,
                    username:username,
                    password:password,
                    operator_code:global.operator_code
                }
            },
            navigationObject : navigationObject
        }
    }
}


export const setAppReady = (bool)=>{
    return {
        type:SET_WEB_APP_READY,
        payload:bool
    }
}
export const logOutAction = (history) =>{
  return {
    type: LOG_OUT_ACTION,
    payload: {
        history :history
    }
  }
}
