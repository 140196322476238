
import {ACTIVATE_REDBUS_CITY_PAIR_URL } from './../../utils/api-url';
import { message } from "antd";
import { isValidArray } from '../../utils/utilities';

export const sendNotificationToRedBus = (cityPairs,onSuccess) =>{
    if(isValidArray(cityPairs)&&cityPairs.length==0){
        message.error("Select atleast one route!");
    }else{
        fetch(ACTIVATE_REDBUS_CITY_PAIR_URL,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                cityPairs : cityPairs ,
                operatorCode:global.operator_code,
                travelsId:global.operator_id,
              }) // body data type must match "Content-Type" header
             }
            ).
            then((Responce)=>Responce.json()).
            then((findresponce)=>
            {
                if(findresponce.Status=="SUCCESS"){
                    message.success(findresponce.Message);
                    onSuccess();
                }else{
                    message.error(findresponce.Message);
                }
            })
    }
}