import { Modal } from 'antd';
import _ from 'underscore';

const { confirm } = Modal;

export const sortArray=(data)=>{
  return data.sort();
}

export const getValuesOfJSonObject=(jsonObject)=>{
  return [..._.values(jsonObject)];
}

/*
Method to redirect page based on history object and url
*/

export const pageRedirect = (history,url) => {
    history(url);
}

export const getUrlVars = (queryParamString) =>{
   let vars = {};
   queryParamString.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
       vars[key] = value;
   });
   return vars;
}

export const capitalizeFirstLetter = (string)=>{
  if(isValidString(string)){
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }else{
    return '';
  }
}

export const capitalizeFirstLetterOfEachWord=(str)=> {
  if(isValidString(str)){
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1).toLowerCase();     
    }
    // Directly return the joined string
    return splitStr.join(' '); 
  }else{
    return ''
  }
}

export const trimString=(txt)=>{
  if(isValidString(txt)){
     return txt.trim();
  }else{
     return txt;
  }
}

/* end of pageRedirect */

export const isValid = (data) => (data != undefined && data != null && data != 0);

export const isValidIndex = (data) => (data != undefined && data != null);

export const isValidNumber = (data) => (data != undefined && data != null);

export const isValidString = (data) => (data != undefined && data!=null && data.length != 0);

export const isValidArrayWithValues = (array) => (array != undefined && array != null
   && typeof(array) == "object" && array.length > 0);

export const isValidArray = (array) => (array != undefined && array != null
   && typeof(array) == "object");
  
export const isValidBool = (data) => (data != undefined && data != null);




export function showConfirm(func,message,params,title='Do you want to delete these items?') {
  confirm({
    title: title,
    content: message ,
    onOk() {
        func(params);
    },
    onCancel() {},
  });
}

export const removeArrayIndexValue=(arr,index)=>{
  return  arr.splice(index, 1);
}

   
   
/*
Remove duplicates of 1st json file based 2nd json file
input:
json1 = [
  {name: 'deepak', place: 'bangalore'},
  {name: 'chirag', place: 'bangalore'},
  {name: 'alok', place: 'berhampur'},
  {name: 'chandan', place: 'mumbai'}
]

json2 = [
  {name: 'deepak', place: 'chennai'},
  {name: 'hareesh', place: 'berhampur'}
];

Output:
[
  {name: 'hareesh', place: 'berhampur'}
]
*/
  export const removeDuplicates = (json1,json2,key1,key2)=>{
    let formatedArray=[...json2]
   if((json1.length==0)||(formatedArray.length==0)){
     return formatedArray;
   }else{
       for( var i=formatedArray.length - 1; i>=0; i--){
               for( var j=0; j<json1.length; j++){
                   if(formatedArray[i] && (formatedArray[i][key2] === json1[j][key1])){
                    formatedArray.splice(i, 1);
                   }
                 }
               }
               return formatedArray;
   }
}



export const getDuplicatesComparingTwostrings = (string1,string2) => {
 if(string1==null){
   var string1array=[];
 }
 else{
   var string1array = string1.toString().split(",");
 }
 if(string2==null){
   var string2array=[];
 }else{
   var string2array=string2.toString().split(',');
 }
 var z = string1array.filter(function(val) {
 return string2array.indexOf(val) != -1;
});

return arrytoCommaSeparetedString(z);

}

export const removeDuplicatesComparingTwostrings = (string1,string2) => {
 if(string1==null){
   var string1array=[];
 }
 else{
   var string1array = string1.toString().split(",");
 }
 if(string2==null){
   var string2array=[];
 }else{
   var string2array=string2.toString().split(',');
 }
 var z = string1array.filter(function(val) {
 return string2array.indexOf(val) == -1;
});

return arrytoCommaSeparetedString(z);
}

//return comma separated string for array
export function arrytoCommaSeparetedString(array) {
 return array.join(',');
}
//
export const array_of_sub_strings = (string,startindex,endindex) => {

       var substring_Array=[];
       var string_array = string.toString().split(",");
       for(var i=0;i<string_array.length;i++){
         substring_Array[i]=substring(string_array[i],startindex,endindex);
       }
       return arrytoCommaSeparetedString(substring_Array);

}


function substring(string,index,endindex){
 return string.substring(index,endindex);
}

export const sortJsonObject=(data,key,isNumber=true)=>{
  if(isValidArray(data)&&data.length>0){
    return data.sort(function(a,b){
        if(isNumber){
          return a[key] - b[key];
        }else{
          return a[key].toLowerCase().localeCompare(b[key].toLowerCase())
        }
      }
    );
  }else{
    return []
  }
}

export function commaseperatedStringToArray(string){
  var ArrayString = null;
  
  if(string!=null){
    var splitChars=",";
    if(string.indexOf(splitChars)>=0){
      ArrayString= string.split(splitChars);
    }
    else {
      ArrayString=[string];
    }
  }
  return ArrayString
}

export function generatePassword(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const deleteIndexValue=(arr,index)=>{
  delete arr[index];
  return arr;

}

export const permissionCheck=(permission)=>{
  if(isValidArray(global.privilages)&&global.privilages.indexOf(permission) != -1){
    return true
  }else{
    return false;
  }
}

export const headOfficePrivilege = 'Head Office';
export const schedulesPrivilege = 'Schedules';


//https://35.244.0.168/api/trackingByURL?https://ui.vehicle-tracking.co.in/SenselRestService.svc/rest/v3/LastPositionData?key=328b8338996a38aa6fa57552cfc9cedc0cc73543&clientid=04d31d93&vehicleid=TN45BL7671