import React,{useState , useEffect} from 'react';
import { Typography , Row ,  Modal, Button} from 'antd';
import {SeatLayoutContainer} from './seat-layout-container.component';
import { isValid } from '../../utils/utilities';

const { Text } = Typography;

export const EditBlockedSeats = (props) => {

    let {
        getBusLayOut,
        busSelected ,
        busReducer ,
        blockedSeats ,
        selectSeat,
        updateTripAction
    } = {...props};

    useEffect(() => {
      if (isValid(busSelected)) {
        getBusLayOut(busSelected.id);
      }
    },[busSelected]);

    const [modal, updateModal] = useState(0);

    return (
      <div>
       <Button type="primary" 
       disabled={isValid(busSelected)?false:true}
       onClick={()=>{
         updateModal(true);
       }}
       style={{bottom:'20px',left:'100px'}}
       >
         Edit Blocked Seats
       </Button>
       <Modal
         title="Seat layout"
         visible={modal}
         onOk={()=>{
           updateTripAction(()=>{
             updateModal(false);
           });
         }}
         onCancel={()=>{
           updateModal(false);
         }}
         okText="Update"
         cancelText="Cancel"
       >
         {busReducer.seatsData.length!=0 &&
           <SeatLayoutContainer seatsData={busReducer.seatsData} blockedSeats={blockedSeats} selectSeat={selectSeat}/>
         }
       </Modal>
     </div>
    );
};
