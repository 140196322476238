import { Typography , Row  , Col , Layout} from 'antd';
import React , { useEffect , useState} from 'react';
import { isValid } from '../../../utils/utilities';
import { DropPointForm } from './drop-point-form.component';
import { getDropPointCities } from './drop-points.module';
import { useDispatch } from 'react-redux'
import { getAddressLocationsByCity } from '../../../actions/address-actions';
import { useSelector } from 'react-redux';
import { addressReducerSelector } from '../../../selectors/address-selectors';
import { DropPointTable } from './drop-point-table.component';

const {Text} = Typography;
const { Header, Content, Footer } = Layout;

export const DropPointComponent = (props) => {

  const {
    routes,
    selectedTrip,
    dropPoints,
    isRowClickEnabled,
    showDeleteUpdateButtons,
    dropPointsLoading
  } = props;
  const {
    id
  }=selectedTrip;

  const dispatch = useDispatch();
  const addressReducer = useSelector(addressReducerSelector)
  const [dropFormData , setDropFormData] = useState({});

  useEffect(()=>{
    if(isValid(routes)){
      dispatch(getAddressLocationsByCity(getDropPointCities(routes),false));
    }
  },[routes]);

  return (
    <Layout>
      <Content>
           <Row type='flex'>
                  <Col span={8}>
                      <DropPointForm 
                        tripId={id}
                        {...addressReducer}
                        dropFormData={dropFormData}
                        setDropFormData={setDropFormData}
                        isRowClickEnabled={isRowClickEnabled}
                        showDeleteUpdateButtons={showDeleteUpdateButtons}
                        dropPoints={dropPoints}
                        selectedTrip={selectedTrip}
                        routes={routes}
                      />
                  </Col>
                  <Col span={16}>
                      <DropPointTable 
                      dropPointsLoading={dropPointsLoading}
                        dropPoints={dropPoints}
                        dropFormData={dropFormData}
                        setDropFormData={setDropFormData}
                        isRowClickEnabled={isRowClickEnabled}
                        showDeleteUpdateButtons={showDeleteUpdateButtons}
                      />
                  </Col>
          </Row>
      </Content>
          
    </Layout>
    
  );
};