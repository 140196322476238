import React, { useEffect, useState }  from 'react';
import { Table } from 'antd';
import { isValid } from "../../../utils/utilities";

export const AdminDriverMappingTable = (props) => {

    const {
        adminDriver,
        updateFormData,
        formData
    } = props;

    const columns= [{
        title: 'Name',
        dataIndex: 'admin_name',
        key: 'admin_name',
      },
      {
        title: 'Admin Phone no',
        dataIndex: 'admin_phone',
        key: "admin_phone"
      },
      {
        title: 'Driver Phone no',
        dataIndex: 'driver_phone',
        key: "driver_phone"
      },
      {
        title: "Operator Code",
        dataIndex: 'operator_code',
        key: 'operator_code'
      }
    ];

    return (
        <div>
            <Table 
                dataSource={adminDriver}
                columns={columns}
                onRow={(record, rowIndex) => {
                    return {
                      onClick: event => {
                        updateFormData(record);
                      }
                    };
                  }}
                  rowClassName={(record, index)=>{
                    let rowClassName="";
                        if(record.id== formData.id){
                            rowClassName=rowClassName+" TableRowClicked"
                        }
                        
                        return  rowClassName
                  }}
            />
        </div>
    );
}
