const DEV_ENV = "dev";
const PROD_ENV = "prod";
const B2B_PROD_ENV = "b2bProd";
const TEST_ENV = "staging";
const APP_ENV = B2B_PROD_ENV;

var SHOW_DISABLE_API_AND_ACTIVATE_BUTTON = false;

var API_BASE_URL = (APP_ENV === DEV_ENV) ? "http://localhost:5000" :
(APP_ENV === TEST_ENV) ? "https://staging-admin-server.appspot.com"
    :"https://admin-manager-server.appspot.com";

var TRACKING_SERVER_URL = (APP_ENV === DEV_ENV) ? "http://localhost:4000" :
    (APP_ENV === TEST_ENV) ? "https://staging-customer-website.appspot.com"
    :"https://qwikbus.co";


var WEBSITE_SERVER_URL = (APP_ENV === DEV_ENV) ? "http://localhost:4000" :
    (APP_ENV === TEST_ENV) ? "https://staging-agent-server.appspot.com"
    :"https://customer-website-server.appspot.com";

const API_B2B_ADMIN_BASE_URL = (APP_ENV === DEV_ENV) ? "http://localhost:5000" :
    (APP_ENV === TEST_ENV) ? "https://staging-admin-server.appspot.com" :
        "https://b2b-travels-admin.appspot.com";

const ACCESS_CODE_STORAGE_KEY = 'hbdcjxnfdkckcbjxnfdcsbxnkfuducosl3190';

if (APP_ENV == B2B_PROD_ENV) {
  SHOW_DISABLE_API_AND_ACTIVATE_BUTTON = false;
  API_BASE_URL = "https://b2b-travels-admin.appspot.com";
  TRACKING_SERVER_URL = "https://qwikbus.co";
  WEBSITE_SERVER_URL = "https://b2b-travels-server.appspot.com";
}

module.exports = {
  SHOW_DISABLE_API_AND_ACTIVATE_BUTTON,
  API_BASE_URL,
  WEBSITE_SERVER_URL,
  TRACKING_SERVER_URL,
  ACCESS_CODE_STORAGE_KEY,
  API_B2B_ADMIN_BASE_URL,
  APP_ENV
}
