/**
 * @author Manne Hareesh
 * @description Reducer for the components in home page
 */
import {
    SET_SCHDULES_BY_DATE_FOR_PRICE_LIST,
    SET_SCHEDULE_GROUP,
    RESET_ALL  } from '../actions/action-types';
  
  const initialState = {
      priceList: [],
      scheduleGroup:[]
  }
  
  export const priceListReducer = (state = initialState,action) => {
      let newState = Object.assign({},state);
      switch (action.type) {

        
         case SET_SCHEDULE_GROUP:
            newState["scheduleGroup"] = action.payload;
            return newState;
  
          case SET_SCHDULES_BY_DATE_FOR_PRICE_LIST:
              newState["priceList"] = action.payload;
              return newState;

        case RESET_ALL :
              newState["priceList"] = [];
              return newState;
  
          default:
              return state;
      }
  }
  