import React  from 'react';
import { VechicleManagementRoot } from '../components/vechicle-management-component/vechicle-management-root/vechicle-management.root';
import { Typography } from 'antd';
import { VerifyPagePermission } from '../components/verify-page-permission-component/verify-page-permission.component';
import Permissions from "../utils/permissions-constant";

const { Title } = Typography;


export const VechicleManagementPage = (props) => {


    const renderChildren =     <div style={{position:'relative',top:'10px'  , margin: '20px'}}>
                                    <Title level={4}>Vehicle Management</Title>
                                    <VechicleManagementRoot 
                                    />
                                </div>

    return (
        <VerifyPagePermission 
        pagePermission={Permissions.pagePermission.ADMIN_VIEW_VEHICLE_MANAGEMENT_PAGE}
        renderChildren={renderChildren}
        onPermissionSuccess={()=>{
  
        }}
        />
    );
}
