import { API_BASE_URL } from "../../../app.config";
import { message } from 'antd';
import { isValidArray } from "../../../utils/utilities";

export const inactiveschedules = (selectedRowKeys,onSuccess)=>{
    if(selectedRowKeys.length==0){
          message.error("Please Select atleast one schedule");
    }else{
      fetch(API_BASE_URL + '/api/makeschedlueinactive',
      {method: "POST",headers: {'Content-Type':'application/json'},
      body: JSON.stringify({"scheduleIds" : selectedRowKeys})}).
      then(response => response.json()).
      then((data)=>{
        if(data.Status=="SUCCESS"){
            onSuccess();
           message.success(data.Message);
        }else{
            message.error(data.Message);
        }
      });
    }
  }

  export const onFilteredChangeCustom=(origin,destination,data , onlyActive=1)=>{
    let filter_data=[],origins=[],destinations=[];
    
    data.forEach((row)=>{
        if(origins.indexOf(row.origin)==-1){
            origins.push(row.origin);
          }
          if(destinations.indexOf(row.destination)==-1){
            destinations.push(row.destination);
          }
       let activeStatus = onlyActive===-1||(isValidArray(row.trips)&&row.trips.length>0&&onlyActive===1)||(!isValidArray(row.trips)&&onlyActive===0)?true:false;
       if((origin=='all'||origin==row.origin)&&(destination=='all'||row.destination==destination)&&activeStatus){
         filter_data.push(row);
       }
    })
    return {destinations,origins,filter_data};
  }
