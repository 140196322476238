import React  from 'react';
import { Table } from 'antd';
import  useColumnSearch  from './../../../assets/ui-components/table-column-search';
import { isValidArray } from '../../../utils/utilities';

const getPrivileges=(data)=>{
  let privileges = [] , privilegesInfo=[];
  if(isValidArray(data)&&data.length>0){
    data.forEach(element => {
      if(privileges.indexOf(element.privilege)==-1){
        privileges.push(element.privilege);
        privilegesInfo.push({
          text:element.privilege,
          value:element.privilege
        });
      }
    });
  }
  return privilegesInfo;
}

export const AgentsTableComponent = (props) => {

    const {
        agents,
        formData,
        updateformData    
    } = props;
    
  const agentColumns = [
    {
      title: 'BO/Agent Name',
      dataIndex: 'name',
      key: 'name',
      ...useColumnSearch(
        {dataIndexExt:"name"}
      )
    },
    {
        title: 'Type',
        dataIndex: 'privilege',
        key: 'privilege',
        filters:getPrivileges(agents),
        onFilter: (value, record) => record.privilege.indexOf(value) === 0
    },
    {
        title: 'Location',
        dataIndex: 'location',
        key: 'location'
    },
    {
        title: 'Phone Number',
        dataIndex: 'phone_number',
        key: 'phone_number'
    },
    {
      title: 'Commision',
      children: [
        {
            title: 'Non A/C',
            children: [
                {
                    title: '₹',
                    dataIndex: 'commission',
                    key: 'commission',
                },
                {
                    title: '%',
                    dataIndex: 'commission_percentage',
                    key: 'commission_percentage',
                },
            ],
        },
        {
            title: 'A/C',
            children: [
              {
                title: '₹',
                dataIndex: 'ac_commission',
                key: 'ac_commission',
              },
              {
                title: '%',
                dataIndex: 'ac_commission_percentage',
                key: 'ac_commission_percentage',
              },
            ],
        },
        // {
        //     title: 'Scania/Volvo',
        //     children: [
        //         {
        //             title: '₹',
        //             dataIndex: 'hitech_commission',
        //             key: 'hitech_commission',
        //         },
        //         {
        //             title: '%',
        //             dataIndex: 'hitech_commission',
        //             key: 'hitech_commission',
        //        }
        //     ],
        // }
      ],
    }
  ];
    

    return (
        <div>
            <Table 
            columns={agentColumns}
            pagination={{ pageSize: 5 }}
             dataSource={agents}
             onRow={(record, rowIndex) => {
              return {
                onClick: event => {
                  updateformData(record);
                }, // click row
                onDoubleClick: event => {
    
                }, // double click row
                onContextMenu: event => {
    
                }, // right button click row
                onMouseEnter: event => {
    
                }, // mouse enter row
                onMouseLeave: event => {
    
                }, // mouse leave row
              };
            }}
            rowClassName={(record, index)=>{
              let rowClassName="";
                  if(record.id== formData.id){
                      rowClassName=rowClassName+" TableRowClicked"
                  }
                  
                  return  rowClassName
            }}
             bordered />
        </div>
    );
}
