import React from 'react'
import { GoogleMap, useJsApiLoader , Marker} from '@react-google-maps/api';
import { GOOGLE_MAP_API_KEY } from '../../utils/constant';
// url lib :https://www.npmjs.com/package/@react-google-maps/api
// https://react-google-maps-api-docs.netlify.app/#marker
const containerStyle = {
  width: '400px',
  height: '400px'
};


function MyComponent(props) {

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAP_API_KEY
  })

  
const center = {
  lat: Number(props.latitude),
  lng: Number(props.longitude)
};
  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        { /* Child components, such as markers, info windows, etc. */ }
        <Marker
          onDragEnd={(coord) => props.onMarkerDragend(coord)}
          position={center}
          draggable={props.draggable}
        />
      </GoogleMap>
  ) : <></>
}

export default React.memo(MyComponent)