import React  from 'react';
import './account-menu.style.scss';
import { Menu } from 'antd';
import {  useDispatch } from 'react-redux';
import { logOutAction }  from '../../../actions/login-actions';


export const AccountMenu = props => {

    const {
        userInfo
    } = props;

  let dispatch = useDispatch();

  return (
    <Menu
          mode="vertical"
          style={{lineHeight: '64px' , float:'right'}}
          breakpoint="lg" collapsedWidth="0">
            <Menu.Item key="1" onClick={()=>{

            }}>{userInfo.name}</Menu.Item>
            <Menu.Item key="2" onClick={()=>{   
                dispatch(logOutAction());
            }}>Log out</Menu.Item>
    </Menu>

  );
};

AccountMenu.defaultProps = {

};

AccountMenu.propTypes = {

};
