import { VALIDATE_TRIP_URL } from './../../../utils/api-url';
import { postCall } from '../../../utils/network.util';
import { isValid } from '../../../utils/utilities';
import { message } from 'antd';


export const validateTripAction=(tripId,onSuccess,onFailure)=>{
    if(!isValid(tripId)){
        message.error("Please select trip!");
    }else{
        var cloneBus = postCall(VALIDATE_TRIP_URL, {
            scheduleId : tripId
          });
          cloneBus.then((data) => {
            if(data.status === "SUCCESS"){
                let clientPayload = data.payload;
                if(clientPayload.Status==="SUCCESS"){
                    onSuccess(clientPayload.payload.warningList);
                }else{
                  onFailure();
                }
            }
          })
          .catch((reason)=> {
            onFailure();
          })
    }
}