import { Form , Input, Button , Select , Typography , Layout, Row , Col , Switch ,Checkbox} from 'antd';
import React, { useEffect, useState }  from 'react';
import { useDispatch } from 'react-redux';
import { isValid, isValidArray } from '../../../utils/utilities';
import  { onFilteredChangeCustom } from './schedule-group-form.module';
import { CheckOutlined ,  CloseOutlined} from '@ant-design/icons';
import { createSchedulesGroup , getSchedulesGroup , updateSchedulesGroup , deleteSchedulesGroup}  from '../../../actions/price-list-actions';
import { showConfirm } from '../../../utils/utilities';

const { Option } = Select;
const { TextArea } = Input;

const { Header, Content, Footer } = Layout;
const { Title , Text } = Typography;

export const  MasterPriceListForm = (props) => {

  const [form] = Form.useForm();
  const dispatch = useDispatch();

      const{
        formData,
        setFormData,
        origins,
        destinations,
        trips,
        onShowPriceList
      } = {...props};

      const [ checked ,setChecked ] = useState(false);
      const [ fliteredSchedules,setFliteredSchedules]  = useState([]);
      const [origin,setOrigin] = useState('all');
      const [destination,setDestination] = useState('all');

      const onSuccess=()=>{
        dispatch(getSchedulesGroup());
        setFormData({});
      }

      const deleteAction=()=>{
        showConfirm(
            ()=>{
                dispatch(deleteSchedulesGroup(formData.id,onSuccess));
            },
            '',
            ''
          )
      }
  
     const printOption = (option) => {
        return <Option key={option} value={option}>{option}</Option>;
    }

   const handleSubmit = (e,buttonType) => {
    e.preventDefault();
    form.validateFields()
    .then(values => {
      if(buttonType=='add'){
        dispatch(createSchedulesGroup({...values},onSuccess));
      }else if(buttonType=='update'){
        values.groupId=formData.id;
        dispatch(updateSchedulesGroup({...values},onSuccess));
      }
    })
    .catch(errorInfo => {
      console.log({errorInfo});
    })
 };


useEffect(()=>{
    fillForm();
},[formData]);

useEffect(()=>{
  let data = onFilteredChangeCustom(trips,origin,destination);
  setFliteredSchedules(data);
},[trips,origin,destination]);

const fillForm=()=>{
    if(isValidArray(formData)&&isValid(formData.id)){
        form.setFieldsValue({
            description : formData.description,
            scheduleIds : formData.schedule_ids
        })
    }else{
        form.resetFields();
    }
}

const  onChangeSwith=(value)=>{
  setChecked(value);
}

const handleChangeDestination=(value)=>{
  setDestination(value);
}

const handleChangeOrigin=(value)=>{
  setOrigin(value);
}

let isRowClicked=isValid(formData)&&isValid(formData.id)?true:false;
    return (
      <Layout>
            <Title level={4}>Schedules Group Form</Title>
              <Content className="form">
                        <Form 
                                 form={form}
                                 initialValues={{}}
                        >
                        <Row>
                               <Form.Item
                                        label="Group Name"
                                        name="description"
                                        rules={[{ required: true, message: 'Please input your group name!' }]}
                                      >
                                        <Input 
                                          placeholder="Group name"
                                        />
                                </Form.Item>
                          </Row>
                              
                          <Row>
                                <Col>
                                        <Text>Origin</Text>
                                </Col>
                                <Col offset={1}>
                                      <Select  
                                      showSearch
                                      placeholder="Select Origin"
                                      optionFilterProp="children"
                                      style={{ width: 240 }}
                                      onChange={handleChangeOrigin}>
                                          <Option key={"all"} value={"all"}>all</Option>
                                         {origins.map(printOption)}
                                      </Select>
                                </Col>

                                  <Col offset={2}>
                                      <Text>Destination</Text>
                                  </Col>
                                  <Col offset={1}>
                                        <Select 
                                          showSearch 
                                          placeholder="Select Origin"        
                                          optionFilterProp="children"
                                          onChange={handleChangeDestination}
                                          style={{ width: 240 }}
                                        >
                                          <Option key={"all"} value={"all"}>all</Option>
                                          {destinations.map(printOption)}
                                        </Select>
                                  </Col>
                          </Row>
                              <Form.Item
                                        label="Select Schedules"
                                        name="scheduleIds"
                                        rules={[{ required: true, message: 'Please input atleast one Schedule!' }]}
                                      >
                                        {checked?
                                          <Checkbox.Group options={fliteredSchedules} />
                                          :
                                          null
                                        }

                            </Form.Item>
                          <Row>
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={checked}
                                    onChange={onChangeSwith}
                                  />
                                  <Text>{checked==false?'Show Schedules':'Hide Schedules'}</Text>
                          </Row>
                  </Form>
              </Content>
              <Footer style={{ textAlign: 'center' }} type="flex">
              <Row type="flex" justify="space-around">
                      <Button
                        style={{margin:'5px',background:'#2EC17C'}}
                        disabled={isRowClicked}
                        onClick={(e)=>{
                        handleSubmit(e,'add');
                        }}>
                        <Text>Add</Text>
                      </Button>
                      <Button type="primary"
                      disabled={!isRowClicked}
                        style={{margin:'5px'}}
                       onClick={(e)=>{
                        handleSubmit(e,'update');
                       }}>
                        Update
                      </Button>
                        <Button type="danger"
                        style={{margin:'5px'}}
                         disabled={!isRowClicked}
                        onClick={deleteAction}>
                        Delete
                      </Button>
                      <Button type="block"
                        style={{margin:'5px'}}
                        onClick={()=>{
                          setFormData({});
                          onShowPriceList(false);
                        }}>
                        Clear
                      </Button>
                      <Col  span={4}>
                        <Button type="primary"  style={{background:'#4a4a4a'}}
                          onClick={(e) => onShowPriceList(true)}
                          disabled={!isRowClicked}
                          >Show Price List</Button>
                  </Col>
                  </Row>
              </Footer>
      </Layout>
    );
}
