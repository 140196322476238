import React, { useEffect, useState }  from 'react';
import { ScheduleGroupTable } from '../schedule-group-table/schedule-group-table.component';
import { getSchedulesGroup } from '../../../actions/price-list-actions';
import { useDispatch , useSelector } from 'react-redux';
import { priceListSelector} from '../../../selectors/price-list-selector';
import { MasterPriceListForm } from '../schedule-group-form/schedule-group-form.component';
import { getOriginDestinations } from './master-price-list.root.component.module';
import { getTrips } from '../../../actions/trip-actions';
import { tripReducerSelector} from '../../../selectors/trip-selectors';
import { MasterPriceListTable } from '../master-price-list-table/master-price-list-table.component';
import { isValid } from '../../../utils/utilities';
import { loginReducerSelector } from '../../../selectors/login-selectors';


export const MasterPriceListRoot = (props) => {

    const dispatch = useDispatch();
    const priceListReducer=useSelector(priceListSelector);
    const [formData , setFormData] = useState({});
    const [origins , setOrigins] = useState([]);
    const [destinations , setDestinations] = useState([]);
    const [showPriceList , onShowPriceList]=useState(false);

    const tripReducer = useSelector(tripReducerSelector);
    // let{ 
    //     scheduleGroup
    // }=priceListReducer;

    const {
        trips
    }=tripReducer;

    const {
        userInfo
    } = useSelector(loginReducerSelector);
    
    useEffect(()=>{
        dispatch(getSchedulesGroup());
        dispatch(getTrips(userInfo.operator_id));
    },[])

    useEffect(()=>{
        let {
            originsList,
            destinationsList
        }=getOriginDestinations(trips);
        setOrigins(originsList);
        setDestinations(destinationsList);
    },[trips]);

  

    

    return (
        <div>
            <ScheduleGroupTable
            {...priceListReducer}
            formData={formData}
            setFormData={setFormData}
           />
           <MasterPriceListForm 
            formData={formData}
            setFormData={setFormData}
            origins={origins}
            destinations={destinations}
            trips={trips}
            onShowPriceList={onShowPriceList}
           />
           {isValid(showPriceList)?
             <MasterPriceListTable 
             groupId={formData.id}
             />
             :
             null
           }
          
        </div>
    );
}
