import React, {useEffect, useState} from 'react';
import { Typography, Select, Button, Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getCitiesAction, getDemandDaysAction, clearDemandDays } from '../../../actions/demand-days.action';
import { demandDaysSelector } from '../../../selectors/demand-days-selectors';
import { isValid, isValidArray } from '../../../utils/utilities';
import { ScheduleListCalender } from '../render-calender/render-calender.component';
import { currentDate } from '../../../utils/dates-util';
import DemandDaysTable from '../demand-days-table/demand-days-table';

const {Text} = Typography;
const { Option } = Select;

const DemandDaysRoot = () => {

    const dispatch = useDispatch();
    const demandDaysData = useSelector(demandDaysSelector);
    const [citiesList, setCitiesList] = useState([]);
    const [selectedOrigin, setSelectedOrigin] = useState([]);
    const [selectedDestination, setSelectedDestination] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true);
    const scheduleDate = currentDate();


    useEffect(() => {
        dispatch(getCitiesAction());
    },[])


    useEffect(() => {
        if (isValidArray(demandDaysData)&&isValid(demandDaysData.cities)) {
            formatCities(demandDaysData.cities);
        }
    },[demandDaysData])

    const formatCities = (cities) => {
        let tempCities = []
        tempCities.push(<Option key={"All"}>{"All"}</Option>);
        for (const [key, value] of Object.entries(cities)) {
            tempCities.push(<Option key={value.city_name}>{value.city_name}</Option>);
        }
        setCitiesList(tempCities);
    }

    const handleSelect = (value, type) => {
        // if (isValid(demandDaysData.demandDaysData)) {
        //     console.log("insidfe");
        //     dispatch(clearDemandDays);
        // }
        if (type=="origin") {
            setSelectedOrigin(value);
            if (selectedDestination.length>0&&value.length>0) {
                setIsDisabled(false);
            }
            else {
                setIsDisabled(true);
            }
        }
        else {
            setSelectedDestination(value);
            if (selectedOrigin.length>0&&value.length>0) {
                setIsDisabled(false);
            }
            else {
                setIsDisabled(true);
            }
        }
    };

    const fetchDemandDays= () => {
        dispatch(getDemandDaysAction(selectedOrigin,selectedDestination));
    }

    return (
        <div>
            <div style= {{marginLeft: '15%'}}>
                <div style= {{flexDirection: 'row', }}>
                    <Text strong style= {{color: '#1558B4'}}>Orign:</Text>
                    <Select
                        mode="multiple"
                        allowClear
                        style={{
                            marginLeft: 55,
                            width: 400
                        }}
                        placeholder="Please select Origin Cities"
                        onChange={(value)=> handleSelect(value,'origin')}
                    >
                        {citiesList}
                    </Select>
                </div>
                <div style= {{flexDirection: 'row', marginTop: 5}}>
                    <Text strong style= {{color: '#1558B4'}}>Destination:</Text>
                    <Select
                        mode="multiple"
                        allowClear
                        style={{
                            marginLeft: 15,
                            width: 400
                        }}
                        placeholder="Please select Destination Cities"
                        onChange={(value)=> handleSelect(value,'destination')}
                    >
                        {citiesList}
                    </Select>
                </div>
                <div>
                    <Button type="primary" shape="round" disabled= {isDisabled} style= {{marginTop:5, marginLeft:'10%'}} onClick= {()=> fetchDemandDays()}>
                        Search Demand days
                    </Button>
                </div>

            </div>
            {isValid(demandDaysData.demandDaysData)&&isValidArray(demandDaysData.demandDaysData['dates'])&&
                <div style= {{marginTop: 10}}>
                    <ScheduleListCalender 
                        selectedDate = {scheduleDate}
                        scheduleData = {demandDaysData.demandDaysData}
                        selectedOrigin = {selectedOrigin}
                        selectedDestination = {selectedDestination}
                    />
                    <Divider style= {{borderColor: '#817D7D'}}/>
                    <DemandDaysTable data = {demandDaysData.demandDaysData.demandDays} />
                </div>
            }
        </div>
    )
}

export default DemandDaysRoot