/**
 * All sagas for Address Saga must be defined here
 * @author Manne Hareesh
 */

 import { takeLatest,put,call,all,fork } from 'redux-saga/effects'
 import { 
    GET_SEAT_GROUP_IDS,
    SET_SEAT_GROUP_IDS,
    DELETE_SEAT,
    LOADING_ACTION,
 } from '../actions/action-types';
 import { message } from 'antd';
 
 /**
  *
  * @param {Get seat group ids} action
  */
 export function* getSeatGroupIds(action) {
     try{
         const {
             api,
             url
         } = {...action.payload};
         const apiResult = yield call(api,url);
         if(apiResult.status=="SUCCESS"){
           let clientPayload = apiResult.payload;
           if(clientPayload.Status=='SUCCESS'){
             let serverPayload = clientPayload.Payload;
             yield put({
                 type: SET_SEAT_GROUP_IDS,
                 payload: serverPayload.seatGroups
             });
           }else{
             message.error(clientPayload.Message);
           }
         }
     }
     catch(error){
         console.error("Error in seat group ids",error);
     }
 }


 
 function* watchGetSeatGroupIds () {
     yield takeLatest(GET_SEAT_GROUP_IDS,getSeatGroupIds)
 }

 export function* deleteSeat(action){
    try{
        yield put({
            type: LOADING_ACTION,
            payload: true
        });
        const {
            api,
            url ,
            apiPayload,
            onSuccess
            } = {...action.payload};
        const apiResults = yield call(api , url,apiPayload);
        if(apiResults.status=="SUCCESS"){
            let clientPayload = apiResults.payload;
            if(clientPayload.Status=='SUCCESS'){
               message.success(clientPayload.Message);
               onSuccess();
            }else{
              message.error(clientPayload.Message);
            }
          }
          yield put({
            type: LOADING_ACTION,
            payload: false
        });
       }catch(error){
            yield put({
                type: LOADING_ACTION,
                payload: false
            });
            console.error("Error in deleting seats",error);
        }  
    }
 
 function* watchDeleteSeatSaga () {
    yield takeLatest(DELETE_SEAT,deleteSeat)
 }
 
 export function*  watchSeatGroupSagas() {
     yield all([
         fork(watchGetSeatGroupIds),
         fork(watchDeleteSeatSaga)
     ])
 }
 