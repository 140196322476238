import { API_BASE_URL } from '../../../app.config';
import React  from 'react';
import { getFormattedTime } from '../../../utils/dates-util';
import { message } from 'antd';
import { commaseperatedStringToArray, isValidString } from '../../../utils/utilities';

export const tripColumns = (seatGroupIds,supportedFare)=>{

  let columns= [
    {
      title: 'Origin',
      dataIndex: 'origin',
      key: 'origin',
    },
    {
      title: 'Destination',
      dataIndex: 'destination',
      key: 'destination',
    },
    {
      title: 'Departure',
      dataIndex: 'start_time',
      key: 'start_time',
      render:(text,record) =>
      (
           <div>
              {getFormattedTime(text)}
           </div>
      )
    },
    {
      title: 'Arrival',
      dataIndex: 'end_time',
      key: 'end_time',
      render:(text,record) =>
      (
           <div>
              {getFormattedTime(text)}
           </div>
      )
    }
  ];

  if(supportedFare === 1){
    columns.push( {
      title: 'Fare(With Out GST)',
      dataIndex: 'fare',
      key: 'fare',
    }); 
  }


  if(isValidString(seatGroupIds)&&seatGroupIds.length>0){
    let seatsArray=commaseperatedStringToArray(seatGroupIds);
    seatsArray.forEach(element => {
      columns.push({
        title:element,
        dataIndex:'fare_json',
        key: 'fare_json',
        render:(text)=>{
          if(isValidString(text)&&text.length>0){
            let fareJsonObject=JSON.parse(text);
            return fareJsonObject[element];
          }
        }
      })
    });
  }

  return columns;
}

  export const getViacitiesDetails=(scheduleId,selectedDate,setViaCitiesDetails)=>{
    var month=selectedDate._d.getMonth()+1;
    var date=selectedDate._d.getDate();
    var year=selectedDate._d.getFullYear();
    var url=API_BASE_URL + "/api/getTripRoutesByScheduleAndDate?scheduleId="+scheduleId+"&date="+month+"/"+date+"/"+year;
    fetch(url).
    then((Responce)=>Responce.json()).
    then((findresponce)=>
    {
      if(findresponce!=false){
        setViaCitiesDetails(findresponce.tripRoutes);
      }else{
        setViaCitiesDetails([]);
        message.success("Trip is Not Activated For given Date!")
      }
    })
  }


  export const getSchedules=(activeStartDate,scheduleId,setTrips)=>{
    var month=activeStartDate.getMonth()+1;
    var url=API_BASE_URL + "/api/getSchedules";
    fetch(url,{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            operator_code:global.operator_code,
            onlyTripsCreated:true,
            year:activeStartDate.getFullYear(),
            month:month,
            travelsId:global.operator_id
          })
        }
      ).
    then((Responce)=>Responce.json()).
    then((findresponce)=>
    {
      if(findresponce.Status=="SUCCESS"){
         getRespectiveSchedule(findresponce.payload.schedules,scheduleId,setTrips);
      }else{
          message.error("Unable to load Schedules active Details")
      }
    })
  }

  export const getRespectiveSchedule=(schedule,scheduleId,setTrips)=>{
    let trips=[];
    for (var i = 0; i < schedule.length; i++) {
    if(schedule[i].id==scheduleId){
      trips=schedule[i].trips;
      break;
      }
    }
    setTrips(trips);
  }