import { combineReducers } from 'redux';
import { loginReducer } from './login-reducer';
import { locationReducer } from './location-reducer';
import { addressReducer } from './address-reducer';
import { busReducer } from './bus-reducer';
import { tripReducer } from './trip-reducer';
import { agentsReducer } from './agents-reducer';
import { schedulesReducer } from './schedule-reducer';
import { vechiclesReducer} from './vechicle-reducer';
import {gstReducer} from './gst-reducer';
import { priceListReducer } from './price-list-reducer';
import { userPrivillegeReducer } from './user-privillege-reducer';
import { homeReducer } from './home-reducer';
import { busOperatorReducer } from './bus-operator-reducer';
import { loadingReducer } from './loading-reducer';
import { seatGroupsReducer } from './seat-group.reducer';
import { agentColorReducer } from './agent-color-reducer';
import { travelManagementReducer } from './travel-management-reducer';
import { cancellationPolicyReducer } from './cancellation-policy-reducer';
import { demandDaysReducer } from './demand-days-reducer';
import { adminDriverReducer } from './admin-driver-reducer';

export const combinedReducer = combineReducers({
  loginReducer ,
  locationReducer ,
  addressReducer ,
  busReducer ,
  tripReducer ,
  agentsReducer ,
  schedulesReducer,
  vechiclesReducer,
  gstReducer,
  priceListReducer,
  userPrivillegeReducer,
  homeReducer,
  busOperatorReducer,
  loadingReducer,
  seatGroupsReducer,
  agentColorReducer,
  travelManagementReducer,
  cancellationPolicyReducer,
  demandDaysReducer,
  adminDriverReducer,
});
