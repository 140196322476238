import React  from 'react';
import "../assets/styles/main.scss";
import { GstComponent} from '../components/gst-componet/gst.component';
import { VerifyPagePermission } from '../components/verify-page-permission-component/verify-page-permission.component';
import Permissions from "../utils/permissions-constant";

export const GstPage = (props) => {

    const renderChildren=  <div>
                                <GstComponent 
                                
                                />
                            </div>

    return (
         <VerifyPagePermission 
            pagePermission={Permissions.pagePermission.ADMIN_VIEW_GST_PAGE}
            renderChildren={renderChildren}
            onPermissionSuccess={()=>{

            }}
            />
    );
}
