import moment from 'moment';

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];
const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday ","Saturday", "Sunday"];

export const getDateInReadableFormat = (date,displayYear,disPlayDay) => {
  if(date === undefined){
    return ""
  }
 if(!date.toLocaleDateString){
    date = new Date(date);
  }
    return (date.getDate())+" "+monthNames[date.getMonth()]
      + (displayYear == true ? " "+ date.getFullYear() : "")
      +(disPlayDay==true ?" "+dayNames[date.getDay()]:"");
};

export const getDayOfDate = (date) => {
  if(date === undefined){
    return ""
  }
 if(!date.toLocaleDateString){
    date = new Date(date);
  }
    return dayNames[date.getDay()];
};

export const getFormattedTime =(time) => {
    var timeArray = time.split(':');
    var d = new Date();
    d.setHours  (timeArray[0]);
    d.setMinutes(timeArray[1]);
    var hours = d.getHours() > 12 ? d.getHours() - 12 : d.getHours();
    var am_pm = d.getHours() >= 12 ? "PM" : "AM";
    hours = hours < 10 ? "0" + hours : hours;
    var minutes = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
    var formattedTime = hours + ":" + minutes + " " + am_pm;
    return formattedTime
}

export const getAmorPmofTime=(timein24)=>{
  var timeArray = timein24.split(':');
  var d = new Date();
  d.setHours  (timeArray[0]);
  d.setMinutes(timeArray[1]);
  var am_pm = d.getHours() >= 12 ? "PM" : "AM";
  return am_pm;
}

/*
MintoHours
Input :720
Output: 12h 00mins

Input :-720
Output: 12h 00mins
*/
export const MintoHours = (minutes)=>{
  let isNegetiveValue = false
  if(minutes<0){
    isNegetiveValue = true;
    minutes = minutes*-1;
  }
    var h = Math.floor(minutes / 60);
    var m = minutes % 60;
    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    var hoursmin=h+"h "+m+"mins";
    if(isNegetiveValue){
      return "-" + hoursmin
    }else{
      return hoursmin
    }
}


/*
MintoHours
Input :date1 ,date2
Output: array of dates in between

*/

export const Alldates = (date1,date2)=>{

  var no_of_days;
  no_of_days=Math.round((date2-date1)/(1000*60*60*24));
  var dates = [],index=0;
  while (index < no_of_days+1) {
       dates.push(ConvertDateformate(date1.clone().add(index, 'd')._d));
       index++;
   }
   return dates;

}

export const ConvertDateformate = (dateString)=>{
  var d = new Date(dateString),
  month = '' + (d.getMonth() + 1),
  day = '' + d.getDate(),
  year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

export const addDays=(date, days)=> {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export const subtractDays=(date, days)=> {
  var result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
}


// input moment object
export const get24HoursTime=(time)=>{
    return moment(time,'HHmm').format("HH:mm:ss")
}

//input moment object
export const getYear=(momentObject)=>{
    return momentObject.year();
}

//input moment object
export const getMonth=(momentObject)=>{
    // jan=0, dec=11
    return momentObject.month();
}

export const getDate=(date)=>{
  return date.date()
}

export const addMonth=(momentObject,incrementNo)=>{
    return moment(momentObject).add(incrementNo, 'M') ;
  }

  export const subtractMonth=(momentObject,decrementNo)=>{
    return moment(momentObject).subtract(decrementNo, 'M');
  }

  export const noOfDaysInMonth = (momentObject)=>{
    return moment(momentObject).daysInMonth();
  }

  export const firstDayOfMonth=(momentObject)=>{
    return moment(momentObject).startOf('month').format('YYYY-MM-DD');
  }

  export const lastDayOfMonth=(momentObject)=>{
    return moment(momentObject).endOf('month').format('YYYY-MM-DD');
  }

  export const incrementDays = (momentObject,incrementValue)=>{
    return moment(momentObject).add(incrementValue, 'days')
  }
  
  export const decrementDays = (momentObject,decrementValue)=>{
    return moment(momentObject).subtract(decrementValue, 'days')
  }

  export const addMin = (momentObject,mins)=>{
    return moment(momentObject).add(mins, 'minutes');
  }
/*
  Returns date object for given datetime
*/

export const getDates=(startDate, stopDate) =>{
  var dateArray =[];
  var currentDate = startDate;
  while (currentDate <= stopDate) {
      dateArray.push(new Date (currentDate));
      currentDate = addDays(currentDate,1);
  }
  return dateArray;
}


export const getAPIDateFormat = (datetime) => {
    if(!datetime.getDate){
      datetime = new Date(datetime);
    }
    var dd = datetime.getDate();
    var mm = datetime.getMonth()+1; //January is 0
    var yyyy = datetime.getFullYear();
    if(dd < 10)
    {
      dd = '0'+ dd;
    }
    if(mm < 10)
    {
      mm = '0' + mm;
    }
    var dateObject = yyyy+'-'+mm+'-'+dd;
    return dateObject;
  }
  
  export const getMinsBetweenTwoTimes=(time1,time2)=>{
    let diff = moment(time2, 'HH:mm').diff(moment(time1, 'HH:mm'))
    let d = moment.duration(diff);
     return Math.floor(d.asMinutes());
  }

  export const currentDate = ()=>{
    return moment();
  }

  export  const getMySqlAddMinToTime=(timein24,offsetInMins)=>{
    var timeArray = timein24.split(':');

    let timeInMins = Number(timeArray[0])*60 + Number(timeArray[1]);
    let  totalMins=timeInMins+offsetInMins;    
      var h = Math.floor(totalMins / 60);
      var m = totalMins % 60;
      if(totalMins<0){
        m=-m;    
      }
      return h + ':' + m + ':'+'00';

  }

  export const getDateMonthInReadable=(momentObject)=>{
    return moment(momentObject).format('MMM YYYY');
  }

  export const isSameOrAfter = (date1, date2 = currentDate()) => {
    let formatDate1 = moment(date1).format('YYYY-MM-DD');
    let formatDate2 = moment(date2).format('YYYY-MM-DD');
    return moment(formatDate1).isSameOrAfter(formatDate2);
  }