import { getFormattedTime } from '../../../utils/dates-util';
import React  from 'react';
import {Typography} from 'antd';
import { sortJsonObject } from './../../../utils/utilities';

const {Text} = Typography;
export const getOriginsDestinations=(data)=>{
  let originsInfo = [] , destinationsInfo = [],origins=[],destinations=[];
  data.forEach(element => {
          if(origins.indexOf(element.origin)==-1){
              origins.push(element.origin);
              originsInfo.push({
                text:element.origin,
                value:element.origin
              });
          }
          if(destinations.indexOf(element.destination)==-1){
              destinations.push(element.destination);
              destinationsInfo.push({
                text:element.destination,
                value:element.destination
              });
          }
  });
  originsInfo = sortJsonObject(originsInfo,'text',false);
  destinationsInfo = sortJsonObject(destinationsInfo,'text',false);
  return {
    originsInfo,
    destinationsInfo
  }
}

export const getTripColumns=(origins,destinations)=>{

  const tripColumns =[
    {
        title: 'TRIP DESCRIPTION',
        dataIndex: 'description',
        key: 'description'
    },
    {
        title: 'ORIGIN',
        dataIndex: 'origin',
        key: 'origin',
        filters:origins,
        onFilter: (value, record) => record.origin.indexOf(value) === 0,
      },
      {
        title: 'DESTINATION',
        dataIndex: 'destination',
        key: 'destination',
        filters : destinations,
        onFilter: (value, record) => record.destination.indexOf(value) === 0,
      },
      {
        title: 'DEPATURE TIME',
        dataIndex: 'start_time',
        key: 'start_time',
        render:(text,record) =>
        (
             <a>
                 {getFormattedTime(text)}
             </a>
        )
      },
      {
        title: 'ARIVAL TIME',
        dataIndex: 'end_time',
        key: 'end_time',
        render:(text,record) =>
        (
             <a>
                 {getFormattedTime(text)}
             </a>
          )
      },
      {
        title: 'ACTIVATED',
        dataIndex: 'is_future_trip',
        key: 'is_future_trip',
        width: 10,
        filters: [{text: 'Yes', value: 1}, {text: 'No', value: 0}],
        filterMultiple: false,
        onFilter: (value, record) => record.is_future_trip === value,
        render: (text,record) => {
          if (text){
            return (
              <Text>Yes</Text>
            )
          }
          else {
            return (
              <Text>No</Text>
            )
          }
        }
      }
];

return tripColumns;

}

