/**
 * All sagas for Agents Saga must be defined here
 * @author Manne Hareesh
 */

import { takeLatest,put,call,all,fork } from 'redux-saga/effects'
import { 
    GET_VECHICLES,
    SET_VECHICLES,
    UPDATE_VECHICLES,
    DELETE_VECHICLE,
    CREATE_VECHICLE,
    LOADING_ACTION
} from '../actions/action-types';
import { message } from 'antd';

/**
 * 
 * @param { get vechicles} action 
 */

export function* getVechicles(action){
    try{
        const {
            api,
            url
        } = {...action.payload};
        const apiResult = yield call(api,url);
        if(apiResult.status=="SUCCESS"){
          let clientPayload=apiResult.payload;
         if(clientPayload.Status=='SUCCESS'){
           let serverPayload=clientPayload.payload;
            yield put({
                type: SET_VECHICLES,
                payload: serverPayload.vechicleInfo
            });
          }
        }
    }
    catch(error){
        console.error("Error in getting vechicles",error);
    }
 }

 
function* watchGetVechiclesSaga () {
    yield takeLatest(GET_VECHICLES,getVechicles)
}


export function* updateVechiclesForSchedule(action){
    try{
      yield put({
        type: LOADING_ACTION,
        payload: true
    });
        const {
            api,
            url,
            apiPayload,
            onSuccess
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload=apiResult.payload;
         if(clientPayload.Status=='SUCCESS'){
           let serverPayload=clientPayload.payload;
            message.success(clientPayload.Message);
            onSuccess();
          }else{
            message.error(clientPayload.Message);
          }
        }else{
            message.error("Unable to update vechicle details");
          }
          yield put({
            type: LOADING_ACTION,
            payload: false
        });
    }
    catch(error){
      yield put({
        type: LOADING_ACTION,
        payload: false
    });
        console.error("Error in updating vechicles for trip",error);
    }
}

function* watchUpdateVechiclesForScheduleSaga () {
    yield takeLatest(UPDATE_VECHICLES,updateVechiclesForSchedule)
}

export function* createVechicle(action){
    try{
      yield put({
        type: LOADING_ACTION,
        payload: true
    });
        const {
            api,
            url,
            apiPayload,
            onSuccess
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload=apiResult.payload;
         if(clientPayload.Status=='SUCCESS'){
           let serverPayload=clientPayload.payload;
            message.success(clientPayload.Message);
            onSuccess();
          }else{
            message.error(clientPayload.Message);
          }
        }else{
            message.error("Unable to create vechicle details");
          }
          yield put({
            type: LOADING_ACTION,
            payload: false
        });
    }
    catch(error){
      yield put({
        type: LOADING_ACTION,
        payload: false
    });
        console.error("Error in create vechicles",error);
    }
}

function* watchCreateVechicleSaga () {
    yield takeLatest(CREATE_VECHICLE,createVechicle)
}

export function* deleteVechicle(action){
    try{
      yield put({
        type: LOADING_ACTION,
        payload: true
    });
        const {
            api,
            url,
            apiPayload,
            onSuccess
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload=apiResult.payload;
         if(clientPayload.Status=='SUCCESS'){
           let serverPayload=clientPayload.payload;
            message.success(clientPayload.Message);
            onSuccess();
          }else{
            message.error(clientPayload.Message);
          }
        }else{
            message.error("Unable to delete vechicle details");
          }
          yield put({
            type: LOADING_ACTION,
            payload: false
        });
    }
    catch(error){
      yield put({
        type: LOADING_ACTION,
        payload: false
    });
        console.error("Error in delete vechicles",error);
    }
}

function* watchDeleteVechicleSaga () {
    yield takeLatest(DELETE_VECHICLE,deleteVechicle)
}

export function*  watchVechiclesDetailsPage() {
    yield all([
        fork(watchGetVechiclesSaga),
        fork(watchUpdateVechiclesForScheduleSaga),
        fork(watchCreateVechicleSaga),
        fork(watchDeleteVechicleSaga)
    ])
}