import { isValid } from "../../../utils/utilities";

export const onFilteredChangeCustom=(data,origin,destination)=>{
    let schedules=[];
    data.forEach(element => {
      if((origin=='all'||origin==element.origin)&&(destination=='all'||destination==element.destination)&&isValid(element.description)){
        schedules.push({label:element.description,value:element.id,origin:element.origin,destination:element.destination})
      }
    })
    return schedules;
  }


// createGroup=(values)=>{
//     let description=values.groupname;
//     let scheduleIds= values.schedule_ids;
//     fetch(HOST_URL + '/api/createSchedulesGroup', {
//         method: "POST",
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({description,scheduleIds})
//       }).then(response => response.json())
//       .then(data => {
//             if(data.Status=="SUCCESS"){
//               this.getSchedulesGroup();
//               message.success('Group Created Successfully');
//             }else{
//               message.warning('Unable to Create Group Try again!');
//             }
//       })
  
//   }
  
//   deleteGroup=()=>{
//     let groupId=this.state.selectedGroupId;
//     fetch(HOST_URL + '/api/deleteSchedulesGroup', {
//         method: "POST",
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({groupId})
//       }).then(response => response.json())
//       .then(data => {
//         if(data.Status=="SUCCESS"){
//           this.getSchedulesGroup();
//           this.clearFrom();
//           message.success('Group Deleted Successfully');
//         }else{
//           message.warning('Unable to Delete Group Try again!');
//         }
//       })
//   }
  
//   updateGroup=(values)=>{
//     let updateDetails={
//                         groupId:this.state.selectedGroupId,
//                         scheduleIds:values.schedule_ids,
//                         description:values.groupname
//                         }
//     fetch(HOST_URL + '/api/updateSchedulesGroup', {
//         method: "POST",
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify(updateDetails)
//       }).then(response => response.json())
//       .then(data => {
//         if(data.Status=="SUCCESS"){
//           this.getSchedulesGroup();
//           message.success('Group Updated Successfully');
//         }else{
//           message.warning('Unable to Update Group Try again!');
//         }
//       })
//   }