import { Form,Row,Col, Button, Space, Select , TimePicker ,Checkbox } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import React , { useEffect, useState }from 'react';
import { getRoutes , getFilteredCities} from './trip-component.module';
import { ShowPossibleRoutes } from './show-possible-routes.component';

const { Option } = Select;

export const CitiesForm = (props) => {

  const {
    citiesData,
    tripId,
    formAddedCities,
    oldRoutes,
    tripFare,
    selectedTrip
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [form] = Form.useForm();
  const [filteredCities,setFilteresCities] = useState(citiesData)


  useEffect(()=>{
    form.setFieldsValue({
      cities:formAddedCities
    })
    setFilteresCities(getFilteredCities(formAddedCities,citiesData));
  },[formAddedCities]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onFinish = values => {
    setRoutes(getRoutes(values.cities,tripId,oldRoutes,tripFare,selectedTrip));
    setIsModalVisible(true);
  };


  const printOption = (option) => {
    return <Option  value={option.name} data={option}>{option.name}</Option>
  };

  return (
    <Form name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off" form={form} className="form">
        <Form.List name="cities">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field,index) => (
                <Space key={index} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                <Row type='flex' span={24}>
                 <Col span={4} justify="center" align="middle"  className="step-two-city">
                    <Form.Item
                      {...field}
                      name={[field.name, 'city']}
                      fieldKey={[field.fieldKey, 'city']}
                      rules={[{ required: true, message: 'Please input city' }]}
                    >
                        <Select
                            showSearch
                            placeholder="Select city"
                            style={{ width: 200 }}
                        >
                        {filteredCities.map(printOption)}
                        </Select>
                    </Form.Item>
                  </Col>
                  <Col span={4} justify="center" align="middle"  className="step-two-time">
                      <Form.Item
                        {...field}
                        name={[field.name, 'time']}
                        fieldKey={[field.fieldKey, 'time']}
                        rules={[{ required: true, message: 'Please input time' }]}
                      >
                                <TimePicker 
                                use12Hours 
                                format='h:mm A'  
                                minuteStep={5} 
                                popupClassName={"time-picker-to-not-display-footer"}
                                onSelect={(value)=>{
                                  let allData = form.getFieldsValue();
                                  allData.cities[index].time=value;
                                    form.setFieldsValue(allData.cities);
                                  }}
                                />
                      </Form.Item>
                  </Col>
                  <Col   className="step-two-is-origin">
                      <Form.Item
                        {...field}
                        valuePropName="checked"
                        name={[field.name, 'isOrigin']}
                        fieldKey={[field.fieldKey, 'isOrigin']}
                        rules={[{ required: false, message: 'Please input origin' }]}
                        initialValue={false}
                      >
                                <Checkbox></Checkbox>
                      </Form.Item>
                  </Col>
                  <Col className="step-two-is-destination">
                        <Form.Item
                          {...field}
                          valuePropName="checked"
                          name={[field.name, 'isDestination']}
                          fieldKey={[field.fieldKey, 'isDestination']}
                          rules={[{ required: false, message: 'Please input destination' }]}
                          initialValue={false}
                        >
                                  <Checkbox></Checkbox>
                        </Form.Item>
                  </Col>
                  <Col  className="step-two-next-day">
                          <Form.Item
                            {...field}
                            // valuePropName="checked"
                            name={[field.name, 'originDay']}
                            fieldKey={[field.fieldKey, 'originDay']}
                            rules={[{ required: false, message: 'Please input day' }]}
                            initialValue={false}
                          >
                                  <Select
                                      showSearch
                                      // disabled={disableForm}
                                      placeholder="Select day"
                                      style={{ width: 200 }}
                                      defaultValue={0}
                                  >
                                    <Option  value={0}>Day 1</Option>{/*same day*/}
                                    <Option  value={1}>Day 2</Option>{/*next day*/}
                                    <Option  value={2}>Day 3</Option>
                                    <Option  value={3}>Day 4</Option>
                                  </Select>         
                          </Form.Item>
                  </Col>
                  <MinusCircleOutlined 
                  style={{margin:'10px'}}
                  onClick={async () => {
                    await remove(field.name)
                    let newCities=form.getFieldValue('cities');
                    setFilteresCities(getFilteredCities(newCities,citiesData));
                    }} />
                  </Row>
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add City
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
         <ShowPossibleRoutes 
            onFinish={onFinish}
            setIsModalVisible={setIsModalVisible}
            isModalVisible={isModalVisible}
            routes={routes}
            setRoutes={setRoutes}
            tripId={tripId}
            oldRoutes={oldRoutes}
            selectedTrip={selectedTrip}
         />
        </Form.Item>
     </Form>
  );
};