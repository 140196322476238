/**
 * All sagas for Schedules Saga must be defined here
 * @author Manne Hareesh
 */

import { takeLatest,put,call,all,fork } from 'redux-saga/effects'
import { 
    GET_ACTIVE_SCHEDULES,
    ACTIVATE_SCHEDULES,
    SET_ACTIVE_SCHEDULES,
    BLOCK_SCHEDULES,
    SET_IN_ACTIVE_SCHEDULES,
    GET_IN_ACTIVE_SCHEDULES,
    REMOVE_SCHEDULES,
    SET_ACTIVE_SCHEDULES_LOADING,
    SET_IN_ACTIVE_SCHEDULES_LOADING,
    LOADING_ACTION
} from '../actions/action-types';
import { message } from 'antd';


/**
 * 
 * @param { block schedule} action 
 */

export function* removeSchedule(action){
    try{
        yield put({
            type:LOADING_ACTION,
            payload:true
        })
        const {
            api,
            url,
            apiPayload,
            onSuccess
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload=apiResult.payload;
         if(clientPayload.Status=='SUCCESS'){
            message.success(clientPayload.Message);
            onSuccess();
          }else{
            message.error(clientPayload.Message);
            }
        }
        else{
            message.error("Removing schedule failed!");
        }
        yield put({
            type:LOADING_ACTION,
            payload:false
        })
    }
    catch(error){
        yield put({
            type:LOADING_ACTION,
            payload:false
        })
        console.error("Error in getting agents",error);
    }
 }

 function* watchRemoveSchedule () {
    yield takeLatest(REMOVE_SCHEDULES,removeSchedule)
}

/**
 * 
 * @param { block schedule} action 
 */

export function* blockSchedules(action){
    try{
        yield put({
            type:LOADING_ACTION,
            payload:true
        })
        const {
            api,
            url,
            apiPayload,
            onSuccess,
            onBlockFailure
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload=apiResult.payload;
         if(clientPayload.Status=='SUCCESS'){
            message.success(clientPayload.Message);
            onSuccess();
          }else{
           onBlockFailure(clientPayload.Message);
         }
        }
        else{
            onBlockFailure("Blocking failed!");
        }
        yield put({
            type:LOADING_ACTION,
            payload:false
        })
    }
    catch(error){
        yield put({
            type:LOADING_ACTION,
            payload:false
        })
        console.error("Error in getting agents",error);
    }
 }

 
 function* watchBlockSchedules () {
    yield takeLatest(BLOCK_SCHEDULES,blockSchedules)
}

/**
 * 
 * @param { active schedule} action 
 */

export function* activateSchedules(action){
    try{
        yield put({
            type:LOADING_ACTION,
            payload:true
        })
        const {
            api,
            url,
            apiPayload,
            onSuccess
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload=apiResult.payload;
         if(clientPayload.Status=='SUCCESS'){
            message.success(clientPayload.Message);
            onSuccess();
          }else{
            message.error(clientPayload.Message);
          }
        }else{
            message.error("Activating failed!");
          }
          yield put({
            type:LOADING_ACTION,
            payload:false
        })
    }
    catch(error){
        yield put({
            type:LOADING_ACTION,
            payload:false
        })
        console.error("Error in getting agents",error);
    }
 }

 
 function* watchActivateSchedules () {
    yield takeLatest(ACTIVATE_SCHEDULES,activateSchedules)
}

/**
 * 
 * @param { get active schedule} action 
 */

export function* getActiveSchedules(action){
    try{
        yield put({
            type: SET_ACTIVE_SCHEDULES_LOADING,
            payload: true
        });
        const {
            api,
            url,
            apiPayload
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload=apiResult.payload;
         if(clientPayload.Status=='SUCCESS'){
           let serverPayload=clientPayload.payload;
            yield put({
                type: SET_ACTIVE_SCHEDULES,
                payload: {schedules:serverPayload.schedules}
            });
          }
        }
        yield put({
            type: SET_ACTIVE_SCHEDULES_LOADING,
            payload: false
        });
    }
    catch(error){
        yield put({
            type: SET_ACTIVE_SCHEDULES_LOADING,
            payload: false
        });
        console.error("Error in getting active schedules",error);
    }
 }

 export function* getInActiveSchedules(action){
     try{
        yield put({
            type: SET_IN_ACTIVE_SCHEDULES_LOADING,
            payload: true
        });
        const {
            api,
            url,
            apiPayload
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload=apiResult.payload;
         if(clientPayload.Status=='SUCCESS'){
           let serverPayload=clientPayload.payload;
            yield put({
                type: SET_IN_ACTIVE_SCHEDULES,
                payload: {schedules:serverPayload.schedules}
            });
          }
        }
        yield put({
            type: SET_IN_ACTIVE_SCHEDULES_LOADING,
            payload: false
        });
     }
     catch(error){
        console.error("Error in getting in active schedules",error);
        yield put({
            type: SET_IN_ACTIVE_SCHEDULES_LOADING,
            payload: false
        });
     }
 }

 function* watchGetActiveSchedules () {
    yield takeLatest(GET_ACTIVE_SCHEDULES,getActiveSchedules)
}

 function* watchGetInActiveSchedules () {
    yield takeLatest(GET_IN_ACTIVE_SCHEDULES,getInActiveSchedules)
}

export function*  watchSchedulesPage() {
    yield all([
        fork(watchGetActiveSchedules),
        fork(watchActivateSchedules),
        fork(watchBlockSchedules),
        fork(watchGetInActiveSchedules),
        fork(watchRemoveSchedule)
    ])
}