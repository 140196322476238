import React  from 'react';
import "../assets/styles/main.scss";
import { Typography } from 'antd';
import { LocationComponent } from '../components/location-component/location-component';
import { connect } from 'react-redux';
import { getLocationDetailsAction  } from '../actions/locations-actions';
import { locationReducerSelector } from "../selectors/location-selectors";
import { loginReducerSelector } from "../selectors/login-selectors";
import { VerifyPagePermission } from '../components/verify-page-permission-component/verify-page-permission.component';
import Permissions from "../utils/permissions-constant";


const { Title } = Typography;

export const Locations = (props) => {
  const {
          getLocationDetailsAction ,
          locationReducer
        } = {...props}

        const renderChildren=  <div style={{position:'relative',top:'10px'  , margin: '20px'}}>
                                      <Title level={4}>Cities</Title>
                                      <LocationComponent getLocationDetailsAction={getLocationDetailsAction}
                                                        locationReducer={locationReducer}/>
                               </div>

    return (
      <VerifyPagePermission 
      pagePermission={Permissions.pagePermission.ADMIN_VIEW_CITIES_PAGE}
      renderChildren={renderChildren}
      onPermissionSuccess={()=>{

      }}
      />
    );
}



const mapReduxStateToProps = reduxState => {
    return {
      locationReducer : locationReducerSelector(reduxState),
      loginReducer : loginReducerSelector(reduxState)
    }
}

const mapReduxActionsToProps = dispatch => {
return {
  getLocationDetailsAction : () => dispatch(getLocationDetailsAction())
  }
}

export const LocationsPage = connect(mapReduxStateToProps,
    mapReduxActionsToProps)(Locations);
