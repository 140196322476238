import React  from 'react';
import { useSelector } from 'react-redux';
import { BoardingPointComponent } from '../../trip-components/boarding-points/boarding-points.component';
import { tripReducerSelector } from '../../../selectors/trip-selectors';

export const BoardingPointsComponent = (props) => {

    let tripReducer = useSelector(tripReducerSelector);

    return (
        <div>
             <BoardingPointComponent 
             {...tripReducer}
             isRowClickable={true}
             showDeleteUpdateButtons={true}
             />  
        </div>
    );
}
