import React, { useState } from 'react';
import { Button , Table , Typography} from 'antd';
import { ExpandableScheduleCell } from '../expandable-cell-component/expandable-cell.component';
import { isValidArray } from '../../../utils/utilities';
import { showConfirm } from '../../../utils/utilities';
import { removeSchedules  , getInActiveSchedules} from '../../../actions/schedule-actions';
import { useDispatch } from 'react-redux';

const { Text } = Typography;

export const SchedulesInActiveTable = (props) => {

    const{
        dataSource,
        loading,
        selectedDate,
        rowSelection
    } = props;

    const dispatch = useDispatch();

    const [expandedRowKeys , setExpandedRowKeys] = useState([]);

    const deleteAction=(record)=>{
      showConfirm(
          ()=>{
              dispatch(removeSchedules(record.id,()=>{
                dispatch(getInActiveSchedules());
              }));
          },
          record.description,
          ''
        )
  }
    const columns = [
        {
          title: 'Trip Description',
          dataIndex: 'description',
          align:'center'
        },
        {
          title: 'Via Cities',
          dataIndex: 'via_cities',
          align:'center',
          width:200,
          render:(text,record)=>{
              if(isValidArray(text)){
                let viaCitiesString = text.join(",");
                return <div style={{width:'300px'}}>
                             <Text >{viaCitiesString + "."}</Text>
                       </div>

              }else{
                return null;
              }
          }
        },
        {
            title: 'Remove',
            dataIndex: 'id',
            align:'center',
            render:(text,record) =>(
                      <Button
                      style={{background:"#D0021B",zIndex:990}}
                      onClick={()=>{
                        deleteAction(record);
                      }}
                      size={"large"}>
                                          <Text strong style={{color:'white'}}>Delete</Text>
                      </Button>
            )
        }
      ]
      
    return (
        <div>
                        <Table
                         rowSelection={rowSelection}
                         dataSource={dataSource!=undefined?dataSource:[]}
                         columns={columns}
                         pagination={false}
                         loading={loading}
                         rowKey={record => record.id}
                         expandedRowRender={record =>
                          <ExpandableScheduleCell
                          record={record}
                          scheduleId={record.id}
                          selectedDate={selectedDate}
                          inactiveExtender={true}
                           />}
                           expandedRowKeys={expandedRowKeys}
                           onRow={(record) => ({ onClick: () => {
                                  let newExpandedRowKeys=[record.id],oldExpandedKeys=expandedRowKeys;
                                  if(oldExpandedKeys.indexOf(record.id)!=-1){
                                    newExpandedRowKeys=[];
                                  }
                                    setExpandedRowKeys(newExpandedRowKeys);
                                  }
                                }
                              )}
                              pagination={false}
                              expandIconColumnIndex={-1}
                              expandIconAsCell={false}
                        />
        </div>
    );
}
