import React  from 'react';
import { Layout ,Typography , Table} from 'antd';
import { isValidArray } from '../../../utils/utilities';

const { Header } = Layout;
const { Title , Text } = Typography;


const columns = [
  {
    title: 'Operator name',
    dataIndex: 'name',
  },
  {
    title:'Owner name',
    dataIndex:'owner_name',
  },
  {
    title:'Operator code',
    dataIndex:'operator_code',
  },
  {
    title:'Contact email',
    dataIndex:'contact_email'
  },
  {
    title:'Phone number',
    dataIndex:'phone_number'
  }
];


export const BusOperatorsTable = props => {

  const { dataSource , updateformData , selectedData , loading}={...props};

  return (
      <div>
            <Table dataSource={!isValidArray(dataSource)?[]:dataSource}
                    pagination={{ pageSize: 5 }}
                   columns={columns}
                   rowClassName={ (record, index) => {return record.id== selectedData.id?"TableRowClicked":""}   }
                   loading={loading}
                   rowKey={record => record.id}
                   onRow={(record, rowIndex) => {
                      return {
                        onClick: event => {
                          updateformData(record);
                        }, // click row
                        onDoubleClick: event => {

                        }, // double click row
                        onContextMenu: event => {

                        }, // right button click row
                        onMouseEnter: event => {

                        }, // mouse enter row
                        onMouseLeave: event => {

                        }, // mouse leave row
                      };
                    }}
                   />
      </div>
  );
};
