import { API_BASE_URL , TRACKING_SERVER_URL} from "../../../app.config";
import moment from 'moment';
import { message } from "antd";

export const getActivetrips=(selectedDate,setTableData)=>{
    var date=moment(selectedDate).format('MM/DD/YYYY')
    fetch(API_BASE_URL + '/api/getTripsBystartDate?date='+date+"&&travelsId="+global.operator_id)
    .then((Responce)=>Responce.json())
    .then((findresponce)=>
    {
        if(findresponce.Status=="SUCCESS"){
            setTableData(getTableData(findresponce.Payload));
        }else{
            message.error(findresponce.Message);
        }
    })
  }

  const getTableData=(data)=>{
    let formatedData=[]
    data.forEach(element => {
      element.oldPhoneNumber = element.phone_no;
      element.oldVechicleId = element.vechicle_id;
      formatedData.push(element);
    });
    return formatedData;
  }

  const getChangedInfo=(data)=>{
    let changedData = [];
    data.forEach(element => {
      if(element.phone_no!=element.oldPhoneNumber||element.vechicle_id!=element.oldVechicleId){
        console.log(element); 
        changedData.push(element);
      }
    })
    return changedData;
  }
  export const updatePhoneNoandVechicleNo=(data,loadingFuncCall)=>{
    let changedData=getChangedInfo(data);
    if(changedData.length>0){
      loadingFuncCall(true);
      fetch(API_BASE_URL + '/api/updateTrips',{
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({"trips" :changedData})
      }).then(response => response.json())
      .then(findresponce =>
        {
          if(findresponce.Status=="SUCCESS"){
              updateTrackingServerCache();
             message.success(findresponce.Message)
           }else{
              message.error(findresponce.Message);
           }
           loadingFuncCall(false);
        })
    }else{
      message.error("Information for trips not changed!")
    }
  }

  export const updateTrackingServerCache=()=>{
    fetch(TRACKING_SERVER_URL + '/api/refreshCache')
    .then((Responce)=>Responce.json())
    .then((findresponce)=>
    {
      if(findresponce.Status=='SUCCESS'){
        message.success(findresponce.Message);
      }else{
        message.error(findresponce.Message);
      }
    })
  }