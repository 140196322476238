/**
 * @author Rejo Varghese
 * @description Pop up Component related to amenities
 */

import {Row, Button, Modal, Checkbox, Divider, Col} from 'antd';
import React, { useEffect, useState }  from 'react';
import { isValidArray } from '../../../utils/utilities';

const CheckboxGroup = Checkbox.Group;

export const BusAmenitiesModal = (props) => {


    const {
        isRowClicked,
        amenities,
        busAmenity,
        addAmenitiesToBus
    } = props;

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [amenityOption, setAmenityOption] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(false);


    const onChange = (list) => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < amenityOption.length);
        setCheckAll(list.length === amenityOption.length);
    };
    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? amenityOption : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    useEffect (() => {
        if (isValidArray(amenities)) {
            let tempOption = [];
            amenities.map((data) => {
                tempOption.push(data.amenity);
            })
            setAmenityOption(tempOption);
        }
    },[amenities])
    
    useEffect(() => {
        if (isValidArray(busAmenity)) {
            let tempBusAmenity = [];
            busAmenity.map((data) => {
                tempBusAmenity.push(data.amenity_name);
            });
            setCheckedList(tempBusAmenity);
        }
    },[busAmenity])

    const showModal = () => {
        setIsModalVisible(true);
    };
    
    const handleCancel = () => {
        setIsModalVisible(false);
    };
      
    return (
        <div>
            {isRowClicked?<div>
            <Button
                onClick={showModal}
                disabled = {!isRowClicked}
                style={{ background:'#FF6767' , borderColor:'#FF6767' ,color:'#FFFFFF'}}
            >
                Amenities
            </Button>
            <Modal title="Amenities" visible={isModalVisible} onOk={handleCancel} onCancel={handleCancel} okText="Ok" footer ={null}>
                <Row>
                    <Col span= {12}>
                        <Button
                            onClick={handleCancel}
                            style={{margin:'5px' , background:'#000000' , borderColor:'#000000' ,color:'#FFFFFF', borderRadius: 5}}
                        >
                            Exit
                        </Button>
                    </Col>
                    <Col span= {12}>
                        <Button
                            onClick={()=> {addAmenitiesToBus(checkedList); handleCancel();}}
                            style={{margin:'5px' , background:'#FF6767' , borderColor:'#FF6767' ,color:'#FFFFFF', borderRadius: 5}}
                        >
                            Update
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                        Amenities
                    </Checkbox>
                    <Divider />
                    <CheckboxGroup options={amenityOption} value={checkedList} onChange={onChange} style={{ display: "flex", flexDirection: 'column' }}/>
                </Row>
            </Modal>
            </div>:null}
        </div>
    );
}
