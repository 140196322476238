/**
 * All global constants can be defined here
 * @author Sanjay
 */

import { message } from "antd";

// Static Messages
export const FAILURE_STATUS = "FAILURE";
export const SUCCESS_STATUS = "SUCCESS";

// Static values
export const IST_OFFSET = 330;   // IST offset UTC +5:30

export const QWIK_BUS_USER = "QB";


export const SLEEPER_SEATER_TYPE="Seater/Sleeper";
export const SLEEPER_GROUP_ID="Sleeper";

export const PRE_PAID_USER ="Prepaid";
export const POST_PAID_USER ="Postpaid";

export const MIN_DURATION_TO_AUTO_CREATE_ROUTE = 29;   // IST offset UTC +5:30
export const PER_DAY_MINS = 1440;

export const GOOGLE_MAP_API_KEY = "AIzaSyDrk4VeM3JL3wDvOXfOVzQbTSNm7GITmT8";

export const getCoordinates = (address,onSuccess)=>{
    fetch("https://maps.googleapis.com/maps/api/geocode/json?address="+address+'&key='+GOOGLE_MAP_API_KEY)
      .then(response => response.json())
      .then(data => {
        if(data.results.length>0){
          const latitude = data.results[0].geometry.location.lat;
          const longitude = data.results[0].geometry.location.lng;
          onSuccess(latitude,longitude);
        }else{
          message.error(`Failed to find lat , lng of ${address}`)
        }
      })
  }

export const verifyQwikBusUser=()=>{
    if(QWIK_BUS_USER===global.name){
        return true;
    }else{
        return false;
    }
}
