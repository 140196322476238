import React from 'react';
import "../assets/styles/main.scss";
import { Typography } from 'antd';
import { locationReducerSelector } from "../selectors/location-selectors";
import { connect } from 'react-redux';
import { SchedulesComponent } from '../components/schedule-components/schedules-component/schedules.component';
import { VerifyPagePermission } from '../components/verify-page-permission-component/verify-page-permission.component';
import Permissions from "../utils/permissions-constant";

const { Title } = Typography;

export const Schedules = (props) => {

  const renderChildren =     <div style={{position:'relative',top:'10px'  , margin: '20px' }}>
                                {/* <Title level={4}>Schedule Details</Title> */}
                                <SchedulesComponent />
                              </div>

    return (
      <VerifyPagePermission 
      pagePermission={Permissions.pagePermission.ADMIN_VIEW_SCHEDULES_PAGE}
      renderChildren={renderChildren}
      onPermissionSuccess={()=>{

      }}
      />
    );
}



const mapReduxStateToProps = reduxState => {
    return {
      locationReducer : locationReducerSelector(reduxState)
    }
}

const mapReduxActionsToProps = dispatch => {
return {
  }
}

export const SchedulesPage = connect(mapReduxStateToProps,
    mapReduxActionsToProps)(Schedules);
