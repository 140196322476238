/**
 * All sagas for Locations Saga must be defined here
 * @author Manne Hareesh
 */

 import { takeLatest,put,call,all,fork } from 'redux-saga/effects'
 import { 
     GET_TRAVEL_MANAGEMENT_ACTIONS , 
     SET_TRAVEL_MANAGEMENT, 
     SET_TRAVEL_MANAGEMENT_LOADING, 
     UPDATE_TRAVEL_MANAGEMENT_ACTION,
     SET_UPDATE_TRAVEL_MANAGEMENT_RUNNING,
     CREATE_TRAVEL_MANAGEMENT_ACTION,
     SET_ADD_TRAVEL_MANAGEMENT_RUNNING,
     SET_DELETE_TRAVEL_MANAGEMENT_RUNNING,
     DELETE_TRAVEL_MANAGEMENT_ACTION
 } from '../actions/action-types';
 import { message } from 'antd';
 
 /**
  *
  * @param {Get travel management details} action
  */
 export function* getTravelManagements(action) {
     try{
         const {
             api,
             url,
             apiPayload
         } = {...action.payload};
         const apiResult = yield call(api,url,apiPayload);
         yield put({
             type: SET_TRAVEL_MANAGEMENT_LOADING,
             payload: true
         });
         if(apiResult.status=="SUCCESS"){
           let clientPayload=apiResult.payload;
           if(clientPayload.Status=='SUCCESS'){
             let serverPayload=clientPayload.Payload;
             yield put({
                 type: SET_TRAVEL_MANAGEMENT,
                 payload: serverPayload.travelManagements
             });
             yield put({
                 type: SET_TRAVEL_MANAGEMENT_LOADING,
                 payload: false
             });
             message.success(clientPayload.Message)
           }
         }
     }
     catch(error){
         console.error("Error in getting travel management ",error);
         yield put({
            type: SET_TRAVEL_MANAGEMENT_LOADING,
            payload: false
        });
     }
 }
 
 function* watchGetTravelManagements () {
     yield takeLatest(GET_TRAVEL_MANAGEMENT_ACTIONS,getTravelManagements)
 }

  /**
  *
  * @param {create travel management details} action
  */
   export function* createTravelManagement(action) {
    try{
        const {
            api,
            url,
            apiPayload,
            onSuccess
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        yield put({
            type: SET_ADD_TRAVEL_MANAGEMENT_RUNNING,
            payload: true
        });
        if(apiResult.status=="SUCCESS"){
          let clientPayload=apiResult.payload;
          if(clientPayload.Status=='SUCCESS'){
            onSuccess();
            yield put({
                type: SET_ADD_TRAVEL_MANAGEMENT_RUNNING,
                payload: false
            });
            message.success(clientPayload.Message);
          }
        }
    }
    catch(error){
        console.error("Error in create travel management ",error);
        yield put({
           type: SET_ADD_TRAVEL_MANAGEMENT_RUNNING,
           payload: false
       });
    }
}

function* watchCreateTravelManagement () {
    yield takeLatest(CREATE_TRAVEL_MANAGEMENT_ACTION,createTravelManagement)
}

 
 /**
  *
  * @param {update travel management details} action
  */
  export function* updateTravelManagement(action) {
    try{
        const {
            api,
            url,
            apiPayload,
            onSuccess
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        yield put({
            type: SET_UPDATE_TRAVEL_MANAGEMENT_RUNNING,
            payload: true
        });
        if(apiResult.status=="SUCCESS"){
          let clientPayload=apiResult.payload;
          if(clientPayload.Status=='SUCCESS'){
            onSuccess();
            yield put({
                type: SET_UPDATE_TRAVEL_MANAGEMENT_RUNNING,
                payload: false
            });
            message.success(clientPayload.Message)
          }
        }
    }
    catch(error){
        console.error("Error in updating travel management ",error);
        yield put({
           type: SET_UPDATE_TRAVEL_MANAGEMENT_RUNNING,
           payload: false
       });
    }
}

function* watchUpdateTravelManagement () {
    yield takeLatest(UPDATE_TRAVEL_MANAGEMENT_ACTION,updateTravelManagement)
}
 

 /**
  *
  * @param {delete travel management details} action
  */
  export function* deleteTravelManagement(action) {
    try{
        const {
            api,
            url,
            apiPayload,
            onSuccess
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        yield put({
            type: SET_DELETE_TRAVEL_MANAGEMENT_RUNNING,
            payload: true
        });
        if(apiResult.status=="SUCCESS"){
          let clientPayload=apiResult.payload;
          if(clientPayload.Status=='SUCCESS'){
            onSuccess();
            yield put({
                type: SET_DELETE_TRAVEL_MANAGEMENT_RUNNING,
                payload: false
            });
            message.success(clientPayload.Message);
          }
        }
    }
    catch(error){
        console.error("Error in delete travel management ",error);
        yield put({
           type: SET_DELETE_TRAVEL_MANAGEMENT_RUNNING,
           payload: false
       });
    }
}

function* watchDeleteTravelManagement () {
    yield takeLatest(DELETE_TRAVEL_MANAGEMENT_ACTION,deleteTravelManagement)
}
 
 export function*  watchGetTravelManagementsPage() {
     yield all([
         fork(watchGetTravelManagements),
         fork(watchUpdateTravelManagement),
         fork(watchCreateTravelManagement),
         fork(watchDeleteTravelManagement)
     ])
 }
 