/**
 * All action types must be defined here
 * It will be referenced in action creators, sagas, containers, pages and reducers
 */

 export const SAMPLE_ACTION = "SAMPLE_ACTION";

 //agents
 export const GET_AGENTS = "GET_AGENTS";
 export const SET_AGENTS = "SET_AGENTS";
 export const CREATE_AGENT = "CREATE_AGENT";
 export const UPDATE_AGENT = "UPDATE_AGENT";
 export const DELETE_AGENT = "DELETE_AGENT";
 
 //agent color
 export const CREATE_AGENT_COLOR = "CREATE_AGENT_COLOR";
 export const UPDATE_AGENT_COLOR = "UPDATE_AGENT_COLOR";
 export const SET_AGENT_COLOR = "SET_AGENT_COLOR";
 export const GET_AGENT_COLOR = "GET_AGENT_COLOR";
 
 //cancellation policy
 export const CREATE_CANCELLATION_POLICY = "CREATE_CANCELLATION_POLICY"
 export const UPDATE_CANCELLATION_POLICY = "UPDATE_CANCELLATION_POLICY"
 export const SET_CANCELLATION_POLICY = "SET_CANCELLATION_POLICY"
 export const GET_CANCELLATION_POLICY = "GET_CANCELLATION_POLICY"
 export const SET_CANCELLATION_POLICY_LOADING = "SET_CANCELLATION_POLICY_LOADING";
 export const DELETE_CANCELLATION_POLICY = "DELETE_CANCELLATION_POLICY"
 
 export const GET_LOCATIONS_ACTION = 'GET_LOCATIONS_ACTION' ;
 export const LOCATIONS_INFO = 'LOCATIONS_INFO' ;
 export const INSERT_LOCATION_ACTION = 'INSERT_LOCATION_ACTION';
 export const UPDATE_LOCATION_ACTION = 'UPDATE_LOCATION_ACTION';
 export const DELETE_LOCATION_ACTION = 'DELETE_LOCATION_ACTION' ;
 export const LOCATION_ACTION_SUCCESS = 'LOCATION_ACTION_SUCCESS';
 export const LOCATION_TABLE_LOADING = 'LOCATION_TABLE_LOADING';
 export const GET_MASTER_CITIES = 'GET_MASTER_CITIES';
 export const SET_MASTER_CITIES = 'SET_MASTER_CITIES';
 
 export const GET_ADDRESSES_ACTION = 'GET_ADDRESSES_ACTION' ;
 export const ADDRESSES_INFO = 'ADDRESSES_INFO' ;
 export const INSERT_ADDRESS_ACTION = 'INSERT_ADDRESS_ACTION';
 export const UPDATE_ADDRESS_ACTION = 'UPDATE_ADDRESS_ACTION';
 export const DELETE_ADDRESS_ACTION = 'DELETE_ADDRESS_ACTION' ;
 export const ADDRESS_ACTION_SUCCESS = 'ADDRESS_ACTION_SUCCESS';
 export const ADDRESS_TABLE_LOADING = 'ADDRESS_TABLE_LOADING';
 export const GET_ADDRESS_LOCATIONS_BY_CITY = 'GET_ADDRESS_LOCATIONS_BY_CITY';
 export const ADD_LOCATION_LOADING = "ADD_LOCATION_LOADING";
 export const UPDATE_LOCATION_LOADING = "UPDATE_LOCATION_LOADING";
 export const DELETE_LOCATION_LOADING = "DELETE_LOCATION_LOADING";
 
 
 //buses
 export const GET_BUSES_ACTION = 'GET_BUSES_ACTION' ;
 export const BUSES_INFO = 'BUSES_INFO' ;
 export const BUS_ACTION_SUCCESS = 'BUS_ACTION_SUCCESS' ;
 export const BUS_TABLE_LOADING = 'BUS_TABLE_LOADING';
 export const GET_SEAT_LAYOUT_BY_BUS_ID_ACTION = 'GET_SEAT_LAYOUT_BY_BUS_ID_ACTION';
 export const SEATS_INFO = 'SEATS_INFO';
 export const CREATE_BUS ="CREATE_BUS";
 export const UPDATE_BUS_SEAT_LAYOUT = "UPDATE_BUS_SEAT_LAYOUT";
 export const DELETE_BUS = "DELETE_BUS";
 export const GET_SEAT_TYPES = "GET_SEAT_TYPES";
 export const SET_SEAT_TYPES = 'SET_SEAT_TYPES';
 export const SEAT_TYPES_LOADING = "SEAT_TYPES_LOADING";
 export const GET_SEAT_GROUP_IDS = "GET_SEAT_GROUP_IDS";
 export const SET_SEAT_GROUP_IDS = "SET_SEAT_GROUP_IDS";
 export const GET_BUS_TEMPALTE = "GET_BUS_TEMPALTE";
 export const BUSES_TEMPALTE_INFO = "BUSES_TEMPALTE_INFO";
 
 export const INSERT_TRIP_ACTION = 'INSERT_TRIP_ACTION';
 export const UPDATE_RECENT_SCHEDULE_ID = 'UPDATE_RECENT_SCHEDULE_ID';
 export const GET_ROUTES_BY_TRIP_ID_ACTION = 'GET_ROUTES_BY_TRIP_ID_ACTION';
 export const UPDATE_ROUTES = 'UPDATE_ROUTES';
 export const ADD_NEW_ROUTE = 'ADD_NEW_ROUTE';
 
 export const UPDATE_LOGIN_COMPONENT = 'UPDATE_LOGIN_COMPONENT';
 export const LOG_OUT_ACTION = 'LOG_OUT_ACTION';
 export const LOG_IN_ACTION = 'LOG_IN_ACTION';
 export const CREATE_ROUTES_ACTION = "CREATE_ROUTES_ACTION";
 export const GET_BOARDING_POINTS_BY_SCHEDULE_ID = "GET_BOARDING_POINTS_BY_SCHEDULE_ID";
 export const UPDATE_BOARDING_POINTS ="UPDATE_BOARDING_POINTS";
 export const CREATE_BOARDING_POINTS_ACTION ="CREATE_BOARDING_POINTS_ACTION";
 export const SET_BOARDING_POINTS_LOCATIONS ="SET_BOARDING_POINTS_LOCATIONS";
 export const SET_DROP_POINTS_LOCATIONS="SET_DROP_POINTS_LOCATIONS";
 export const CREATE_DROP_POINTS = "CREATE_DROP_POINTS";
 export const UPDATE_DROP_POINTS = "UPDATE_DROP_POINTS";
 export const GET_DROP_POINTS_BY_SCHEDULE_ID = "GET_DROP_POINTS_BY_SCHEDULE_ID";
 export const SET_WEB_APP_READY = "SET_WEB_APP_READY";
 
 // schedules
 export const SET_ACTIVE_SCHEDULES_LOADING= "SET_ACTIVE_SCHEDULES_LOADING";
 export const SET_IN_ACTIVE_SCHEDULES_LOADING = "SET_IN_ACTIVE_SCHEDULES_LOADING";
 export const GET_ACTIVE_SCHEDULES = 'GET_ACTIVE_SCHEDULES';
 export const GET_IN_ACTIVE_SCHEDULES = 'GET_IN_ACTIVE_SCHEDULES';
 export const SET_ACTIVE_SCHEDULES = 'SET_ACTIVE_SCHEDULES';
 export const SET_IN_ACTIVE_SCHEDULES = 'SET_IN_ACTIVE_SCHEDULES';
 export const ACTIVATE_SCHEDULES = 'ACTIVATE_SCHEDULES';
 export const BLOCK_SCHEDULES = 'BLOCK_SCHEDULES';
 export const REMOVE_SCHEDULES = "REMOVE_SCHEDULES";
 
 //trips
 export const GET_SCHEDULE_BY_ID ="GET_SCHEDULE_BY_ID";
 export const GET_TRIPS="GET_TRIPS";
 export const SET_TRIPS ="SET_TRIPS";
 export const DELETE_TRIP="DELETE_TRIP";
 export const DELETE_ROUTE_BY_ID="DELETE_ROUTE_BY_ID";
 export const RESET_TRIPS_DATA="RESET_TRIPS_DATA";
 export const SET_SELECTED_TRIP="SET_SELECTED_TRIP";
 export const DELETE_BOARDING_POINT = "DELETE_BOARDING_POINT";
 export const UPDATE_BOARDING_POINT = "UPDATE_BOARDING_POINT"
 export const DELETE_DROP_POINT="DELETE_DROP_POINT";
 export const UPDATE_DROP_POINT="UPDATE_DROP_POINT";
 export const CLEAR_SELECTED_TRIP="CLEAR_SELECTED_TRIP";
 export const UPDATE_TRIP = " UPDATE_TRIP";
 export const RESET_SEAT_INFO ="RESET_SEAT_INFO";
 export const UPDATE_ROUTES_FARE ="UPDATE_ROUTES_FARE";
 export const SET_TRIPS_LOADING = "SET_TRIPS_LOADING";
 export const SET_ROUTES_LOADING = "SET_ROUTES_LOADING";
 export const SET_BOARDING_LOADING = "SET_BOARDING_LOADING";
 export const SET_DROP_LOADING = "SET_DROP_LOADING";
 export const CLONE_TRIP = "CLONE_TRIP";
 export const CLONE_AS_RETURN_TRIP = "CLONE_AS_RETURN_TRIP";
 export const INSERT_BOARDING_POINTS_INTO_TEMPLATE = "INSERT_BOARDING_POINTS_INTO_TEMPLATE";
 export const INSERT_DROP_POINTS_INTO_TEMPLATE ="INSERT_DROP_POINTS_INTO_TEMPLATE";
 
 //vechicles
 export const GET_VECHICLES = "GET_VECHICLES";
 export const SET_VECHICLES = "SET_VECHICLES";
 export const UPDATE_VECHICLES = "UPDATE_VECHICLES";
 export const CREATE_VECHICLE = "CREATE_VECHICLE";
 export const DELETE_VECHICLE = "DELETE_VECHICLE";
 
 //gst 
 export const UPDATE_GST="UPDATE_GST";
 export const  GET_GST="GET_GST";
 export const SET_GST = 'SET_GST';
 
 //price list
 export const GET_SCHDULES_BY_DATE_FOR_PRICE_LIST="GET_SCHDULES_BY_DATE_FOR_PRICE_LIST";
 export const SET_SCHDULES_BY_DATE_FOR_PRICE_LIST="SET_SCHDULES_BY_DATE_FOR_PRICE_LIST";
 export const GET_SCHEDULE_GROUP="GET_SCHEDULE_GROUP";
 export const SET_SCHEDULE_GROUP="SET_SCHEDULE_GROUP";
 export const CREATE_SCHEDULE_GROUP = "CREATE_SCHEDULE_GROUP";
 export const DELETE_SCHEDULE_GROUP = "DELETE_SCHEDULE_GROUP";
 export const UPDATE_SCHEDULE_GROUP = "UPDATE_SCHEDULE_GROUP";
 
 //user privillege
 export const CREATE_USER_PRIVILLEGE="CREATE_USER_PRIVILLEGE";
 export const DELETE_USER_PRIVILLEGE="DELETE_USER_PRIVILLEGE";
 export const UPDATE_USER_PRIVILLEGE="UPDATE_USER_PRIVILLEGE";
 export const GET_USER_PRIVILLEGE="GET_USER_PRIVILLEGE";
 export const SET_PRIVILLEGE_INFO="SET_PRIVILLEGE_INFO";
 export const GET_AGENT_PRIVILEGES = "GET_AGENT_PRIVILEGES";
 export const SET_AGENT_PRIVILLEGES = "SET_AGENT_PRIVILLEGES";
 
 //home actions
 export const GET_SCHEDULES_BY_DATE="GET_SCHEDULES_BY_DATE";
 export const SET_DASH_BOARD_SCHEDULES ="SET_DASH_BOARD_SCHEDULES";
 export const SET_DASH_BOARD_LOADING ="SET_DASH_BOARD_LOADING";
 
 export const RE_NAME_SEAT_NAME = "RE_NAME_SEAT_NAME"
 export const RESET_BUSES = "RESET_BUSES"
 
 export const RESET_ALL = 'RESET_ALL';
 
 //refresh cache 
 export const REFRESH_CACHE = "REFRESH_CACHE"; 
 
 //bus operators 
 
 export const GET_BUS_OPERATORS = "GET_BUS_OPERATORS";
 export const SET_BUS_OPERATORS = "SET_BUS_OPERATORS";
 export const SET_BUS_OPERATORS_LOADING = "SET_BUS_OPERATORS_LOADING";
 export const SET_ADD_BUS_OPERATOR_RUNNING="SET_ADD_BUS_OPERATOR_RUNNING";
 export const SET_UPDATE_BUS_OPERATOR_RUNNING ="SET_UPDATE_BUS_OPERATOR_RUNNING";
 export const SET_DELETE_BUS_OPERATOR_RUNNING ="SET_DELETE_BUS_OPERATOR_RUNNING";
 export const INSERT_BUS_OPERATOR = "INSERT_BUS_OPERATOR";
 export const DELETE_BUS_OPERATOR = "DELETE_BUS_OPERATOR";
 export const UPDATE_BUS_OPERATOR = "UPDATE_BUS_OPERATOR";
 export const UPDATE_BUS_DETAILS = "UPDATE_BUS_DETAILS";
 export const DELETE_SEAT = "DELETE_SEAT";
 export const SET_SEATER_TO_SLEEPER = "SET_SEATER_TO_SLEEPER";
 export const VALIDATE_SEAT = "VALIDATE_SEAT";
 export const FETCH_AMENITIES = "FETCH_AMENITIES";
 export const SET_AMENITIES = "SET_AMENITIES";
 export const FETCH_AMENITIES_BY_BUS_ID = "FETCH_AMENITIES_BY_BUS_ID";
 export const ADD_AMENITIES_TO_BUS = "ADD_AMENITIES_TO_BUS";
 
 //loading actions
 
 export const LOADING_ACTION = "LOADING_ACTION";
 
 //travel mangement actions 
 
 export const GET_TRAVEL_MANAGEMENT_ACTIONS = "GET_TRAVEL_MANAGEMENT_ACTIONS";
 export const SET_TRAVEL_MANAGEMENT = "SET_TRAVEL_MANAGEMENT";
 export const SET_TRAVEL_MANAGEMENT_LOADING = "SET_TRAVEL_MANAGEMENT_LOADING";
 export const UPDATE_TRAVEL_MANAGEMENT_ACTION = "UPDATE_TRAVEL_MANAGEMENT_ACTION";
 export const CREATE_TRAVEL_MANAGEMENT_ACTION = "CREATE_TRAVEL_MANAGEMENT_ACTION";
 export const DELETE_TRAVEL_MANAGEMENT_ACTION = "DELETE_TRAVEL_MANAGEMENT_ACTION";
 export const SET_ADD_TRAVEL_MANAGEMENT_RUNNING="SET_ADD_TRAVEL_MANAGEMENT_RUNNING";
 export const SET_UPDATE_TRAVEL_MANAGEMENT_RUNNING ="SET_UPDATE_TRAVEL_MANAGEMENT_RUNNING";
 export const SET_DELETE_TRAVEL_MANAGEMENT_RUNNING ="SET_DELETE_TRAVEL_MANAGEMENT_RUNNING";

 export const CREATE_OPERATOR_MAPPING = "CREATE_OPERATOR_MAPPING";

 //Demand Days
 export const GET_CITIES = "GET_CITIES";
 export const SET_CITIES = "SET_CITIES";
 export const GET_DEMAND_DAYS = "GET_DEMAND_DAYS";
 export const SET_DEMAND_DAYS = "SET_DEMAND_DAYS";
 export const SET_DEMAND_DAYS_LOADING = "SET_DEMAND_DAYS_LOADING";
 export const INSERT_DEMAND_DAYS = "INSERT_DEMAND_DAYS";
 export const CLEAR_DEMAND_DAYS = "CLEAR_DEMAND_DAYS";
 
 //Admin Driver Mapping
 export const GET_ADMIN_DRIVER_BY_OPERATOR_CODE = "GET_ADMIN_DRIVER_BY_OPERATOR_CODE";
 export const GET_DRIVER_MANAGEMENT_BY_OPERATOR = "GET_DRIVER_MANAGEMENT_BY_OPERATOR";
 export const SET_DRIVER_MANAGEMENT_BY_OPERATOR = "SET_DRIVER_MANAGEMENT_BY_OPERATOR";
 export const GET_ALL_ADMIN_DRIVER = "GET_ALL_ADMIN_DRIVER";
 export const SET_ALL_ADMIN_DRIVER = "SET_ALL_ADMIN_DRIVER";
 export const ADD_ADMIN_DRIVER = "ADD_ADMIN_DRIVER";
 
 