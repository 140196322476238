import React, { useEffect, useState }  from 'react';
import { getBusDetailsAction, getBusTemplateAction, fetchAmenitiesAction } from  '../../../actions/bus-actions';
import { useDispatch , useSelector} from 'react-redux';
import { busReducerSelector } from '../../../selectors/bus-selectors';
import { BusTable } from '.././bus-table-component/bus-table.component'; 
import{ BusForm } from '../bus-form-component/bus-form.component';
import { seatGroupIdsSelector } from './../../../selectors/seat-group.selector';

export const BusRoot = (props) => {

    const dispatch = useDispatch();
    const busReducer = useSelector(busReducerSelector);
    const seatGroupsIds=useSelector(seatGroupIdsSelector);
    const [formData,setFormData]  =useState([]);

    useEffect(()=>{
        dispatch(getBusDetailsAction());
        dispatch(getBusTemplateAction())
        dispatch(fetchAmenitiesAction());
    },[]);

    return (
        <div>
            <BusTable
            {...busReducer}
            formData={formData}
            setFormData={setFormData}
            />
            <BusForm 
            formData={formData}
            setFormData={setFormData}
            {...busReducer}
            seatGroupsIds={seatGroupsIds}
            />
        </div>
    );
}
