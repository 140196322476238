import React from 'react';
import { WrappedLoginForm } from './login-form';
import './login-component.style.scss';

export const LoginComponent = props => {

  return (
      <div>
             <WrappedLoginForm
             />
      </div>
  );
};
