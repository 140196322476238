import React , { useEffect, useState} from 'react';
import {  useSelector} from 'react-redux';
import {agentsReducerSelector} from '../../../selectors/agents-selectors';
import { AgentColorForm } from '../agent-color-form-component/agent-color-form.component';
import { agentColorReducerSelector } from '../../../selectors/agent-color-selectors';
import { useDispatch } from 'react-redux';
import { getAgentColorDetails } from '../../../actions/agent-color.actions';

export const AgentsRootComponent = (props) => {


    const dispatch = useDispatch();

    const {
        defaultLoadAgents,
        operatorCode,
        loadData
    } = props;

    const agentsReducer = useSelector(agentsReducerSelector);
    const [ formData,updateformData  ] = useState({});

    const agentColorReducer = useSelector(agentColorReducerSelector)

    useEffect(() => {
        dispatch(getAgentColorDetails(global.operator_code));
    },[]);

    useEffect(()=>{
        if(defaultLoadAgents){
            loadData(operatorCode);
        }
    },[])

    return (
        <div>
                <AgentColorForm
                {...agentsReducer}
                formData={formData}
                updateformData={updateformData}
                operatorCode={operatorCode}
                {...agentColorReducer}
                />
        </div>
    );
}
