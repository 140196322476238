import React , { useState} from 'react';
import { Row , Typography  , Modal , Button , Col, message} from 'antd';
import { TableCalenderCell } from '../schedule-calender-cell-component/schedule-calender-cell.component';
import { getAPIDateFormat } from '../../../utils/dates-util';
import { blockSchedules ,activateSchedules} from '../../../actions/schedule-actions';
import { useDispatch } from 'react-redux';
import { showConfirm } from './edit-days-for-schedules.module';
import { SHOW_DISABLE_API_AND_ACTIVATE_BUTTON } from '../../../app.config';
import { DatesSelector } from './../dates-selector/dates-selector.component';
import { isValidArray } from '../../../utils/utilities';
import './edit-days-for-schedules.style.scss';
import { validateTripAction } from '../../trip-components/validate-trip-button/validate-trip-button.module';


const { Text } = Typography;

export const EditActivateDays = (props) => {

    const {
        record,
        scheduleId,
        selectedDate
    } = props;

    const dispatch=useDispatch();

    let today = getAPIDateFormat(new Date());

    const [ visible , setVisible] = useState(false);
    // const [ dates , setDates] = useState([]);
    // const [ rangeValue , setRangeValue] = useState([]);
    const [ reload , setReload]=useState(0);
    const  [ selectedDays , setSelectedDays] = useState([]);

    const showModal = () => {
              setVisible(true)
      };
    
     const handleOk = async (e) => {
        setVisible(false)
      };
    
     const handleCancel = e => {
        setVisible(false)
      };

      const onSuccess=()=>{
        let increment = reload+1;
        setReload(increment);
      }
    
      const onActivateSchedule =(visibleOutside)=>{
        validateTripAction(scheduleId,(responceData)=>{
            let warningsList=[];
            if(responceData.length>0){
                let i=1;
                responceData.forEach(text => {
                    warningsList.push(<div>{i}. {text}</div>);
                    warningsList.push(<br />);
                    i=i+1;
                });
                let content = <div>

                    </div>
            showConfirm(()=>{
              dispatch(activateSchedules(scheduleId,visibleOutside,selectedDays,onSuccess));
            },(
                <div>
                 {warningsList}
                </div>
              ),null,'Are you sure to proceed?');
          }else{
            dispatch(activateSchedules(scheduleId,visibleOutside,selectedDays,onSuccess));
          }
        },()=>{
          dispatch(activateSchedules(scheduleId,visibleOutside,selectedDays,onSuccess));
        });
      }

      const onBlockFailure = (data)=>{              
        onSuccess();
        if(data.search("Failed to block trips as tickets exists")!=-1){
          showConfirm(()=>{
            dispatch(blockSchedules(scheduleId,selectedDays,'',true,onSuccess,()=>{
              console.log("empty call");
            }));
          },data + " Are you sure you want to block seats for those days and block rest of the trips.",'')
        }else{
          message.error(data);
        }
      }

      let enableButton=isValidArray(selectedDays)&&selectedDays.length>0?false:true;

    return (
     <div>
        <Button type="primary" onClick={showModal} >
        Edit active days
        </Button>
            <Modal
            title={record.description}
            visible={visible}
            onOk={handleOk}
            okText = {''}
            onCancel={handleCancel}
            okButtonProps={''}
            className="activate-popup"
            >
              <Row>
              <Col span={8}>
                      <DatesSelector 
                            record={record}
                            scheduleId={scheduleId}
                            setSelectedDays={setSelectedDays}
                            selectedDays={selectedDays}
                      />
                </Col>
                <Col span={16}>
                    <TableCalenderCell
                              data={record.trips}
                              className="table_calender_big_internal"
                              scheduleId={scheduleId}
                              selectedDate={selectedDate}
                              showNavigation={true}
                              selectRange={false}
                              // rangeValue={rangeValue}
                              reload={reload}
                            />
                  </Col>
              </Row>
              <Row type="flex" style={{margin:'10px'}}>
                  {SHOW_DISABLE_API_AND_ACTIVATE_BUTTON?  
                  <Col>
                            <Button
                            style={{background:"#4A90E2"}}
                            onClick={()=>{
                              onActivateSchedule(false);
                            }}
                            size={"large"}
                            disabled={enableButton}>
                                      <Text strong style={{color:'white'}}>Disable Api And Activate</Text>
                            </Button>
                    </Col>
                    :null  
                  }
                    <Col offset={1}>
                            <Button
                            style={{background:"#B8E986"}}
                            onClick={()=>{
                              onActivateSchedule(true);
                            }}
                            size={"large"}
                            disabled={enableButton}>
                                      <Text strong style={{color:'white'}}>Activate</Text>
                            </Button>
                    </Col>
                    <Col offset={1}>
                            <Button
                            style={{background:"#D0021B"}}
                            onClick={()=>{
                              dispatch(blockSchedules(scheduleId,selectedDays,'',false,onSuccess,onBlockFailure));
                            }}
                            size={"large"}
                            disabled={enableButton}>
                                      <Text strong style={{color:'white'}}>Block</Text>
                            </Button>
                    </Col>
              </Row>
            </Modal>
      </div>
    );
}
