import { getAPIDateFormat, isSameOrAfter } from "../../../utils/dates-util";
import { isValidArray } from "../../../utils/utilities";


export const formateDataForDayWise = (data)=>{
    let  formateData={};
      if(isValidArray(data)){
        data.forEach((row)=>{
          formateData[getAPIDateFormat(row)]={'visible_outside': 1, "is_active": isSameOrAfter(row)?1:0};
        });
      }
      return formateData;
}

export const getDayStatus =(apidate,formatedData)=>{
    if(formatedData[apidate]!=undefined&&formatedData[apidate].visible_outside==1&&formatedData[apidate].is_active==1){
          return "active-status"
    }
//     else if (formatedData)
//     else if (formatedData[apidate]!=undefined&&formatedData[apidate].visible_outside==0&&formatedData[apidate].is_active==1) {
//           return "disable-api-status"
//     }
    else if (isSameOrAfter(apidate)) {
      return ""
    }
    else{
          return "not-active-status"
    }
}

