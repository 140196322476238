import React, {useState}  from 'react';
import "../assets/styles/main.scss";
import { CancellationPolicyRootComponent } from '../components/cancellation-policy-component/cancellation-policy-root-component/cancellation-policy-root.component';
import { Typography , Col , Button , Form } from 'antd';
import { VerifyPagePermission } from '../components/verify-page-permission-component/verify-page-permission.component';
import Permissions from "../utils/permissions-constant";
import { TravelsSelect } from '../components/travels-select-component/travels-select.component';
import { getCancellationPolicyDetails } from '../actions/cancellation-policy.actions';
import { useDispatch, useSelector } from 'react-redux';

const { Title, Text } = Typography;

export const CancellationPolicyPage = (props) => {

    const [form] = Form.useForm();
    
    const dispatch = useDispatch();

    const [operatorData, setOperatorData] = useState(null);

    const onSelect=(data)=>{
      setOperatorData(data);
      loadData(data);
    }
  
    const loadData=(code)=>{
      dispatch(getCancellationPolicyDetails(code));
    }

const handleSubmit = (e,buttonType) => {
    e.preventDefault();
    form.validateFields()
    .then(values => {
      if(buttonType=='get'){
        console.log({values});
         dispatch(getCancellationPolicyDetails(operatorData.operator_code))
        }
    })
    .catch(errorInfo => {
      console.log({errorInfo});
    })
 };


    const renderChildren =     <div style={{position:'relative',top:'10px'  , margin: '20px'}}>
                                    <Title level={4}>Cancellation Policy</Title>
                                    <Col>
                                    <Form>
                                    <TravelsSelect
                                  isFormItem={true}
                                  showingFiled="operator_code"
                                  onSelect = {onSelect}
                              />
                              
                              <Form.Item wrapperCol={{ offset: 8, span: 16 }}
                              >
                        <Button
                        style={{margin:'5px',background:'#2EC17C'}}
                        onClick={(e)=>{
                         handleSubmit(e,'get');
                        }}      
                        >
                        <Text>Get</Text>
                      </Button>
                              </Form.Item>
                              </Form>
                              <CancellationPolicyRootComponent />
                                </Col>
                                </div>

    return (
        <VerifyPagePermission 
        pagePermission={Permissions.pagePermission.ADMIN_VIEW_CANCELLATION_POLICY_PAGE}
        renderChildren={renderChildren}
        onPermissionSuccess={()=>{
  
        }}
        />
    );
}