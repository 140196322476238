
/**
 * All sagas for Address Saga must be defined here
 * @author Manne Hareesh
 */

import { takeLatest,put,call,all,fork } from 'redux-saga/effects'
import { INSERT_TRIP_ACTION ,
         GET_ROUTES_BY_TRIP_ID_ACTION,
         UPDATE_ROUTES,
         CREATE_ROUTES_ACTION,
         GET_BOARDING_POINTS_BY_SCHEDULE_ID,
         UPDATE_BOARDING_POINTS,
         CREATE_BOARDING_POINTS_ACTION,
         CREATE_DROP_POINTS,
         GET_DROP_POINTS_BY_SCHEDULE_ID,
         UPDATE_DROP_POINTS,
         DELETE_TRIP,
         GET_TRIPS,
         SET_TRIPS,
         DELETE_ROUTE_BY_ID,
         DELETE_BOARDING_POINT,
         UPDATE_BOARDING_POINT,
         DELETE_DROP_POINT,
         UPDATE_DROP_POINT,
         SET_SELECTED_TRIP,
         GET_SCHEDULE_BY_ID,
         UPDATE_TRIP,
         SET_TRIPS_LOADING,
         UPDATE_ROUTES_FARE,
         SET_DROP_LOADING,
         SET_BOARDING_LOADING,
         SET_ROUTES_LOADING,
         CLONE_TRIP,
         LOADING_ACTION,
         INSERT_BOARDING_POINTS_INTO_TEMPLATE,
         INSERT_DROP_POINTS_INTO_TEMPLATE,
         CLONE_AS_RETURN_TRIP
      } from '../actions/action-types';
import { message } from 'antd';

export function* insertDropPointsIntoTemplate(action){
  try{
        yield put({
            type: LOADING_ACTION,
            payload: true
        });
            const {
              api,
              url ,
              apiPayload,
              onSuccess
              } = {...action.payload};
          const apiResults = yield call(api , url,apiPayload);
          if(apiResults.status=="SUCCESS"){
            let clientPayload = apiResults.payload;
            if(clientPayload.Status=='SUCCESS'){
                message.success(clientPayload.Message);
                onSuccess();
              }else{
                message.error(clientPayload.Message);
            }
          }else{
                message.error("Unable to save drop in template");
          }
        yield put({
          type: LOADING_ACTION,
          payload: false
        });
  }catch(error){
          yield put({
            type: LOADING_ACTION,
            payload: false
        });
        message.error("Unable to add drop points to template!")
        console.error("Error in inserting boarding points",error);
    }
}

function* watchInsertDropPointsIntoTemplateSaga () {
  yield takeLatest(INSERT_DROP_POINTS_INTO_TEMPLATE,insertDropPointsIntoTemplate)
}

export function* insertBoardingPointsIntoTemplate(action){
  try{
    yield put({
        type: LOADING_ACTION,
        payload: true
    });
      const {
        api,
        url ,
        apiPayload,
        onSuccess
        } = {...action.payload};
    const apiResults = yield call(api , url,apiPayload);
    if(apiResults.status=="SUCCESS"){
      let clientPayload = apiResults.payload;
      if(clientPayload.Status=='SUCCESS'){
          message.success(clientPayload.Message);
          onSuccess();
        }else{
          message.error(clientPayload.Message);
      }
    }else{
          message.error("Unable to save boarding in template");
    }
    yield put({
      type: LOADING_ACTION,
      payload: false
  });
  }catch(error){
        console.error("Error in inserting boarding points",error);
        message.error("Unable to save boarding in template");
        yield put({
          type: LOADING_ACTION,
          payload: false
      });
    }
}

function* watchInsertBoardingPointsIntoTemplateSaga () {
  yield takeLatest(INSERT_BOARDING_POINTS_INTO_TEMPLATE,insertBoardingPointsIntoTemplate)
}

export  function* cloneTrip(action){
  try{
    yield put({
      type: LOADING_ACTION,
      payload: true
  });
    const {
        api,
        url ,
        apiPayload,
        onSuccess
        } = {...action.payload};
    const apiResults = yield call(api , url,apiPayload);
    if(apiResults.status=="SUCCESS"){
        let clientPayload = apiResults.payload;
        if(clientPayload.Status=='SUCCESS'){
           message.success(clientPayload.Message);
           onSuccess();
          }else{
          message.error(clientPayload.Message);
        }
      }else{
        message.error("Unable to clone trip`");
      }
      yield put({
        type: LOADING_ACTION,
        payload: false
    });
   }catch(error){
        console.error("Error in clone trip",error);
        yield put({
          type: LOADING_ACTION,
          payload: false
      });
    }  
}

function* watchCloneTripSaga () {
  yield takeLatest(CLONE_TRIP,cloneTrip)
}

export  function* cloneAsReturnTrip(action){
  try{
    yield put({
      type: LOADING_ACTION,
      payload: true
  });
    const {
        api,
        url ,
        apiPayload,
        onSuccess
        } = {...action.payload};
    const apiResults = yield call(api , url,apiPayload);
    if(apiResults.status=="SUCCESS"){
        let clientPayload = apiResults.payload;
        if(clientPayload.Status=='SUCCESS'){
           message.success(clientPayload.Message);
           onSuccess();
          }else{
          message.error(clientPayload.Message);
        }
      }else{
        message.error("Unable to clone as return trip`");
      }
      yield put({
        type: LOADING_ACTION,
        payload: false
    });
   }catch(error){
        console.error("Error in clone as return trip",error);
        yield put({
          type: LOADING_ACTION,
          payload: false
      });
    }  
}

function* watchCloneAsReturnTripSaga () {
  yield takeLatest(CLONE_AS_RETURN_TRIP,cloneAsReturnTrip)
}

export function* updateRoutes(action){
  try{
    yield put({
      type: LOADING_ACTION,
      payload: true
  });
    const {
        api,
        url ,
        apiPayload,
        onSuccess
        } = {...action.payload};
    const apiResults = yield call(api , url,apiPayload);
    if(apiResults.status=="SUCCESS"){
        let clientPayload = apiResults.payload;
        if(clientPayload.Status=='SUCCESS'){
           message.success(clientPayload.Message);
           onSuccess();
          }else{
          message.error(clientPayload.Message);
        }
      }else{
        message.error("Unable to update Routes");
      }
      yield put({
        type: LOADING_ACTION,
        payload: false
    });
   }catch(error){
      yield put({
        type: LOADING_ACTION,
        payload: false
    });
        console.error("Error in update trip",error);
    }  
}

function* watchUpdateRoutesSaga () {
  yield takeLatest(UPDATE_ROUTES_FARE,updateRoutes)
}

export function* updateTrip(action){
  try{
    yield put({
      type: LOADING_ACTION,
      payload: true
  });
    const {
        api,
        url ,
        apiPayload,
        onSuccess
        } = {...action.payload};
    const apiResults = yield call(api , url,apiPayload);
    if(apiResults.status=="SUCCESS"){
        let clientPayload = apiResults.payload;
        if(clientPayload.Status=='SUCCESS'){
           message.success(clientPayload.Message);
           onSuccess();
        }else{
          message.error(clientPayload.Message);
        }
      }else{
        message.error("Unable to update");
      }
      yield put({
        type: LOADING_ACTION,
        payload: false
    });
   }catch(error){
          yield put({
            type: LOADING_ACTION,
            payload: false
        });
        console.error("Error in update trip",error);
    }  
}

function* watchUpdateTripSaga () {
  yield takeLatest(UPDATE_TRIP,updateTrip)
}

export function* getTripById(action){
  try{
    const {
        api,
        url ,
        apiPayload
        } = {...action.payload};
    const apiResults = yield call(api , url,apiPayload);
    if(apiResults.status=="SUCCESS"){
        let clientPayload = apiResults.payload;
        if(clientPayload.Status=='SUCCESS'){
           message.success(clientPayload.Message);
           yield put({
            type: SET_SELECTED_TRIP,
            payload: clientPayload.payload.schedule_details.schedule
        });
        }else{
          message.error(clientPayload.Message);
        }
      }
   }catch(error){
        console.error("Error in update drop point",error);
    }  
}

function* watchGetTripByIdSaga () {
  yield takeLatest(GET_SCHEDULE_BY_ID,getTripById)
}


export function* updateDropPoint(action){
  try{
    yield put({
      type: LOADING_ACTION,
      payload: true
  });
    const {
        api,
        url ,
        apiPayload,
        onSuccess
        } = {...action.payload};
    const apiResults = yield call(api , url,apiPayload);
    if(apiResults.status=="SUCCESS"){
        let clientPayload = apiResults.payload;
        if(clientPayload.Status=='SUCCESS'){
           message.success(clientPayload.Message);
           onSuccess();
        }else{
          message.error(clientPayload.Message);
        }
      }
      yield put({
        type: LOADING_ACTION,
        payload: false
    });
   }catch(error){
        yield put({
          type: LOADING_ACTION,
          payload: false
        });
        console.error("Error in update drop point",error);
    }  
}

function* watchUpdateDropPointSaga () {
  yield takeLatest(UPDATE_DROP_POINT,updateDropPoint)
}

export function* deleteDropPoint(action){
  try{
      yield put({
        type: LOADING_ACTION,
        payload: true
    });
    const {
        api,
        url ,
        apiPayload,
        onSuccess
        } = {...action.payload};
    const apiResults = yield call(api , url,apiPayload);
    if(apiResults.status=="SUCCESS"){
        let clientPayload = apiResults.payload;
        if(clientPayload.Status=='SUCCESS'){
           message.success(clientPayload.Message);
           onSuccess();
        }else{
          message.error(clientPayload.Message);
        }
      }
      yield put({
        type: LOADING_ACTION,
        payload: false
    });
   }catch(error){
        yield put({
            type: LOADING_ACTION,
            payload: false
        });
        console.error("Error in delete drop point",error);
    }  
}
function* watchDeleteDropPointSaga () {
  yield takeLatest(DELETE_DROP_POINT,deleteDropPoint)
}

export function*  deleteBoardingPoints(action){
  try{
    yield put({
      type: LOADING_ACTION,
      payload: true
  });
   const {
       api,
       url ,
       apiPayload,
       onSuccess
       } = {...action.payload};
   const apiResults = yield call(api , url,apiPayload);
   if(apiResults.status=="SUCCESS"){
       let clientPayload = apiResults.payload;
       if(clientPayload.Status=='SUCCESS'){
          message.success(clientPayload.Message);
          onSuccess();
       }else{
         message.error(clientPayload.Message);
       }
     }
     yield put({
      type: LOADING_ACTION,
      payload: false
  });
  }catch(error){
        yield put({
          type: LOADING_ACTION,
          payload: false
      });
       console.error("Error in delete boarding point",error);
   }
}

function* watchDeleteBoardingPointsSaga () {
   yield takeLatest(DELETE_BOARDING_POINT,deleteBoardingPoints)
}

export function*  updateBoardingPoints(action){
  try{
    yield put({
      type: LOADING_ACTION,
      payload: true
  });
   const {
       api,
       url ,
       apiPayload,
       onSuccess
       } = {...action.payload};
   const apiResults = yield call(api , url , apiPayload);
   if(apiResults.status=="SUCCESS"){
       let clientPayload = apiResults.payload;
       if(clientPayload.Status=='SUCCESS'){
          message.success(clientPayload.Message);    
          onSuccess();    
       }else{
         message.error(clientPayload.Message);
       }
     }
     yield put({
      type: LOADING_ACTION,
      payload: false
  });
  }catch(error){
      yield put({
        type: LOADING_ACTION,
        payload: false
    });
       console.error("Error in update boarding point",error);
   }
}

function* watchUpdateBoardingPointsSaga () {
   yield takeLatest(UPDATE_BOARDING_POINT,updateBoardingPoints)
}


export function* deleteRoutesById(action){
  try{
    yield put({
      type: LOADING_ACTION,
      payload: true
  });
    const {
        api,
        url ,
        apiPayload,
        onSuccess
        } = {...action.payload};
    const apiResults = yield call(api , url , apiPayload);
    if(apiResults.status=="SUCCESS"){
        let clientPayload = apiResults.payload;
        if(clientPayload.Status=='SUCCESS'){
           message.success(clientPayload.Message);
           onSuccess();
        }else{
          message.error(clientPayload.Message);
        }
      }
      yield put({
        type: LOADING_ACTION,
        payload: false
    });
}catch(error){
    yield put({
      type: LOADING_ACTION,
      payload: false
  });
    console.error("Error in removing routes",error);
}
}


function* watchDeleteRouteSaga () {
  yield takeLatest(DELETE_ROUTE_BY_ID,deleteRoutesById)
}


export function* deleteTripById(action){
    try{
      yield put({
        type: LOADING_ACTION,
        payload: true
    });
        const {
            api,
            url ,
            apiPayload,
            onSuccess
            } = {...action.payload};
        const apiResults = yield call(api , url , apiPayload);
        if(apiResults.status=="SUCCESS"){
            let clientPayload = apiResults.payload;
            if(clientPayload.Status=='SUCCESS'){
               message.success(clientPayload.Message);
               onSuccess();
            }else{
              message.error(clientPayload.Message);
            }
          }
          yield put({
            type: LOADING_ACTION,
            payload: false
        });
    }catch(error){
      yield put({
        type: LOADING_ACTION,
        payload: false
    });
        console.error("Error in getting trips",error);
    }
}

function* watcTripDeleteSaga () {
    yield takeLatest(DELETE_TRIP,deleteTripById)
 }


export function*  getTrips(action){
   try{ 
     
    yield put({
      type: SET_TRIPS_LOADING,
       payload:true
    });

    const {
        api,
        url,
        apiPayload
        } = {...action.payload};
    const apiResults = yield call(api , url , apiPayload);
    if(apiResults.status=="SUCCESS"){
        let clientPayload = apiResults.payload;
        if(clientPayload.Status=='SUCCESS'){
          let serverPayload = clientPayload.payload;
           message.success(clientPayload.Message);
           yield put({
            type: SET_TRIPS,
            payload: {trips:serverPayload.schedules}
        });
        }else{
          message.error(clientPayload.Message);
        }
      }
      yield put({
        type: SET_TRIPS_LOADING,
         payload: false
      });
  
   }catch(error){
        console.error("Error in getting trips",error);
        yield put({
          type: SET_TRIPS_LOADING,
           payload: false
        });
    }
}

function* watchGetTripsSaga () {
    yield takeLatest(GET_TRIPS,getTrips)
 }


export function* getDropLocationsByScheduleId(action){
    try{
      yield put({
        type: SET_DROP_LOADING,
         payload:true
      });
        const {
            api,
            url ,
            apiPayload
        } = {...action.payload};
        const apiResults = yield call(api , url , apiPayload);
        if(apiResults.status=="SUCCESS"){
            let clientPayload = apiResults.payload;
            if(clientPayload.Status=='SUCCESS'){
              let serverPayload = clientPayload.Payload;
               message.success(clientPayload.Message);
              yield put({
                  type: UPDATE_DROP_POINTS,
                  payload: {dropPoints:serverPayload.dropLocations}
              });
            }else{
              message.error(clientPayload.Message);
            }
          } 
          yield put({
            type: SET_DROP_LOADING,
             payload:false
          });
    }
    catch(error){
      yield put({
        type: SET_DROP_LOADING,
         payload:false
      });
        console.error("Error in resetting password",error);
    }
}

function* watchGetDropLocationsByScheduleIdSaga () {
    yield takeLatest(GET_DROP_POINTS_BY_SCHEDULE_ID,getDropLocationsByScheduleId)
 }

/**
 * 
 * @param {insert drop points} action 
 */

 export function* createDropPoint(action){
    try{
      yield put({
        type: LOADING_ACTION,
        payload: true
    });
        const {
            api,
            url ,
            onSuccess,
            apiPayload
        } = {...action.payload};
        const apiResults = yield call(api , url , apiPayload);
        if(apiResults.status=="SUCCESS"){
            let clientPayload = apiResults.payload;
            if(clientPayload.Status=='SUCCESS'){
              message.success(clientPayload.Message);
              onSuccess();
            }else{
              message.error(clientPayload.Message);
            }
          }       
          yield put({
            type: LOADING_ACTION,
            payload: false
        });
    }
    catch(error){
      yield put({
        type: LOADING_ACTION,
        payload: false
    });
        console.error("Error in inserting drop point",error);
    }
 }

 function* watchCreateDropPointsSaga () {
    yield takeLatest(CREATE_DROP_POINTS,createDropPoint)
 }


/**
 * 
 * @param {insert boadring points } action 
 */

export function* createBoardingPoints(action){
    try{
      yield put({
        type: LOADING_ACTION,
        payload: true
    });
        const {
            api,
            url ,
            apiPayload,
            onSuccess
        } = {...action.payload};
        const apiResults = yield call(api , url , apiPayload);
        if(apiResults.status=="SUCCESS"){
            let clientPayload = apiResults.payload;
            if(clientPayload.Status=='SUCCESS'){
              message.success(clientPayload.Message);
              onSuccess();
            }else{
              message.error(clientPayload.Message);
            }
          }
          yield put({
            type: LOADING_ACTION,
            payload: false
        });
    }
    catch(error){
      yield put({
        type: LOADING_ACTION,
        payload: false
    });
        console.error("Error in inserting boarding point",error);
    }
}

function* watchCreateBoardingPointsSaga () {
    yield takeLatest(CREATE_BOARDING_POINTS_ACTION,createBoardingPoints)
 }

/**
 * get boarding points by schedule id
 * 
 */

 export function* getPickupLocationsByScheduleId(action){
    try{
      yield put({
        type: SET_BOARDING_LOADING,
         payload:true
      });
        const {
            api,
            url ,
            apiPayload
        } = {...action.payload};
        const apiResults = yield call(api , url , apiPayload);
        if(apiResults.status=="SUCCESS"){
            let clientPayload = apiResults.payload;
            if(clientPayload.Status=='SUCCESS'){
              let serverPayload = clientPayload.Payload;
               message.success(clientPayload.Message);
              yield put({
                  type: UPDATE_BOARDING_POINTS,
                  payload: {boardingPoints:serverPayload.pickupLocations}
              });
            }else{
              message.error(clientPayload.Message);
            }
          }
          yield put({
            type: SET_BOARDING_LOADING,
             payload:false
          });    
    }
    catch(error){
        console.error("Error in getting pickup points",error);
        yield put({
          type: SET_BOARDING_LOADING,
           payload:false
        });
    }
 }

 function* watchGetPickupLocationsByScheduleIdSaga () {
    yield takeLatest(GET_BOARDING_POINTS_BY_SCHEDULE_ID,getPickupLocationsByScheduleId)
 }

/**
 * 
 * @param {create routes} action 
 */

 export function* createRoutes(action){
     try{
      yield put({
        type: LOADING_ACTION,
        payload: true
    });
        const {
            api,
            url ,
            apiPayload,
            onSuccess
        } = {...action.payload};
        const apiResults = yield call(api , url , apiPayload);
        if(apiResults.status=="SUCCESS"){
            let clientPayload = apiResults.payload;
            if(clientPayload.Status=='SUCCESS'){
                   message.success(clientPayload.Message);
                   onSuccess();
            }else{
              message.error(clientPayload.Message);
            }
          }
          yield put({
            type: LOADING_ACTION,
            payload: false
        }); 
    }
    catch(error){
      yield put({
        type: LOADING_ACTION,
        payload: false
    });
        console.error("Error in resetting password",error);
    }
 }

function* watchCreateRoutesSaga () {
    yield takeLatest(CREATE_ROUTES_ACTION,createRoutes)
}


/**
 *
 * @param {Get Trip Details} action
 */
export function* insertTripDetails(action) {
    try{
      yield put({
        type: LOADING_ACTION,
        payload: true
    });
        const {
            api,
            url ,
            apiPayload,
            onSuccess
        } = {...action.payload};
        const apiResult = yield call(api , url , apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload = apiResult.payload;
          if(clientPayload.Status=='SUCCESS'){
            let serverPayload = clientPayload.payload;
            message.success(clientPayload.Message);
            onSuccess(serverPayload.scheduleId);
          }else{
            message.error(clientPayload.Message);
          }
        }
        yield put({
          type: LOADING_ACTION,
          payload: false
      });
    }
    catch(error){
      yield put({
        type: LOADING_ACTION,
        payload: false
    });
        console.error("Error in resetting password",error);
    }
}

/**
 *
 * @param {Get Routes Details} action
 */
export function* getRoutesDetails(action) {
    try{
      yield put({
        type: SET_ROUTES_LOADING,
         payload:true
      });
        const {
            api,
            url ,
            apiPayload
        } = {...action.payload};
        const apiResult = yield call(api , url , apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload = apiResult.payload;
          if(clientPayload.Status=='SUCCESS'){
            let serverPayload = clientPayload.Payload;
            message.success(clientPayload.Message);
            yield put({
                type: UPDATE_ROUTES,
                payload: serverPayload.routes
            });
          }else{
            message.error(clientPayload.Message);
          }
        }
        yield put({
          type: SET_ROUTES_LOADING,
           payload:false
        });
    }
    catch(error){
      yield put({
        type: SET_ROUTES_LOADING,
         payload:false
      });
        console.error("Error in resetting password",error);
    }
}

function* watchInsertTripDetails () {
    yield takeLatest(INSERT_TRIP_ACTION,insertTripDetails)
}

function* watchGetRouteDetails () {
    yield takeLatest(GET_ROUTES_BY_TRIP_ID_ACTION,getRoutesDetails)
}

export function*  watchTripsDetailsPage() {
    yield all([
        fork(watchInsertTripDetails),
        fork(watchGetRouteDetails),
        fork(watchCreateRoutesSaga),
        fork(watchGetPickupLocationsByScheduleIdSaga),
        fork(watchCreateBoardingPointsSaga),
        fork(watchCreateDropPointsSaga),
        fork(watchGetDropLocationsByScheduleIdSaga),
        fork(watchGetTripsSaga),
        fork(watcTripDeleteSaga),
        fork(watchDeleteRouteSaga),
        fork(watchUpdateBoardingPointsSaga),
        fork(watchDeleteBoardingPointsSaga),
        fork(watchUpdateDropPointSaga),
        fork(watchDeleteDropPointSaga),
        fork(watchGetTripByIdSaga),
        fork(watchUpdateTripSaga),
        fork(watchUpdateRoutesSaga),
        fork(watchCloneTripSaga),
        fork(watchInsertBoardingPointsIntoTemplateSaga),
        fork(watchInsertDropPointsIntoTemplateSaga),
        fork(watchCloneAsReturnTripSaga)
    ])
}
