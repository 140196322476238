import React, { useEffect, useState }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPrivilleges } from '../../../actions/user-privillege-actions';
import { userPrivillegeReducerSelector } from '../../../selectors/user-privillege-selector';
import { UserPrivillegeTable } from '../user-privillege-table/user-privillege-table.component';
import { UserPrivillegeForm} from '../user-privillege-form/user-privillege-form.component';
import { getAgentActions , getAgentPrivileges } from './user-privillege-root.module';

export const UserPrivillegeRoot = (props) => {

    const dispatch = useDispatch();
    const userPrivillegeReducer = useSelector(userPrivillegeReducerSelector);
    const [formData , setFormData] = useState({});
    const [agentActions , setAgentActions] = useState([]);
    const [agentPrivileges, setAgentPrivileges] = useState([]);



    useEffect(()=>{
            dispatch(getPrivilleges());
            getAgentActions(setAgentActions);
            getAgentPrivileges(setAgentPrivileges,false);
    },[]);

    return (
        <div>
                <UserPrivillegeTable 
                {...userPrivillegeReducer}
                formData={formData}
                setFormData={setFormData}
                />
                <UserPrivillegeForm 
                formData={formData}
                agentPrivileges={agentPrivileges}
                agentActions={agentActions}
                setFormData={setFormData}
                />
        </div>
    );
}
