import React , { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select ,Form } from 'antd';
import { getBusOperators } from  '../../actions/bus-operator-actions';
import { busOperatorReducerSelector} from '../../selectors/bus-operators-selector';
import { useDispatch , useSelector } from 'react-redux';
import { isValid } from '../../utils/utilities';
const { Option } = Select;


export const TravelsSelect = props => {

  const { 
    onSelect,
    isFormItem=false,
    showingFiled="name"
  }={...props};

  const dispatch = useDispatch();

  const {
    busOperators,
    isFetchingBusOperators
} = useSelector(busOperatorReducerSelector)

  useEffect(()=>{
    dispatch(getBusOperators());
 },[]);

 const printOptionOperator = (option) => {
   if(isValid(option[showingFiled])){
    return <Option  value={option[showingFiled]} data={option}>{option[showingFiled]}</Option>
   }else{
     return null
   }
  };

  if(isFormItem){
      return (
        <Form.Item
        label="Operator Code"
        name="operatorCode"
        rules={[{ required: true, message: 'Please input your operator code!' }]} 
        >
           <Select 
                    showSearch
                    placeholder="Select a travel"
                    loading={isFetchingBusOperators}
                    onChange={(value,option)=>{
                        onSelect(option.data)
                    }}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
            >
                    {busOperators.map(printOptionOperator)}
            </Select> 
        </Form.Item>
    )
  }

  return (
            <Select 
                    showSearch
                    placeholder="Select a travel"
                    loading={isFetchingBusOperators}
                    onChange={(value,option)=>{
                        onSelect(option.data)
                    }}
            >
                    {busOperators.map(printOptionOperator)}
            </Select> 
  );
};
