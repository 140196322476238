/**
 * @author Sanjay
 * @description Reducer for the components in home page
 */
 import {
    SET_BUS_OPERATORS,
    SET_BUS_OPERATORS_LOADING,
    RESET_ALL,
    SET_ADD_BUS_OPERATOR_RUNNING,
    SET_UPDATE_BUS_OPERATOR_RUNNING,
    SET_DELETE_BUS_OPERATOR_RUNNING
  } from '../actions/action-types';
import { sortJsonObject } from '../utils/utilities';
  
  const initialState = {
        busOperators:[],
        isFetchingBusOperators:true,
        isAddBusOperatorRunning:false,
        isDeleteBusOperatorRunning:false,
        isUpdateBusOperatorRunning:false
  }
  
  export const busOperatorReducer = (state = initialState,action) => {
      let newState = Object.assign({},state);
      switch (action.type) {
  
        case SET_BUS_OPERATORS:{
          let {
              busOperators
          } = action.payload
          newState["busOperators"] = sortJsonObject(busOperators,"name",false);
          return newState;
        }

        case SET_BUS_OPERATORS_LOADING:{
          newState["isFetchingBusOperators"] = action.payload;
          return newState;
        }

        case SET_ADD_BUS_OPERATOR_RUNNING:{
          newState["isAddBusOperatorRunning"] = action.payload;
          return newState;
        }

        case SET_UPDATE_BUS_OPERATOR_RUNNING:{
          newState["isUpdateBusOperatorRunning"] = action.payload;
          return newState;
        }

        case SET_DELETE_BUS_OPERATOR_RUNNING:{
          newState["isDeleteBusOperatorRunning"] = action.payload;
          return newState;
        }

        case RESET_ALL :
            newState["busOperators"] = null;
            newState["isFetchingBusOperators"] = true;
            newState["isAddBusOperatorRunning"] = false;
            newState["isDeleteBusOperatorRunning"] = false;
            newState["isUpdateBusOperatorRunning"] = false;
            return newState;
  
          default:
              return state;
      }
  }
  