import { Form , Input, Button , Select , Typography , Layout, Row, Col  } from 'antd';
import React, { useEffect, useState }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isValid, isValidArrayWithValues  } from '../../../utils/utilities';
import './vechicle-management-form.style.scss';
import { addAdminDriverAction, getDriverManagementByOperator } from '../../../actions/admin-driver-actions';
import { TravelsSelect } from '../../travels-select-component/travels-select.component';
import { adminDriverSelector } from '../../../selectors/admin-driver-selector';



const { Option } = Select;
const { TextArea } = Input;

const { Header, Content, Footer } = Layout;
const { Title , Text } = Typography;

export const  AdminDriverMappingForm = (props) => {
  
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [operator, setOperator] = useState(null);
  const [driverData, setDriverData] = useState(null);

  const adminDriverData = useSelector(adminDriverSelector);

  const { driverByOperator } = adminDriverData;
  
  const{
    formData,
    updateFormData,
  } = {...props};

  useEffect(() => {
    if (isValid(operator)) {
      dispatch(getDriverManagementByOperator(operator));
      setDriverData(null);
    }
  },[operator])

  
  const onSuccess=()=>{
    onReset();
  }
  
  const onReset = () => {
    updateFormData({});
    setDriverData(null);
    setOperator(null);
  };

  const printOptionOperator = (option, showingFiled) => {
    if(isValid(option[showingFiled])){
     return <Option  value={option[showingFiled]} data={option}>{option["phone_number"]}</Option>
    }else{
      return null
    }
   };
  
  const handleSubmit = (e,buttonType) => {
    e.preventDefault();
    form.validateFields()
    .then(values => {
      values.driver_phone = driverData;
      dispatch(addAdminDriverAction({...values}, onSuccess))
    })
    .catch(errorInfo => {
      console.log({errorInfo});
    })
  };
  
  useEffect(()=>{
    fillForm()
  },[formData])
  
  const fillForm=()=>{
    if(isValid(formData)){
      let {
        admin_phone,
        admin_name,
        operator_code,
        driver_management_id,
        driver_phone
      }=formData;
      form.setFieldsValue({
        admin_phone: admin_phone,
        admin_name: admin_name,
        operatorCode : operator_code,
        driver_management_id: driver_management_id
      })
      setOperator(operator_code);
      setDriverData(driver_phone);
    }
    else{
      onReset();
    }
  }
  
  
  let isRowClicked = isValid(formData)&&isValid(formData.admin_id)?true:false;
  
  return (
    <Layout>
      <Title level={4}>Admin Driver Mapping Form</Title>
      <Content style={{ margin:'10px'}} className='vechicle-content'>
        <Form
          className="form"
          form={form}
          name="basic"
          initialValues={{ }}
        >
          <Row>
            <Col span= {12}>
            <Form.Item
              label="Name"
              name="admin_name"
              rules={[{ required: true, message: 'Please input your name!' }]}
            >
              <Input
                placeholder="Name"
              />
            </Form.Item>
            </Col>
            <Col span={11} offset= {1}>
            <Form.Item
              label="Admin Phone"
              name="admin_phone"
              rules={[{ required: true, message: 'Please input your type!' }]}
            >
              <Input
                placeholder="Admin Phone"
                disabled = {isRowClicked}
              />
            </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span = {12}>
              <TravelsSelect
                onSelect={(data)=>{
                  let driverId = null;
                  if (isRowClicked&&data.operator_code==formData.operator_code) {
                    driverId = formData.driver_management_id
                  }
                  form.setFieldsValue({
                    driver_management_id: driverId
                  })
                  setOperator(data.operator_code);
                }}
                isFormItem={true}
                showingFiled="operator_code"
              />
            </Col>
            <Col span={11} offset= {1}>
            <Form.Item
              label="Driver Phone"
              name = "driver_management_id"
              rules={[{ required: true, message: 'Please select driver number to map!' }]}
            >
              <Select
                showSearch
                placeholder="Select driver"
                notFoundContent="No Driver available for operator"
                onChange={(value,option)=>{
                  setDriverData(option.data.phone_number)
              }}
              >
              {isValidArrayWithValues(driverByOperator)&&driverByOperator.map((option)=>printOptionOperator(option, "id"))}
              </Select>
            </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content>
      
      <Footer style={{ textAlign: 'center' }} type="flex">
        <Row type="flex" justify="space-around">
          <Button
            style={{margin:'5px',background:'#2EC17C'}}
            onClick={(e)=>{
              handleSubmit(e);
            }}>
            <Text>Add/Update</Text>
          </Button>
          <Button
            type="block"
            style={{margin:'5px'}}
            onClick={()=>{
              updateFormData({});
            }}
          >
            Clear
          </Button>
        </Row>
      </Footer>
    </Layout>
  );
}
