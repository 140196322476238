import React  from 'react';
import './navbar-menu.component.scss';
import { Menu } from 'antd';
import { pageRedirect, permissionCheck } from '../../../utils/utilities';
import { useNavigate } from "react-router-dom";
import Permissions from "../../../utils/permissions-constant";

export const NavbarMenu = props => {
  
  let history = useNavigate();

  let {userInfo} = props;
 

  return (
    <Menu
          mode="vertical"
          style={{lineHeight: '64px' , float:'right'}}
          breakpoint="lg" collapsedWidth="0">
            {permissionCheck(Permissions.pagePermission.ADMIN_VIEW_CITIES_PAGE)?
            <Menu.Item key="1" onClick={()=>{
              pageRedirect(history,'cities');
            }}>Cities</Menu.Item> 
            :
            null 
            }
            {permissionCheck(Permissions.pagePermission.ADMIN_VIEW_ADDRESS_PAGE)?
            <Menu.Item key="2" onClick={()=>{
              pageRedirect(history,'address');
            }}>Address Book</Menu.Item>
            :
            null
            }
            {permissionCheck(Permissions.pagePermission.ADMIN_VIEW_TRIPS_PAGE)?
            <Menu.Item key="4" onClick={()=>{
                    pageRedirect(history,'trips');
            }}>Trips</Menu.Item>
            :
            null
            }
            {permissionCheck(Permissions.pagePermission.ADMIN_VIEW_SCHEDULES_PAGE)?
            <Menu.Item key="5" onClick={()=>{
              pageRedirect(history,'schedules');
            }}>Schedules</Menu.Item>
            :
            null
            }
            {permissionCheck(Permissions.pagePermission.ADMIN_VIEW_AGENTS_PAGE)?
              <Menu.Item key="6" onClick={()=>{
                pageRedirect(history,'agents');
            }}>Agents
            </Menu.Item>
            :
            null
            }
            {permissionCheck(Permissions.pagePermission.ADMIN_VIEW_BUS_PAGE)?
            <Menu.Item key="8" onClick={()=>{
              pageRedirect(history,'buses');
            }}>Buses
            </Menu.Item>
            :
            null
            }
            {permissionCheck(Permissions.pagePermission.ADMIN_VIEW_GST_PAGE)?
            <Menu.Item key="9" onClick={()=>{
              pageRedirect(history,'gst');
            }}>Gst
            </Menu.Item>
            :
            null
            }
            {permissionCheck(Permissions.pagePermission.ADMIN_VIEW_VEHICLE_MANAGEMENT_PAGE)?
            <Menu.Item key="10" onClick={()=>{
              pageRedirect(history,'vechicle-managemment');
            }}>Vechicle Management
            </Menu.Item>
            :
            null
            }
            {permissionCheck(Permissions.pagePermission.ADMIN_VIEW_VEHICLE_ALLOCATION_PAGE)?
            <Menu.Item key="11" onClick={()=>{
               pageRedirect(history,'vechicle-allocation');
            }}>Vechicle Allocation
            </Menu.Item>
            :
            null
            }
            {permissionCheck(Permissions.pagePermission.ADMIN_VIEW_PRICE_PAGE)?
            <Menu.Item key="12" onClick={()=>{
                    pageRedirect(history,'price-list');
            }}>Price List
            </Menu.Item>
            :
            null
            }
            {permissionCheck(Permissions.pagePermission.ADMIN_VIEW_MASTER_PRICE_PAGE)?
            <Menu.Item key="13" onClick={()=>{
              pageRedirect(history,'master-price-list');
            }}>Master Price List
            </Menu.Item>
            :
            null
            }
            {permissionCheck(Permissions.pagePermission.ADMIN_VIEW_PRIVILAGES_PAGE)?
            <Menu.Item key="14" onClick={()=>{
              pageRedirect(history,'user-privillege');
            }}>User Privileges
            </Menu.Item>
            :
            null
            }
            {permissionCheck(Permissions.pagePermission.BUS_OPERATOR_PAGE)?
            <Menu.Item key="7" onClick={()=>{
              pageRedirect(history,'bus-operators');
            }}>Bus Operator
            </Menu.Item>
            :
            null
            }
            {permissionCheck(Permissions.pagePermission.ADMIN_VIEW_AGENTS_COLOR_PAGE)?
              <Menu.Item key="15" onClick={()=>{
                pageRedirect(history,'agent-portal-colors');
            }}>Agent Portal Colors
            </Menu.Item>
            :
            null
            }
            {permissionCheck(Permissions.pagePermission.ACTION_TO_VIEW_DEMAND_DAYS)?
              <Menu.Item key="18" onClick={()=>{
                pageRedirect(history,'demand-days');
            }}>Demand Days
            </Menu.Item>
            :
            null
            }
            {permissionCheck(Permissions.pagePermission.DAFIDY_USER_PAGE)&&
              <Menu.Item key="18" onClick={()=>{
                pageRedirect(history,'admin-driver-mapping');
            }}>Admin Driver Mapping
            </Menu.Item>
            }
             {permissionCheck(Permissions.pagePermission.ADMIN_TO_VIEW_TRAVEL_MANAGEMENT_PAGE)?
              <Menu.Item key="16" onClick={()=>{
                pageRedirect(history,'travel-management');
            }}>Travel Management
            </Menu.Item>
            :
            null
            }
            {permissionCheck(Permissions.pagePermission.ADMIN_VIEW_CANCELLATION_POLICY_PAGE)?
              <Menu.Item key="17" onClick={()=>{
                pageRedirect(history,'cancellation-policy');
            }}>Cancellation Policy
            </Menu.Item>
            :
            null
            }
    </Menu>

  );
};

NavbarMenu.defaultProps = {

};

NavbarMenu.propTypes = {

};