import React , { useEffect, useState  } from 'react';
import { Typography } from 'antd';
import moment from 'moment';
import { PriceListTable } from '../price-list-table/price-list-table.component';
import { PriceListHeader } from '../price-list-header/price-list-header.component';
import { getPriceListForDate } from '../../../actions/price-list-actions';
import { useDispatch , useSelector} from 'react-redux';
import { priceListSelector } from './../../../selectors/price-list-selector';

const { Title } = Typography;


export const PriceListRoot = (props) => {

    const [selectedDate,setDate] = useState(moment());
    const dispatch = useDispatch();
    const priceListReducer = useSelector(priceListSelector);

    useEffect(()=>{
        var date=moment(selectedDate).format('YYYY-MM-DD');
        dispatch(getPriceListForDate(date));
    },[selectedDate]);

    const {
        priceList
    } = priceListReducer;
    const [filteredData,setFilteredData] = useState([]);
    return (
        <div>
            <PriceListHeader
            selectedDate={selectedDate}
            setDate={setDate}
            unFilteredData={priceList}
            setFilteredData={setFilteredData}
            />
            <PriceListTable 
                 selectedDate={selectedDate}
                 priceList={filteredData}
            />
           
        </div>
    );
}