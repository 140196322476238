import { message } from 'antd';
import { CLONE_BUS_URL } from './../../../utils/api-url';
import { postCall } from "./../../../utils/network.util";

export const handleCloneBus=(busId,isAc,identifier,onSuccess=()=>{},onFailure=()=>{})=>{
        let ac = isAc?1:0;
      var cloneBus = postCall(CLONE_BUS_URL, {
        busId : busId,
        isAc : ac,
        identifier:identifier
      });
      cloneBus.then((data) => {
        if(data.status === "SUCCESS"&&data.payload.Status === "SUCCESS"){
          message.success(data.payload.Message);
          onSuccess();
        }else{
          onFailure();
        }
      })
      .catch((reason)=> {
        onFailure();
      })
  }