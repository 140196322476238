import { takeLatest,put,call,all,fork } from 'redux-saga/effects'
import {
    CREATE_AGENT_COLOR,
    UPDATE_AGENT_COLOR,
    SET_AGENT_COLOR,
    GET_AGENT_COLOR,
    LOADING_ACTION
} from '../actions/action-types';
import { message } from 'antd';


export function* getAgentColorDetails(action){
    try{
        const {
            api,
            url,
            apiPayload
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload=apiResult.payload;
         if(clientPayload.Status=='SUCCESS'){
           let serverPayload=clientPayload.Payload;
            yield put({
                type: SET_AGENT_COLOR,
                payload: serverPayload
            });
          }
        }
    }
    catch(error){
        console.error("Error in getting agent color",error);
    }
 }

 function* watchGetAgentColorSaga () {
    yield takeLatest(GET_AGENT_COLOR,getAgentColorDetails)
 }


 export function* createAgentColor(action){
    try{
        yield put({
            type:LOADING_ACTION,
            payload:true
        })
        const {
            api,
            url ,
            apiPayload,
            onSuccess
            } = {...action.payload};
        const apiResults = yield call(api , url,apiPayload);
        if(apiResults.status=="SUCCESS"){
            let clientPayload = apiResults.payload;
            if(clientPayload.Status=='SUCCESS'){
               message.success(clientPayload.Message);
               onSuccess();
            }else{
              message.error(clientPayload.Message);
            }
          }
          yield put({
            type:LOADING_ACTION,
            payload:false
        })
       }catch(error){
        yield put({
              type:LOADING_ACTION,
              payload:false
          })
            console.error("Error in creating agent colors",error);
        }  
    }

    function* watchCreateAgentColorSaga () {
        yield takeLatest(CREATE_AGENT_COLOR,createAgentColor)
     }

     export function* updateAgentColor(action){
        try{
          yield put({
              type:LOADING_ACTION,
              payload:true
          })
            const {
                api,
                url ,
                apiPayload,
                } = {...action.payload};

            const apiResults = yield call(api , url,apiPayload);
            if(apiResults.status=="SUCCESS"){
                let clientPayload = apiResults.payload;
                if(clientPayload.Status=='SUCCESS'){
                   message.success(clientPayload.Message);
                }else{
                  message.error(clientPayload.Message);
                }
              }
              yield put({
                type:LOADING_ACTION,
                payload:false
            })
           }catch(error){
            yield put({
              type:LOADING_ACTION,
              payload:false
            })
                console.error("Error in updating agent colors",error);
            }  
        }
     
     function* watchUpdateAgentColorSaga () {
        yield takeLatest(UPDATE_AGENT_COLOR,updateAgentColor)
     }

     export function*  watchAgentColorDetailsPage() {
        yield all([
            fork(watchGetAgentColorSaga),
            fork(watchCreateAgentColorSaga),
            fork(watchUpdateAgentColorSaga),
        ])
    }