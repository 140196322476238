/**
 * @author Rejo Varghese
 * @description Reducer for the components in demand days
 */
import { SET_DRIVER_MANAGEMENT_BY_OPERATOR, SET_ALL_ADMIN_DRIVER, RESET_ALL } from '../actions/action-types';

const initialState = {
  adminDriver: null,
  driverByOperator: null
  // demandDaysData: null
}
  
export const adminDriverReducer = (state = initialState,action) => {
  let newState = Object.assign({},state);
  switch (action.type) {
    case SET_DRIVER_MANAGEMENT_BY_OPERATOR:
      let driverByOperator = action.payload;
      newState["driverByOperator"] = driverByOperator;
      return newState;

    case SET_ALL_ADMIN_DRIVER:
      let adminDriver = action.payload;
      newState["adminDriver"] = adminDriver;
      return newState;
    // case SET_DEMAND_DAYS:
    //   let demandDaysData = action.payload;
    //   newState['demandDaysData'] = demandDaysData;
    //   return newState;

    // case CLEAR_DEMAND_DAYS:
    //   newState['demandDaysData'] = null;
    //   return newState;
      
    case RESET_ALL :
      newState["adminDriver"] = null;
      // newState['demandDaysData'] = null;
      return newState;
      
    default:
      return state;
  }
}
  