/**
 * All sagas for Address Saga must be defined here
 * @author Manne Hareesh
 */

import { takeLatest,put,call,all,fork } from 'redux-saga/effects'
import { 
    CREATE_USER_PRIVILLEGE ,
    DELETE_USER_PRIVILLEGE ,
    UPDATE_USER_PRIVILLEGE ,
    SET_PRIVILLEGE_INFO,
    GET_USER_PRIVILLEGE,
    LOADING_ACTION
} from '../actions/action-types';
import { message } from 'antd';

/**
 *
 * @param {Get privileges Details} action
 */
export function* getPrivilleges(action) {
    try{
        const {
            api,
            url
        } = {...action.payload};
        const apiResult = yield call(api,url);
        if(apiResult.status=="SUCCESS"){
          let clientPayload = apiResult.payload;
          if(clientPayload.Status=='SUCCESS'){
            let serverPayload = clientPayload.Payload
            yield put({
                type: SET_PRIVILLEGE_INFO,
                payload: serverPayload
            });
            message.success(clientPayload.Message);
          }else{
            message.error(clientPayload.Message);
          }
        }

    }
    catch(error){
        console.error("Error in getting priviliges",error);
    }
}


export function* createPrivillege(action) {
    try{
      yield put({
        type: LOADING_ACTION,
        payload: true
    });
        const {
            api,
            url,
            apiPayload,
            onSuccess
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload = apiResult.payload;
          if(clientPayload.Status=='SUCCESS'){
            message.success(clientPayload.Message);
            onSuccess();
          }else{
            message.error(clientPayload.Message);
          }
        }
        yield put({
          type: LOADING_ACTION,
          payload: false
      });
    }
    catch(error){
      yield put({
        type: LOADING_ACTION,
        payload: false
    });
        console.error("Error in create privillege",error);
    }
}


export function* updatePrivillege(action) {
    try{
      yield put({
        type: LOADING_ACTION,
        payload: true
    });
        const {
            api,
            url,
            apiPayload,
            onSuccess
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload = apiResult.payload;
          if(clientPayload.Status=='SUCCESS'){
            message.success(clientPayload.Message);
            onSuccess();
          }else{
            message.error(clientPayload.Message);
          }
        }
        yield put({
          type: LOADING_ACTION,
          payload: false
      });
    }
    catch(error){
          yield put({
            type: LOADING_ACTION,
            payload: false
        });
        console.error("Error in update privillege",error);
    }
}


export function* deletePrivillege(action) {
    try{
      yield put({
        type: LOADING_ACTION,
        payload: true
    });
        const {
            api,
            url,
            apiPayload,
            onSuccess
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload = apiResult.payload;
          if(clientPayload.Status=='SUCCESS'){
            message.success(clientPayload.Message);
            onSuccess();
          }else{
            message.error(clientPayload.Message);
          }
        }
        yield put({
          type: LOADING_ACTION,
          payload: false
      });
    }
    catch(error){
      yield put({
        type: LOADING_ACTION,
        payload: false
    });
        console.error("Error in delete privillege",error);
    }
}


function* watchGetUserPrivillegeSaga (){
    yield takeLatest(GET_USER_PRIVILLEGE,getPrivilleges);
}


function* watchCreatePrivillegeSaga () {
    yield takeLatest(CREATE_USER_PRIVILLEGE,createPrivillege)
}

function* watchDeletePrivillegeSaga () {
    yield takeLatest(DELETE_USER_PRIVILLEGE,deletePrivillege)
}

function* watchUpdatePrivillegeSaga(){
    yield takeLatest(UPDATE_USER_PRIVILLEGE,updatePrivillege)
}

export function*  watchPrivillegePage() {
    yield all([
        fork(watchGetUserPrivillegeSaga),
        fork(watchUpdatePrivillegeSaga),
        fork(watchDeletePrivillegeSaga),
        fork(watchCreatePrivillegeSaga)
    ])
}
