import React  from 'react';
import {Table} from 'antd';
import { isValid, isValidArray } from '../../../utils/utilities';


export const ScheduleGroupTable = (props) => {


    const {
        scheduleGroup,
        loading,
        formData,
        setFormData
    } = props;

    const columns = [
        {
          title: 'Name',
          dataIndex: 'description',
          key: 'id',
          align:'center',
        }
      ];

    return (
        <div>
             <Table
                    dataSource={scheduleGroup}
                    pagination={{ pageSize: 4 }}
                    loading={loading}
                    columns={columns}
                    onRow={(record, rowIndex) => {
                        return {
                          onClick: event => {
                            console.log({record});
                            if(record.id != formData.id){
                              if(isValid(record.schedule_ids)&&!isValidArray(record.schedule_ids)){
                                record.schedule_ids=record.schedule_ids.split('"').join('');
                                record.schedule_ids=JSON.parse(record.schedule_ids);
                              }
                            setFormData(record);
                            }
                          }, // click row
                          onDoubleClick: event => {
              
                          }, // double click row
                          onContextMenu: event => {
              
                          }, // right button click row
                          onMouseEnter: event => {
              
                          }, // mouse enter row
                          onMouseLeave: event => {
              
                          }, // mouse leave row
                        };
                      }}
                      rowClassName={(record, index)=>{
                        let rowClassName="";
                            if(record.id== formData.id){
                                rowClassName=rowClassName+" TableRowClicked"
                            }
                            
                            return  rowClassName
                      }}
                 
                    />
        </div>
    );
}
