import { Typography , Row , Col, Button  , Layout} from 'antd';
import { RouteTable } from './routes-table';
// import { CitiesTimeLine } from './cities-time-line';
import { CitiesForm } from './city-form';
import { useDispatch } from 'react-redux';
import { addNewRoute , updateRoutes } from '../../actions/trip-actions';
import React, { useState , useEffect } from 'react';
import { getAddedCities } from './trip-component.module';
import { getRoutesByTripId } from './../../actions/trip-actions';

const {Text ,Title} = Typography;


export const RouteStep = (props) => {

  let {
    citiesData,
    selectedTrip,
    routes,
    routesLoading
  } = {...props};

  const {
    id,
    fare,
  }=selectedTrip;


const dispatch = useDispatch();
const [editedRoutes,setEditedRoutes] = useState([]);
const [isEditFareEnabled , setIsEditFareEnabled] = useState(false);

useEffect(()=>{
  setEditedRoutes(routes);
},[routes])


const checkAndRoute=(route)=>{
  dispatch(addNewRoute(route.city,route.time,route.isOrigin,route.isDestination,route.originDay));
}

  return (
    <Layout>
        <Row type='flex'>
              <Col span={24} >
                <Row type='flex' >
                    <Col  span={4}  justify="center" align="middle" className="step-two-city"> <Text>City </Text></Col>
                    <Col  span={4}  justify="center" align="middle" className="step-two-time"> <Text>Time</Text></Col>
                    <Col  className="step-two-origin"> <Text>Origin</Text></Col>
                    <Col  className="step-two-destination"> <Text>Destination</Text></Col>
                    <Col  className="step-two-next-day"> <Text>Day</Text></Col>
                </Row>
                <CitiesForm 
                formAddedCities={getAddedCities(routes)}
                tripId={id}
                tripFare={fare}
                checkAndRoute={checkAndRoute}
                oldRoutes={routes}
                selectedTrip={selectedTrip}
                citiesData={citiesData}/>
              </Col>
        </Row>
        <Row>
              {/* <Col span={12}>
                <CitiesTimeLine 
                 routes={routes}
                />
              </Col> */}
              <Col>
                <Title
                level={5}
                style={{margin:'10px'}}
                >Routes</Title>
                <RouteTable 
                dataSource={editedRoutes} 
                tripId={id}              
                showFare={true}
                isRemoveRouteEnable={false}
                showDuration={true}
                isEditFareEnabled= {isEditFareEnabled}
                setEditedRoutes={setEditedRoutes}
                editedRoutes={editedRoutes}
                showDeleteRoute={true}
                routesLoading={routesLoading}
                isUpdateRoutesAction={true}
                showNextDay={true}
                selectedTrip={selectedTrip}
                />
                <Row justify="end" style={{padding:'10px'}}>
                  <Col >
                      <Button
                      style={{margin:'10px'}}
                      onClick={()=>{
                        setIsEditFareEnabled(!isEditFareEnabled);
                      }}
                      >{isEditFareEnabled?"Cancel":"Edit"}</Button>
                      {isEditFareEnabled?
                      <Button
                      type="primary"
                      onClick={()=>{
                       dispatch(updateRoutes(editedRoutes,()=>{
                        dispatch(getRoutesByTripId(id));
                       }));
                      }}
                      >Update</Button>
                        :
                        null
                      }
                  </Col>
               </Row>
              </Col>
          </Row>
    </Layout>
  );
};