import React  from 'react';
import "../assets/styles/main.scss";

import { Typography, Divider } from 'antd';
import { VerifyPagePermission } from '../components/verify-page-permission-component/verify-page-permission.component';
import Permissions from "../utils/permissions-constant";
import DemandDaysRoot from '../components/demand-days-component/demand-days-root-component/demand-days-root-component';

const { Title } = Typography;

export const DemandDaysPage = (props) => {

    const renderChildren =     <div style={{position:'relative',top:'10px' , margin: '20px'}} className= "page-root">
                                    <Title level={4}>Demand Days</Title>
                                    <div className= "title-underline" />
                                    <Divider style= {{borderColor: '#817D7D'}}/>
                                    <DemandDaysRoot />
                                </div>

    return (
        <VerifyPagePermission 
        pagePermission={Permissions.pagePermission.ACTION_TO_VIEW_DEMAND_DAYS}
        renderChildren={renderChildren}
        onPermissionSuccess={()=>{
  
        }}
        />
    );
}
