/**
 * @description All the sagas must be imported and combined here
 */

 import { all, fork } from 'redux-saga/effects'
 import { watchLoginPage } from "./login-saga";
 import { watchLocationsDetailsPage } from "./location.saga";
 import { watchAddressDetailsPage } from "./address.saga";
 import { watchBusesDetailsPage } from './bus.saga';
 import { watchTripsDetailsPage } from './trip.saga';
 import { watchAgentsDetailsPage } from './agent.saga';
 import { watchAgentColorDetailsPage } from './agent-color.saga';
 import { watchSchedulesPage } from './schedules.saga';
 import { watchVechiclesDetailsPage } from './vechicles.saga';
 import { watchGstDetailsPage } from './gst.saga';
 import { watchPriceListsDetailsPage } from './price-list.saga';
 import { watchPrivillegePage } from './user-privillege.saga';
 import { watchHomePage } from './home.saga';
 import { watchBusOperatorsPage } from './bus-operators.saga';
 import { watchSeatGroupSagas } from './seat-group.saga';
 import { watchGetTravelManagementsPage } from './travel-management.saga';
 import { watchCancellationPolicyDetailsPage } from './cancellation-policy-saga';
import { watchDemandDaysSagas } from './demand-days.saga';
import { watchAdminDriverManagementSagas } from './admin-driver.saga';
 
 
 export function* rootSaga() {
   yield all([
     fork(watchLoginPage),
     fork(watchLocationsDetailsPage),
     fork(watchAddressDetailsPage) ,
     fork(watchBusesDetailsPage) ,
     fork(watchTripsDetailsPage),
     fork(watchAgentsDetailsPage),
     fork(watchAgentColorDetailsPage),
     fork(watchSchedulesPage),
     fork(watchVechiclesDetailsPage),
     fork(watchGstDetailsPage),
     fork(watchPriceListsDetailsPage),
     fork(watchPrivillegePage),
     fork(watchHomePage),
     fork(watchBusOperatorsPage),
     fork(watchSeatGroupSagas),
     fork(watchGetTravelManagementsPage),
     fork(watchCancellationPolicyDetailsPage),
     fork(watchDemandDaysSagas),
     fork(watchAdminDriverManagementSagas),
   ])
 }
 