/**
 * All sagas for Address Saga must be defined here
 * @author Manne Hareesh
 */

import { takeLatest,put,call,all,fork } from 'redux-saga/effects'
import { 
    GET_GST,
    UPDATE_GST,
    SET_GST,
    LOADING_ACTION
} from '../actions/action-types';
import { SUCCESS_STATUS } from '../utils/constant';
import { pageRedirect } from '../utils/utilities';
import { message } from 'antd';

/**
 *
 * @param {Get Bus Details} action
 */
export function* getGstsDetails(action) {
    try{
        const {
            api,
            url
        } = {...action.payload};
        const apiResult = yield call(api,url);
        if(apiResult.status=="SUCCESS"){
          let clientPayload = apiResult.payload;
          if(clientPayload.Status=='SUCCESS'){
            let serverPayload = clientPayload.Payload
            message.success(clientPayload.Message);
            yield put({
                type: SET_GST,
                payload: serverPayload.GST
            });
          }else{
            message.error(clientPayload.Message);
          }
        }

    }
    catch(error){
        console.error("Error in getting gst",error);
    }
}


export function* updateGstDetails(action) {
    try{
        yield put({
            type: LOADING_ACTION,
            payload: true
        });
        const {
            api,
            url,
            apiPayload
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload = apiResult.payload;
          if(clientPayload.Status=='SUCCESS'){
            message.success(clientPayload.Message);
          }else{
            message.error(clientPayload.Message);
          }
        }
        yield put({
            type: LOADING_ACTION,
            payload: false
        });
    }
    catch(error){
        yield put({
            type: LOADING_ACTION,
            payload: false
        });
        console.error("Error in updating gst",error);
    }
}



function* watchGetGstsDetails () {
    yield takeLatest(GET_GST,getGstsDetails)
}

function* watchUpdateGstDetails () {
    yield takeLatest(UPDATE_GST,updateGstDetails)
}

export function*  watchGstDetailsPage() {
    yield all([
        fork(watchGetGstsDetails),
        fork(watchUpdateGstDetails)
    ])
}
