import {
    GET_SCHDULES_BY_DATE_FOR_PRICE_LIST_URL,
    GET_SCHEDULE_GROUP_URL,
    CREATE_SCHEDULE_GROUP_URL,
    DELETE_SCHEDULE_GROUP_URL,
    UPDATE_SCHEDULE_GROUP_URL
} from '../utils/api-url';
import {
    GET_SCHDULES_BY_DATE_FOR_PRICE_LIST,
    GET_SCHEDULE_GROUP,
    CREATE_SCHEDULE_GROUP,
    DELETE_SCHEDULE_GROUP,
    UPDATE_SCHEDULE_GROUP
} from './action-types';
import { getCall, postCall } from "../utils/network.util";


export const createSchedulesGroup=({description,scheduleIds},onSuccess)=>{
    return {
        type: CREATE_SCHEDULE_GROUP,
        payload: {
            url: CREATE_SCHEDULE_GROUP_URL,
            api:postCall,
            apiPayload:{
                description,
                scheduleIds,
                operatorCode:global.operator_code
            },
            onSuccess:onSuccess
        }
    }
}

export const updateSchedulesGroup=( {groupId,
    scheduleIds,
    description},onSuccess)=>{
    return {
        type: UPDATE_SCHEDULE_GROUP,
        payload: {
            url: UPDATE_SCHEDULE_GROUP_URL,
            api:postCall,
            apiPayload:{
                groupId,
                scheduleIds,
                description,
                operatorCode:global.operator_code
            },
            onSuccess:onSuccess
        }
    }
}

export const deleteSchedulesGroup=( groupId,onSuccess)=>{
    return {
        type: DELETE_SCHEDULE_GROUP,
        payload: {
            url: DELETE_SCHEDULE_GROUP_URL,
            api:postCall,
            apiPayload:{
                groupId,
                operatorCode:global.operator_code
            },
            onSuccess:onSuccess
        }
    }
}

export const getPriceListForDate= (date) => {
    return {
        type: GET_SCHDULES_BY_DATE_FOR_PRICE_LIST,
        payload: {
            url: GET_SCHDULES_BY_DATE_FOR_PRICE_LIST_URL,
            api: postCall,
            apiPayload:{
                travelsId:global.operator_id,
                date:date,
                noofdays:2,
                travels_id:global.operator_id
            }
        }
    }
}

export const getSchedulesGroup=()=>{
    return{
        type:GET_SCHEDULE_GROUP,
        payload:{
            url:GET_SCHEDULE_GROUP_URL+"?operatorCode="+global.operator_code,
            api:getCall,
            apiPayload:{
                operatorCode:global.operator_code
            }    
        }
    }
}