import { 
    GET_BUS_OPERATORS ,
    INSERT_BUS_OPERATOR,
    DELETE_BUS_OPERATOR,
    UPDATE_BUS_OPERATOR,
    CREATE_OPERATOR_MAPPING
} from './action-types';
import { 
    GET_BUS_OPERATORS_URL ,
    INSERT_BUS_OPERATOR_URL,
    DELETE_BUS_OPERATOR_URL,
    UPDATE_BUS_OPERATOR_URL,
    CREATE_OPERATOR_MAPPING_URL
  } from "../utils/api-url";
import { postCall } from "../utils/network.util";
import { APP_ENV } from './../app.config';

export const getBusOperators= () => {
    return {
        type: GET_BUS_OPERATORS,
        payload: {
            url: GET_BUS_OPERATORS_URL,
            api: postCall,
            apiPayload:{
                operatorCode:global.operator_code
            }
        }
    }
}

export const createBusOperator= (travelInfo,onSuccess) => {
    return {
        type: INSERT_BUS_OPERATOR,
        payload: {
            url: INSERT_BUS_OPERATOR_URL,
            api: postCall,
            apiPayload:{
                travelInfo:{
                    ...travelInfo,
                    milesPercent:0,
                    accessCode:'',
                    userName:travelInfo.name,
                    encryptedPassword:null
                }
            },
            onSuccess:onSuccess
        }
    }
}

export const updateBusOperator= (travelInfo,travelId,onSuccess) => {
    return {
        type:UPDATE_BUS_OPERATOR,
        payload: {
            url: UPDATE_BUS_OPERATOR_URL,
            api: postCall,
            apiPayload:{
                travelInfo:{
                    ...travelInfo,
                    milesPercent:0,
                    accessCode:'',
                    userName:travelInfo.name,
                    encryptedPassword:null
                },
                travelId:travelId
            },
            onSuccess:onSuccess
        }
    }
}

export const deleteBusOperator= (travelsId,onSuccess) => {
    return {
        type: DELETE_BUS_OPERATOR,
        payload: {
            url: DELETE_BUS_OPERATOR_URL,
            api: postCall,
            apiPayload:{
                operatorCode:global.operator_code,
                travelsId:travelsId
            },
            onSuccess:onSuccess
        }
    }
}

export const createOperatorMappingAction = (operatorCode) => {
    return {
        type: CREATE_OPERATOR_MAPPING,
        payload: {
            url: CREATE_OPERATOR_MAPPING_URL,
            api: postCall,
            apiPayload: {
                operatorCode: operatorCode,
                operatorEnv: APP_ENV
            }
        }
    }
}
