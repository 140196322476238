import { Typography , Row , Table,Popover  } from 'antd';
import React from 'react';
import { isValid } from '../../../utils/utilities';
import { getFormattedTime } from '../../../utils/dates-util';

const {Text} = Typography;

export const BoardingPointTable = (props) => {

  const {
    boardingPoints,
    isRowClickable,
    boardingFormData , 
    setBoardingFormData,
    boardingPointsLoading
  } = props;
  
const columns = [
  {
    title: 'Boarding Point',
    dataIndex: 'location',
    key: 'location',
    render:(text,record)=>{
      if(isValid(record.landmark)){
        let content=()=>(
          <div>
              {record.landmark}
          </div>
        )
        return (
          <Popover content={content} title="Land mark">
            <div>{text}</div>
          </Popover>
        )
      }else{
        return <div>{text}</div>
      }
    }
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city' 
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    width:200
  },
  // {
  //   title: 'Land mark',
  //   dataIndex: 'landmark',
  //   key: 'landmark',
  //   width:200
  // },
  {
    title: 'Phone no',
    dataIndex: 'phone_number',
    key: 'phone_number'
  },
  {
    title: 'Day',
    dataIndex: 'route_origin_day',
    key: 'route_origin_day',
    render:(text,record)=>{
      let day = record.route_origin_day + record.origin_day;
      return(
        <div style={{width:'60px'}}>
          Day {day+1}
        </div>
      )
    }
  },
  // {
  //   title: 'Next Day',
  //   dataIndex: 'origin_day',
  //   key: 'origin_day',
  //   render:(text)=>(
  //     <span>
  //       {text?
  //           "yes"
  //           :"no"
  //       }
  //     </span>
  //   )
  // },
  {
    title: 'Boarding time',
    dataIndex: 'timing',
    key: 'timing',
    render:(text,record) =>
    (
         <a>
             {getFormattedTime(text)}
         </a>
    )
  },
  {
    title: 'Trip close point',
    dataIndex: 'trip_close_point',
    key: 'trip_close_point',
    render: (text) => (
      <span>
        {text?
            "yes"
            :"no"
        }
      </span>
    )
  },
  {
    title: 'Route close point',
    dataIndex: 'route_close_point',
    key: 'route_close_point',
    render: (text) => (
      <span>
        {text?
            "yes"
            :"no"
        }      
        </span>
    )
  }
];


  return (
    <Row type='flex' justify="center" align="middle">
        <Table 
        pagination={false}
        style={{margin:'10px'}}
        dataSource={boardingPoints} 
        columns={columns} 
        loading={boardingPointsLoading}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              if(isValid(isRowClickable)&&isRowClickable){
                setBoardingFormData(record);
              }
            }, // click row
            onDoubleClick: event => {

            }, // double click row
            onContextMenu: event => {

            }, // right button click row
            onMouseEnter: event => {

            }, // mouse enter row
            onMouseLeave: event => {

            }, // mouse leave row
          };
        }}
        rowClassName={(record, index)=>{
          let rowClassName="";
              if(record.id== boardingFormData.id){
                  rowClassName=rowClassName+" TableRowClicked"
              }
              
              return  rowClassName
      }}
        />
    </Row>
  );
};