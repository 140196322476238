/**
 * @author Sanjay
 * @description Reducer for the components in home page
 */
import {
    SET_VECHICLES,
    RESET_ALL  } from '../actions/action-types';
  
  const initialState = {
      vechicles: []
  }
  
  export const vechiclesReducer = (state = initialState,action) => {
      let newState = Object.assign({},state);
      switch (action.type) {
  
          case SET_VECHICLES:
              newState["vechicles"] = action.payload;
              return newState;

        case RESET_ALL :
              newState["vechicles"] = [];
              return newState;
  
          default:
              return state;
      }
  }
  