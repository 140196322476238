import React , { useEffect , useState} from 'react';
import { useDispatch , useSelector } from 'react-redux';
import { getBusOperators } from  '../../../actions/bus-operator-actions';
import { busOperatorReducerSelector} from '../../../selectors/bus-operators-selector';
import { BusOperatorsTable } from '../bus-operators-table/bus-operators-table.component';
import { BusOperatorForm } from '../bus-operator-form/bus-operator-form.component';
import { isValid, isValidArray } from './../../../utils/utilities';
import { IntegrationEmailComponent } from '../integration-component/integration.component';

export const BusOperatorsRoot = (props) => {

    const [formData,updateformData]=useState({});
    const [operatorList, setOperatorList] = useState([]);
    const [integrationModalOpen, setIntegrationModalOpen] = useState(false);

    const dispatch = useDispatch();

    const {
        busOperators,
        isFetchingBusOperators,
        isAddBusOperatorRunning,
        isDeleteBusOperatorRunning,
        isUpdateBusOperatorRunning
    } = useSelector(busOperatorReducerSelector)

    useEffect(()=>{
       dispatch(getBusOperators());
    },[]);

    useEffect(() => {
        let tempOperators = [];
        if(isValidArray(busOperators)&&busOperators.length>0){
            busOperators.map((operator) => {
                if (isValid(operator.cancellationOperator)&&isValid(operator.name)) {
                    tempOperators.push({'key':operator.cancellationOperator, 'value': operator.name})
                }
            })
        }
        setOperatorList(tempOperators);
    },[busOperators])

    return (
        <div>
            <BusOperatorsTable 
                dataSource={busOperators}
                loading={isFetchingBusOperators}
                updateformData={updateformData}
                selectedData={formData}
            />
            <BusOperatorForm 
                formData={formData}
                updateformData={updateformData}
                addLoading={isAddBusOperatorRunning}
                updateLoading={isUpdateBusOperatorRunning}
                deleteLoading={isDeleteBusOperatorRunning}
                operatorList = {operatorList}
                setIntegrationModalOpen = {setIntegrationModalOpen}
            />
            <IntegrationEmailComponent 
                selectedData={formData}
                integrationModalOpen={integrationModalOpen}
                setIntegrationModalOpen={setIntegrationModalOpen}
            />
        </div>
    );
}
