import React , {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Row, Form , Radio , Typography} from 'antd';

const { Title } = Typography;

export const BeforeScreenOne = (props) => {

const {
    beforScreenOneForm   
} = props;

  return (
      <div>
            <Row>
                <Title level={4}>Do you want to clear existing day specific timings?</Title>
            </Row>
            <div>
            <Form
                    initialValues={{}}
                    name="basic"
                    form={beforScreenOneForm}
                    >
                        <Form.Item    
                        name="clearExistingDaySpecificTimings"
                        rules={[{ required: true ,  message:"Please input option"}]}
                        label="">
                            <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
            </Form>
            </div>
      </div>
  );
};
