import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { mountApp } from './app/app'; 
import { getStore } from './app/store/store';
import { combinedReducer } from './app/reducers/combine-reducer';
import { rootSaga } from './app/sagas/root-saga';
import createSagaMiddleware from 'redux-saga';

const initialState = {};
const sagaMiddleware = createSagaMiddleware();
const store = getStore(combinedReducer,initialState,sagaMiddleware);
sagaMiddleware.run(rootSaga);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {mountApp(store)}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
