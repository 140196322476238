import { 
    GET_SCHEDULES_BY_DATE_URL ,
    REFRESH_CACHE_URL
  } from "../utils/api-url";
import {
    GET_SCHEDULES_BY_DATE,
    REFRESH_CACHE
   } from "./action-types";
import { postCall } from "../utils/network.util";

export const getSchedulesByDate= (date,noofdays) => {
    return {
        type: GET_SCHEDULES_BY_DATE,
        payload: {
            url: GET_SCHEDULES_BY_DATE_URL,
            api: postCall,
            apiPayload:{
                travelsId:global.operator_id,
                date:date,
                noofdays:noofdays
            }
        }
    }
}

export const refreshCache= () => {
    return {
        type: REFRESH_CACHE,
        payload: {
            url: REFRESH_CACHE_URL,
            api: postCall,
            apiPayload:{
                operator_code:global.operator_code
            }
        }
    }
}