import React from 'react';
import { Pie , measureTextWidth} from '@ant-design/plots';
import { isValidNumber  } from '../../utils/utilities';

export const DonutChartComponent = (props) => {

    const {
        Admin_Blocked,
        Blocked,
        Booked,
        Vacant,
        basefare,
        sleeperFare
    }=props;
    let data=[] ;
    
    if(isValidNumber(Admin_Blocked)&&Admin_Blocked>0){
      data.push({
        type: 'Admin Blocked',
        value: Admin_Blocked,
        color : '#479FF8'
      })
    }
    if(isValidNumber(Booked)&&Booked>0){
      data.push({
        type: 'Booked',
        value: Booked,
        color : '#81D653'
      })
    }
    if(isValidNumber(Blocked)&&Blocked>0){
      data.push({
        type: 'Blocked',
        value: Blocked ,
        color : '#EFBD40'
      })
    }
    if(isValidNumber(Vacant)&&Vacant>0){
      data.push({
        type: 'Vacant',
        value: Vacant,
        color : '#EA4025'
      })
    }

    function renderStatistic(containerWidth, text, style) {
      const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
      const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2
  
      let scale = 1;
  
      if (containerWidth < textWidth) {
        scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
      }
  
      const textStyleStr = `width:${containerWidth}px;`;
      return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
    }
    const config = {
      appendPadding: 10,
      data,
      angleField: 'value',
      radius: 1,
      innerRadius: 0.64,
      tooltip: {
        customContent: (title, data) => {
          return `<div style="padding:10px">Fare : ${basefare}</div>`;
        }
      },
      label: {
        type: 'inner',
        offset: '-50%',
        style: {
          textAlign: 'center',
        },
        autoRotate: false,
        content: '{value}',
      },
      statistic: {
        title: {
          // offsetY: 4,
          customHtml: (container, view, datum) => {
            const { width, height } = container.getBoundingClientRect();
            const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
            const text = datum ? datum.type : 'Vacant';
            return text
          },
        },
        content: {
          // offsetY: 4,
          style: {
            fontSize: '15px',
          },
          customHtml: (container, view, datum, data) => {
            const { width } = container.getBoundingClientRect();
            // const text = datum ? `${datum.value}` : `${data.reduce((r, d) => r + d.value, 0)}`;
            return renderStatistic(width, Vacant, {
              fontSize: 10,
            });
          },
        },
      },
      legend:false,
      colorField:'color',
      interactions: [
        {
          type: 'element-selected',
        },
        {
          type: 'element-active',
        }
        // {
        //   type: 'pie-statistic-active',
        // },
      ],
      pieStyle:({color})=>{
          return {fill:color}
      }
    };
    return (
        <div style={{width:200,height:150}}>
          <Pie {...config} />
        </div>
    );
}
