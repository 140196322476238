import React , {  useState} from 'react';
import { Row , Typography   , Button , Col , Table , Modal ,Tooltip , InputNumber , message  } from 'antd';
import { API_BASE_URL} from '../../../app.config';
import{  ConvertDateformate } from '../../../utils/dates-util';
import './edit-fare.style.scss';
import {
    ArrowDownOutlined
   } from '@ant-design/icons';
import { isValidArray, isValidString , commaseperatedStringToArray, isValid} from '../../../utils/utilities';
import { DatesSelector } from './../dates-selector/dates-selector.component';


const { Title } = Typography;
const { Text } = Typography;

export const EditFareDetailsPopUp = (props) => {

    const {
        record,
        scheduleId,
        selectedDate,
        viaCitiesDetails,
        setViaCitiesDetails
    } = props;

    const [ visible , setVisible] = useState(false);

    const  [ selectedDays , setSelectedDays] = useState([]);
    const  [ isUpdateSeatGroupIds , setIsUpdateSeatGroupIds] = useState(false);

    const {
        bus 
    }=record;

    const allowToChangeSeatStatus=()=>{
      setIsUpdateSeatGroupIds(true);
    }

    const showModal = () => {
        setVisible(true)
     };

    const handleOk = async (e) => {
        setVisible(false)
    };

    const handleCancel = e => {
        setVisible(false);
    };

      const copy_Fare_to_all_routes = ()=>{
        for(var i=0;i<viaCitiesDetails.length;i++){
            viaCitiesDetails[i]["cgst"]=viaCitiesDetails[0]["cgst"];
            viaCitiesDetails[i]["sgst"]=viaCitiesDetails[0]["sgst"];
            viaCitiesDetails[i]["totalfare"]=viaCitiesDetails[0]["totalfare"];
            viaCitiesDetails[i]["fare"]=viaCitiesDetails[0]["fare"];
        }
        setViaCitiesDetails([...viaCitiesDetails]);
      }

      const copy_Fare_Json_Fare=(key)=>{
        if(isValid(key)){
            let fare=null;
            if(isValidArray(viaCitiesDetails)&&viaCitiesDetails.length>0){
              let fareJsonObject=JSON.parse(viaCitiesDetails[0].fare_json);
              fare=fareJsonObject[key]; 
            }
            for(var i=0;i<viaCitiesDetails.length;i++){
              let fareJsonObject=JSON.parse(viaCitiesDetails[i].fare_json);
              if(!isValidArray(fareJsonObject)){
                fareJsonObject={}
              }
              fareJsonObject[key]=fare;
              viaCitiesDetails[i]["fare_json"]=JSON.stringify(fareJsonObject);
          }
          setViaCitiesDetails([...viaCitiesDetails]); 
          allowToChangeSeatStatus();
        }
      }


    const renderComponentTable=({text,dataIndex,index,fareJsonKey})=>{
      let fareJsonObject={};
      if(isValidString(fareJsonKey)&&isValidString(text)){
        fareJsonObject=JSON.parse(text);
        text=fareJsonObject[fareJsonKey];
      }
        return {
          props: {
            className:'',
          },
         children:  <span>
                        <Row type="flex" justify='space-between' >
                          <Col>
                              <InputNumber value={text}
                                      onChange={(value)=>{
                                        if(isValidString(fareJsonKey)){
                                          fareJsonObject[fareJsonKey]=value;
                                          viaCitiesDetails[index][dataIndex]=JSON.stringify(fareJsonObject);
                                        }else{
                                          viaCitiesDetails[index][dataIndex]=value;
                                        }
                                        setViaCitiesDetails([...viaCitiesDetails]);
                                        allowToChangeSeatStatus();
                                      }}/>
                                      {index==0&&viaCitiesDetails.length>1&&
                                        <Tooltip placement="topLeft" title={"Copy fare of 1st route to all routes"}>
                                        <ArrowDownOutlined 
                                          style={{paddingLeft:10}}
                                          size={'large'}
                                          onClick={()=>{
                                            if(dataIndex=="fare"){
                                                copy_Fare_to_all_routes();
                                            }else{
                                              copy_Fare_Json_Fare(fareJsonKey)
                                            }
                                          }}
                                         type="arrow-down" />
                                         </Tooltip>
                                      }
                          </Col>
                        </Row>
                     </span>,
        };
      }

      
  const tripPopUpColumns = [
    {
      title: 'Origin',
      dataIndex: 'origin',
      key: 'origin',
    },
    {
      title: 'Destination',
      dataIndex: 'destination',
      key: 'destination',
    },
    // {
    //   title: 'Departure',
    //   dataIndex: 'start_time',
    //   key: 'start_time',
    //   width:150,
    //   render: (text,rowIndex) => {
    //       let key = rowIndex.id;
    //       const index = viaCitiesDetails.findIndex(item => key === item.id);
    //       return <TimePicker 
    //       value={moment(text, "h:mm a")}
    //       use12Hours 
    //       format='h:mm A'
    //       popupClassName={"time-picker-to-not-display-footer"}
    //       onSelect={(value)=>{
    //           let selectedDepTime=moment(value, "h:mm a").format("HH:mm:ss");
    //           viaCitiesDetails[index]['start_time']=selectedDepTime;
    //           setViaCitiesDetails([...viaCitiesDetails]);
    //         }}
    //       />
    //   }
    // },
    // {
    //   title: 'Arrival',
    //   dataIndex: 'end_time',
    //   key: 'end_time',
    //   width:150,
    //   render: (text,rowIndex) => {
    //       let key = rowIndex.id;
    //       const index = viaCitiesDetails.findIndex(item => key === item.id);
    //       return <TimePicker 
    //       value={moment(text, "h:mm a")}
    //       use12Hours 
    //       format='h:mm A'
    //       popupClassName={"time-picker-to-not-display-footer"}
    //       onSelect={(value)=>{
    //           let selectedArTime=moment(value, "h:mm a").format("HH:mm:ss");
    //           viaCitiesDetails[index]['end_time']=selectedArTime;
    //           setViaCitiesDetails([...viaCitiesDetails]);
    //         }}
    //       />
    //   }
    // },
/*        {
      title: 'CGST',
      dataIndex: 'fare',
      key: 'fare',
      render: (text,rowIndex ,viewIndex) => (
      <div>{(bus.cgst_percentage*text)/100}</div>
      )
    },
    {
      title: 'SGST',
      dataIndex: 'fare',
      key: 'fare',
      render: (text,rowIndex ,viewIndex) => (
      <div>{(bus.sgst_percentage*text)/100}</div>
      )
    },*/
  ];


  
    if(isValidArray(bus)&&bus.supported_fare === 1){
      tripPopUpColumns.push({
        title: <div>
              <Tooltip placement="topLeft" title={"With Out GST"}>
                  <Text>Fare</Text>
              </Tooltip>
              </div>,
        dataIndex: 'fare',
        key: 'fare',
        width:150,
        render: (text,rowIndex ,viewIndex) => {
          let dataIndex='fare';
          let key = rowIndex.id;
          const index = viaCitiesDetails.findIndex(item => key === item.id);
          return renderComponentTable({text,rowIndex ,viewIndex,dataIndex,index});
          }
      });

      tripPopUpColumns.push({
        title: 'Total Fare',
        dataIndex: 'fare',
        key: 'fare',
        width:150,
        render: (text,rowIndex ,viewIndex) => (
        <div>{((bus.cgst_percentage*text)/100)+((bus.sgst_percentage*text)/100)+text}</div>
        )
      });
  }

  if(isValidArray(bus)&&isValidString(bus.seat_group_ids)){
    let seatsArray=commaseperatedStringToArray(bus.seat_group_ids);
    seatsArray.forEach(fareJsonKey => {
      tripPopUpColumns.push({
        title:fareJsonKey,
        dataIndex:'fare_json',
        key: 'fare_json',
        width:150,
        render: (text,rowIndex ,viewIndex) => {
          let dataIndex='fare_json';
          let key = rowIndex.id;
          const index = viaCitiesDetails.findIndex(item => key === item.id);
          return renderComponentTable({text,rowIndex ,viewIndex,dataIndex,index,fareJsonKey});
          }
      })
    });
  }

  const updateScheduleTripFare=()=>{

          let tripRoutes=[] , formatedSelectedDates=[],rangeDates=[];
          const {bus}={...record};
          selectedDays.forEach((date)=>{
            formatedSelectedDates.push(ConvertDateformate(date));
          })
          var alldates = rangeDates.concat(formatedSelectedDates);
          if(isValidArray(alldates)&&alldates.length<=0){
            message.error("Please select date");
            return false
          }
          let seatsArray = [], supportedFare = true;
          if(isValidArray(bus)&&isValidString(bus.seat_group_ids)){
            seatsArray = commaseperatedStringToArray(bus.seat_group_ids);
          }
          if(isValidArray(bus)&&bus.supported_fare === 0){
            supportedFare = false;
          }
          for(var route_index=0;route_index<viaCitiesDetails.length;route_index++){
                    let newFareJson = null ;
                    let newFare = viaCitiesDetails[route_index].fare;
                    if(seatsArray.length>0 && isValidString(viaCitiesDetails[route_index].fare_json)){
                      newFareJson={};
                      let fareJsonObject = JSON.parse(viaCitiesDetails[route_index].fare_json);
                      seatsArray.forEach((fareJsonKey, index) => {
                        if(isValid(fareJsonObject[fareJsonKey])){
                          newFareJson[fareJsonKey]=fareJsonObject[fareJsonKey];
                        }
                        if (!supportedFare) {
                          if (index==0) {
                            newFare = fareJsonObject[fareJsonKey];
                          }
                          else {
                            if (fareJsonObject[fareJsonKey]<newFare) {
                              newFare = fareJsonObject[fareJsonKey];
                            }
                          }
                        }
                      })
                    }

                    if(isValid(newFareJson)){
                      newFareJson = JSON.stringify(newFareJson);
                    }
                  
                    tripRoutes.push({
                                        route_id : viaCitiesDetails[route_index].id,
                                        fare : newFare,
                                        cgst : (bus.cgst_percentage*viaCitiesDetails[route_index].fare)/100,
                                        sgst :(bus.cgst_percentage*viaCitiesDetails[route_index].fare)/100,
                                        igst :(bus.igst_percentage*viaCitiesDetails[route_index].fare)/100,
                                        trip_route_id : viaCitiesDetails[route_index].trip_route_id,
                                        fare_json : newFareJson,
                                        start_time: viaCitiesDetails[route_index].start_time,
                                        end_time: viaCitiesDetails[route_index].end_time
                                        });
                                      }
                return  fetch(API_BASE_URL + '/api/updateFareForTripRoutes',
                  {method: "POST",headers: {'Content-Type':'application/json'},
                  body: JSON.stringify({"scheduleId" : scheduleId,"tripRoutes" : tripRoutes,"travelDates" : alldates ,"isUpdateSeatGroupIds":isUpdateSeatGroupIds,"userName":global.user_name})}).
                  then(response => response.json()).
                  then(data => {
                    if(data.Status=="SUCCESS"){
                      message.success("Update Fare Successfully");
                      setSelectedDays([]);
                      handleOk();
                      return true;
                    }else{
                      message.error(data.Message);
                      return false;
                    }
                  });
   }

    return (
     <div>
         <Row type='flex' justify='end'>
             <Button type="primary" onClick={showModal} >
                Edit Fare
            </Button>
         </Row>
           
            <Modal
            title={record.description}
            visible={visible}
            onOk={updateScheduleTripFare}
            okText = {'Update'}
            onCancel={handleCancel}
            width	={'85vw'}
            okButtonProps={''}
            >
            <div>
                <Row type="flex"  >
                    <Col>
                          <Title level={4}>Edit Fare</Title>
                    </Col>
                </Row>
                <Row>
                    <Col>
                            <DatesSelector 
                            record={record}
                            scheduleId={scheduleId}
                            setSelectedDays={setSelectedDays}
                            selectedDays={selectedDays}
                            />
                    </Col>
                    <Col>
                    <Table
                          dataSource={viaCitiesDetails}
                          columns={tripPopUpColumns}
                          pagination={{ pageSize: 4 }}
                          />
                    </Col>
                </Row>
            </div>
            </Modal>
      </div>
    );
}
