import React , {useState} from 'react';
import { Button, Modal, Row  , Col , Table} from 'antd';
import { sendNotificationToRedBus } from './red-bus-activation.module';


export const RedBusActivation = (props) => {


    const {
        selectedRoutes
    } = props;


    const [rowKeys,setRowKeys] = useState([]);
    const [selectedRows,setSelectedRows] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const onSuccess=()=>{
        handleOk();
        setSelectedRows([]);
        setRowKeys([])
    }



    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);

    };


    const onSubmit=(e)=>{
        e.preventDefault();
        sendNotificationToRedBus(selectedRows,onSuccess);
    }

    let columns =[
        {
            title: 'Origin',
            dataIndex: 'origin',
            key: 'origin'
        },
        {
            title: 'Destination',
            dataIndex: 'destination',
            key: 'destination'
        }
    ];

    const rowSelection = {
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
         setRowKeys(selectedRowKeys);
         setSelectedRows(selectedRows);
        },
        getCheckboxProps: record => ({
          disabled: record.name === 'Disabled User', // Column configuration not to be checked
          name: record.name,
        }),
      };

  return (
      <div>
            <Row justify="center" align="middle">
                <Button type="primary" onClick={showModal}>
                    Redbus Activate
                </Button>
            </Row>
          <Modal title="Red Bus Alert" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
            <Table
                rowSelection={rowSelection}
                rowKey={record => record.origin+record.destination}
                dataSource={selectedRoutes}
                columns={columns}
                pagination={false}
                />
             <Row gutter={[16,16]} style={{paddingTop:'10px'}} justify="end">
                 <Col>
                    <Button onClick={handleCancel}>Close</Button>
                 </Col>
                 <Col>
                    <Button onClick={onSubmit} type="primary">Submit</Button>
                 </Col>
             </Row>
          </Modal>
      </div>
  );
};