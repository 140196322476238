import {
    SET_AGENT_COLOR,
    RESET_ALL
} from '../actions/action-types'

const initialState = {
    agentColor: null
}

export const agentColorReducer = (state = initialState,action) => {
    let newState = Object.assign({},state);
    switch (action.type) {

        case SET_AGENT_COLOR:
            newState["agentColor"] = action.payload;
            return newState;

      case RESET_ALL :
            newState["agentColor"] = null;
            return newState;

        default:
            return state;
    }
}