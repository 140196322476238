import { Row  , Modal, Button , Radio , Form, Input} from 'antd';
import React , {useState} from 'react';
import { isValid, isValidArray } from '../../../utils/utilities';
import { getUnqiueOriginCities } from './export-boarding-points.module';
import { insertBoardingPointsIntoTemplate } from './../../../actions/trip-actions';
import { useDispatch } from 'react-redux';
import { QWIK_BUS_USER } from './../../../utils/constant';


export const ExportBoardingPoints = (props) => {

  const {
    selectedTrip,
    routes
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const dispatch = useDispatch()

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish=(values)=>{
    dispatch(insertBoardingPointsIntoTemplate(selectedTrip.id,values.templateName, values.city,()=>{
      handleCancel();
    }))
  }

    if(isValidArray(selectedTrip)&&isValid(selectedTrip.id)&&QWIK_BUS_USER===global.name){
      return (
        <Row type='flex' justify="center" align="middle">
            <Button type="primary" onClick={showModal}>
                Export
            </Button>
            <Modal 
            title="Export Boarding Points" 
            visible={isModalVisible} 
            onOk={handleOk} 
            onCancel={handleCancel} 
            footer={null}
            >
            <Form
                // {...layout}
                className="form"
                form={form}
                name="basic"
                initialValues={{}}
                onFinish={onFinish}
                >
                <Form.Item
                  label="Template name"
                  name="templateName"
                  rules={[{ 
                        required: true, 
                        message: 'Please input your template name!'
                     }]}
                  >
                  <Input placeholder="Template name"/>
                  </Form.Item>
                <Form.Item
                  label="City"
                  name="city"
                  rules={[{ 
                        required: true, 
                        message: 'Please input your boarding point!'
                     }]}
                  >
                  <Radio.Group
                      options={getUnqiueOriginCities(routes)}
                    />
                  </Form.Item>
                  <Form.Item>
                          <Button type="primary" htmlType="submit">Export</Button>
                  </Form.Item>
              </Form>
            </Modal>
        </Row>
      );
    }else{
      return null;
    }
};