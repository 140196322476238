import { 
    GET_VECHICLES , 
    UPDATE_VECHICLES ,
    DELETE_VECHICLE,
    CREATE_VECHICLE
} from './action-types';
import { 
    GET_VECHICLES_URL ,
    UPDATE_VECHICLES_URL,
    CREATE_VECHICLE_URL,
    DELETE_VECHICLE_URL
  } from "../utils/api-url";
import { postCall,getCall } from "../utils/network.util";

export const createVechicle = ({  
    bus_id,
    vechicle_no
},onSuccess)=>{
    return {
        type: CREATE_VECHICLE,
        payload: {
            url:CREATE_VECHICLE_URL,
            api: postCall,
            apiPayload:{
                vechicleInfo:{
                    bus_id:bus_id,
                    vechicle_no:vechicle_no
                },
                operator_code:global.operator_code
            },
            onSuccess:onSuccess
        }
    }
}

export const deleteVechicle = (vechicleId,onSuccess)=>{
    return {
        type: DELETE_VECHICLE,
        payload: {
            url:DELETE_VECHICLE_URL,
            api: postCall,
            apiPayload:{
                vechicleId:vechicleId,
                operator_code:global.operator_code
            },
            onSuccess:onSuccess
        }
    }
}

export const getVechicles= () => {
    return {
        type: GET_VECHICLES,
        payload: {
            url:GET_VECHICLES_URL+"?travelsId="+global.operator_id,
            api: getCall,
            apiPayload:{
                operator_code:global.operator_code
            }
        }
    }
}

export const updateVechiclesForSchedule=(scheduleId,vechicleId,phoneNo,onSuccess=()=>{})=>{
    return {
        type:UPDATE_VECHICLES,
        payload:{
            url:UPDATE_VECHICLES_URL,
            api:postCall,
            apiPayload:{
                schedule:{
                    id:scheduleId,
                    phone_no:phoneNo,
                    vechicle_id:vechicleId
                },
                operator_code:global.operator_code
            },
            onSuccess
        }
    }
}