/**
 * Travel management related actions can be created here
 */

 import { GET_TRAVEL_MANAGEMENT_URL , UPDATE_TRAVEL_MANAGEMENT_URL , CREATE_TRAVEL_MANAGEMENT_URL , DELETE_TRAVEL_MANAGEMENT_URL} from "../utils/api-url";
 import { GET_TRAVEL_MANAGEMENT_ACTIONS , UPDATE_TRAVEL_MANAGEMENT_ACTION , CREATE_TRAVEL_MANAGEMENT_ACTION , DELETE_TRAVEL_MANAGEMENT_ACTION} from "./action-types";
 import { postCall } from "../utils/network.util";
 
 export const getTravelManagements = () => {
     return {
         type: GET_TRAVEL_MANAGEMENT_ACTIONS,
         payload: {
             url: GET_TRAVEL_MANAGEMENT_URL,
             api: postCall,
             apiPayload:{
                 operatorCode:global.operator_code,
                 travelsId:global.operator_id
             }
         }
     }
 }
 
export const updateTravelManagement = ({
    travelManagementId,
    phoneNumber,
    name,
    adminServerUrl,
    travelsId,
    operatorCode
},onSuccess) =>{
    return {
        type:UPDATE_TRAVEL_MANAGEMENT_ACTION,
        payload: {
            url : UPDATE_TRAVEL_MANAGEMENT_URL,
            api : postCall,
            apiPayload:{
                travelManagementId:travelManagementId,
                travelsId,
                operatorCode,
                data:{
                    phoneNumber,
                    name,
                    adminServerUrl,
                    travelsId,
                    operatorCode
                }
            },
            onSuccess:onSuccess
        }
    }
}


export const createTravelManagement = ({
    phoneNumber,
    name,
    adminServerUrl,
    travelsId,
    operatorCode
},onSuccess) =>{
    return {
        type:CREATE_TRAVEL_MANAGEMENT_ACTION,
        payload: {
            url : CREATE_TRAVEL_MANAGEMENT_URL,
            api : postCall,
            apiPayload:{
                travelsId,
                operatorCode,
                data:{
                    phoneNumber,
                    name,
                    adminServerUrl,
                    travelsId,
                    operatorCode
                }
            },
            onSuccess:onSuccess
        }
    }
}


export const deleteTravelManagement = ({
    travelManagementId
},onSuccess) =>{
    return {
        type: DELETE_TRAVEL_MANAGEMENT_ACTION,
        payload: {
            url : DELETE_TRAVEL_MANAGEMENT_URL,
            api : postCall,
            apiPayload:{
                operatorCode:global.operator_code,
                travelsId:global.operator_id,
                travelManagementId:travelManagementId
            },
            onSuccess:onSuccess
        }
    }
}